import * as React from 'react';
import { ErrorBanner } from '../../BannerTemplates/ErrorBanner';
import { Button, createStyles, Link, Theme, Typography } from '@material-ui/core';
import {
  DWELO_CARE_PHONE,
  DWELO_CARE_PHONE_HREF
} from '../../../Constants/installerAppConstants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ol: {
      paddingLeft: '20px'
    }
  })
);

type Props = {
  onActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function HubOfflineBanner(props: Props) {
  const classes = useStyles();

  return (
    <ErrorBanner
      title={'The hub is offline.'}
      body={
        <React.Fragment>
          <Typography>Try these steps to get the hub online:</Typography>
          <ol className={classes.ol}>
            <li>
              <Typography>Check power and ethernet connections.</Typography>
            </li>
            <li>
              <Typography>Power cycle the hub.</Typography>
            </li>
            <li>
              <Typography>
                For more help,{' '}
                <Link href="#" target="_blank" rel="noopener noreferrer">
                  see our help site
                </Link>
                .
              </Typography>
            </li>
            <li>
              <Typography>
                Contact Dwelo support at{' '}
                <Link href={DWELO_CARE_PHONE_HREF}>{DWELO_CARE_PHONE}</Link> or 'Get Help'
                below.
              </Typography>
            </li>
          </ol>
        </React.Fragment>
      }
      action={
        <Button variant="contained" color="secondary" onClick={props.onActionClick}>
          OK
        </Button>
      }
    />
  );
}
