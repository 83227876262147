import { DeviceSensor, DoorbellDevice } from '../../../Common/Types/cloudApi';
import { RingDeviceDetails, RingPage } from './types';
import { createMetadataId, splitMetadataId } from '../../../Common/utils/deviceCapabilities';
import {
  ChimeTypeAttr,
  DeviceCapabilitiesModel,
  DoorbellModel,
  RingChimeAttr
} from '../../../Common/Constants/deviceCapabilities';

export function initRingInfo(
  page: RingPage,
  details: Partial<RingDeviceDetails>,
  device?: DoorbellDevice
): RingDeviceDetails {
  // Unfortunately, device capabilities doesn't specify an explicit 'no' option for ring_chime,
  // so 'no' is used if ring_chime has no value while on the edit page.
  const ringChimeFallback = page === RingPage.Edit ? 'no' : '';
  return {
    ringModel:
      (device && getRingModelFromMetadataId(device.metadata_id)) || details.ringModel || '',
    chimeType: device?.device_metadata?.chime_type || details.chimeType || '',
    ringChime: device?.device_metadata?.ring_chime || details.ringChime || ringChimeFallback,
    hasTransformer: details.hasTransformer || false
  };
}

export function getModelDisplayName(model: RingDeviceDetails['ringModel']) {
  switch (model) {
    case 'pro':
      return 'Ring Doorbell Pro';
    case 'wired':
      return 'Ring Doorbell Wired';
    default:
      return 'Ring Doorbell';
  }
}

export function createRingMetadataId(details: {
  ringModel: RingDeviceDetails['ringModel'];
  chimeType?: RingDeviceDetails['chimeType'];
  ringChime?: RingDeviceDetails['ringChime'];
}) {
  // Wired doorbells don't support hardwired chimes
  const chimeType = details.ringModel === 'wired' ? '' : details.chimeType;
  const baseId: DeviceCapabilitiesModel =
    details.ringModel === 'pro' ? DoorbellModel.RingPro : DoorbellModel.RingWired;
  let attributes = '';
  switch (chimeType) {
    case 'digital':
      attributes += ChimeTypeAttr.Digital;
      break;
    case 'mechanical':
      attributes += ChimeTypeAttr.Mechanical;
      break;
    case 'none':
      attributes += ChimeTypeAttr.None;
      break;
  }
  if (details.ringChime === 'yes') {
    attributes += RingChimeAttr.Yes;
  }
  if (attributes) {
    return createMetadataId(baseId, attributes);
  } else {
    return baseId;
  }
}

export function getRingModelFromMetadataId(
  metadata_id: DeviceSensor['metadata_id']
): RingDeviceDetails['ringModel'] {
  const split = splitMetadataId(metadata_id);
  if (!split.base) {
    return '';
  }
  switch (split.base) {
    case DoorbellModel.RingPro:
      return 'pro';
    case DoorbellModel.RingWired:
      return 'wired';
    default:
      return '';
  }
}
