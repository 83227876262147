import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Link,
  Theme,
  createStyles,
  Typography,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getModelDisplayName } from './utils';
import { NumberedStep } from '../../../Common/MiscComponents/NumberedStep';
import { ButtonName, RingDeviceDetails } from './types';
import { DWELO_DOORBELL_HELP_SITE } from '../../../Common/Constants/installerAppConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: '32px 24px 12px'
    },
    dialogActions: {
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '100px'
    },
    divider: {
      marginTop: '24px',
      marginBottom: '24px'
    },
    link: {
      textAlign: 'right',
      fontSize: '14px'
    }
  })
);

interface Props {
  ringInfo: RingDeviceDetails;
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function InstallStepsContent(props: Props) {
  const classes = useStyles();

  const [stepNumbers] = useState(() => {
    let count = 1;
    let transformer, model, chime;
    if (props.ringInfo.hasTransformer) {
      transformer = count++;
    }
    model = count++;
    if (props.ringInfo.ringChime === 'yes') {
      chime = count++;
    }
    return {
      transformer,
      model,
      chime
    };
  });

  const modelDisplayName = getModelDisplayName(props.ringInfo.ringModel);

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          Install a {modelDisplayName}
          {props.ringInfo.ringChime === 'yes' && ' and Ring Chime'}.
        </Typography>
        <Divider className={classes.divider} />
        {props.ringInfo.hasTransformer && (
          <NumberedStep number={stepNumbers.transformer}>
            <Typography>
              Install the transformer.
              <span style={{ float: 'right', paddingLeft: '16px' }}>
                <Link
                  className={classes.link}
                  href={DWELO_DOORBELL_HELP_SITE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instructions
                </Link>
              </span>
            </Typography>
          </NumberedStep>
        )}
        <NumberedStep number={stepNumbers.model}>
          <Typography>
            Install the {modelDisplayName}.{' '}
            <strong>
              If you are wiring through an existing chime, install the{' '}
              {props.ringInfo.ringModel === 'pro' ? 'Ring Pro Power Kit' : 'jumper cable'}{' '}
              while the breaker is off.
            </strong>
          </Typography>
          <Link
            className={classes.link}
            variant="body1"
            style={{ marginTop: '12px' }}
            href={DWELO_DOORBELL_HELP_SITE}
            target="_blank"
            rel="noopener noreferrer"
          >
            Instructions
          </Link>
        </NumberedStep>
        {props.ringInfo.ringChime === 'yes' && (
          <NumberedStep number={stepNumbers.chime}>
            <Typography>
              Leave the Ring Chime plugged into an easily accessible and visible outlet.
            </Typography>
          </NumberedStep>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          name={ButtonName.GoToTroubleshoot}
          onClick={props.handleSubmit}
          variant="outlined"
          color="secondary"
          disabled={false}
          fullWidth
        >
          Troubleshoot
        </Button>
        <Button
          name={ButtonName.StepsDone}
          onClick={props.handleSubmit}
          variant="contained"
          color="secondary"
          disabled={false}
          fullWidth
        >
          Done
        </Button>
      </DialogActions>
    </>
  );
}
