import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import '../../Common/stylesheets/global.scss';
import theme from '../../Common/Themes/login_theme';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%',
    borderBottom: 'none',
    marginTop: '0px',
    marginBottom: '24px'
  },
  resize: {
    fontSize: '20px'
  }
});

class HubInfoList extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
    this.state = {
      unit: this.props.unit,
      devices: this.props.devices
    };
  }

  getDate(date) {
    if (!date || date === 'Loading...') {
      return date;
    }
    let time = new Date(date);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return `${time.toLocaleDateString()} ${time.toLocaleTimeString('en-US')} ${
      timezone ? timezone : 'Unknown Timezone'
    }`;
  }

  convertTimeIssued(date) {
    if (!date || date === 'Loading...') {
      return date;
    } else {
      var today = new Date();
      var time = new Date(date);
      var diffMs = today - time; // milliseconds between now & Christmas
      var diffDays = Math.floor(diffMs / 86400000);
      var returnString = '';
      if (diffDays > 0) {
        return this.getDate(date);
      }
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
      if (diffHrs > 0) {
        returnString += diffHrs;
        if (diffHrs === 1) {
          returnString += ' hour ';
        } else {
          returnString += ' hours ';
        }
      }
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      if (diffMins === 1) {
        return returnString + diffMins + ' minute ago';
      }
      return returnString + diffMins + ' minutes ago';
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <form className={this.classes.container}>
          <TextField
            label="DWELO HUB SERIAL NUMBER"
            defaultValue={this.props.status.hub_serial_number}
            key={this.props.status.hub_serial_number + 'DWELO HUB SERIAL NUMBER'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="DWELO HUB GATEWAY ID"
            defaultValue={this.props.status.gateway_id}
            key={this.props.status.gateway_id + 'DWELO HUB GATEWAY ID'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="COMMAND BROKER"
            value={this.props.status.is_iot_hub ? 'AWS IoT' : 'Twilio Sync'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="BALENA COMMIT HASH"
            defaultValue={this.props.status.balena_commit_hash}
            key={this.props.status.balena_commit_hash + 'BALENA COMMIT HASH'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="BALENA BRANCH"
            defaultValue={this.props.status.balena_branch}
            key={this.props.status.balena_branch + 'BALENA BRANCH'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="BALENA RELEASE GROUP"
            defaultValue={this.props.status.balena_release_group}
            key={this.props.status.balena_release_group + 'BALENA RELEASE GROUP'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="LAST BOOT TIME"
            defaultValue={this.getDate(this.props.status.boot_time)}
            key={this.props.status.boot_time + 'LAST BOOT TIME'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="COMMUNITY INTERNET DELIVERY"
            defaultValue={this.props.status.internet_delivery}
            key={this.props.status.internet_delivery + 'INTERNET DELIVERY'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="ETHERNET IP ADDRESS"
            defaultValue={this.props.status.ethernet_ip_address}
            key={this.props.status.ethernet_ip_address + 'ETHERNET IP ADDRESS'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="CELLULAR IP ADDRESS"
            defaultValue={this.props.status.cellular_ip_address}
            key={this.props.status.cellular_ip_address + 'CELLULAR IP ADDRESS'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="CELLULAR SIGNAL STRENGTH"
            defaultValue={this.props.status.cellular_signal + '%'}
            key={this.props.status.cellular_signal + 'CELLULAR SIGNAL STRENGTH'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="SIM ICCID"
            defaultValue={this.props.status.iccid ? this.props.status.iccid : 'Not Available'}
            key={this.props.status.iccid + 'SIM ICCID'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="LAST HEARTBEAT"
            defaultValue={this.convertTimeIssued(this.props.status.last_seen)}
            key={this.props.status.last_seen + 'LAST SEEN'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
        </form>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(HubInfoList);
