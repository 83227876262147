export function ADDRESS(addressId) {
  return '/v4/installer/address/?addressId=' + addressId;
}

export function COMMUNITY(communityId) {
  return '/v4/installer/community/?communityId=' + communityId;
}

export const ASSOCIATE_ADDRESS = '/v4/address/associate/';

export function GET_WORK_TICKET_DATA(addressId) {
  return '/v4/installer/workticket/?addressId=' + addressId;
}

export function SUBMIT_WORK_TICKET(addressId) {
  return '/v4/installer/workticket/?addressId=' + addressId;
}

export function SENSOR(hub_serial) {
  return '/v4/sensor/hub/' + hub_serial + '/';
}

export function DELETE_DEVICE(gateway_id, serial_id) {
  return `/v3/nest/device/${gateway_id}/${serial_id}/`;
}

export function CREATE_DEVICE(gateway_id, serial_id) {
  return `/v3/nest/device/${gateway_id}/${serial_id}/`;
}

export function LEGACY_DELETE_DEVICE(deviceId) {
  return '/v3/device/' + deviceId + '/';
}
export function LEGACY_EDIT_DEVICE(deviceId) {
  return '/v3/device/' + deviceId + '/';
}
export const LEGACY_CREATE_DEVICE = '/v3/device/';

export function INCLUDE(hub_serial) {
  return 'v4/hub/' + hub_serial + '/inclusion/';
}
export function EXCLUDE(hub_serial) {
  return 'v4/hub/' + hub_serial + '/exclusion/';
}
export function RESET_CONTROLLER(hub_serial) {
  return 'v4/hub/' + hub_serial + '/resetnetwork/';
}
export function REBOOT_HUB(hub_serial) {
  return 'v4/hub/' + hub_serial + '/reboot/';
}
export const CREATE_STREAM = 'v4/integrations/twilio/stream/';

export function HUB_STATUS(hub_serial) {
  return '/v4/hub/' + hub_serial + '/status/';
}
export const REMOVE_HUB = 'v4/address/disassociate/';
export const REQUEST_PERMISSION = 'v4/permission/create/';
export function UPDATE_DEVICE(deviceId) {
  return 'v3/device/' + deviceId + '/';
}
export function UPDATE_STATUS(hub_serial) {
  return '/v4/hub/' + hub_serial + '/softwareupdate/status/';
}
export const TWILIO_TOKEN = '/v4/auth/twilio/token/';

export function COMMUNITY_AND_UNIT_CONFIGURATIONS(communityId, unitId) {
  return `/v4/installer/unit-configurations/?communityId=${communityId}&addressId=${unitId}`;
}

export const PROVISION_HUB3 = '/v4/provision/hub3/';
