import { CodeSyncStatus } from './apiService/types';

// TODO add more to this array, and update the names if needed.
// https://levelhome.atlassian.net/browse/MDU2-45
const statusesInPriorityOrder = [['in_progress'], ['paused']];

export function findActiveCodeSync(codeSyncs: CodeSyncStatus[]) {
  for (const statuses of statusesInPriorityOrder) {
    const codeSync = codeSyncs.find((codeSync) => statuses.includes(codeSync.status));
    if (codeSync) {
      return codeSync;
    }
  }
  return null;
}

export function isCodeSyncActive(codeSync: CodeSyncStatus | null) {
  if (!codeSync) {
    return false;
  }
  return statusesInPriorityOrder.flat().includes(codeSync.status);
}
