import React, { Component } from 'react';

import '../stylesheets/global.scss';

class NoMatch extends Component {
  render() {
    return (
      <div className="unit row height-60 margin-list-item no-bottom-border">
        <p className="no-match">{this.props.matchText}</p>
      </div>
    );
  }
}

export default NoMatch;
