import * as deviceConstants from '../Constants/deviceConstants';
import * as sensorConstants from '../Constants/sensorConstants';
import * as sensorValueMapConstants from '../Constants/sensorValueMapConstants';

const SUPPORTED_SENSOR_DEVICE_READINGS = [
  ...sensorConstants.SENSOR_DEVICE_PRIMARY_READINGS,
  sensorConstants.DISTURBANCE_RESET
];

export default function extractSensors(device) {
  let sensors = device.sensors;

  //Locks
  if (device.deviceType === deviceConstants.LOCK) {
    let return_struct = {
      [sensorConstants.LOCK_STATE]: '',
      [sensorConstants.BATTERY_LEVEL]: ''
    };

    for (let i = 0; i < sensors.length; i++) {
      if (sensors[i].sensorType === sensorConstants.LOCK_STATE) {
        return_struct[sensors[i].sensorType] =
          sensors[i].value === 'True' ? 'Locked' : 'Unlocked';
      } else {
        return_struct[sensors[i].sensorType] = sensors[i].value;
      }
    }
    return return_struct;

    //Thermostats
  } else if (
    device.deviceType === deviceConstants.THERMOSTAT &&
    device.model !== deviceConstants.NEST
  ) {
    let return_struct = {
      [sensorConstants.THERMOSTAT_SET_POINT_COOL]: '',
      [sensorConstants.THERMOSTAT_SET_POINT_HEAT]: '',
      [sensorConstants.THERMOSTAT_MODE]: '',
      [sensorConstants.THERMOSTAT_TEMPERATURE]: '',
      [sensorConstants.THERMOSTAT_HUMIDITY]: '',
      [sensorConstants.THERMOSTAT_OPERATING_STATE]: '',
      [sensorConstants.THERMOSTAT_FAN_MODE]: '',
      [sensorConstants.THERMOSTAT_FAN_STATE]: '',
      [sensorConstants.BATTERY_LEVEL]: ''
    };
    for (let i = 0; i < sensors.length; i++) {
      if (
        [
          sensorConstants.THERMOSTAT_SET_POINT_COOL,
          sensorConstants.THERMOSTAT_SET_POINT_HEAT,
          sensorConstants.THERMOSTAT_TEMPERATURE
        ].includes(sensors[i].sensorType)
      ) {
        return_struct[sensors[i].sensorType] = JSON.parse(sensors[i].value);
      } else {
        return_struct[sensors[i].sensorType] = sensors[i].value;
      }
    }
    return return_struct;

    //Dimmers and Switches
  } else if (
    device.deviceType === deviceConstants.SWITCH ||
    device.deviceType === deviceConstants.DIMMER
  ) {
    let return_struct = {
      [sensorConstants.BINARY_SWITCH]: '',
      [sensorConstants.MULTILEVEL_SWITCH]: ''
    };
    for (let i = 0; i < sensors.length; i++) {
      if (
        [sensorConstants.BINARY_SWITCH, sensorConstants.MULTILEVEL_SWITCH].includes(
          sensors[i].sensorType
        )
      ) {
        return_struct[sensors[i].sensorType] = JSON.parse(sensors[i].value);
      }
    }
    return return_struct;

    //Binary Sensors
  } else if (device.deviceType === deviceConstants.BINARY_SENSOR) {
    const extractedSensors = {
      [sensorConstants.BATTERY_LEVEL]: '',
      [sensorConstants.BATTERY_LAST_READING]: ''
    };

    sensors.forEach((sensor) => {
      if (SUPPORTED_SENSOR_DEVICE_READINGS.includes(sensor.sensorType)) {
        if (!extractedSensors[sensor.sensorType]) {
          extractedSensors[sensor.sensorType] = {};
        }
        try {
          extractedSensors[sensor.sensorType]['value'] = JSON.parse(sensor.value);
        } catch {
          extractedSensors[sensor.sensorType]['value'] = sensor.value;
        }
        extractedSensors[sensor.sensorType]['timeIssued'] = sensor.timeIssued;
      } else if (sensor.sensorType === sensorConstants.BATTERY_LEVEL) {
        extractedSensors[sensorConstants.BATTERY_LEVEL] = sensor.value;
        extractedSensors[sensorConstants.BATTERY_LAST_READING] = sensor.timeIssued;
      }
    });
    return extractedSensors;
  } else {
    return '';
  }
}

/**
 * Determines if the sensorObj has a supported sensorType, and returns a string based on the sensorType and value.
 * @param sensorObj Output of the extractSensors() function
 */
export function getSensorStateMapKey(sensorObj: Record<string, any>): string | null {
  if (sensorObj[sensorConstants.BINARY_SENSOR_WATER]) {
    const value = sensorObj[sensorConstants.BINARY_SENSOR_WATER].value;
    if (!value || !Object.prototype.hasOwnProperty.call(value, 'Water')) {
      return null;
    }
    return sensorValueMapConstants.BINARY_WATER_SENSOR(value.Water);
  } else if (sensorObj[sensorConstants.MOTION]) {
    const motionTime = sensorObj[sensorConstants.MOTION].timeIssued;
    const resetTime = sensorObj[sensorConstants.DISTURBANCE_RESET]?.timeIssued;
    if (!motionTime) {
      return null;
    } else if (motionTime && !resetTime) {
      return sensorValueMapConstants.MOTION_DETECTED;
    }
    return sensorValueMapConstants.MOTION(new Date(motionTime) > new Date(resetTime));
  } else if (sensorObj[sensorConstants.LOCK_CHANGE]) {
    const value = sensorObj[sensorConstants.LOCK_CHANGE].value;
    if (!value) {
      return null;
    }
    return `${sensorConstants.LOCK_CHANGE}_${value}`;
  } else {
    return null;
  }
}
