import React, { Component } from 'react';
import Header from '../../Common/Headers/Header';
import { withStyles } from '@material-ui/core/styles';
import StatusBar from '../../Common/Headers/StatusBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import client from '../../Common/utils/client';
import * as balena from '../../Common/Constants/balenaConstants';
import * as routes from '../unitdetail_apiRoutes';
import hub from '../../Common/images/Hub_LED.png';
import axios from 'axios';
import header_theme from '../../Common/Themes/header_theme';

import '../../Common/stylesheets/global.scss';
import LinearDeterminate from '../../Common/Loading/Linear';
import { captureExceptionIfNot424 } from '../../Common/utils/errorHandling';

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: '24px',
    float: 'right'
  },
  first_line: {
    padding: '24px 24px 12px 24px',
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '24px'
  },
  bold_line: {
    paddingLeft: '24px',
    paddingRight: '24px',
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '24px',
    fontWeight: '800'
  },
  middle_line: {
    paddingLeft: '24px',
    paddingRight: '24px',
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '24px'
  },
  padding_middle: {
    padding: '24px 24px 12px 24px'
  }
});

class HubUpdating extends Component {
  constructor(props) {
    super(props);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const cancel = {
      cancelToken: source.token
    };
    this.__source = source;
    this.__cancel = cancel;
    this.openAlert = this.openAlert.bind(this);
    this.returnToUnits = this.returnToUnits.bind(this);
    const { classes } = props;
    this.classes = classes;
    this.state = {
      previousCommit: null,
      completed: 0,
      totalProcesses: 0,
      downloadedProcesses: 0,
      runningProcesses: 0,
      updateInterval: null,
      initial: this.props.initial ? this.props.initial : null,
      failures: 0
    };
  }

  componentDidMount() {
    if (this.props.unit.hub_serial && this.props.devices.length) {
      var updateRefresh = setInterval(() => {
        if (document.hasFocus()) {
          let status = this.refreshUpdates();
          if (status === false) {
            clearInterval(updateRefresh);
          }
        }
      }, 5000);

      this.setState({
        updateInterval: updateRefresh
      });
    }
    if (this.state.initial && this.props.unit.hub_serial && this.props.devices.length) {
      this.updateStatuses(this.state.initial);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.updateInterval);
    if (this.props.unit.hub_serial && this.props.devices.length) {
      this.__source.cancel('Operation canceled by the user.');
    }
  }

  handleUnknownUpdate() {
    if (this.state.failures >= 5) {
      window.location.reload();
    } else {
      this.setState((prevState) => ({ failures: prevState.failures + 1 }));
    }
  }

  refreshUpdates() {
    client
      .get(routes.UPDATE_STATUS(this.props.unit.hub_serial), this.__cancel)
      .then((res) => {
        let result = res.data.result;
        if (!result) {
          this.handleUnknownUpdate();
          return;
        } else if (!result.update_pending) {
          window.location.reload();
          return;
        }
        this.setState({ failures: 0 });
        this.updateStatuses(result);
        return Promise.resolve();
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          captureExceptionIfNot424(thrown);
          this.handleUnknownUpdate();
          return false;
        }
      });
  }

  updateStatuses = (data) => {
    let services = data.service_statuses;
    if (!services || !data) {
      return null;
    }
    let totalProcesses = services.length;
    let downloaded = 0;
    let running = 0;
    let aggregate_percentage = 0;
    if (!this.state.previousCommit) this.setState({ previousCommit: data.current_commit });
    if (!this.state.totalProcesses) this.setState({ totalProcesses: totalProcesses });
    services.map((service) => {
      service = Object.values(service)[0];
      if (!service) return null;
      if (service.status === balena.RUNNING && service.commit !== data.current_commit) {
        downloaded = downloaded + 1;
        running = running + 1;
        aggregate_percentage = aggregate_percentage + 100 / totalProcesses;
      }
      if (service.status === balena.DOWNLOADED) {
        downloaded = downloaded + 1;
        aggregate_percentage = aggregate_percentage + 100 / totalProcesses;
      }
      if (service.status === balena.DOWNLOADING) {
        if (service.download_progress) {
          aggregate_percentage =
            aggregate_percentage + (100 / totalProcesses) * (service.download_progress / 100);
          if (service.download_progress === 100) downloaded = downloaded + 1;
        }
      }
      return null;
    });
    this.setState({
      completed: aggregate_percentage,
      downloadedProcesses: downloaded,
      runningProcesses: running
    });
  };

  openAlert(e) {
    e.preventDefault();
    this.setState({
      alertdialog: true
    });
  }

  returnToUnits() {
    const communityId = this.props.match.params.communityId;
    this.props.history.push('/community/' + communityId);
  }

  getStatusString = () => {
    if (!this.state.totalProcesses) {
      return 'Fetching data';
    }
    if (this.state.downloadedProcesses === this.state.totalProcesses) {
      return `${this.state.runningProcesses} of ${this.state.totalProcesses} processes running`;
    }
    return `${this.state.downloadedProcesses} of ${
      this.state.totalProcesses
    } processes updated (${this.state.completed.toFixed(1)}%)`;
  };

  render() {
    let statusString = this.getStatusString();
    return (
      <div className="full-height">
        <MuiThemeProvider theme={header_theme}>
          <Header
            headerText="Update Hub"
            back={true}
            return={this.returnToUnits}
            arrow={true}
            user={this.props.user}
            history={this.props.history}
            subheaderText={this.props.unit.communityName}
            subheaderText2={this.props.unit.unit}
          />
          <StatusBar message="Hub updating..." />
          <div>
            <p className={this.classes.first_line}>This may take several minutes.</p>
            <p className={this.classes.bold_line}>DO NOT UNPLUG THE HUB!</p>
            <p className={this.classes.middle_line}>
              Progress: <strong>{statusString}</strong>
            </p>
            <div className={this.classes.padding_middle}>
              <LinearDeterminate completed={this.state.completed} />
            </div>
            <p className={this.classes.middle_line}>
              When the hub has finished updating the first LED on the hub will turn green.
            </p>
            <div style={{ textAlign: 'center', padding: '24px 24px 0px 24px' }}>
              <img src={hub} alt="successful update" style={{ width: '100%' }} />
            </div>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(HubUpdating);
