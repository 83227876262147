/**
 * Helpers for anything related to device capabilities (i.e. the device_capabilities.toml file in the cloud api).
 */

import { DeviceSensor, DeviceToBeInstalled } from '../Types/cloudApi';
import {
  contactSensorPlacement_Fenestration_Mapping,
  CONTACT_SENSOR_PLACEMENTS,
  SensorPlacement,
  sensorPlacement_DefaultMetadataId_Mapping,
  sensorPlacement_SensorReading_Mapping,
  sensorPlacement_WTSkuField_Mapping
} from '../Constants/sensorPlacementConstants';
import { isContactSensorPlacement } from './sensorPlacement';

export function createMetadataId(base: string, attributes?: string) {
  if (attributes) {
    return `${base}?${attributes}`.toUpperCase();
  } else {
    return base.toUpperCase();
  }
}

export function splitMetadataId(
  metadata_id: DeviceSensor['metadata_id']
):
  | Record<string, never>
  | {
      base: string;
      attributes?: string;
    } {
  const metadataObj = {};
  if (!metadata_id) {
    return metadataObj;
  }
  const split = metadata_id.split('?');
  metadataObj['base'] = split[0];
  if (split.length > 1) {
    metadataObj['attributes'] = split[1];
  }
  return metadataObj;
}

export function convertPlacementToMetadataIdUsingSalesforceDevices(
  sensorPlacement: SensorPlacement | '',
  devicesToBeInstalled?: DeviceToBeInstalled[]
): string | undefined {
  const sensorReading = sensorPlacement_SensorReading_Mapping[sensorPlacement];
  if (!sensorReading) {
    return undefined;
  }
  const deviceToBeInstalled = devicesToBeInstalled?.find((d) => {
    const deviceContainsReading = d.sensorReadings.includes(sensorReading);
    if (isContactSensorPlacement(sensorPlacement)) {
      return (
        deviceContainsReading &&
        d.wtField &&
        sensorPlacement_WTSkuField_Mapping[sensorPlacement] === d.wtField
      );
    } else {
      return deviceContainsReading;
    }
  });
  let metadata_id =
    deviceToBeInstalled?.modelNumber ||
    sensorPlacement_DefaultMetadataId_Mapping[sensorPlacement] ||
    undefined;
  if (!metadata_id) {
    return undefined;
  }
  if (
    (CONTACT_SENSOR_PLACEMENTS as SensorPlacement[]).includes(
      sensorPlacement as SensorPlacement
    )
  ) {
    const attribute = contactSensorPlacement_Fenestration_Mapping[sensorPlacement];
    metadata_id = createMetadataId(metadata_id, attribute);
  }
  return metadata_id;
}
