import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as sfConstants from '../Constants/salesforceConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import grey from '@material-ui/core/colors/grey';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  value: {
    marginBottom: theme.spacing(2),
    color: grey[900],
    fontSize: '1rem'
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

function CommunityInfoDialog(props) {
  const buildingAddressTitle = 'Building Address';
  const defaultHubLocationTitle = 'Default Hub Location';
  const propertyManagerTitle = 'Property Manager';
  const phoneNumberTitle = 'Phone Number';
  const lightingLayoutTitle = 'Lighting Layout';
  const installPageTitle = 'Install Page';
  const hardwareTitle = 'Hardware';
  let communityInfoList = [];

  const pushToCommunityInfoList = (pairs) => {
    // concat() allows pushToCommunityInfoList() to be called
    // multiple times without overwriting previous info
    communityInfoList = communityInfoList.concat(
      pairs.map((pair) => {
        return { title: pair[0], value: pair[1] };
      })
    );
  };

  if (props.community) {
    const street = props.community.street;
    const city = props.community.city;
    const state = props.community.state;
    const postal = props.community.postal;
    const buildingAddress = street + ', ' + city + ', ' + state + ' ' + postal;
    pushToCommunityInfoList([[buildingAddressTitle, buildingAddress]]);
    const { salesforceInfo } = props.community;
    const { SharedVars } = sfConstants;

    if (salesforceInfo) {
      const defaultHubLocation = salesforceInfo[SharedVars.DEFAULT_HUB_LOCATION];
      if (defaultHubLocation) {
        pushToCommunityInfoList([[defaultHubLocationTitle, defaultHubLocation]]);
      }

      // TODO the way the devices are shown could be improved later (i.e. not a bulleted list).
      let hardware = [
        salesforceInfo[SharedVars.LOCK],
        salesforceInfo[SharedVars.THERMOSTAT],
        salesforceInfo[SharedVars.OUTLET],
        salesforceInfo[SharedVars.PRIMARY_SWITCH],
        salesforceInfo[SharedVars.DIMMER_SWITCH],
        salesforceInfo[SharedVars.VOICE_CONTROL],
        salesforceInfo[SharedVars.LEAK_SENSOR]
      ].filter((device) => device);
      if (
        salesforceInfo[SharedVars.PRIVACY_LOCK] &&
        salesforceInfo[SharedVars.PRIVACY_LOCK][SharedVars.NAME]
      ) {
        hardware.push(salesforceInfo[SharedVars.PRIVACY_LOCK][SharedVars.NAME]);
      }
      if (
        salesforceInfo[SharedVars.ADD_ON_SWITCH] &&
        salesforceInfo[SharedVars.ADD_ON_SWITCH][SharedVars.NAME]
      ) {
        hardware.push(salesforceInfo[SharedVars.ADD_ON_SWITCH][SharedVars.NAME]);
      }

      pushToCommunityInfoList([
        [lightingLayoutTitle, salesforceInfo[SharedVars.LIGHTING_LAYOUT_LINK]],
        [installPageTitle, salesforceInfo[SharedVars.INSTALL_PAGE_LINK]],
        [propertyManagerTitle, salesforceInfo[SharedVars.PROPERTY_MANAGER]],
        [phoneNumberTitle, salesforceInfo[SharedVars.PHONE_NUMBER]],
        [hardwareTitle, hardware]
      ]);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle>
        {props.community ? props.community.name : null}
        <IconButton className={props.classes.closeIcon} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {/* eslint-disable-next-line */}
        {communityInfoList.map(info => {
          if (info.value) {
            if ([lightingLayoutTitle, installPageTitle].includes(info.title)) {
              return (
                <div key={info.title}>
                  <Typography variant={'overline'}>{info.title}</Typography>
                  <Typography variant={'body1'} className={props.classes.value}>
                    <Link href={info.value}>{info.value}</Link>
                  </Typography>
                </div>
              );
            } else if (info.title === hardwareTitle) {
              if (info.value.length > 0) {
                return (
                  <div key={info.title}>
                    <Typography variant={'overline'}>{info.title}</Typography>
                    <List>
                      {/* eslint-disable-next-line */}
                      {info.value.map(device => {
                        if (device) {
                          return (
                            <Typography
                              key={device}
                              variant={'body1'}
                              component={'li'}
                              style={{
                                marginBottom: 0,
                                listStyleType: 'circle',
                                listStylePosition: 'inside'
                              }}
                              className={props.classes.value}
                            >
                              {device}
                            </Typography>
                          );
                        } else return null;
                      })}
                    </List>
                  </div>
                );
              } else return null;
            } else {
              return (
                <div key={info.title}>
                  <Typography variant={'overline'}>{info.title}</Typography>
                  <Typography variant={'body1'} className={props.classes.value}>
                    {info.value}
                  </Typography>
                </div>
              );
            }
          } else return null;
        })}
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(CommunityInfoDialog);
