import {
  YALE_CODE_BLACKLIST,
  INITIAL_YALE_CODE_KEY
} from '../../../Common/Constants/installerAppConstants';

const _genRandomCode = () => {
  let code = '';
  for (let i = 0; i < 6; i++) {
    code += Math.floor(Math.random() * 10);
  }
  return code;
};

const generateInitialYaleCode = (old_codes: string[]) => {
  old_codes = old_codes || []
  let code = _genRandomCode();
  while (YALE_CODE_BLACKLIST.includes(code) || old_codes.includes(code)) {
    code = _genRandomCode();
  }
  return code;
};

export const removeInitialYaleCode = () => {
  localStorage.removeItem(INITIAL_YALE_CODE_KEY);
};

export const getOrCreateInitialYaleCode = (old_codes: string[]) => {
  let code = localStorage.getItem(INITIAL_YALE_CODE_KEY);
  // If code doesn't exist, store it in browser cache
  if (!code) {
    code = generateInitialYaleCode(old_codes);
    localStorage.setItem(INITIAL_YALE_CODE_KEY, code);
  }
  return code;
};
export const deleteStoredYaleCode = () => {
  localStorage.removeItem(INITIAL_YALE_CODE_KEY)
}
export { generateInitialYaleCode };
