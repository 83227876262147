import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff'
    },
    secondary: {
      light: '#F6861E',
      main: '#F6861E',
      dark: '#F6861E',
      contrastText: '#000'
    }
  }
});
