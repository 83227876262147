import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#4ad4d4',
      main: '#4ad4d4',
      dark: '#4ad4d4',
      contrastText: '#fff'
    },
    secondary: {
      light: '#4ad4d4',
      main: '#4ad4d4',
      dark: '#4ad4d4',
      contrastText: '#000'
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        '&&&&:hover:before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        }
      }
    }
  }
});
