import { createMuiTheme } from '@material-ui/core/styles';
import { defaultPaletteOptions } from './dweloPalette';
import { themeOptions as buttonTheme_v2 } from './button_theme_v2';
import { Overrides } from '@material-ui/core/styles/overrides';

export default createMuiTheme({
  props: {
    MuiDialog: {
      PaperProps: {
        square: true
      }
    }
  },
  overrides: {
    MuiButton: { ...(buttonTheme_v2['overrides'] as Overrides)['MuiButton'] },
    MuiLink: {
      root: {
        color: defaultPaletteOptions.secondary.main,
        '&:hover': {
          color: defaultPaletteOptions.secondary.dark
        }
      }
    }
  },
  typography: {
    fontFamily: `proxima-nova, 'Roboto','Helvetica','Arial', sans-serif`
  },
  palette: defaultPaletteOptions
});
