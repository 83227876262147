import { DeviceSensor } from '../Types/cloudApi';
import { DweloDeviceType } from '../Constants/deviceConstants_v2';

export function isRingDoorbellDevice(device: DeviceSensor): boolean {
  return device.deviceType === DweloDeviceType.Doorbell && device.model === 'ring';
}

export function getDeviceDisplayName(
  name: string | undefined,
  deviceType: string | undefined
): string {
  if (deviceType === DweloDeviceType.BinarySensor) {
    return name ? `${name} Sensor` : 'Sensor';
  }
  return name || '';
}
