import { DeviceSensor } from '../Types/cloudApi';
import { DweloDeviceType } from '../Constants/deviceConstants_v2';
import { LOCK_CHANGE, SENSOR_DEVICE_PRIMARY_READINGS } from '../Constants/sensorConstants';
import { splitMetadataId } from './deviceCapabilities';
import {
  CONTACT_SENSOR_PLACEMENTS,
  fenestration_ContactSensorPlacement_Mapping,
  SensorPlacement,
  sensorReading_SensorPlacement_Mapping
} from '../Constants/sensorPlacementConstants';

export function getSensorPlacementFromMetadata(
  metadataId: DeviceSensor['metadata_id'],
  deviceMetadata: DeviceSensor['device_metadata']
): SensorPlacement | '' {
  if (deviceMetadata.type !== DweloDeviceType.BinarySensor) {
    return '';
  }

  const reading = deviceMetadata.sensor_readings?.find((sr) =>
    SENSOR_DEVICE_PRIMARY_READINGS.includes(sr)
  );
  if (!reading) {
    return '';
  }

  // For contact sensors, placement is determined by the fenestration attribute on the metadata_id.
  // For other sensors, we can go off the sensor reading.
  if (reading === LOCK_CHANGE) {
    // For now, we need to split instead of doing "deviceMetadata.fenestration" because there is a cloud bug
    // where the attributes for sensors are not being appended to the device's device_metadata.
    const split = splitMetadataId(metadataId);
    if (!split.attributes) {
      return '';
    }
    for (const attr of split.attributes) {
      const placement = fenestration_ContactSensorPlacement_Mapping[attr];
      if (placement) {
        return placement;
      }
    }
    return '';
  }

  return sensorReading_SensorPlacement_Mapping[reading] || '';
}

export function isContactSensorPlacement(placement) {
  return CONTACT_SENSOR_PLACEMENTS.includes(placement);
}
