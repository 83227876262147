import React, { Component } from 'react';
import AuthService from './AuthService';
import { ChatContext } from './chatContext';
import * as Sentry from '@sentry/browser';

export default function withAuth(AuthComponent) {
  const Auth = new AuthService();
  return class AuthWrapped extends Component {
    static contextType = ChatContext;
    constructor() {
      super();
      this.state = {
        user: null
      };
    }
    UNSAFE_componentWillMount() {
      if (!Auth.loggedIn()) {
        this.props.history.replace('/login');
        this.context.setDisplayChat(false);
      } else {
        try {
          const user = Auth.getUser();
          if (this.props.ldClient) {
            this.props.ldClient.identify({ key: user.email });
          }
          this.setState({
            user: user
          });
          this.context.setDisplayChat(true);
        } catch (err) {
          Sentry.captureException(err);
          Auth.logout();
          this.props.history.replace('/login');
          this.context.setDisplayChat(false);
          window.location.reload();
        }
      }
    }
    render() {
      if (this.state.user) {
        return <AuthComponent user={this.state.user} {...this.props} />;
      } else {
        return null;
      }
    }
  };
}
