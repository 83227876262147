import React, { useState } from 'react';

const ChatContext = React.createContext();

const ChatContextProvider = (props) => {
  const [displayChat, setDisplayChat] = useState(false);
  return (
    <ChatContext.Provider value={{ displayChat: displayChat, setDisplayChat: setDisplayChat }}>
      {props.children}
    </ChatContext.Provider>
  );
};

export { ChatContext, ChatContextProvider };
