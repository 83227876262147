import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { defaultPaletteOptions, dweloPalette } from './dweloPalette';

const outlinedSecondaryDisabled = {
  borderColor: dweloPalette.primary.grey[150],
  color: dweloPalette.primary.grey[150]
};

export const themeOptions: ThemeOptions = {
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '0px'
      },
      containedSecondary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        },
        '&:disabled': {
          backgroundColor: dweloPalette.primary.grey[100],
          color: dweloPalette.primary.grey[150]
        }
      },
      outlinedSecondary: {
        borderColor: defaultPaletteOptions.secondary.main,
        '&:hover': {
          backgroundColor: defaultPaletteOptions.primary.contrastText,
          borderColor: defaultPaletteOptions.secondary.dark,
          color: defaultPaletteOptions.secondary.dark,
          '@media (hover: none)': {
            borderColor: defaultPaletteOptions.secondary.main,
            color: defaultPaletteOptions.secondary.main,
            // Fix for buttons in LockThrowDialog.tsx on mobile
            // because on mobile phones, button focus after click doesn't go away until tapping somewhere else.
            '&:disabled': outlinedSecondaryDisabled
          }
        },
        '&:disabled': outlinedSecondaryDisabled
      }
    }
  },
  palette: defaultPaletteOptions
};

export default createMuiTheme(themeOptions);
