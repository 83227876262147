import { DweloDevice_SFProductFamily_Mappings } from '../../Constants/salesforceConstants_v2';
import * as deviceConstants from '../../Constants/deviceConstants';
import {
  DeviceSensor,
  UnitConfiguration,
  V4_SF_Unit_Configuration
} from '../../Types/cloudApi';

// This array will always be in this order:
// [[unit install locations], [community install locations], [hard-coded install locations]]
// In order words, the unit array's index will always be smaller than all the other indexes,
// community will always be greater than unit, etc.
// It's possible that the unit and/or community install locations arrays won't exist in certain scenarios,
// such as [[unit install locations], [hard-coded install locations]]
export type InstallLocationsArray = string[][];

type DeviceType = NonNullable<DeviceSensor['deviceType']>;

export interface PopulatedInstallLocationsArrayArgs {
  sfConfiguration: V4_SF_Unit_Configuration;
  currentDevice: DeviceSensor;
  devices: DeviceSensor[];
  // We won't want to show the device's current name immediately after it is paired,
  // because by default it is "Unnamed" and we want to encourage
  // the user to name the device properly.
  includeCurrentNameFirst?: boolean;
}

export function getPopulatedInstallLocationsArray({
  sfConfiguration,
  currentDevice,
  devices,
  includeCurrentNameFirst = false
}: PopulatedInstallLocationsArrayArgs): InstallLocationsArray {
  const deviceType = currentDevice.deviceType;
  const installLocations = getInstallLocationsFromConfigurations(sfConfiguration, deviceType);
  const locationsAlreadyUsed = getUsedDeviceNamesByType(devices, deviceType);
  const filtered = filterOutBadArraysAndUsedValues(installLocations, locationsAlreadyUsed);
  if (filtered.length > 0 && includeCurrentNameFirst) {
    filtered[0] = [currentDevice.name, ...filtered[0]];
  }
  return filtered;
}

function getInstallLocationsFromConfigurations(
  sfConfiguration: V4_SF_Unit_Configuration,
  deviceType: DeviceType
): InstallLocationsArray {
  const family = DweloDevice_SFProductFamily_Mappings[deviceType];
  if (!family || !sfConfiguration) {
    return [deviceConstants.DEVICE_LOCATIONS];
  }

  let selectedInstallLocations: string[] = [];
  let selectedConfig: UnitConfiguration | undefined = undefined;
  // we use all of them regardless
  let communityConfigs: UnitConfiguration[] = sfConfiguration.unitConfigurations;

  if (sfConfiguration.selectedConfigId !== undefined) {
    // Find the selected line item config and pull out the items
    selectedConfig = sfConfiguration.unitConfigurations[sfConfiguration.selectedConfigId];
    // is there ever a case of multiple of the same install location name
    selectedConfig.lineItems.forEach((item) => {
      if (item.productFamily === family) {
        selectedInstallLocations.push(item.installLocation);
      }
    });
  }

  // every line from every other config.
  let communityInstallLocations: string[] = [];
  communityConfigs.forEach((config) => {
    if (!selectedConfig || config !== selectedConfig) {
      config.lineItems.forEach((item) => {
        if (
          item.productFamily === family &&
          !communityInstallLocations.includes(item.installLocation)
        ) {
          communityInstallLocations.push(item.installLocation);
        }
      });
    }
  });

  return [
    selectedInstallLocations,
    communityInstallLocations,
    deviceConstants.DEVICE_LOCATIONS
  ];
}

const getUsedDeviceNamesByType = (
  devices: DeviceSensor[],
  deviceType: DeviceType
): string[] => {
  return devices.reduce((allNames, device) => {
    const deviceProductFamily = DweloDevice_SFProductFamily_Mappings[device.deviceType];
    const deviceTypeProductFamily = DweloDevice_SFProductFamily_Mappings[deviceType];
    if (deviceProductFamily && deviceProductFamily === deviceTypeProductFamily) {
      allNames.push(device.name);
    }
    return allNames;
  }, [] as string[]);
};

const filterOutBadArraysAndUsedValues = (
  locationArrays: InstallLocationsArray,
  usedValues: string[]
): InstallLocationsArray => {
  let allUsedValues = [...usedValues];
  const mapped = locationArrays.map((locationArray) => {
    return locationArray.filter((location) => {
      const isLocationUsed = allUsedValues.includes(location);
      allUsedValues.push(location);
      return !isLocationUsed;
    });
  });
  return mapped.filter((locationArray) => {
    return locationArray.length !== 0;
  });
};
