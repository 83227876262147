import { AxiosError } from 'axios';
import { captureException, Severity } from '@sentry/browser';
import { History, LocationState } from 'history';

/**
 * This is mostly used for handling '/v4/hub/<hub_serial>/softwareupdate/status/' errors.
 * It usually returns 424 when hub has no update occurring, and since this is called on an interval,
 * capturing every 424 isn't useful.
 */
export function captureExceptionIfNot424(error: AxiosError): void {
  if (error.response?.status !== 424) {
    captureException(error);
  }
}

export function is401Error(error: AxiosError): boolean {
  return error.response?.status === 401;
}

export function handle401ErrorRedirect(
  error: AxiosError,
  history: History<LocationState>
): void {
  history.push('/login');
  captureException(error, { level: Severity.Warning });
}

export function handleNon401ErrorRedirect(
  error: AxiosError,
  history: History<LocationState>
): void {
  history.push('/');
  captureException(error);
}
