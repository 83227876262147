import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '120px',
      marginLeft: '0px'
    },
    hidden: {
      display: 'none'
    }
  })
);

type Props = {
  value: string;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: string }>,
    value: string
  ) => void;
  options: string[][];
  label: string;
  fullWidth?: boolean;
  required?: boolean;
};

const MORE = 'more';

/**
 * A custom component based on the material-ui Select dropdown component.
 *
 * One limitation with MuiSelect (in terms of business needs) is that the options would be directly in a single array, like ["Bar", "Hallway", "Bedroom"].
 * With ExpandableSelect, the options are in possibly separate arrays, all wrapped around an outer array,
 * like [["Bar", "Hallway"], ["Bedroom"]]
 *
 * This lets us control when we want users to see certain options, and only expand/show
 * other options when they explicitly want to do so.
 *
 * When opening the ExpandableSelect dropdown,
 * the user would see ["Bar", "Hallway", "More..."] ("More..." is inserted automatically).
 * If they click "More...", then they would see ["Bar", "Hallway" ,"Bedroom"].
 * Because there is no other array after the "Bedroom" array, then another "More..." isn't inserted automatically.
 */
function ExpandableSelect(props: Props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [arrayDepth, setArrayDepth] = useState(0);

  function handleChange(event: React.ChangeEvent<{ name?: string; value: string }>) {
    event.preventDefault();
    const value = event.target.value;
    if (value !== MORE) {
      props.onChange(event, value);
    }
  }

  const toggleOpen = () => {
    setOpen(true);
    setArrayDepth(0);
  };
  const toggleClose = () => {
    setOpen(false);
  };
  function MoreItem() {
    return (
      <MenuItem
        value={MORE}
        onClick={() => {
          setArrayDepth((prev) => prev + 1);
        }}
      >
        More...
      </MenuItem>
    );
  }

  const items = props.options
    .filter((_, i) => i <= arrayDepth)
    .reduce((prev, next) => prev.concat(next), []);
  const hasRemainingArrays = arrayDepth < props.options.length - 1;

  if (props.value && !items.includes(props.value)) {
    items.push(props.value);
  }

  const renderedItems = items.sort().map((v) => (
    <MenuItem key={v} value={v}>
      {v}
    </MenuItem>
  ));

  const labelId = `${props.label}-expandableselect-label`;
  const inputId = `${props.label}-expandableselect`;

  return (
    <FormControl
      variant={'outlined'}
      className={classes.formControl}
      style={props.fullWidth ? { width: '100%' } : undefined}
      required={!!props.required}
    >
      <InputLabel id={labelId} htmlFor={inputId}>
        {props.label}
      </InputLabel>
      <Select
        inputProps={{
          id: inputId
        }}
        labelId={labelId}
        value={props.value}
        onChange={handleChange}
        onClose={toggleClose}
        onOpen={toggleOpen}
        open={open}
        label={props.label}
      >
        {renderedItems}
        {/* If we have more arrays add the MORE item */}
        {hasRemainingArrays && <MoreItem />}
      </Select>
    </FormControl>
  );
}

export default ExpandableSelect;
