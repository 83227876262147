import React from 'react';

import {
  Avatar,
  ButtonProps,
  createStyles,
  IconButtonProps,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';
import unitTodoTheme from '../../Common/Themes/unit_todo_theme';
import { dweloPalette } from '../../Common/Themes/dweloPalette';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      width: '28px',
      height: '28px'
    },
    avatarComplete: {
      backgroundColor: theme.palette.primary.main
    },
    avatarIncomplete: {
      backgroundColor: 'transparent'
    },
    avatarDisabled: {
      borderColor: dweloPalette.primary.grey[150],
      backgroundColor: 'transparent'
    },
    button: {},
    deviceIconIncomplete: {
      fill: theme.palette.primary.main
    },
    doneIcon: {
      width: '22px',
      height: '22px'
    },
    iconButton: {
      color: '#4F4F4F',
      width: '48px',
      height: '48px'
    },
    iconEnabled: {
      fill: theme.palette.primary.main
    },
    iconDisabled: {
      fill: dweloPalette.primary.grey[150]
    },
    listItem: {
      backgroundColor: '#FFF',
      paddingLeft: '20px',
      height: '64px'
    },
    listItemAvatar: {
      minWidth: '38px'
    },
    listItemBottom: {
      marginBottom: '10px',
      boxShadow: '0px 1px 1px #BBBBBB'
    },
    textDisabled: {
      color: theme.palette.text.disabled
    }
  })
);

type Props = {
  complete: boolean;
  primaryText: string;
  disabled?: boolean;
  disableBottomStyles?: boolean;
  children?: JSX.Element;
  avatarIcon?: JSX.Element;
  button?: JSX.Element;
  iconButton?: JSX.Element;
};

export const TodoListItem = (props: Props) => {
  const classes = useStyles(unitTodoTheme);

  function getButton() {
    // Cloning so that TodoListItem can own basic styles,
    // and we may conditionally use more classes later on
    if (props.iconButton) {
      return React.cloneElement(props.iconButton, {
        className: classes.iconButton
      } as IconButtonProps);
    } else if (props.button) {
      return React.cloneElement(props.button, {
        className: classes.button,
        disabled: !!props.disabled || !!props.button.props.disabled
      } as ButtonProps);
    }
  }

  function getAvatarIcon() {
    if (props.complete) {
      return <DoneIcon className={classes.doneIcon} />;
    } else if (props.avatarIcon) {
      return React.cloneElement(props.avatarIcon, {
        className: props.disabled ? classes.iconDisabled : classes.iconEnabled
      });
    }
  }

  const avatarStatusClass = props.complete ? classes.avatarComplete : classes.avatarIncomplete;
  const avatarDisabledClass = props.disabled ? classes.avatarDisabled : '';
  const extraListItemClass = props.disableBottomStyles ? '' : classes.listItemBottom;

  const avatarIcon = getAvatarIcon();
  const button = getButton();

  return (
    <React.Fragment>
      <ListItem className={`${classes.listItem} ${extraListItemClass}`}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={`${classes.avatar} ${avatarStatusClass} ${avatarDisabledClass}`}>
            {avatarIcon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={props.primaryText}
          primaryTypographyProps={{
            variant: 'body1',
            color: 'textPrimary',
            className: props.disabled ? classes.textDisabled : undefined
          }}
        />
        {button}
      </ListItem>
      {props.children}
    </React.Fragment>
  );
};
