import React from 'react';
import { Theme, createStyles, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: '24px',
      marginBottom: '24px'
    },
    container: {
      display: 'flex'
    },
    number: {
      textAlign: 'left'
    },
    numberContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '32px'
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  })
);

interface Props {
  number: number;
  children: React.ReactNode;
  hideDivider?: boolean;
}

export function NumberedStep(props: Props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.numberContainer}>
          <Typography className={classes.number}>{`${props.number}.`}</Typography>
        </div>
        <div className={classes.contentContainer}>{props.children}</div>
      </div>
      {!props.hideDivider && <Divider className={classes.divider} />}
    </>
  );
}
