import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Link,
  Theme,
  createStyles,
  Typography,
  Divider
} from '@material-ui/core';
import { LockThrow } from '../../Common/utils/storageInterface';
import { FullScreenDialog } from '../../Common/Popups/FullScreenDialog';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CelebrationSvg } from '../../Common/images/celebration.svg';
import unit_todo_theme from '../../Common/Themes/unit_todo_theme';
import { Community, DeviceSensor, Unit } from '../../Common/Types/cloudApi';
import {
  DEVICE_TROUBLESHOOTING_ARTICLES_URL,
  NOTHING_BUT_AIR_VIDEO_URL
} from '../../Common/Constants/installerAppConstants';
import { LockControlWidget } from './LockControlWidget';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    celebrationSvg: {
      display: 'block',
      margin: 'auto auto 30px auto'
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '112px'
    },
    dialogContent: {
      padding: '30px 40px',
      justifyContent: 'center'
    },
    resultPage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    thankYouText: {
      marginBottom: '30px',
      textAlign: 'center'
    },
    ul: {
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.fontFamily,
      marginLeft: '16px'
    }
  })
);

type Props = {
  description: string;
  isDialogOpen: boolean;
  didDweloInstallLock: boolean;
  lockDoor: () => void;
  closeDialog: () => void;
  unitName: Unit['unit'];
  communityName: Community['name'];
  updateProperties: (properties: Partial<LockThrow>) => void;
  isDoorLocked?: boolean;
  device?: DeviceSensor;
  topComponent: React.ReactElement | null;
};

enum ButtonName {
  Primary = 'primary',
  Secondary = 'secondary'
}

enum Page {
  InitialAssessment,
  Troubleshooting,
  Result
}

export function LockThrowDialog(props: Props) {
  const classes = useStyles(unit_todo_theme);

  const [description, setDescription] = useState(props.description);
  const [page, setPage] = useState(Page.InitialAssessment);

  const disableButtons = !description && page === Page.Troubleshooting;

  useEffect(() => {
    setDescription(props.description);
  }, [props.description]);

  useEffect(() => {
    if (props.isDialogOpen) {
      setPage(Page.InitialAssessment);
    }
  }, [props.isDialogOpen]);

  function closeAndUpdate() {
    props.closeDialog();
    props.updateProperties({ description });
  }

  function goToNextPage() {
    setPage((prev) => prev + 1);
  }

  function handleDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDescription(e.currentTarget.value);
  }

  function handleButtonClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const wasPrimaryClicked = e.currentTarget.name === ButtonName.Primary;
    switch (page) {
      case Page.InitialAssessment:
        props.updateProperties({ nothingButAir: wasPrimaryClicked });
        if (wasPrimaryClicked) {
          props.closeDialog();
        } else {
          goToNextPage();
        }
        break;
      case Page.Troubleshooting:
        goToNextPage();
        props.updateProperties({
          description,
          resultOfIssue: wasPrimaryClicked ? 'FIXED' : 'REPORTED'
        });
        break;
    }
  }

  function getUpperBodyContent() {
    switch (page) {
      case Page.InitialAssessment:
        return getInitialPageUpperContent();
      case Page.Troubleshooting:
        return getTroubleshootingPageUpperContent();
      case Page.Result:
        return getResultPageContent();
    }
  }

  function getLowerBodyContent() {
    switch (page) {
      case Page.InitialAssessment:
        return getInitialPageLowerContent();
      case Page.Troubleshooting:
        return getTroubleshootingPageLowerContent();
    }
  }

  function getInitialPageUpperContent() {
    const firstPartOfSentence = props.didDweloInstallLock
      ? 'Ensure that'
      : 'We need your help to know if';
    return (
      <>
        <Typography paragraph>
          {`${firstPartOfSentence} this lock throw meets our `}
          <Link href={NOTHING_BUT_AIR_VIDEO_URL} target={'_blank'} rel={'noopener noreferrer'}>
            Nothing but Air
          </Link>{' '}
          standard.
        </Typography>
        <ul className={classes.ul}>
          <li style={{ paddingBottom: '10px' }}>
            First, make sure the door is <strong>open and unlocked</strong>, then lock it from
            the button below.
          </li>
          <li>
            Next, with the door <strong>closed and unlocked</strong>, lock it below.
          </li>
        </ul>
      </>
    );
  }

  function getInitialPageLowerContent() {
    return (
      <Typography style={{ marginBottom: '0' }}>
        Does the lock throw as smoothly when it’s both <strong>open and closed</strong>, and
        does the motor sound the same?
      </Typography>
    );
  }

  function getTroubleshootingPageUpperContent() {
    const requestToInstaller = props.didDweloInstallLock
      ? 'Please resolve all lock throw issues now.'
      : 'If you are able to fix the problem within five minutes of work please do so now.';
    return (
      <>
        {!props.didDweloInstallLock && (
          <>
            <Typography paragraph>
              Please describe the issues you found with the door or lock when you arrived.
            </Typography>
            {getDescriptionTextField()}
          </>
        )}
        <Typography>
          {requestToInstaller}
          {` If you need help, see our `}
          <Link
            href={DEVICE_TROUBLESHOOTING_ARTICLES_URL}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            troubleshooting instructions
          </Link>
          .
        </Typography>
      </>
    );
  }

  function getTroubleshootingPageLowerContent() {
    const conclusionText = props.didDweloInstallLock
      ? 'Please describe the issue and anything you did to fix it.'
      : 'Were you able to fix the issue or do you need to report it?';
    return (
      <>
        <Typography paragraph={props.didDweloInstallLock}>{conclusionText}</Typography>
        {props.didDweloInstallLock && getDescriptionTextField()}
      </>
    );
  }

  function getDescriptionTextField() {
    return (
      <TextField
        label="Description"
        type="text"
        color={disableButtons ? 'primary' : 'secondary'}
        fullWidth
        multiline
        style={{ marginBottom: props.didDweloInstallLock ? '0px' : '30px' }}
        error={disableButtons}
        value={description}
        onChange={handleDescriptionChange}
        helperText={disableButtons && 'Required*'}
      />
    );
  }

  function getResultPageContent() {
    return (
      <div className={classes.resultPage}>
        <CelebrationSvg className={classes.celebrationSvg} />
        <Typography className={classes.thankYouText}>
          Thanks for helping give our customers a great experience with Dwelo!
        </Typography>
        <Button onClick={props.closeDialog} variant={'contained'} color={'secondary'}>
          Done
        </Button>
      </div>
    );
  }

  function getLockControlWidget() {
    if (props.device && props.isDoorLocked !== undefined) {
      return (
        <LockControlWidget
          name={props.device.name}
          isDoorLocked={props.isDoorLocked}
          lockDoor={props.lockDoor}
        />
      );
    }
  }

  return (
    <FullScreenDialog
      topComponent={props.topComponent}
      open={props.isDialogOpen}
      title={'Check the Lock Throw'}
      onClose={closeAndUpdate}
      subtitle1={props.communityName}
      subtitle2={props.unitName}
    >
      <>
        <DialogContent className={classes.dialogContent}>
          {getUpperBodyContent()}
        </DialogContent>

        {page < Page.Result && (
          <>
            {getLockControlWidget()}

            <Divider />

            <DialogContent className={classes.dialogContent} style={{ marginBottom: 0 }}>
              {getLowerBodyContent()}
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
              <Button
                name={ButtonName.Secondary}
                onClick={handleButtonClick}
                variant={'outlined'}
                color={'secondary'}
                style={{ marginRight: '40px' }}
                disabled={disableButtons}
              >
                {page === Page.InitialAssessment ? 'No' : 'Report It'}
              </Button>
              <Button
                name={ButtonName.Primary}
                onClick={handleButtonClick}
                variant={'contained'}
                color={'secondary'}
                disabled={disableButtons}
              >
                {page === Page.InitialAssessment ? 'Yes' : 'I Fixed It'}
              </Button>
            </DialogActions>
          </>
        )}
      </>
    </FullScreenDialog>
  );
}
