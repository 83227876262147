export function ADDRESS(addressId) {
  return '/v4/installer/address/?addressId=' + addressId;
}
export function SINGLE_DEVICE_SENSOR(hub_serial, deviceId) {
  return '/v4/sensor/hub/' + hub_serial + '/?deviceId=' + deviceId;
}
export function ALL_DEVICE_SENSORS(hub_serial) {
  return '/v4/sensor/hub/' + hub_serial + '/';
}
export function COMMAND(deviceId) {
  return 'v3/device/' + deviceId + '/command/';
}
export function UPDATE_NAME(deviceId) {
  return 'v3/device/' + deviceId + '/';
}
export function SET_NOTIFICATIONS(deviceId) {
  return '/v4/device/' + deviceId + '/setnotifications/';
}
export function COMMUNITY_AND_UNIT_CONFIGURATIONS(communityId, unitId) {
  return `/v4/installer/unit-configurations/?communityId=${communityId}&addressId=${unitId}`;
}
