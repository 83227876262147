import { orange } from '@material-ui/core/colors';

export const buttonCircularProgress = {
  color: orange[500],
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
};
