import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      padding: '10px 20px',
      zIndex: 1,
      display: 'flex',
      position: 'sticky',
      top: 0,
      alignItems: 'center',
      justifyContent: 'left'
    },
    icon: {
      marginRight: '12px'
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      flexWrap: 'wrap'
    }
  })
);

type Props = {
  iconElement: React.ReactElement;
  textElement: React.ReactElement;
  backgroundColor: string;
};

export function ConnectivityBannerLayout(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.banner} style={{ backgroundColor: props.backgroundColor }}>
      {React.cloneElement(props.iconElement, {
        className: `${classes.icon} ${props.iconElement.props.className}`
      })}
      <div className={classes.textContainer}>{props.textElement}</div>
    </div>
  );
}
