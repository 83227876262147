import { useState } from 'react';
import withChatContext from '../utils/withChatContext';
/**
 * @return {null}
 */

function SalesforceChat(props) {
  // TODO
  // The reason this is here is because toggling on this salesforce chat widget
  // from launch darkly if it was toggled on previously without refreshing the page
  // will cause the app to crash. Needs to be looked at once other higher priority tasks are done.
  const [toggledOnce, setToggledOnce] = useState(false);

  const loadScripts = () => {
    let script1 = document.createElement('script');
    script1.classList.add('salesforce-chat-widget');
    // script1.async = true;
    script1.type = 'text/javascript';
    script1.src = 'https://service.force.com/embeddedservice/5.0/esw.min.js';
    script1.onload = () => {
      let script2 = document.createElement('script');
      script2.classList.add('salesforce-chat-widget');
      script2.async = true;
      script2.type = 'text/javascript';
      // CSP hash: sha256-y09stCJmlgyISS7nN4pi6f/XVZ9fabF+BbrjP+/ocmE=
      script2.innerHTML = `
        let initESW = function (gslbBaseURL) {
          user = JSON.parse(localStorage.getItem('user'));
          embedded_svc.settings.displayHelpButton = true;
          embedded_svc.settings.language = 'en-US';
          embedded_svc.settings.defaultMinimizedText = 'Get Help';
          embedded_svc.settings.disabledMinimizedText = 'Agent Offline'; 
          embedded_svc.settings.prepopulatedPrechatFields = user ? {
              FirstName: user.firstName,
              LastName: user.lastName,
              Email: user.email,
              Subject: 'Chat with ' + user.firstName + ' ' + user.lastName
          } : {};
          embedded_svc.settings.enabledFeatures = ['LiveAgent'];
          embedded_svc.settings.entryFeature = 'LiveAgent';
          embedded_svc.init(
            'https://dwelo.my.salesforce.com',
            'https://help.dwelo.com/',
            gslbBaseURL,
            '00DG0000000knyi',
            'Chat_Team',
            {
              baseLiveAgentContentURL: 'https://c.la2-c2-iad.salesforceliveagent.com/content',
              deploymentId: '5721M000000PVqU',
              buttonId: '5731M000000PWgN',
              baseLiveAgentURL: 'https://d.la2-c2-iad.salesforceliveagent.com/chat',
              eswLiveAgentDevName: 'Chat_Team',
              isOfflineSupportEnabled: false
            }
          );
        };
        if (!window.embedded_svc) {
          let s = document.createElement('script');
          s.classList.add('salesforce-chat-widget');
          s.setAttribute('src', 'https://dwelo.my.salesforce.com/embeddedservice/5.0/esw.min.js');
          s.onload = function () {
            initESW(null);
          };
          document.body.appendChild(s);
        } else {
          initESW('https://service.force.com');
        }
        `;

      document.body.appendChild(script2);
    };

    document.body.appendChild(script1);
  };

  if (props.context.displayChat && !toggledOnce) {
    loadScripts();
    setToggledOnce(true);
  }

  return null;
}

export default withChatContext(SalesforceChat);
