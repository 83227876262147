export type DeviceCapabilitiesModel = DoorbellModel;

// Doorbells

export enum ChimeTypeAttr {
  Digital = 'D',
  Mechanical = 'M',
  None = 'N'
}

export enum RingChimeAttr {
  Yes = 'Y'
}

export enum DoorbellModel {
  RingPro = 'RING_PRO',
  RingWired = 'RING_WIRED'
}
export const TransformerModel = {
  DoorbellTransformer: 'DOORBELL_TRANSFORMER'
} as const;
export const ChimeModel = {
  RingChime: 'RING_CHIME'
} as const;

// Contact Sensors

export const ContactSensorModel = {
  Default: 'DEFAULT_CONTACT_SENSOR'
} as const;

export enum FenestrationAttrId {
  ConventionalDoor = 'D',
  SlidingDoor = 'S',
  Window = 'W'
}

// Water Sensors

export const WaterSensorModel = {
  Default: 'DEFAULT_LEAK_SENSOR'
} as const;

// Motion Sensors

export const MotionSensorModel = {
  Default: 'DEFAULT_MOTION_SENSOR'
} as const;
