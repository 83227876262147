import * as React from 'react';
import { ErrorBanner } from '../../BannerTemplates/ErrorBanner';
import { Button, createStyles, Link, Theme, Typography } from '@material-ui/core';
import {
  DWELO_CARE_PHONE,
  DWELO_CARE_PHONE_HREF
} from '../../../Constants/installerAppConstants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ol: {
      paddingLeft: '20px'
    }
  })
);

type Props = {
  onActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function FailingCodesBanner(props: Props) {
  const classes = useStyles();

  return (
    <ErrorBanner
      title={'Too many codes are failing.'}
      body={
        <React.Fragment>
          <Typography>Try these steps to resolve the issue:</Typography>
          <ol className={classes.ol}>
            <li>
              <Typography>Factory reset the lock.</Typography>
            </li>
            <li>
              <Typography>Exclude and re-include the lock.</Typography>
            </li>
            <li>
              <Typography>
                Contact Dwelo support at{' '}
                <Link href={DWELO_CARE_PHONE_HREF}>{DWELO_CARE_PHONE}</Link> or 'Get Help'
                below.
              </Typography>
            </li>
          </ol>
        </React.Fragment>
      }
      action={
        <Button variant="contained" color="secondary" onClick={props.onActionClick}>
          OK
        </Button>
      }
    />
  );
}
