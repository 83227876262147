import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import lock from '../../Common/images/lock.svg';
import thermometer from '../../Common/images/thermometer.svg';
import sun from '../../Common/images/sun.svg';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import OpacityOutlinedIcon from '@material-ui/icons/OpacityOutlined';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import * as sfConstants from '../../Common/Constants/salesforceConstants';
import '../../Common/stylesheets/global.scss';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { YALE_LOCK_MASTER_CODES } from '../../Common/Constants/launchDarklyConstants';

const styles = (theme) => ({
  primaryText: {
    fontSize: '20px'
  },
  avatarBackground: {
    backgroundColor: 'transparent'
  },
  spacing: {
    padding: '8px 0px 8px 0px',
    marginBottom: '25px',
    marginTop: '-20px',
    borderRadius: '5px'
  },
  bordered: {
    border: 2,
    borderStyle: 'solid',
    borderColor: grey[500],
    backgroundColor: grey[100]
  },
  placeholderText: {
    width: '85%',
    lineHeight: '24px'
  }
});

function DevicePlaceholder(props) {
  const { classes } = props;
  const [device, setDevice] = useState(props.device);
  const { [YALE_LOCK_MASTER_CODES]: yaleFlag } = useFlags();

  useEffect(() => {
    setDevice(props.device);
  }, [props.device]);

  const getIcon = () => {
    if (
      [sfConstants.DeviceTypes.LOCK, sfConstants.DeviceTypes.PRIVACY_LOCK].includes(
        device[sfConstants.SharedVars.DEVICE_TYPE]
      )
    ) {
      return <img src={lock} alt="lock" />;
    } else if (
      device[sfConstants.SharedVars.DEVICE_TYPE] === sfConstants.DeviceTypes.THERMOSTAT
    ) {
      return <img src={thermometer} alt="thermostat" />;
    } else if (
      [
        sfConstants.DeviceTypes.PRIMARY_SWITCH,
        sfConstants.DeviceTypes.ADD_ON_SWITCH,
        sfConstants.DeviceTypes.DIMMER_SWITCH,
        sfConstants.DeviceTypes.OUTLET
      ].includes(device[sfConstants.SharedVars.DEVICE_TYPE])
    ) {
      return <img src={sun} alt="switch" />;
    } else if (device[sfConstants.SharedVars.DEVICE_TYPE] === sfConstants.DeviceTypes.SENSOR) {
      return (
        <OpacityOutlinedIcon alt="sensor" fontSize={'medium'} style={{ color: 'black' }} />
      );
    } else if (
      device[sfConstants.SharedVars.DEVICE_TYPE] === sfConstants.DeviceTypes.VOICE_CONTROL
    ) {
      return (
        <RecordVoiceOverOutlinedIcon
          alt="voice"
          style={{ color: 'black' }}
          fontSize={'medium'}
        />
      );
    } else {
      return '';
    }
  };

  const getInclusionButton = () => {
    if (yaleFlag) {
      return (
        <IconButton
          name={device[sfConstants.SharedVars.MODEL]}
          style={{ border: '1px solid #D7D7D7', marginLeft: '0.5rem' }}
          onClick={props.startInclusion}
        >
          <AddIcon />
        </IconButton>
      );
    } else {
      return null;
    }
  };

  const icon = getIcon();
  const inclusionButton = getInclusionButton();

  return (
    <ListItem
      className={`${classes.spacing} ${classes.bordered}`}
      style={{ paddingRight: 20 }}
    >
      <ListItemAvatar>
        <Avatar classes={{ colorDefault: classes.avatarBackground }}>{icon}</Avatar>
      </ListItemAvatar>
      <Typography variant={'overline'} className={classes.placeholderText}>
        {device[sfConstants.SharedVars.QUANTITY]}
        {' x '}
        {device[sfConstants.SharedVars.MODEL]}
      </Typography>
      {inclusionButton}
    </ListItem>
  );
}

export default withStyles(styles)(DevicePlaceholder);
