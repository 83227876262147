export function INCLUDE(hub_serial) {
  return 'v4/hub/' + hub_serial + '/inclusion/';
}
export function EXCLUDE(hub_serial) {
  return 'v4/hub/' + hub_serial + '/exclusion/';
}
export function FAIL_DEVICE(hub_serial) {
  return 'v4/hub/' + hub_serial + '/faildevice/';
}
export function REBOOT_HUB(hub_serial) {
  return 'v4/hub/' + hub_serial + '/reboot/';
}
export function RESTART_HUB(hub_serial) {
  return 'v4/hub/' + hub_serial + '/restart/';
}
export function RESET_CONTROLLER(hub_serial) {
  return 'v4/hub/' + hub_serial + '/resetnetwork/';
}
export function HEAL_NETWORK(hub_serial) {
  return 'v4/hub/' + hub_serial + '/healnetwork/';
}
export function REFRESH_STATUS(deviceId) {
  return 'v4/device/' + deviceId + '/refreshstatus/';
}
export function RESEND_PINS(gatewayId) {
  return 'access/lock_access_code/' + gatewayId + '/resend/';
}
export const REMOVE_HUB = 'v4/address/disassociate/';
export const TRANSFER_PERMISSIONS = 'v4/permission/transfer/';

export function DELETE_NEST_DEVICE(gateway_id, serial_id) {
  return `/v3/nest/device/${gateway_id}/${serial_id}/`;
}
