/*
 * Please use deviceConstants_v2.ts if an alternative is available there.
 * The contents in deviceConstants.ts should be eventually moved over to _v2
 * in the form of enums (or something else) when it is reasonable to do so as we gradually move to more typescript.
 */

// Use DweloDeviceType (in the _v2 file) instead of these device types, if possible.
export const NEST = 'nest';
export const GATEWAY = 'gateway';
export const LOCK = 'lock';
export const SWITCH = 'switch';
export const THERMOSTAT = 'thermostat';
export const DIMMER = 'dimmer';
export const BINARY_SENSOR = 'binary_sensor';
export const LIGHT = 'light'; // sometimes used by the installer app to refer to switches or dimmers
export const DOORBELL = 'doorbell';

export const WIFI_DEVICES = [NEST];

export const DEVICE_TYPES_RELATIVE_TO_YALE = {
  IS_YALE: 'is-yale',
  NOT_YALE: 'not-yale',
  UNKNOWN: 'unknown'
};

export enum DeviceCommand {
  Lock = 'lock'
}

export const DEVICE_LOCATIONS = [
  'Back Door',
  'Bar',
  'Bath',
  'Bathroom',
  'Bathroom 2',
  'Bedroom',
  'Bedroom 2',
  'Bedroom 2 Closet',
  'Bedroom 2 Fan',
  'Bedroom 2 Outlet',
  'Bedroom 3',
  'Bedroom 3 Closet',
  'Bedroom 3 Fan',
  'Bedroom 3 Outlet',
  'Bedroom Closet',
  'Bedroom Fan',
  'Bedroom Outlet',
  'Chandelier',
  'Closet',
  'Countertop',
  'Den',
  'Desk',
  'Dining Room',
  'Dining Room Fan',
  'Dining Room Outlet',
  'Dishwasher',
  'Downstairs Thermostat',
  'Entryway',
  'Fan',
  'Foyer',
  'Front Door',
  'Front Porch',
  'Garage Entry',
  'Hallway',
  'Island',
  'Kitchen',
  'Kitchen 2',
  'Laundry Room',
  'Living Room',
  'Living Room 2',
  'Living Room Fan',
  'Living Room Outlet',
  'Loft',
  'Master Bathroom',
  'Master Bedroom',
  'Master Bedroom Fan',
  'Master Bedroom Outlet',
  'Master Bedroom Closet',
  'Master Bedroom Hallway',
  'Media Box',
  'Office',
  'Office Outlet',
  'Patio',
  'Pendant',
  'Porch',
  'Side Door',
  'Sink',
  'Study',
  'Thermostat',
  'Thermostat 2',
  'Track Lights',
  'Upstairs Thermostat',
  'Utility Closet',
  'Vanity',
  'Water Heater'
];
