import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';
import Header from '../../Common/Headers/Header';
import {
  Theme,
  ThemeProvider as MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import PairingMessage from './PairingMessage';
import StatusBar from '../../Common/Headers/StatusBar';
import Button from '@material-ui/core/Button';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { resetFabPosition } from '../../Common/utils/commonUtils';
import AlertDialog from '../../Common/Popups/AlertDialog';

import * as deviceConstants from '../../Common/Constants/deviceConstants';
import theme from '../../Common/Themes/login_theme';
import button_theme_v2 from '../../Common/Themes/button_theme_v2';
import client from '../../Common/utils/client';
import * as routes from '../unitdetail_apiRoutes';
import { spinnerService } from '../../Common/utils/spinnerService';
import Spinner from '../../Common/Loading/Spinner';
import logo from '../../Common/images/logo.svg';
import axios from 'axios';

import '../../Common/stylesheets/global.scss';
import {
  HANDLE_S2_INCLUSION_ATTEMPTS,
  USE_SALESFORCE_INSTALL_LOCATIONS,
  YALE_LOCK_MASTER_CODES
} from '../../Common/Constants/launchDarklyConstants';
import { DEVICE_TYPES_RELATIVE_TO_YALE as yale } from '../../Common/Constants/deviceConstants';
import {
  EXCLUSION_DURATION,
  EXCLUSION_DURATION_YALE,
  INCLUSION_ATTEMPTS_BEFORE_S2_UI,
  INCLUSION_DURATION,
  INCLUSION_DURATION_MS,
  INCLUSION_EXCLUSION_STAGES as stages
} from '../../Common/Constants/installerAppConstants';
import DeviceLocationSelect from '../../Common/Dropdowns/DeviceLocationSelect/DeviceLocationSelect';
import YaleHeader from './YaleHeader';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import * as commonStyles from '../../Common/styles/styles';

import { TextField } from '@material-ui/core';
import { getOrCreateStreamForHub } from '../../Common/utils/twilio';
import { DeviceToBeInstalled, Unit, User } from '../../Common/Types/cloudApi';
import { Styles } from '@material-ui/core/styles/withStyles';
import {
  createMasterCode,
  getMasterCodes,
  MasterCodesResponse
} from '../common/yale/masterCodes';
import { getIntervalFromEnv } from '../../Common/utils/envService';
import { DweloDeviceType } from '../../Common/Constants/deviceConstants_v2';
import { SensorPlacementSelect } from '../../Common/Dropdowns/SensorPlacementSelect';
import { SensorPlacement } from '../../Common/Constants/sensorPlacementConstants';
import { convertPlacementToMetadataIdUsingSalesforceDevices } from '../../Common/utils/deviceCapabilities';
import { isUnitV2Path, makeDeviceRoute } from '../../Common/utils/routes';
import { RouteComponentProps } from 'react-router';
import SyncStream from 'twilio-sync/lib/streams/syncstream';

const styles: Styles<Theme, any, any> = (theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: '16px',
    backgroundColor: '#4ad4d4'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%'
  },
  inputText: {
    fontSize: '18px'
  },
  removeIcon: {
    color: '#fff'
  },
  countdown: {
    color: '#F6861E',
    fontSize: '180px',
    margin: '0 auto',
    textAlign: 'center',
    marginBottom: '-24px'
  },
  yaleCountdown: {
    color: '#F6861E',
    fontSize: '90px',
    margin: '0 auto',
    textAlign: 'center',
    marginBottom: '-24px'
  },
  button: {
    margin: '24px 24px 56px 6px',
    float: 'right'
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  circularProgress: {
    ...commonStyles.buttonCircularProgress
  }
});

type RouteParams = {
  communityId: string;
  unitId: string;
};

type InclusionProps = RouteComponentProps<RouteParams> & {
  yaleLock: string;
  exclusion: boolean;
  flags: any | undefined;
  classes: any;
  devices: { uid: number }[];
  return: any;
  user: User;
  unit: Unit;
  sfConfiguration: any;
  devicesToBeInstalled?: DeviceToBeInstalled[];
  isIotHub: boolean | undefined;
};

interface InclusionState {
  stage: stages;
  yaleLock: string;
  yaleCode: string;
  inactiveYaleCodes: string[];
  error: boolean;
  errorMessage: string;
  transitioning: boolean;
  waiting: boolean;
  countdown: number;
  device_name: string;
  sensors_failed: boolean;
  exclusion: boolean;
  countdownInterval: null | NodeJS.Timeout;
  sensorInterval: null | NodeJS.Timeout;
  sensorTimeout: null | NodeJS.Timeout;
  initial_device_ids: number[];
  master_timeout: null | NodeJS.Timeout;
  new_device: any;
  devices: any[];
  streaming: boolean;
  showResetDialog: boolean;
  inclusionAttempts: number;
  sensorPlacement: SensorPlacement | '';
  syncStream: SyncStream | null;
  awsWebSocket: WebSocket | null;
}

const ButtonText = {
  TRY_AGAIN: 'TRY AGAIN',
  GO_BACK: 'GO BACK',
  SKIP_EXCLUSION: 'SKIP EXCLUSION',
  EXCLUDE: 'EXCLUDE'
};

class Inclusion extends Component<InclusionProps, InclusionState> {
  private __source: any;
  private __cancel: { cancelToken: any }; // change this later to cancelToken
  private classes: any;
  private stream: any;

  constructor(props) {
    super(props);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const cancel = {
      cancelToken: source.token
    };
    this.__source = source;
    this.__cancel = cancel;
    const { classes } = props;
    this.classes = classes;
    this.state = {
      stage: stages.PAIRING_START,
      yaleLock: props.yaleLock,
      yaleCode: '',
      inactiveYaleCodes: [],
      error: false,
      errorMessage: '',
      transitioning: false,
      waiting: false,
      countdown: INCLUSION_DURATION,
      device_name: this.getDefaultDeviceName(),
      sensors_failed: false,
      exclusion: this.props.exclusion,
      countdownInterval: null,
      sensorInterval: null,
      sensorTimeout: null,
      initial_device_ids: this.props.devices.map((d) => d.uid),
      master_timeout: null,
      new_device: null,
      devices: this.props.devices,
      streaming: false,
      showResetDialog: false,
      inclusionAttempts: 0,
      sensorPlacement: '',
      syncStream: null,
      awsWebSocket: null
    };
  }

  getDefaultDeviceName = () => {
    return this.props.flags && this.props.flags[USE_SALESFORCE_INSTALL_LOCATIONS]
      ? ''
      : 'Unnamed';
  };
  go_to_stream_failed = (errorMessage = '') => {
    this.setState(
      {
        stage: stages.PAIRING_START,
        error: true,
        errorMessage: errorMessage,
        transitioning: false,
        waiting: false,
        countdown: INCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false,
        streaming: false
      },
      () => {
        resetFabPosition();
        this.stop_timer();
        this.abort_current_state();
      }
    );
  };

  go_to_pairing_start = () => {
    if (this.state.exclusion) {
      this.go_to_exclusion_prep();
      return;
    }
    this.setState(
      {
        stage: stages.PAIRING_START,
        error: false,
        errorMessage: '',
        transitioning: false,
        waiting: false,
        countdown: INCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        resetFabPosition();
      }
    );
  };

  go_to_exclusion_prep = () => {
    this.setState(
      {
        stage: stages.EXCLUSION_PREP,
        error: false,
        errorMessage: '',
        transitioning: true,
        waiting: false,
        countdown:
          this.state.yaleLock === yale.IS_YALE ? EXCLUSION_DURATION_YALE : EXCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false,
        exclusion: true
      },
      () => {
        if (this.state.yaleLock === yale.IS_YALE) {
          getMasterCodes(this.props.unit.uid)
            .then(this.updateStateWithMasterCodes)
            .finally(() => this.startExclusion());
        } else {
          this.startExclusion();
        }
      }
    );
  };

  go_to_exclusion_start = () => {
    this.setState(
      {
        stage: stages.EXCLUSION_START,
        error: false,
        errorMessage: '',
        transitioning: false,
        waiting: true,
        countdown:
          this.state.yaleLock === yale.IS_YALE ? EXCLUSION_DURATION_YALE : EXCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        this.startCountdown();
      }
    );
  };

  go_to_excluding = () => {
    this.setState(
      {
        stage: stages.EXCLUDING,
        error: false,
        errorMessage: '',
        transitioning: true,
        waiting: false,
        countdown:
          this.state.yaleLock === yale.IS_YALE ? EXCLUSION_DURATION_YALE : EXCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        this.start_timer();
        this.clear_sensor_timers();
      }
    );
  };

  go_to_exclusion_success = () => {
    this.setState(
      {
        stage: stages.EXCLUSION_SUCCESS,
        error: false,
        errorMessage: '',
        transitioning: false,
        waiting: false,
        countdown:
          this.state.yaleLock === yale.IS_YALE ? EXCLUSION_DURATION_YALE : EXCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        this.stop_timer();
        this.clear_sensor_timers();
        resetFabPosition();
      }
    );
  };

  go_to_inclusion_prep = () => {
    this.setState(
      {
        stage: stages.INCLUSION_PREP,
        error: false,
        errorMessage: '',
        transitioning: true,
        waiting: false,
        countdown: INCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        this.startInclusion();
      }
    );
  };

  go_to_inclusion_start = () => {
    this.setState(
      {
        stage: stages.INCLUSION_START,
        error: false,
        errorMessage: '',
        transitioning: false,
        waiting: true,
        countdown: INCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        this.startCountdown();
      }
    );
  };

  go_to_including = () => {
    this.setState(
      {
        stage: stages.INCLUDING,
        error: false,
        errorMessage: '',
        transitioning: true,
        waiting: false,
        countdown: INCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        this.start_timer(INCLUSION_DURATION_MS);
        this.clear_sensor_timers();
      }
    );
  };

  go_to_device_info = () => {
    this.setState(
      {
        stage: stages.DEVICE_INFO,
        error: false,
        errorMessage: '',
        transitioning: true,
        waiting: false,
        countdown: INCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        this.start_sensor_polling();
      }
    );
  };

  go_to_inclusion_success = () => {
    this.setState(
      {
        stage: stages.INCLUSION_SUCCESS,
        error: false,
        errorMessage: '',
        transitioning: false,
        waiting: false,
        countdown: INCLUSION_DURATION,
        device_name: this.getDefaultDeviceName(),
        sensors_failed: false
      },
      () => {
        this.clear_sensor_timers();
        this.stop_timer();
        resetFabPosition();
        if (this.state.yaleLock === yale.IS_YALE) {
          createMasterCode(this.props.unit.uid);
        }
      }
    );
  };

  go_to_error_state = () => {
    this.setState(
      (prevState) => ({
        error: true,
        inclusionAttempts: !prevState.exclusion
          ? prevState.inclusionAttempts + 1
          : prevState.inclusionAttempts
      }),
      () => {
        this.stop_timer();
        this.abort_current_state();
      }
    );
  };

  go_to_error_state_without_abort = (incrementAttempts = false) => {
    this.setState(
      (prevState) => ({
        error: true,
        inclusionAttempts:
          !prevState.exclusion && !prevState.error && incrementAttempts
            ? prevState.inclusionAttempts + 1
            : prevState.inclusionAttempts
      }),
      () => {
        this.stop_timer();
      }
    );
  };

  go_to_sensor_error_state = () => {
    this.setState(
      {
        error: true,
        sensors_failed: true
      },
      () => {
        this.stop_timer();
        this.clear_sensor_timers();
      }
    );
  };

  transition_to_next_state = () => {
    if (!this.state.exclusion) {
      switch (this.state.stage) {
        case stages.PAIRING_START:
          this.go_to_inclusion_prep();
          break;
        case stages.INCLUSION_PREP:
          this.go_to_inclusion_start();
          break;
        case stages.INCLUSION_START:
          this.go_to_device_info();
          break;
        case stages.DEVICE_INFO:
          this.go_to_inclusion_success();
          break;
        case stages.INCLUSION_SUCCESS:
          this.update_device_and_exit();
          break;
        default:
          break;
      }
    } else {
      switch (this.state.stage) {
        case stages.EXCLUSION_PREP:
          this.go_to_exclusion_start();
          break;
        case stages.EXCLUSION_START:
          this.go_to_excluding();
          break;
        case stages.EXCLUDING:
          this.go_to_exclusion_success();
          break;
        case stages.EXCLUSION_SUCCESS:
          this.exit_to_unit_detail_and_reload();
          break;
        default:
          break;
      }
    }
  };

  exit_to_unit_detail = () => {
    this.props.return();
  };

  exit_to_unit_detail_and_reload = () => {
    window.location.reload();
  };

  update_device_and_exit = () => {
    spinnerService.show('default');
    if (this.state.new_device) {
      const body = {
        givenName: this.state.device_name
      };
      if (this.state.new_device.deviceType === DweloDeviceType.BinarySensor) {
        body['metadata_id'] = convertPlacementToMetadataIdUsingSalesforceDevices(
          this.state.sensorPlacement,
          this.props.devicesToBeInstalled
        );
      }
      client
        .put(routes.UPDATE_DEVICE(this.state.new_device.uid), body)
        .catch((error) => {
          Sentry.captureException(error);
        })
        .finally(() => {
          // Only take user to the device screen for certain devices.
          // Locks can be tested on the unit page, so no need to take them to device page.
          if (this.state.new_device.deviceType === DweloDeviceType.Lock) {
            window.location.reload();
          } else {
            const isUnitV2 = isUnitV2Path(this.props.match.path);
            this.props.history.push(
              makeDeviceRoute(
                this.props.unit.communityId,
                this.props.unit.uid,
                this.state.new_device.uid,
                isUnitV2
              )
            );
          }
        });
    } else {
      window.location.reload();
    }
  };

  getActionData(action) {
    return {
      action: action,
      client: 'concierge',
      userId: this.props.user.uid
    };
  }

  startExclusion = () => {
    let data = this.getActionData('start');
    client
      .post(routes.EXCLUDE(this.props.unit.hub_serial), data)
      .then(() => {
        if (!this.state.error) {
          this.go_to_exclusion_start();
        }
      })
      .catch((err) => {
        this.go_to_error_state_without_abort();
        Sentry.captureException(err);
      });
  };
  startInclusion = () => {
    let data = this.getActionData('start');
    client
      .post(routes.INCLUDE(this.props.unit.hub_serial), data)
      .then(() => {
        if (!this.state.error) {
          this.go_to_inclusion_start();
        }
      })
      .catch((err) => {
        this.go_to_error_state_without_abort();
        Sentry.captureException(err);
      });
  };
  abortInclusion = () => {
    let data = this.getActionData('abort');
    client.post(routes.INCLUDE(this.props.unit.hub_serial), data).catch((err) => {
      Sentry.captureException(err);
    });
  };
  abortExclusion = () => {
    let data = this.getActionData('abort');
    client.post(routes.EXCLUDE(this.props.unit.hub_serial), data).catch((err) => {
      Sentry.captureException(err);
    });
  };

  abort_current_state = () => {
    if (
      [stages.INCLUSION_PREP, stages.INCLUSION_START, stages.INCLUDING].includes(
        this.state.stage
      )
    ) {
      this.abortInclusion();
    } else if (
      [stages.EXCLUSION_PREP, stages.EXCLUSION_START, stages.EXCLUDING].includes(
        this.state.stage
      )
    ) {
      this.abortExclusion();
    }
  };
  refreshSensors = () => {
    client
      .get(routes.SENSOR(this.props.unit.hub_serial), this.__cancel)
      .then((res) => {
        let data = res.data.results;
        if (data.length > 1) {
          data.forEach((d) => {
            if (!this.state.initial_device_ids.includes(d.uid)) {
              this.setState({
                new_device: d,
                devices: data
              });
              this.go_to_inclusion_success();
              return false;
            }
            return true;
          });
        }
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          Sentry.captureException(thrown);
        }
      });
  };

  start_sensor_polling = () => {
    let sensorRefresh: null | NodeJS.Timeout = null;
    let sensorTimeout: null | NodeJS.Timeout = null;

    if (this.props.unit.gatewayId) {
      sensorRefresh = setInterval(() => {
        this.refreshSensors();
      }, parseInt(getIntervalFromEnv('REACT_APP_SENSOR_POLL'), 10));

      sensorTimeout = setTimeout(() => {
        if (this.state.stage === stages.DEVICE_INFO) {
          this.go_to_error_state_without_abort();
          this.stop_timer();
          this.clear_sensor_timers();
        }
      }, INCLUSION_DURATION_MS);
    }
    this.setState({
      sensorInterval: sensorRefresh,
      sensorTimeout: sensorTimeout
    });
  };

  clear_sensor_timers = () => {
    if (this.state.sensorInterval) {
      clearInterval(this.state.sensorInterval);
    }
    if (this.state.sensorTimeout) {
      clearTimeout(this.state.sensorTimeout);
    }
    if (this.state.countdownInterval) {
      clearInterval(this.state.countdownInterval);
    }
  };

  connectToAwsWebsocketService = (isRetry = false) => {
    const token = localStorage.getItem('token');

    const webSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_SERVICE_URL}?token=${token}&hubSerialNumber=${this.props.unit.hub_serial}`
    );

    webSocket.addEventListener('open', (event) => {
      // The `streaming` state seems redundant, but keeping it here for now
      // for compatibility with the old flow since it isn't straightforward to remove.
      this.setState({ awsWebSocket: webSocket, streaming: true });
    });

    webSocket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.state) {
        this.decode_and_transition_states(data.state);
      }
    });

    webSocket.addEventListener('close', (event) => {
      if (event.reason === 'intentionally_closed_by_client') {
        return;
      }
      if (isRetry) {
        this.go_to_stream_failed('Connection to AWS was unexpectedly closed.');
        Sentry.captureException(new Error('Connection to AWS was unexpectedly closed.'), {
          tags: {
            hubSerialNumber: this.props.unit.hub_serial
          }
        });
      } else {
        this.connectToAwsWebsocketService(true);
      }
    });
  };

  connectToSyncStream = async () => {
    const { hub_serial, gatewayId } = this.props.unit;
    // Shouldn't really happen, but need to check before calling getOrCreateStreamForHub()
    if (!hub_serial || !gatewayId) {
      throw new TypeError('Unable to set up stream, hub_serial or gatewayId are invalid.');
    }

    const streamResult = await getOrCreateStreamForHub(hub_serial, gatewayId);
    if (streamResult.error) {
      throw streamResult.error;
    }

    this.subscribe_to_message_stream(streamResult.stream);
  };

  subscribe_to_message_stream = (syncStream: SyncStream) => {
    this.setState({ syncStream, streaming: true }, () => {
      syncStream.on('messagePublished', (args) => {
        try {
          this.decode_and_transition_states(args.message.value.state);
        } catch (err) {
          Sentry.captureException(err);
        }
      });
    });
  };

  decode_and_transition_states = (message) => {
    if (message.AddNode) {
      if (Array.isArray(message.AddNode)) {
        if (message.AddNode[0] === 'Failed') {
          this.stop_timer();
          this.go_to_error_state_without_abort(true);
        } else if (message.AddNode[0] === 'Done') {
          this.reset_timer();
          if (!this.state.error && this.state.stage !== stages.DEVICE_INFO) {
            this.go_to_device_info();
          }
        } else if (message.AddNode[0] === 'GettingNodeInfo') {
          this.reset_timer();
          if (!this.state.error && this.state.stage !== stages.INCLUDING) {
            this.go_to_including();
          }
        } else if (message.AddNode[0] === 'ProtocolDone') {
          this.reset_timer();
          if (!this.state.error && this.state.stage !== stages.INCLUDING) {
            this.go_to_including();
          }
        }
      }
    } else if (message.RemoveNode) {
      if (message.RemoveNode === 'Failed') {
        this.stop_timer();
        this.go_to_error_state_without_abort();
      } else if (message.RemoveNode === 'Done') {
        this.stop_timer();
        if (!this.state.error && this.state.stage !== stages.EXCLUSION_SUCCESS) {
          this.go_to_exclusion_success();
        }
      }
    } else if (message.NetworkChanged) {
      if (message.NetworkChanged === 'Done') {
        if ([stages.INCLUSION_START, stages.INCLUDING].includes(this.state.stage)) {
          this.reset_timer();
          if (!this.state.error && this.state.stage !== stages.DEVICE_INFO) {
            this.go_to_device_info();
          }
        }
        if ([stages.EXCLUSION_START, stages.EXCLUDING].includes(this.state.stage)) {
          this.stop_timer();
          if (!this.state.error && this.state.stage !== stages.EXCLUSION_SUCCESS) {
            this.go_to_exclusion_success();
          }
        }
      }
    } else if (message.DetailedNodeTotals) {
      this.reset_timer();
      if (!this.state.error && this.state.stage !== stages.INCLUDING) {
        this.go_to_including();
      }
    }
  };

  start_timer = (timeout = 90000) => {
    if (this.state.master_timeout) {
      clearTimeout(this.state.master_timeout);
    }
    let masterTimeout = setTimeout(() => {
      this.go_to_error_state();
      this.clear_sensor_timers();
    }, timeout);
    this.setState({ master_timeout: masterTimeout });
  };

  reset_timer = () => {
    if (this.state.master_timeout) {
      clearTimeout(this.state.master_timeout);
      this.start_timer();
    }
  };

  stop_timer = () => {
    if (this.state.master_timeout) {
      clearTimeout(this.state.master_timeout);
      this.setState({ master_timeout: null });
    }
    if (this.state.countdownInterval) {
      clearTimeout(this.state.countdownInterval);
      this.setState({ countdownInterval: null });
    }
  };

  updateStateWithMasterCodes = (
    codes: MasterCodesResponse,
    callback: (() => void) | null = null
  ) => {
    const stateUpdates = {
      yaleCode: codes.activeMasterCode || '',
      inactiveYaleCodes: codes.inactiveMasterCodes
    };
    if (callback) {
      this.setState(stateUpdates, callback);
    } else {
      this.setState(stateUpdates);
    }
  };

  componentDidMount() {
    if (this.props.flags[YALE_LOCK_MASTER_CODES]) {
      if (this.state.yaleLock === yale.IS_YALE) {
        getMasterCodes(this.props.unit.uid).then((codes) =>
          this.updateStateWithMasterCodes(codes, () => this.go_to_pairing_start())
        );
      } else if (this.state.yaleLock === yale.NOT_YALE) {
        this.go_to_pairing_start();
      } else if (this.state.yaleLock === yale.UNKNOWN) {
        this.setState({ stage: stages.CHOOSE_DEVICE });
      }
    } else {
      this.go_to_pairing_start();
      resetFabPosition();
    }

    if (this.props.isIotHub) {
      this.connectToAwsWebsocketService();
    } else {
      this.connectToSyncStream().catch((error) => {
        this.go_to_stream_failed(error.message);
        Sentry.captureException(error);
      });
    }
  }

  componentWillUnmount() {
    this.clear_sensor_timers();
    if (this.state.syncStream) {
      this.state.syncStream.close();
    }
    if (this.state.awsWebSocket) {
      this.state.awsWebSocket.close(1000, 'intentionally_closed_by_client');
    }
    if (
      !this.state.error &&
      ![stages.DEVICE_INFO, stages.INCLUSION_SUCCESS].includes(this.state.stage)
    ) {
      this.abort_current_state();
    }
    if (this.props.unit.hub_serial && this.props.devices.length) {
      this.__source.cancel('Operation canceled by the user.');
    }
  }

  getHeaderMessage() {
    const stage = this.state.stage;
    if (this.state.error) {
      return 'Something went wrong';
    } else if (stage === stages.EXCLUSION_PREP) {
      return 'Preparing hub...';
    } else if (stage === stages.EXCLUSION_START) {
      return 'Hub in exclusion mode';
    } else if (stage === stages.EXCLUDING) {
      return 'Excluding device...';
    } else if (stage === stages.EXCLUSION_SUCCESS) {
      return 'Exclusion successful';
    } else if (stage === stages.INCLUSION_PREP) {
      return 'Preparing hub...';
    } else if (stage === stages.INCLUSION_START) {
      return 'Hub in inclusion mode';
    } else if (stage === stages.INCLUDING) {
      return 'Including device...';
    } else if (stage === stages.DEVICE_INFO) {
      return 'Getting device info...';
    } else if (stage === stages.INCLUSION_SUCCESS) {
      return 'Success! Device added';
    }
  }

  getPairingInfo() {
    if (this.state.error || this.state.stage === stages.INCLUSION_SUCCESS) {
      return '';
    }

    const isYale = this.state.yaleLock === yale.IS_YALE;
    const stage = this.state.stage;
    let pairingText;
    if (stage === stages.EXCLUSION_START && !isYale) {
      pairingText = 'Please put the device in exclusion mode before the timer runs out';
    } else if (stage === stages.EXCLUSION_SUCCESS && !this.state.exclusion) {
      pairingText =
        'Nice work. Now, let’s add the new device to the hub’s Z-Wave network. Please wait 5 seconds before proceeding.';
    } else if (stage === stages.EXCLUSION_SUCCESS && this.state.exclusion) {
      pairingText =
        'Nice work. The device was successfully excluded. Click to navigate back to the unit detail page.';
    } else if (stage === stages.INCLUSION_START && !isYale) {
      pairingText = 'Please put the device in inclusion mode before the timer runs out';
    } else {
      return '';
    }

    return <p style={{ padding: '24px' }}>{pairingText}</p>;
  }

  getPairingMessage() {
    const yaleFlag = this.props.flags[YALE_LOCK_MASTER_CODES];
    const stage = this.state.stage;
    let status = '';

    if (this.state.error) {
      if (stage === stages.PAIRING_START) status = 'start-error';
      if (stage === stages.EXCLUSION_START && !this.state.exclusion)
        status = 'exclude-start-error';
      if (!this.state.sensors_failed && stage === stages.DEVICE_INFO)
        status = 'sensors-failed';
      if ([stages.EXCLUSION_PREP, stages.INCLUSION_PREP].includes(stage))
        status = 'prep-error';
      if ([stages.EXCLUDING, stages.EXCLUSION_START].includes(stage)) status = 'exclude-error';
      if ([stages.INCLUDING, stages.INCLUSION_START].includes(stage)) status = 'include-error';
    } else {
      if (stage === stages.PAIRING_START) status = 'start';
      if (yaleFlag) {
        if (stage === stages.EXCLUSION_START) status = stages.EXCLUSION_START;
        if (stage === stages.CHOOSE_DEVICE) status = stages.CHOOSE_DEVICE;
        if (stage === stages.INCLUSION_START) status = stages.INCLUSION_START;
      }
    }

    if (!status && this.state.sensors_failed) status = 'sensors-failed';
    if (status) {
      let yaleProps = {};
      if (yaleFlag) {
        yaleProps = {
          yaleLock: this.state.yaleLock,
          yaleCode: this.state.yaleCode,
          inactiveYaleCodes: this.state.inactiveYaleCodes,
          exclusion: this.state.exclusion,
          streaming: this.state.streaming,
          handleChooseDevice: this.handleChooseDevice,
          goToInclusion: this.go_to_inclusion_prep,
          goToExclusion: this.go_to_exclusion_prep,
          openResetDialog: this.openResetDialog
        };
      }
      return (
        <PairingMessage
          status={status}
          errorMessage={this.state.errorMessage}
          {...yaleProps}
          inclusionAttempts={this.state.inclusionAttempts}
        />
      );
    } else {
      return '';
    }
  }

  /**
   * When a user clicks the Yes or No button during the choose-device stage
   * to indicate if the device they are including/excluding is a yale lock,
   * this function is called.
   */
  handleChooseDevice = (event) => {
    const yaleLock = event.currentTarget.name;
    this.setState({ yaleLock: yaleLock });

    if (yaleLock === yale.IS_YALE && !this.state.exclusion) {
      getMasterCodes(this.props.unit.uid).then(this.updateStateWithMasterCodes);
    }

    this.go_to_pairing_start();
  };

  getPairingImage() {
    // scr expects type string, not SVG element
    // @ts-ignore
    return <img className="pulsing" src={logo} alt="pairing" />;
  }

  getSensorPlacement() {
    if (
      this.state.stage === stages.INCLUSION_SUCCESS &&
      this.state.new_device?.deviceType === DweloDeviceType.BinarySensor
    ) {
      return (
        <div style={{ padding: '32px 24px 0px' }}>
          <Typography style={{ marginBottom: '16px' }}>What is this sensor for?</Typography>
          <SensorPlacementSelect
            onChange={this.handleSensorPlacementChange}
            value={this.state.sensorPlacement}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  getNameChange() {
    if (this.state.stage === stages.INCLUSION_SUCCESS) {
      return (
        <div style={{ padding: '32px 24px 24px' }}>
          <Typography style={{ marginBottom: '16px' }}>Where is it located?</Typography>
          <MuiThemeProvider theme={theme}>
            <DeviceLocationSelect
              sfConfiguration={this.props.sfConfiguration}
              currentDevice={this.state.new_device}
              devices={this.state.devices}
              value={this.state.device_name}
              id="device_name"
              label="Device Name"
              name="device_name"
              options={deviceConstants.DEVICE_LOCATIONS}
              getOptionLabel={(option) => option}
              style={{ width: '100%' }}
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <TextField {...params} label="Device Name" variant="outlined" />
              )}
              onChange={this.handleDeviceNameChange}
            />
          </MuiThemeProvider>
        </div>
      );
    } else {
      return '';
    }
  }

  handleSensorPlacementChange = (
    event: React.ChangeEvent<{ name: string; value: SensorPlacement }>
  ) => {
    this.setState({
      sensorPlacement: event.target.value
    });
  };

  handleDeviceNameChange = (event, value) => {
    if (value) {
      this.setState({
        device_name: value
      });
    }
  };

  getFab() {
    const yaleFlag = this.props.flags[YALE_LOCK_MASTER_CODES];

    const pairingStart = !yaleFlag && this.state.stage === stages.PAIRING_START;
    // pairingStartNotYale implies that yaleFlag is true but we are not including/excluding a yale lock,
    // so we can go ahead and show the non-yale workflow's fab button
    const pairingStartNotYale =
      yaleFlag &&
      this.state.stage === stages.PAIRING_START &&
      this.state.yaleLock === yale.NOT_YALE;
    const successStage = [stages.EXCLUSION_SUCCESS, stages.INCLUSION_SUCCESS].includes(
      this.state.stage
    );

    if ((pairingStart || pairingStartNotYale || successStage) && !this.state.error) {
      const unfilledDropdown =
        !this.state.device_name ||
        (!this.state.sensorPlacement &&
          this.state.new_device?.deviceType === DweloDeviceType.BinarySensor);
      const waitingForNameToBeSelected =
        this.props.flags[USE_SALESFORCE_INSTALL_LOCATIONS] &&
        this.state.stage === stages.INCLUSION_SUCCESS &&
        unfilledDropdown;
      return (
        <Fab
          color="primary"
          aria-label="Next Step"
          className={this.classes.fab + ' keep-fixed'}
          disabled={!this.state.streaming || waitingForNameToBeSelected}
          onClick={this.transition_to_next_state}
        >
          <ArrowForwardIcon />
          {!this.state.streaming && (
            <CircularProgress size={24} className={this.classes.circularProgress} />
          )}
        </Fab>
      );
    } else {
      return '';
    }
  }

  getButton(remove, message, back) {
    if (remove) {
      return (
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          className={this.classes.button}
          onClick={this.go_to_exclusion_prep}
        >
          {ButtonText.EXCLUDE}
        </Button>
      );
    } else if (message === 'SKIP EXCLUSION') {
      return (
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          className={this.classes.button}
          onClick={this.go_to_inclusion_prep}
        >
          <SkipNextIcon className={this.classes.leftIcon} />
          {message}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          className={this.classes.button}
          onClick={back ? this.exit_to_unit_detail : this.go_to_pairing_start}
        >
          {message}
        </Button>
      );
    }
  }

  getActionButtons() {
    const stage = this.state.stage;
    const error = this.state.error;
    const isYale = this.state.yaleLock === yale.IS_YALE;
    const showExcludeButton =
      !this.props.flags[HANDLE_S2_INCLUSION_ATTEMPTS] ||
      this.state.inclusionAttempts > INCLUSION_ATTEMPTS_BEFORE_S2_UI;
    let button_left: JSX.Element = <></>;
    let button_right: JSX.Element = <></>;

    if (error && stage === stages.PAIRING_START) {
      button_right = this.getButton(false, ButtonText.GO_BACK, true);
    } else if (error && (stage === stages.EXCLUSION_PREP || stage === stages.INCLUSION_PREP)) {
      button_right = this.getButton(false, ButtonText.TRY_AGAIN, false);
    } else if (error && !isYale && stage === stages.EXCLUSION_START) {
      button_right = this.getButton(false, ButtonText.TRY_AGAIN, false);
      if (error && stage === stages.EXCLUSION_START && !this.state.exclusion) {
        button_left = this.getButton(false, ButtonText.SKIP_EXCLUSION, false);
      }
    } else if (error && !isYale && stage === stages.INCLUSION_START) {
      button_left = showExcludeButton ? this.getButton(true, null, false) : <></>;
      button_right = this.getButton(false, ButtonText.TRY_AGAIN, false);
    } else if (error && stage === stages.EXCLUDING) {
      button_right = this.getButton(false, ButtonText.TRY_AGAIN, false);
    } else if (error && stage === stages.INCLUDING) {
      button_left = showExcludeButton ? this.getButton(true, null, false) : <></>;
      button_right = this.getButton(false, ButtonText.TRY_AGAIN, false);
    } else if (error && !this.state.sensors_failed && stage === stages.DEVICE_INFO) {
      button_left = this.getButton(true, null, false);
      //button_right = this.getButton(false, 'START OVER', false);
    } else if (this.state.sensors_failed) {
      button_right = this.getButton(true, null, false);
    }

    if (button_left || button_right) {
      return (
        <MuiThemeProvider theme={button_theme_v2}>
          {button_right}
          {button_left}
        </MuiThemeProvider>
      );
    } else {
      return '';
    }
  }

  getStatusBar(message) {
    if (
      ![stages.PAIRING_START, stages.CHOOSE_DEVICE].includes(this.state.stage) &&
      !this.state.error
    ) {
      return <StatusBar message={message} />;
    } else if (this.state.error) {
      return <StatusBar message={message} error={true} />;
    } else {
      return '';
    }
  }

  getYaleHeader = () => {
    if (this.state.yaleLock === yale.IS_YALE && this.state.stage !== stages.CHOOSE_DEVICE) {
      return (
        <YaleHeader
          stage={this.state.stage}
          error={this.state.error}
          exclusion={this.state.exclusion}
        />
      );
    }
    return null;
  };

  startCountdown = () => {
    let countdownInterval = setInterval(() => {
      this.setState(
        {
          countdown: this.state.countdown - 1
        },
        () => {
          if (this.state.countdown === 0 || this.state.countdown < 0) {
            clearInterval(countdownInterval);
            this.go_to_error_state();
          }
        }
      );
    }, 1000);
    this.setState({ countdownInterval: countdownInterval });
  };

  getYaleInclusionButton = () => {
    if (
      !this.state.error &&
      this.state.stage === stages.PAIRING_START &&
      this.state.yaleLock === yale.IS_YALE
    ) {
      return (
        <MuiThemeProvider theme={button_theme_v2}>
          <Button
            variant={'contained'}
            size={'large'}
            color={'secondary'}
            disabled={!this.state.streaming}
            onClick={this.transition_to_next_state}
            style={{
              margin: '32px auto 72px',
              display: 'block'
            }}
          >
            Start Inclusion
            {!this.state.streaming && (
              <CircularProgress size={24} className={this.classes.circularProgress} />
            )}
          </Button>
        </MuiThemeProvider>
      );
    }
    return null;
  };

  resetController = () => {
    client.post(routes.RESET_CONTROLLER(this.props.unit.hub_serial), {
      client: 'concierge',
      userId: this.props.user.uid
    });
    this.setState({ showResetDialog: false });
  };

  openResetDialog = () => {
    this.setState({ showResetDialog: true });
  };

  closeDialog = (event) => {
    this.setState({ showResetDialog: false });
  };

  render() {
    let fab = this.getFab();
    let pairingImage = this.getPairingImage();
    let headerMessage = this.getHeaderMessage();
    let pairingInfo = this.getPairingInfo();
    let pairingMessage = this.getPairingMessage();
    let sensorPlacement = this.getSensorPlacement();
    let deviceName = this.getNameChange();
    let yaleHeader = this.getYaleHeader();
    let statusBar = this.getStatusBar(headerMessage);
    let actionButtons = this.getActionButtons();
    const yaleInclusionButton = this.getYaleInclusionButton();
    // yale uses a different class for smaller font size
    const countdownClass =
      this.props.flags[YALE_LOCK_MASTER_CODES] && this.state.yaleLock === yale.IS_YALE
        ? this.classes.yaleCountdown
        : this.classes.countdown;
    // We want to encourage user to name the device.
    const hideClose =
      this.props.flags[USE_SALESFORCE_INSTALL_LOCATIONS] &&
      this.state.stage === stages.INCLUSION_SUCCESS;

    return (
      <div className="full-height">
        <Header
          headerText={(this.state.exclusion ? 'Exclude' : 'Setup') + ' Z-wave device'}
          back={true}
          user={this.props.user}
          history={this.props.history}
          subheaderText={this.props.unit.communityName}
          subheaderText2={this.props.unit.unit}
          return={this.props.return}
          hideClose={hideClose}
        />
        <AlertDialog
          alertdialog={this.state.showResetDialog}
          title={'Reset Controller?'}
          text={'This will remove every device that has been included.'}
          onClick={this.resetController}
          onClose={this.closeDialog}
        />
        {statusBar}
        {yaleHeader}

        {this.state.transitioning === true && !this.state.error ? pairingImage : ''}
        {this.state.waiting && !this.state.error ? (
          <p className={countdownClass}>{this.state.countdown}</p>
        ) : (
          ''
        )}
        {pairingInfo}
        {pairingMessage}
        {yaleInclusionButton}
        {sensorPlacement}
        {deviceName}
        {actionButtons}
        {fab}
        <Spinner name="default" loadingImage={logo} />
      </div>
    );
  }
}

const _withRouter = withRouter(Inclusion);
const _withStyles = withStyles(styles)(_withRouter);
const _withLDConsumer = withLDConsumer()(_withStyles);

export default _withLDConsumer;
