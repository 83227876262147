export const baseUri = '/door-code/v1/sync/';

export function makeBaseRoute() {
  return baseUri;
}

export function makePauseSyncRoute(deviceId: string | number) {
  return `${baseUri}pause/${deviceId}`;
}

export function makeResumeSyncRoute(deviceId: string | number) {
  return `${baseUri}resume/${deviceId}`;
}

export function makeGetStatusByDeviceRoute(deviceId: string | number) {
  return `${baseUri}device/${deviceId}`;
}

export function makeGetStatusByAddressRoute(addressId: string | number) {
  return `${baseUri}address/${addressId}`;
}
