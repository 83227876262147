import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as sfConstants from '../../Common/Constants/salesforceConstants';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BuildIcon from '@material-ui/icons/Build';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import salt from '../../Common/images/s-circle.svg';
import withStyles from '@material-ui/core/styles/withStyles';
import '../../Common/stylesheets/global.scss';
import '../unit.css';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';

import { Box } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import Button from '@material-ui/core/Button';

import client from '../../Common/utils/client';
import { removePendingWorkTicket } from '../../Common/utils/storageInterface';
import * as Sentry from '@sentry/browser';

import AlertDialog from '../../Common/Popups/AlertDialog';
import * as routes from '../../Units/units_apiRoutes';
import { removeInitialYaleCode } from '../../UnitDetail/common/yale/codeGeneration';

const styles = (theme) => ({
  overrideRootBlue: {
    float: 'right',
    marginTop: '-35px',
    marginRight: '16px',
    position: 'unset',
    color: '#4ad4d4'
  },
  expandButton: {
    marginRight: theme.spacing(5)
  },
  green: {
    color: green[500]
  },
  red: {
    color: red[500]
  },
  nested: {
    paddingLeft: theme.spacing(9),
    marginBottom: theme.spacing(1)
  },
  nestedContent: {
    fontStyle: 'italic',
    marginRight: theme.spacing(5)
  },
  completeLabel: {
    backgroundColor: green[100],
    color: green[500]
  },
  incompleteLabel: {
    backgroundColor: yellow[200],
    color: yellow[800]
  },
  pendingWorkTicket: {
    backgroundColor: red[100]
  },
  label: {
    padding: '3px',
    lineHeight: 1
  }
});

class Unit extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.navigateToUnit = this.navigateToUnit.bind(this);
    this.classes = classes;
    this.state = {
      expandNotes: false,
      workTicketAlert: false
    };
  }

  navigateToUnit(e) {
    removeInitialYaleCode();
    e.preventDefault();
    if (
      this.props.pendingWorkTicket &&
      this.props.pendingWorkTicket.addressId !== this.props.unit.uid
    ) {
      this.props.setLoader();
      client
        .get(this.props.pendingWorkTicket.workTicketRoute)
        .then((res) => {
          const workTicketData = res.data;
          if (
            workTicketData &&
            (sfConstants.WorkTicket.SUBMITTED_STATUSES.includes(
              workTicketData[sfConstants.SharedVars.WORK_TICKET_STATUS]
            ) ||
              workTicketData[sfConstants.SharedVars.WORK_TICKET_STATUS] == null)
          ) {
            removePendingWorkTicket();
            this.goToUnitDetail();
          } else {
            this.props.removeLoader();
            this.setState({
              workTicketAlert: true
            });
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          try {
            if (error.response.status === 401) {
              this.props.history.push('/login');
            } else {
              removePendingWorkTicket();
              this.goToUnitDetail();
            }
          } catch (err) {
            this.props.history.push('/');
          }
        });
    } else if (
      this.props.unit.permission !== undefined &&
      !this.props.unit.permission &&
      this.props.unit.gatewayId
    ) {
      this.props.setLoader();
      client
        .post(routes.REQUEST_PERMISSION, {
          gateway_id: this.props.unit.gatewayId
        })
        .then(() => {
          this.props.removeLoader();
          this.goToUnitDetail();
        })
        .catch((error) => {
          this.props.removeLoader();
          Sentry.captureException(error);
          if (error.response.status === 401) {
            this.props.history.push('/login');
          } else {
            this.props.history.push('/');
          }
        });
    } else {
      this.goToUnitDetail();
    }
  }

  goToPendingUnit = () => {
    const unitPath = this.workTicketIsAvailable() ? 'unit_v2' : 'unit';
    const communityId = this.props.pendingWorkTicket.communityId;
    const unitId = this.props.pendingWorkTicket.addressId;
    return this.props.history.push(`/community/${communityId}/${unitPath}/${unitId}`);
  };

  goToUnitDetail = () => {
    const unitPath = this.workTicketIsAvailable() ? 'unit_v2' : 'unit';
    const communityId = this.props.unit.communityId;
    const unitId = this.props.unit.uid;
    return this.props.history.push(`/community/${communityId}/${unitPath}/${unitId}`);
  };

  closeDialog = (event) => {
    this.setState({
      workTicketAlert: false
    });
  };

  handleNotesClick = () => {
    this.setState((prevState) => ({ expandNotes: !prevState.expandNotes }));
  };

  workTicketIsAvailable = () => {
    return !!this.props.unit[sfConstants.SharedVars.INSTALLER_PREVIEW_DATA];
  };

  render() {
    let names = '';
    this.props.unit.residents.map((resident, index) => {
      if (resident.firstName) {
        let name = resident.firstName.charAt(0).toUpperCase() + '. ' + resident.lastName;
        names += name + ', ';
      } else {
        names += resident.email + ', ';
      }
      return '';
    });
    if (names === '' && this.props.unit.gatewayId) {
      names = 'Vacant';
    } else {
      names = names.slice(0, -2);
    }
    //defaults
    let no_residents = this.props.unit.residents.length < 1;

    let icon = <PersonIcon />;
    //check if there are residents/a code
    if (no_residents) {
      icon = <MeetingRoomIcon />;
    }

    let avatarColor = '';
    if (!this.props.unit.gatewayId) {
      avatarColor = 'orange-avatar';
      icon = <BuildIcon />;
    } else if (this.props.unit.permission) {
      avatarColor = 'dwelo-avatar';
    }
    let salticon = null;
    if (this.props.unit.gatewayId && !this.props.unit.hub_serial) {
      salticon = (
        <ListItemSecondaryAction>
          <IconButton aria-label="Delete" disabled={true}>
            <img src={salt} alt="salt" />
          </IconButton>
        </ListItemSecondaryAction>
      );
    }

    let unitListItem = (
      <div>
        <ListItem onClick={this.navigateToUnit} button={true}>
          <ListItemAvatar>
            <Avatar className={avatarColor}>{icon}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={this.props.unit.unit} secondary={names} />
          {salticon ? salticon : ''}
        </ListItem>
      </div>
    );

    const installerPreviewData = this.props.unit[
      sfConstants.SharedVars.INSTALLER_PREVIEW_DATA
    ];

    if (installerPreviewData) {
      let completeBox = null;
      let pteTypeBox = null;
      let timeFrameBox = null;
      let workTypeBox = null;
      let dateBox = null;
      let notesExpandButton;
      let notesCollapse;
      let specialHandling;
      let notesToTechnician;
      let pteNotes;

      const workTicketStatus = installerPreviewData[sfConstants.SharedVars.WORK_TICKET_STATUS];
      if (
        workTicketStatus === sfConstants.WorkTicket.COMPLETE ||
        workTicketStatus === sfConstants.WorkTicket.INCOMPLETE
      ) {
        const statusLabelClass =
          workTicketStatus === sfConstants.WorkTicket.COMPLETE
            ? this.classes.completeLabel
            : this.classes.incompleteLabel;
        completeBox = (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography
              className={`${statusLabelClass} ${this.classes.label}`}
              variant="overline"
              color="primary"
            >
              {workTicketStatus}
            </Typography>
          </Box>
        );
      }

      // the unit name with possibly a "Complete" or "Incomplete" label
      // next to it, depending on the work ticket status.
      const unitWithStatus = (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" alignContent="flex-start" mt={0.7}>
            <Box mt={0} mr={2}>
              <Typography variant={'body1'}>{this.props.unit.unit}</Typography>
            </Box>
            {completeBox}
          </Box>
        </Grid>
      );
      const residentsOrVacant = (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" alignContent="flex-start" mb={2.8}>
            <Box>
              <Typography variant={'body2'} color={'textSecondary'}>
                {names}
              </Typography>
            </Box>
          </Box>
        </Grid>
      );

      if (installerPreviewData[sfConstants.SharedVars.SCHEDULED_DATE]) {
        dateBox = (
          <Box mr={1} mt={-1}>
            <Typography variant="overline" color="textSecondary">
              {installerPreviewData[sfConstants.SharedVars.SCHEDULED_DATE]}
            </Typography>
          </Box>
        );
      }

      const workType = installerPreviewData[sfConstants.SharedVars.WORK_TYPE];
      if (workType) {
        workTypeBox = (
          <Box mr={1} mt={-1}>
            <Typography variant="overline" color="textSecondary">
              {workType}
            </Typography>
          </Box>
        );
      }

      let pteType = null;
      if (workType === 'service') {
        pteType = installerPreviewData[sfConstants.SharedVars.PTE];
        pteTypeBox = (
          <Box mr={1} mt={-1}>
            <Typography
              variant="overline"
              className={pteType ? this.classes.green : this.classes.red}
            >
              {'PTE: ' + (pteType ? 'YES' : 'NO')}
            </Typography>
          </Box>
        );
      }
      if (installerPreviewData[sfConstants.SharedVars.ARRIVAL_WINDOW]) {
        const timeFrame = installerPreviewData[sfConstants.SharedVars.ARRIVAL_WINDOW];
        timeFrameBox = (
          <Box mr={1} mt={-1}>
            <Typography variant="overline" color="primary">
              {timeFrame}
            </Typography>
          </Box>
        );
      }

      if (installerPreviewData[sfConstants.SharedVars.PTE_NOTES]) {
        // pteNotes lives inside the notesCollapse component.
        pteNotes = (
          <div>
            <Typography variant={'overline'} className={this.classes.nested}>
              {'PTE Notes '}
              {<InfoIcon color={'error'} fontSize={'small'} />}
            </Typography>
            <Typography
              variant={'body2'}
              className={`${this.classes.nested} ${this.classes.nestedContent}`}
            >
              {installerPreviewData[sfConstants.SharedVars.PTE_NOTES]}
            </Typography>
          </div>
        );
      }

      if (installerPreviewData[sfConstants.SharedVars.NOTES]) {
        specialHandling = installerPreviewData[sfConstants.SharedVars.SPECIAL_HANDLING];
        // notesToTechnician lives inside the notesCollapse component.
        notesToTechnician = (
          <div>
            <Typography variant={'overline'} className={this.classes.nested}>
              {'Notes to Technician '}
              {specialHandling && <InfoIcon color={'error'} fontSize={'small'} />}
            </Typography>
            <Typography
              variant={'body2'}
              className={`${this.classes.nested} ${this.classes.nestedContent}`}
            >
              {installerPreviewData[sfConstants.SharedVars.NOTES]}
            </Typography>
          </div>
        );
      }

      // Displays a button on the right side of the unit to open the notesCollapse component,
      // which will display the pte notes and/or notes to technician.
      // The type of icon in the button depends on if there is
      // at least special handling or pte notes.
      if (pteNotes || notesToTechnician) {
        let expandMoreIndicator;
        const showInfoIcon = specialHandling || pteNotes;
        // Only displaying 'small' icons on an xs screen.
        const smScreenOrGreater = isWidthUp('sm', this.props.width);
        if (showInfoIcon) {
          // InfoIcon indicates there is something important for the technician to see.
          expandMoreIndicator = (
            <InfoIcon color={'error'} fontSize={smScreenOrGreater ? 'default' : 'small'} />
          );
        } else {
          expandMoreIndicator = (
            <ExpandMoreIcon fontSize={smScreenOrGreater ? 'default' : 'small'} />
          );
        }
        notesExpandButton = (
          <ListItemSecondaryAction className={salticon ? this.classes.expandButton : null}>
            <Button
              size={'large'}
              variant={'outlined'}
              color={showInfoIcon ? 'secondary' : 'default'}
              onClick={this.handleNotesClick}
            >
              {this.state.expandNotes ? (
                <ExpandLessIcon fontSize={smScreenOrGreater ? 'default' : 'small'} />
              ) : (
                expandMoreIndicator
              )}
            </Button>
          </ListItemSecondaryAction>
        );
        notesCollapse = (
          <Collapse in={this.state.expandNotes} timeout="auto" unmountOnExit>
            {pteNotes}
            {notesToTechnician}
          </Collapse>
        );
      }

      unitListItem = (
        <div>
          <ListItem onClick={this.navigateToUnit} button={true}>
            <ListItemAvatar>
              <Avatar className={avatarColor}>{icon}</Avatar>
            </ListItemAvatar>
            <Grid container direction={'column'}>
              {unitWithStatus}
              {residentsOrVacant}
              <Grid item xs={12} container>
                <Box display="flex" flexWrap="wrap" alignContent="flex-start" mr={4}>
                  {workTypeBox}
                  {dateBox}
                  {pteTypeBox}
                  {timeFrameBox}
                </Box>
              </Grid>
            </Grid>
            {salticon}
            {notesExpandButton}
          </ListItem>
          {notesCollapse}
        </div>
      );
    }

    return (
      <div
        className={
          this.props.pendingWorkTicket &&
          this.props.pendingWorkTicket.addressId === this.props.unit.uid
            ? this.classes.pendingWorkTicket
            : ''
        }
      >
        {unitListItem}
        <AlertDialog
          alertdialog={this.state.workTicketAlert}
          onClick={this.goToPendingUnit}
          onClose={this.closeDialog}
          disableClick={true}
          acceptText="Go to Unit"
          rejectText="Cancel"
          text={
            this.props.pendingWorkTicket
              ? 'Please mark unit ' +
                this.props.pendingWorkTicket.unit +
                ' at ' +
                this.props.pendingWorkTicket.communityName +
                ' as complete or incomplete before proceeding to the next unit.'
              : ''
          }
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(withWidth()(Unit)));
