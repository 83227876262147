import * as React from 'react';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { SENSOR_PLACEMENT, SensorPlacement } from '../../Constants/sensorPlacementConstants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '120px',
    marginLeft: '0px'
  }
}));

type Props = {
  onChange: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
  value: SensorPlacement | '';
  contactSensorsOnly?: boolean;
  unknownPlaceholder?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
};

export function SensorPlacementSelect(props: Props) {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      style={props.style}
      required={!!props.required}
      fullWidth
    >
      <InputLabel id="sensor-placement-label">Sensor Placement</InputLabel>
      <Select
        labelId="sensor-placement-label"
        id="sensor-placement-select"
        value={props.value || (props.unknownPlaceholder ? 'unknown' : '')}
        onChange={props.onChange}
        label="Sensor Placement"
      >
        {!props.value && props.unknownPlaceholder && (
          <MenuItem value={'unknown'} aria-label={'Unknown'} disabled>
            Unknown
          </MenuItem>
        )}
        <MenuItem value={SENSOR_PLACEMENT.Door} aria-label={'Door'}>
          Door
        </MenuItem>
        <MenuItem value={SENSOR_PLACEMENT.SlidingDoor} aria-label={'Sliding Door'}>
          Sliding Door
        </MenuItem>
        <MenuItem value={SENSOR_PLACEMENT.Window} aria-label={'Window'}>
          Window
        </MenuItem>
        {!props.contactSensorsOnly && (
          <MenuItem value={SENSOR_PLACEMENT.Leak} aria-label={'Leak'}>
            Leak
          </MenuItem>
        )}
        {!props.contactSensorsOnly && (
          <MenuItem value={SENSOR_PLACEMENT.Motion} aria-label={'Motion'}>
            Motion
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
