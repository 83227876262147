import React from 'react';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { dweloPalette } from '../../Themes/dweloPalette';
import unit_todo_theme from '../../Themes/unit_todo_theme';
import { ReactComponent as WarningIcon } from '../../images/warning_outlined.svg';
import { DCM } from '../../Types/cloudApi';
import { CodeSyncProgress } from './CodeSyncProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      textAlign: 'center',
      paddingTop: '24px',
      paddingBottom: '24px'
    },
    dialogContent: {
      padding: '20px 40px 40px',
      justifyContent: 'center'
    },
    banner: {
      display: 'flex',
      backgroundColor: dweloPalette.primary.teal[100],
      padding: '16px 20px'
    },
    bannerMessage: {
      color: theme.palette.secondary.dark
    },
    helpMessage: {
      marginTop: '10px',
      color: dweloPalette.primary.grey[300]
    },
    phoneNumber: {
      color: theme.palette.secondary.main
    },
    codeSyncParentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '-30px'
    },
    bannerIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '20px'
    },
    warningIcon: {
      fill: dweloPalette.primary.teal[400]
    }
  })
);

type Props = {
  open: boolean;
  dcm: DCM | undefined;
};

/**
 * Dialog for displaying overall progress of codes that are syncing.
 * While open, it prevents user from interacting with any other part of the screen.
 */
export function LegacyCodeSyncDialog(props: Props) {
  const classes = useStyles(unit_todo_theme);
  return (
    <Dialog open={props.open} PaperProps={{ square: true }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant={'body1'}>Syncing Door Codes</Typography>
      </DialogTitle>
      <div className={classes.banner}>
        <div className={classes.bannerIconContainer}>
          <WarningIcon className={classes.warningIcon} />
        </div>
        <div>
          <Typography className={classes.bannerMessage}>
            Do not disturb the hub or lock while door codes are syncing.
            <br />
            This takes about 5 minutes.
          </Typography>
        </div>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.codeSyncParentContainer}>
          <CodeSyncProgress useMasterCode={false} dcm={props.dcm} />
          {props.dcm?.masterCodeExpected ? (
            <CodeSyncProgress useMasterCode={true} dcm={props.dcm} />
          ) : null}
        </div>
        <Typography className={classes.helpMessage}>
          If you need help, contact DweloCare at{' '}
          <a href={'tel:+1-415-582-6701'} className={classes.phoneNumber}>
            415-582-6701
          </a>{' '}
          or chat below.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
