import * as React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress';

type Props = {
  loading: boolean;
  buttonProps: ButtonProps;
  progressProps: CircularProgressProps;
  children: React.ReactText;
};

export function LoadableButton(props: Props) {
  return (
    <Button {...props.buttonProps}>
      {props.children}
      {props.loading ? (
        <CircularProgress
          {...props.progressProps}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
            ...props.progressProps.style
          }}
        />
      ) : null}
    </Button>
  );
}
