export const pageVisited = () => {
  // @ts-ignore
  return window.analytics && window.analytics.page();
};

export const identifyUser = () => {
  let userJson = localStorage.getItem('user');
  if (userJson !== null) {
    let { uid, email } = JSON.parse(userJson);

    // Ran into a problem on the Brave browser (others may have this too) but basically
    // .  the window.analytics.identify call is not returning a promise.  This code is
    // .  handling the scenario that it is by identifying if it has a catch on the result
    // .  and wrapping in a larger try/catch to not break the standard flow.
    try {
      // @ts-ignore
      const possiblePromise = window.analytics.identify(uid, { email });
      if (possiblePromise && possiblePromise.catch) {
        possiblePromise.catch((err) => console.error(err));
      }
    } catch (err) {
      console.error(err);
    }
  }
};
