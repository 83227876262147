import React from 'react';
import { Router, Route } from 'react-router-dom';
import { Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import './Common/stylesheets/global.scss';
import ErrorBoundary from './Common/Loading/ErrorBoundary';
import CommunitiesPage from './Communities/CommunitiesPage';
import UnitsPage from './Units/UnitsPage';
import LoginPage from './Login/LoginPage';
import UnitDetailPage from './UnitDetail/UnitDetailPage';
import DeviceDetailPage from './DeviceDetail/DeviceDetailPage';
import SalesforceChat from './Common/scripts/salesforceChat';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ChatContextProvider } from './Common/utils/chatContext';
import {
  TEST_CLIENT_SIDE_ID,
  PROD_CLIENT_SIDE_ID
} from './Common/Constants/launchDarklyConstants';
import AuthService from './Common/utils/AuthService';
import { User } from './Common/Types/cloudApi';
import { pageVisited } from './Common/utils/segmentAnalytics';

async function makeApp() {
  const user: User | null = new AuthService().getUser();
  const history = createBrowserHistory();
  let oldPath: string | undefined;
  let oldSearch: string | undefined;
  history.listen((location) => {
    if (oldPath !== location.pathname || oldSearch !== location.search) {
      oldPath = location.pathname;
      oldSearch = location.search;
      pageVisited();
    }
  });
  const LDProvider = await asyncWithLDProvider({
    clientSideID:
      process.env.REACT_APP_ENVIRONMENT === 'master'
        ? PROD_CLIENT_SIDE_ID
        : TEST_CLIENT_SIDE_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    user: {
      key: user?.email || 'anonymous'
    }
  });

  return (
    <LDProvider>
      <Router history={history}>
        <div id="app" className="container-fluid">
          <div className="row full-height">
            <div id="central-column" className="col-centered">
              <ErrorBoundary>
                <ChatContextProvider>
                  <AppRoutes />
                  <SalesforceChat />
                </ChatContextProvider>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </Router>
    </LDProvider>
  );
}

function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={CommunitiesPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/community/:communityId" component={UnitsPage} />
      <Route
        exact
        path={[
          '/community/:communityId/unit/:unitId',
          '/community/:communityId/unit_v2/:unitId'
        ]}
        component={UnitDetailPage}
      />
      <Route
        exact
        path={[
          '/community/:communityId/unit/:unitId/device/:deviceId',
          '/community/:communityId/unit_v2/:unitId/device/:deviceId'
        ]}
        component={DeviceDetailPage}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export { makeApp, AppRoutes };
