import React, { Component } from 'react';
import Header from '../../Common/Headers/Header';
import { withStyles } from '@material-ui/core/styles';
import StatusBar from '../../Common/Headers/StatusBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import header_theme from '../../Common/Themes/header_theme';
import axios from 'axios';

import '../../Common/stylesheets/global.scss';
import LinearDeterminate from '../../Common/Loading/Linear';
import { hubRestartDateStorage } from '../../Common/utils/storageInterface';

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: '24px',
    float: 'right'
  },
  first_line: {
    padding: '24px 24px 12px 24px',
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '24px'
  },
  bold_line: {
    paddingLeft: '24px',
    paddingRight: '24px',
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '24px',
    fontWeight: '800'
  },
  middle_line: {
    paddingLeft: '24px',
    paddingRight: '24px',
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '24px'
  },
  padding_middle: {
    padding: '24px 24px 12px 24px'
  }
});

class HubRestarting extends Component {
  constructor(props) {
    super(props);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const cancel = {
      cancelToken: source.token
    };
    this.__source = source;
    this.__cancel = cancel;
    this.openAlert = this.openAlert.bind(this);
    this.returnToUnits = this.returnToUnits.bind(this);
    const { classes } = props;
    this.classes = classes;
    this.state = {
      completed: this.props.completed ? this.props.completed : 0,
      timer: null,
      prev_restart: new Date(hubRestartDateStorage(this.props.unit.hub_serial).get())
    };
  }

  componentDidMount() {
    this.setState({
      timer: setInterval(() => {
        this.setCompleted(this.state.completed);
      }, 600)
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  setCompleted = (oldCompleted) => {
    if (oldCompleted === 100) {
      clearInterval(this.state.timer);
      hubRestartDateStorage(this.props.unit.hub_serial).remove();
      window.location.reload();
    }
    let now = new Date();
    let diffSecs = (now.getTime() - this.state.prev_restart.getTime()) / 1000;
    let completed = (diffSecs / 30) * 100;
    this.setState({
      completed: Math.min(completed, 100)
    });
  };

  openAlert(e) {
    e.preventDefault();
    this.setState({
      alertdialog: true
    });
  }

  returnToUnits() {
    const communityId = this.props.match.params.communityId;
    this.props.history.push('/community/' + communityId);
  }

  render() {
    return (
      <div className="full-height">
        <MuiThemeProvider theme={header_theme}>
          <Header
            headerText="Restart Hub"
            back={true}
            return={this.returnToUnits}
            arrow={true}
            user={this.props.user}
            history={this.props.history}
            subheaderText={this.props.unit.communityName}
            subheaderText2={this.props.unit.unit}
          />
          <StatusBar message="Hub restarting..." />
          <div>
            <p className={this.classes.first_line}>This may take up to 30 seconds.</p>
            <p className={this.classes.bold_line}>DO NOT UNPLUG THE HUB!</p>
            <p className={this.classes.middle_line}>
              Restart progress: <strong>{this.state.completed.toFixed(0)}%</strong>
            </p>
            <div className={this.classes.padding_middle}>
              <LinearDeterminate completed={this.state.completed} />
            </div>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(HubRestarting);
