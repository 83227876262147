import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import CommunityList from './components/CommunityList';

import * as routes from './communities_apiRoutes';
import withSpinner from '../Common/utils/withSpinner';
import client from '../Common/utils/client';
import withAuth from '../Common/utils/withAuth';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { handle401ErrorRedirect, is401Error } from '../Common/utils/errorHandling';

const CommunityListWithSpinner = withSpinner(CommunityList);

class CommunitiesPage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      communities: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    client
      .get(routes.COMMUNITY)
      .then((res) => {
        let sorted = res.data.results.sort((a, b) => (a.name > b.name ? 1 : -1));
        if (sorted.length === 1) {
          this.props.history.push('/community/' + sorted[0].uid);
        } else {
          this.setState({
            communities: sorted,
            loading: false
          });
        }
      })
      .catch((error) => {
        if (is401Error(error)) {
          handle401ErrorRedirect(error, this.props.history);
        } else {
          this.setState({ loading: false });
          Sentry.captureException(error);
        }
      });
  }
  render() {
    return (
      <CommunityListWithSpinner
        isLoading={this.state.loading}
        communities={this.state.communities}
        {...this.props}
      />
    );
  }
}

export default withLDConsumer()(withAuth(CommunitiesPage));
