import { Dispatch, Reducer, ReducerAction, ReducerState, useEffect, useReducer } from 'react';
import { Unit } from '../../Types/cloudApi';

export type EmailOrVacant = 'emailOrVacant';

export type UnitName = 'unitName';

export type TextBoxNames = EmailOrVacant | UnitName;

type TextBoxProperties = { value: string; errorMessage: string };

type TextBoxValue = Pick<TextBoxProperties, 'value'>;

type TextBoxErrorMessage = Pick<TextBoxProperties, 'errorMessage'>;

type DialogMetaState = ReducerState<DialogMetaReducer>;

export type DialogMetaAction = ReducerAction<DialogMetaReducer>;

type DialogMetaReducer = Reducer<
  { pageNumber: number; disablePrimaryButton: boolean } & Record<
    TextBoxNames,
    TextBoxProperties
  >,
  | { type: 'CLEAR_ERRORS' }
  | { type: 'CORRECT_EMAIL_OR_VACANT_TYPED' }
  | { type: 'CORRECT_UNIT_TYPED' }
  | { type: 'ENABLE_PRIMARY_BUTTON' }
  | { type: 'NEXT_PAGE' }
  | { type: 'RESET_TO_DEFAULT' }
  | ({ type: 'SET_EMAIL_OR_VACANT_VALUE' } & TextBoxValue)
  | ({ type: 'SET_UNIT_VALUE' } & TextBoxValue)
  | ({ type: 'WRONG_EMAIL_OR_VACANT_TYPED' } & TextBoxErrorMessage)
  | ({ type: 'WRONG_UNIT_TYPED' } & TextBoxErrorMessage)
>;

export enum Page {
  VerifyUnit,
  VerifyEmailOrVacant,
  RemoveHub
}

const initialDialogMetaState: DialogMetaState = {
  pageNumber: Page.VerifyUnit,
  disablePrimaryButton: true,
  emailOrVacant: {
    value: '',
    errorMessage: ''
  },
  unitName: {
    value: '',
    errorMessage: ''
  }
};

/**
 * Reducer function used by the useDialogMetaReducer hook
 */
export function dialogMetaReducer(
  state: DialogMetaState,
  action: DialogMetaAction
): DialogMetaState {
  switch (action.type) {
    case 'CLEAR_ERRORS':
      return {
        ...state,
        emailOrVacant: {
          ...state.emailOrVacant,
          errorMessage: ''
        },
        unitName: {
          ...state.unitName,
          errorMessage: ''
        }
      };

    case 'CORRECT_EMAIL_OR_VACANT_TYPED':
      return {
        ...state,
        disablePrimaryButton: false,
        emailOrVacant: {
          ...state.emailOrVacant,
          errorMessage: ''
        }
      };

    case 'CORRECT_UNIT_TYPED':
      return {
        ...state,
        disablePrimaryButton: false,
        unitName: {
          ...state.unitName,
          errorMessage: ''
        }
      };

    case 'ENABLE_PRIMARY_BUTTON':
      return {
        ...state,
        disablePrimaryButton: false
      };

    case 'NEXT_PAGE':
      return {
        ...state,
        pageNumber: state.pageNumber + 1,
        disablePrimaryButton: true
      };

    case 'RESET_TO_DEFAULT':
      return { ...initialDialogMetaState };

    case 'SET_EMAIL_OR_VACANT_VALUE':
      return {
        ...state,
        emailOrVacant: {
          ...state.emailOrVacant,
          value: action.value
        }
      };

    case 'SET_UNIT_VALUE':
      return {
        ...state,
        unitName: {
          ...state.unitName,
          value: action.value
        }
      };

    case 'WRONG_EMAIL_OR_VACANT_TYPED':
      return {
        ...state,
        disablePrimaryButton: true,
        emailOrVacant: {
          ...state.emailOrVacant,
          errorMessage: action.errorMessage
        }
      };

    case 'WRONG_UNIT_TYPED':
      return {
        ...state,
        disablePrimaryButton: true,
        unitName: {
          ...state.unitName,
          errorMessage: action.errorMessage
        }
      };
  }
}

/**
 * Custom hook for handling state for RemoveHubDialog
 */
export function useDialogMetaReducer(
  unit: Unit,
  isOpen: boolean,
  isOccupied: boolean
): [DialogMetaState, Dispatch<DialogMetaAction>] {
  const [dialogMeta, dispatchDialogMeta] = useReducer(
    dialogMetaReducer,
    initialDialogMetaState
  );

  /**
   * Reset defaults when opening dialog
   */
  useEffect(() => {
    if (isOpen) {
      dispatchDialogMeta({ type: 'RESET_TO_DEFAULT' });
    }
  }, [isOpen]);

  /**
   * Handles side effects for when unitName.value changes,
   * such as showing/hiding errors, enabling/disabling primary button, etc.
   */
  useEffect(() => {
    if (dialogMeta.unitName.value === '') {
      // We don't display errors if the textbox has no input
      dispatchDialogMeta({ type: 'CLEAR_ERRORS' });
      return;
    }

    const lowerCaseExpected = unit.unit.toLowerCase();
    const lowerCaseActual = dialogMeta.unitName.value.toLowerCase();

    if (lowerCaseActual === lowerCaseExpected) {
      dispatchDialogMeta({ type: 'CORRECT_UNIT_TYPED' });
    } else {
      dispatchDialogMeta({
        type: 'WRONG_UNIT_TYPED',
        errorMessage: 'This is the wrong unit.'
      });
    }
  }, [dialogMeta.unitName.value, unit.unit]);

  /**
   * Handles side effects for when emailOrVacant.value changes,
   * such as showing errors, enabling primary button, etc.
   */
  useEffect(() => {
    if (dialogMeta.emailOrVacant.value === '') {
      dispatchDialogMeta({ type: 'CLEAR_ERRORS' });
      return;
    }

    const actualLowerCaseInput = dialogMeta.emailOrVacant.value.toLowerCase();
    let doesInputMatch;

    if (isOccupied) {
      const allowedLowerCaseEmails = unit.residents.map((resident) =>
        resident.email.toLowerCase()
      );
      doesInputMatch = allowedLowerCaseEmails.includes(actualLowerCaseInput);
    } else {
      doesInputMatch = actualLowerCaseInput === 'vacant';
    }

    if (doesInputMatch) {
      dispatchDialogMeta({ type: 'CORRECT_EMAIL_OR_VACANT_TYPED' });
    } else {
      dispatchDialogMeta({
        type: 'WRONG_EMAIL_OR_VACANT_TYPED',
        errorMessage: 'Invalid entry'
      });
    }
  }, [dialogMeta.emailOrVacant.value, unit.residents, isOccupied]);

  return [dialogMeta, dispatchDialogMeta];
}
