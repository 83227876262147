import React, { useRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../../Themes/login_theme';
import { Unit } from '../../Types/cloudApi';
import {
  DialogMetaAction,
  EmailOrVacant,
  Page,
  TextBoxNames,
  UnitName,
  useDialogMetaReducer
} from './utils';
import { dweloPalette } from '../../Themes/dweloPalette';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      color: dweloPalette.primary.teal[300]
    },
    buttonProgress: {
      color: '#F6861E',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -34,
      marginLeft: -34
    },
    buttonWarning: {
      color: dweloPalette.secondary.red[300]
    },
    copyContainer: {
      textAlign: 'center',
      marginTop: '16px'
    },
    copyField: {
      width: '115px',
      height: '30px',
      border: 'none',
      resize: 'none',
      marginTop: '-1px',
      display: 'inline-block'
    },
    copyForm: {
      display: 'inline-block'
    },
    textField: {
      width: '100%'
    },
    unitSummary: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '16px'
    },
    youAreRemoving: {
      marginBottom: '16px'
    }
  })
);

type Props = {
  isOpen: boolean;
  loading: boolean;
  unit: Unit;
  handleInputChange: (data: string) => void;
  handleClose: () => void;
  removeHub: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  inputValue: string;
  apiErrorMessage: string;
};

/**
 * A lot of the code in this file is based off of src/common/popups/AlertDialog.js.
 * This component was created separately since it is also very different in some behaviors than AlertDialog
 * that it would've been difficult/messy to combine it.
 * RemoveHubDialog is meant to be a quick-fix for erroneous and accidental hub removals happening production.
 * There will be a longer term solution in the future.
 */
function RemoveHubDialog(props: Props) {
  const classes = useStyles();

  const textAreaRef = useRef<null | HTMLTextAreaElement>(null);

  const isOccupied = props.unit.residents.length > 0;

  const [dialogMeta, dispatchDialogMeta] = useDialogMetaReducer(
    props.unit,
    props.isOpen,
    isOccupied
  );

  function handleTextBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const name: TextBoxNames = e.target.name as TextBoxNames;
    let type: DialogMetaAction['type'];

    if (name === 'unitName') {
      type = 'SET_UNIT_VALUE';
    } else if (name === 'emailOrVacant') {
      type = 'SET_EMAIL_OR_VACANT_VALUE';
    } else {
      return;
    }

    dispatchDialogMeta({
      type: type,
      value: e.target.value
    });
  }

  function handlePrimaryClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    switch (dialogMeta.pageNumber) {
      case Page.VerifyUnit:
      case Page.VerifyEmailOrVacant:
        dispatchDialogMeta({ type: 'NEXT_PAGE' });
        break;
      case Page.RemoveHub:
        props.removeHub(e);
        break;
    }
  }

  function copyToClipboard(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!textAreaRef.current) {
      return;
    }

    textAreaRef.current.select();
    document.execCommand('copy');
    e.currentTarget.focus();
    dispatchDialogMeta({ type: 'ENABLE_PRIMARY_BUTTON' });
  }

  function getCopyArea() {
    if (dialogMeta.pageNumber !== Page.RemoveHub) {
      return;
    }

    return (
      <div className={classes.copyContainer}>
        <form className={classes.copyForm}>
          <textarea
            ref={textAreaRef}
            value={String(props.unit.gatewayId)}
            className={classes.copyField}
            readOnly={true}
          />
        </form>
        <Button variant="contained" onClick={copyToClipboard} color="primary">
          Copy
        </Button>
      </div>
    );
  }

  function getTextBox() {
    let textFieldProps:
      | undefined
      | (TextFieldProps & {
          name: UnitName | EmailOrVacant;
        });

    if (dialogMeta.pageNumber === Page.VerifyUnit) {
      const { errorMessage } = dialogMeta.unitName;
      textFieldProps = {
        label: 'Unit name',
        name: 'unitName',
        value: dialogMeta.unitName.value,
        error: !!errorMessage,
        helperText: errorMessage
      };
    } else if (dialogMeta.pageNumber === Page.VerifyEmailOrVacant) {
      const { errorMessage } = dialogMeta.emailOrVacant;
      textFieldProps = {
        label: 'Email address or "vacant"',
        name: 'emailOrVacant',
        value: dialogMeta.emailOrVacant.value,
        error: !!errorMessage,
        helperText: errorMessage
      };
    } else {
      return;
    }

    return (
      <TextField
        {...textFieldProps}
        className={classes.textField}
        onChange={handleTextBoxChange}
        margin="normal"
      />
    );
  }

  function getDialogText() {
    switch (dialogMeta.pageNumber) {
      case Page.VerifyUnit:
        return <Typography>Please verify the unit name</Typography>;

      case Page.VerifyEmailOrVacant:
        return (
          <Typography>Please verify the resident email or that the unit is vacant.</Typography>
        );

      case Page.RemoveHub:
        return (
          <>
            <Typography className={classes.youAreRemoving}>You are removing:</Typography>
            <div className={classes.unitSummary}>
              <Typography variant={'h6'}>{props.unit.unit}</Typography>
              <Typography>{props.unit.communityName}</Typography>
              {isOccupied && <Typography>{dialogMeta.emailOrVacant.value}</Typography>}
            </div>
            <Typography paragraph>
              Please copy the old gateway id - this will be necessary to transfer permissions
              later.
            </Typography>
          </>
        );
    }
  }

  function getErrorMessage() {
    return (
      props.apiErrorMessage && (
        <Typography component="p" color="error" className={classes.textField}>
          {props.apiErrorMessage}
        </Typography>
      )
    );
  }

  function getCircularProgress() {
    return props.loading && <CircularProgress size={68} className={classes.buttonProgress} />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        maxWidth="xs"
        open={props.isOpen}
        aria-labelledby="alert-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">Remove hub</DialogTitle>

        <DialogContent>
          {getDialogText()}
          {getTextBox()}
          {getCopyArea()}
          {getErrorMessage()}
        </DialogContent>

        {getCircularProgress()}

        <DialogActions>
          <Button
            onClick={props.handleClose}
            className={classes.button}
            disabled={props.loading}
          >
            Cancel
          </Button>
          <Button
            onClick={handlePrimaryClick}
            className={
              dialogMeta.pageNumber === Page.RemoveHub ? classes.buttonWarning : classes.button
            }
            disabled={props.loading || dialogMeta.disablePrimaryButton}
          >
            {dialogMeta.pageNumber === Page.RemoveHub ? 'Remove' : 'Continue'}
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default RemoveHubDialog;
