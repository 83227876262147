import React from 'react';
import Spinner from '../Loading/Spinner';

import logo from '../images/logo.svg';

export default function withSpinner(Component) {
  return function withSpinnerComponent({ isLoading, ...props }) {
    if (!isLoading) {
      return <Component {...props} />;
    }
    return <Spinner name="default" loadingImage={logo} show={isLoading} />;
  };
}
