// import client from "../../utils/client";
import { AxiosPromise } from 'axios';
import {
  GetDeviceSyncStatusResponse,
  GetAddressSyncStatusResponse,
  PauseSyncResponse,
  StartSyncResponse,
  ResumeSyncResponse
  // PauseSyncResponse,
  // ResumeSyncResponse,
  // StartSyncResponse
} from './types';
import {
  makeGetStatusByAddressRoute,
  makeGetStatusByDeviceRoute
  // makeBaseRouteByDevice,
  // makePauseSyncRoute,
  // makeResumeSyncRoute,
  // makeBaseRoute
} from './routes';

// TODO come back to this file later to tidy up any inconsistencies with backend implementation and variable names.

export async function startCodeSync(deviceId: number) {
  return new Promise((resolve) => {
    return resolve({
      data: {
        status: 'in_progress'
      } as StartSyncResponse
    });
  });
  // TODO uncomment this when mock data is no longer needed. also check where this is correct or not later.
  // return client.post<AxiosPromise<StartSyncResponse>>(makeBaseRoute(), {device_id: deviceId})
}

export async function pauseCodeSync(deviceId: string) {
  return new Promise((resolve) => {
    return resolve({
      data: {
        status: 'paused',
        sent_percent: 50,
        verified_percent: 40
      } as PauseSyncResponse
    });
  });
  // TODO uncomment this when mock data is no longer needed. also check where this is correct or not later.
  // return client.put<AxiosPromise<PauseSyncResponse>>(makePauseSyncRoute(deviceId));
}

export async function resumeCodeSync(deviceId: string) {
  return new Promise((resolve) => {
    return resolve({
      data: {
        status: 'in_progress',
        sent_percent: 50,
        verified_percent: 40
      } as ResumeSyncResponse
    });
  });
  // TODO uncomment this when mock data is no longer needed. also check where this is correct or not later.
  // return client.put<AxiosPromise<ResumeSyncResponse>>(makeResumeSyncRoute(deviceId));
}

export async function getCodeSyncStatusByDevice(deviceId: number) {
  return new Promise((resolve) => {
    return resolve({
      status: 200,
      request: {
        responseURL: makeGetStatusByDeviceRoute(deviceId)
      },
      data: {
        device_id: 2,
        status: 'in_progress',
        created: '2020-01-08T12:00:00Z',
        last_updated: '2020-01-08T12:00:00Z',
        completed: null,
        reason: 'something',
        sent_percent: 50,
        verified_percent: 40
      } as GetDeviceSyncStatusResponse
    });
  });
  // TODO uncomment this when mock data is no longer needed. also check where this is correct or not later.
  // return client.get<AxiosPromise<GetDeviceSyncStatusResponse>>(makeGetStatusByDeviceRoute(deviceId));
}

export async function getCodeSyncStatusByAddress(addressId: number | string) {
  return (new Promise((resolve) => {
    return resolve({
      status: 200,
      request: {
        responseURL: makeGetStatusByAddressRoute(addressId)
      },
      data: {
        syncs: [
          {
            device_id: 2,
            status: 'in_progress',
            created: '2020-01-08T12:00:00Z',
            last_updated: '2020-01-08T12:00:00Z',
            completed: null,
            reason: 'something',
            sent_percent: 50,
            verified_percent: 40
          }
        ]
      } as GetAddressSyncStatusResponse
    });
  }) as unknown) as Promise<AxiosPromise<GetAddressSyncStatusResponse>>;
  // TODO uncomment this when mock data is no longer needed. also check where this is correct or not later.
  // return client.get<AxiosPromise<GetAddressSyncStatusResponse>>(makeGetStatusByAddressRoute(addressId));
}
