import * as React from 'react';
import { ProgressBar } from '../ProgressBar';

type Props = {
  progress: number;
  disabled?: boolean;
};

export function SentProgressBar(props: Props) {
  return (
    <ProgressBar
      progress={props.progress}
      description={
        props.progress >= 100 ? 'Sending complete' : `Sending: ${props.progress}% complete`
      }
      disabled={!!props.disabled}
    />
  );
}
