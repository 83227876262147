import * as React from 'react';
import { ErrorBanner } from '../../BannerTemplates/ErrorBanner';
import { Link, Typography } from '@material-ui/core';

type Props = {
  deviceName: string;
  handleCantChangeBatteries: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

export function LowBatteryBanner(props: Props) {
  const name = props.deviceName ? `${props.deviceName} lock` : 'lock';

  return (
    <ErrorBanner
      title={`The ${name} batteries are too low.`}
      body={
        <Typography>
          Replace the batteries. We will resume syncing as soon as they are replaced.{' '}
          <Link onClick={props.handleCantChangeBatteries}>I can’t change batteries now.</Link>
        </Typography>
      }
    />
  );
}
