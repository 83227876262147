import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Header from '../../Common/Headers/Header';
import StatusBar from '../../Common/Headers/StatusBar';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import AlertDialog from '../../Common/Popups/AlertDialog';
import Typography from '@material-ui/core/Typography';
import { resetFabPosition } from '../../Common/utils/commonUtils';

import * as deviceConstants from '../../Common/Constants/deviceConstants';
import theme from '../../Common/Themes/login_theme';
import client from '../../Common/utils/client';
import * as routes from '../unitdetail_apiRoutes';
import '../../Common/stylesheets/global.scss';
import { spinnerService } from '../../Common/utils/spinnerService';
import Spinner from '../../Common/Loading/Spinner';
import logo from '../../Common/images/logo.svg';

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: '16px',
    backgroundColor: '#4ad4d4'
  },
  deleteFab: {
    position: 'fixed',
    bottom: '16px',
    backgroundColor: '#991210'
  },
  container: {
    marginTop: '-8px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  legacyContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '100%'
  },
  textField: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100%'
  },
  legacyTextField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '100%'
  },
  errorField: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
    marginTop: '16px',
    marginBottom: '-16px',
    fontWeight: '800'
  },
  heading: {
    marginTop: '24px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%'
  },
  removeIcon: {
    color: '#fff'
  }
});

// This uses the "legacy" nest implementation.
// See git history for the "new" nest implementation that was later put on hold
// (and then removed from the code for cleanliness).
class AddWifiDevice extends Component {
  constructor(props) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openAlert = this.openAlert.bind(this);
    this.createNest = this.createNest.bind(this);
    this.legacyCreateNest = this.legacyCreateNest.bind(this);
    this.removeNest = this.removeNest.bind(this);
    this.legacyRemoveNest = this.legacyRemoveNest.bind(this);

    const { classes } = props;
    this.classes = classes;
    this.state = {
      serial_number: '',
      alertdialog: false,
      error: '',
      loading: false,
      displayStatus: false,
      statusMessage: '',
      newDevice: null
    };
  }

  openAlert(e) {
    e.preventDefault();
    this.setState({
      alertdialog: true
    });
  }

  closeDialog = (event) => {
    this.setState({
      alertdialog: false
    });
  };

  genericCatchFunction = (error) => {
    this.setState({ loading: false });
    Sentry.captureException(error);
    try {
      if (error.response.status === 401) {
        this.props.history.push('/login');
      } else {
        if (error.response.data.message) {
          this.setState({
            error: error.response.data.message
          });
        } else {
          this.setState({
            error: 'Something went wrong. Please contact support.'
          });
        }
      }
    } catch (err) {
      this.setState({
        error: 'Something went wrong. Please contact support.'
      });
    }
    resetFabPosition();
  };

  componentDidMount() {
    resetFabPosition();
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  cancel(e) {
    return this.props.history.goBack();
  }

  returnToList = () => {
    this.props.return(1, this.state.newDevice, null);
  };

  getFab() {
    if (this.props.device) {
      return (
        <Fab
          aria-label="Remove Nest"
          color="primary"
          className={this.classes.deleteFab + ' keep-fixed delete'}
          onClick={this.openAlert}
        >
          <DeleteIcon className={this.classes.removeIcon} />
        </Fab>
      );
    } else {
      return (
        <Fab
          color="primary"
          aria-label="Next"
          className={this.classes.fab + ' keep-fixed'}
          onClick={this.legacyCreateNest}
        >
          <ArrowForwardIcon />
        </Fab>
      );
    }
  }

  legacyCreateNest(e) {
    e.preventDefault();
    const data = {
      gatewayId: this.props.unit.gatewayId,
      localId: this.state.serial_number,
      model: deviceConstants.NEST
    };
    spinnerService.show('default');
    client
      .post(routes.LEGACY_CREATE_DEVICE, data)
      .then((res) => {
        spinnerService.hide('default');
        this.props.return(1, res.data, null);
        return Promise.resolve();
      })
      .catch((error) => {
        spinnerService.hide('default');
        Sentry.captureException(error);
        if (error.response.status === 401) {
          this.props.history.push('/login');
        } else {
          this.setState({
            error: 'Something went wrong - please try again later'
          });
        }
      });
  }

  createNest(e) {
    e.preventDefault();
    const data = {
      installer: this.props.user.email,
      deviceName: 'Nest thermostat',
      deviceType: deviceConstants.THERMOSTAT
    };
    this.setState({
      error: '',
      loading: true,
      displayStatus: true,
      statusMessage: 'Provisioning thermostat...'
    });
    client
      .put(routes.CREATE_DEVICE(this.props.unit.gatewayId, this.state.serial_number), data)
      .then((res) => {
        res.data.sensors = [];
        this.setState(
          {
            loading: false,
            newDevice: res.data,
            statusMessage: 'Success! Nest is provisioned.'
          },
          () => {
            resetFabPosition();
          }
        );
        return Promise.resolve();
      })
      .catch((error) => {
        this.setState({
          statusMessage: 'Provisioning failed. Try again.'
        });
        this.genericCatchFunction(error);
      });
  }

  legacyRemoveNest(e) {
    e.preventDefault();
    spinnerService.show('default');
    client
      .delete(routes.LEGACY_DELETE_DEVICE(this.props.device.uid))
      .then((res) => {
        spinnerService.hide('default');
        this.props.return(1, null, this.props.device.uid);
        return Promise.resolve();
      })
      .catch((error) => {
        spinnerService.hide('default');
        Sentry.captureException(error);
        if (error.response.status === 401) {
          this.props.history.push('/login');
        } else {
          this.setState({
            error: 'Something went wrong - please try again later'
          });
        }
      });
  }

  removeNest(e) {
    e.preventDefault();
    this.setState({ loading: true });
    client
      .delete(routes.DELETE_DEVICE(this.props.unit.gatewayId, this.props.device.localId))
      .then((res) => {
        this.setState({ loading: false });
        this.props.return(1, null, this.props.device.uid);
        return Promise.resolve();
      })
      .catch((error) => {
        this.genericCatchFunction(error);
      });
  }

  getPageContent = () => {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <form className={this.classes.legacyContainer}>
            <TextField
              label="Nest Serial Number"
              name="serial_number"
              defaultValue={this.props.device ? this.props.device.localId : ''}
              className={this.classes.legacyTextField}
              onChange={this.handleChange}
              margin="normal"
              InputProps={{
                readOnly: !!this.props.device
              }}
            />
          </form>
        </MuiThemeProvider>
        <Typography component="p" color="error" className={this.classes.legacyTextField}>
          {this.state.error}
        </Typography>
      </div>
    );
  };

  getStatusBar = () => {
    if (this.state.displayStatus && !this.state.error) {
      return <StatusBar message={this.state.statusMessage} />;
    } else if (this.state.displayStatus) {
      return <StatusBar message={this.state.statusMessage} error={true} />;
    } else {
      return '';
    }
  };

  render() {
    let fab = this.getFab();
    let statusBar = this.getStatusBar();
    let pageContent = this.getPageContent();
    return (
      <div className="full-height">
        <div className="addunit-container">
          <Header
            headerText="Nest Thermostat"
            back={true}
            user={this.props.user}
            history={this.props.history}
            subheaderText={this.props.unit.communityName}
            subheaderText2={this.props.unit.unit}
            return={this.props.return}
          />
          {statusBar}
          {pageContent}
        </div>
        {fab}
        <Spinner name="default" loadingImage={logo} />
        <AlertDialog
          alertdialog={this.state.alertdialog}
          onClick={this.removeNest}
          onClose={this.closeDialog}
          error={this.state.error}
          loading={this.state.loading}
          disableClick={true}
          acceptText="Remove Nest"
          rejectText="Cancel"
          text="This action will remove the Nest device"
          title="Remove Nest serial number?"
        />
      </div>
    );
  }
}

export default withStyles(styles)(AddWifiDevice);
