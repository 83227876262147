import React from 'react';
import {
  Avatar,
  Checkbox,
  createStyles,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Theme
} from '@material-ui/core';
import { ReactComponent as LockKey } from '../../Common/images/lock-key.svg';
import { ReactComponent as LockCylinder } from '../../Common/images/lock-cylinder.svg';
import { ReactComponent as NavigateNext } from '../../Common/images/navigate-next.svg';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';
import unitTodoTheme from '../../Common/Themes/unit_todo_theme';
import { dweloPalette } from '../../Common/Themes/dweloPalette';
import {
  LockMetaWithIdentifiers,
  LockDevice,
  unitTodoMetaStorage
} from '../../Common/utils/storageInterface';
import { Community, DeviceSensor, Unit } from '../../Common/Types/cloudApi';
import { LockThrowDialogDispatch, UnitMetaDispatch } from '../common/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: '10px',
      height: '10px'
    },
    listItemIcon: {
      width: '20px',
      height: '20px'
    },
    avatar: {
      border: '1px solid',
      width: '20px',
      height: '20px'
    },
    doneIcon: {
      fill: '#FFF',
      height: '16px',
      width: '16px'
    },
    doneIconAvatarComplete: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    textDisabled: {
      color: theme.palette.text.disabled
    },
    iconEnabled: {
      fill: theme.palette.primary.main
    },
    iconEnabledGrey: {
      fill: dweloPalette.primary.grey[400]
    },
    iconDisabled: {
      fill: dweloPalette.primary.grey[150]
    },
    avatarDisabled: {
      borderColor: dweloPalette.primary.grey[150],
      backgroundColor: 'transparent'
    },
    avatarEnabled: {
      borderColor: theme.palette.primary.main,
      backgroundColor: 'transparent'
    },
    taskListItem: {
      padding: '0px 16px 0px 4px',
      height: '56px'
    },
    taskListItemIcon: {
      minWidth: '30px'
    },
    divider: {
      background: dweloPalette.primary.grey[100]
    },
    taskList: {
      paddingTop: '0',
      marginLeft: '88px'
    },
    listItemAvatar: {
      minWidth: '30px'
    },
    iconButton: {
      height: '48px',
      width: '48px'
    }
  })
);

type Props = {
  unitId: Unit['uid'];
  dcm2Community: Community['usesPrefilledPins'];
  batteriesInstalled: LockDevice['batteriesInstalled'];
  dispatchUnitMeta: UnitMetaDispatch;
  dispatchId: DeviceSensor['uid'] | number;
} & (
  | {
      paired: true;
      codesExpected: boolean;
      codeSyncInProgress: boolean;
      wasLockThrowRated: boolean;
      dispatchLockThrowDialog: LockThrowDialogDispatch;
    }
  | {
      paired: false;
      codesExpected?: never;
      codeSyncInProgress?: never;
      dispatchLockThrowDialog?: never;
      wasLockThrowRated?: never;
    }
);

export function DeviceTasks(props: Props) {
  const classes = useStyles(unitTodoTheme);

  function handleBatteriesChange() {
    props.dispatchUnitMeta({
      type: 'TOGGLE_LOCK_BATTERIES_INSTALLED',
      paired: props.paired,
      id: props.dispatchId
    });

    const newLockMeta: LockMetaWithIdentifiers = props.paired
      ? {
          paired: props.paired,
          lock: {
            uid: props.dispatchId,
            batteriesInstalled: !props.batteriesInstalled
          }
        }
      : {
          paired: props.paired,
          arrayIndex: props.dispatchId,
          lock: {
            batteriesInstalled: !props.batteriesInstalled
          }
        };

    unitTodoMetaStorage.updateLockDevices(props.unitId, newLockMeta);
  }

  function handleLockThrowClick() {
    if (props.dispatchLockThrowDialog) {
      props.dispatchLockThrowDialog({ type: 'OPEN_DIALOG', id: props.dispatchId });
    }
  }

  return (
    <List className={classes.taskList}>
      <IndustrialBatteries classes={classes} onClick={handleBatteriesChange} {...props} />
      {(!props.paired && props.dcm2Community) || props.codesExpected ? (
        <DoorCodes classes={classes} {...props} />
      ) : null}
      <LockThrow
        classes={classes}
        onClick={handleLockThrowClick}
        paired={props.paired}
        wasLockThrowRated={props.wasLockThrowRated}
      />
    </List>
  );
}

function Task({ text, classes, ...props }): JSX.Element {
  let textClass = props.textDisabled ? classes.textDisabled : '';
  return (
    <>
      <ListItem className={classes.taskListItem}>
        {props.listItemAvatar ? props.listItemAvatar : props.listItemIcon}
        <ListItemText primary={text} primaryTypographyProps={{ className: textClass }} />
        {props.iconButton}
      </ListItem>
      <Divider className={classes.divider} />
    </>
  );
}

function IndustrialBatteries({ onClick, classes, ...props }): JSX.Element {
  return (
    <Task
      text={'Install industrial batteries'}
      classes={classes}
      listItemIcon={
        <ListItemIcon className={classes.taskListItemIcon}>
          <Checkbox
            className={classes.iconDisabled}
            edge="start"
            checked={props.batteriesInstalled}
            onClick={onClick}
          />
        </ListItemIcon>
      }
    />
  );
}

function DoorCodes({ classes, ...props }): JSX.Element {
  let text = 'Sync door codes';
  let icon;
  let extraAvatarClass;
  if (!props.paired || props.codeSyncInProgress) {
    extraAvatarClass = classes.avatarDisabled;
    icon = <LockKey className={`${classes.icon} ${classes.iconDisabled}`} />;
  } else {
    extraAvatarClass = classes.doneIconAvatarComplete;
    icon = <DoneIcon className={`${classes.doneIcon}`} />;
  }
  return (
    <Task
      text={text}
      classes={classes}
      textDisabled={!props.paired || props.codeSyncInProgress}
      listItemAvatar={
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={`${classes.avatar} ${extraAvatarClass}`}>{icon}</Avatar>
        </ListItemAvatar>
      }
    />
  );
}

function LockThrow({ onClick, classes, paired, wasLockThrowRated }): JSX.Element {
  const extraAvatarClass = !paired
    ? classes.avatarDisabled
    : wasLockThrowRated
    ? classes.doneIconAvatarComplete
    : classes.avatarEnabled;
  return (
    <Task
      text={'Check the lock throw'}
      textDisabled={!paired}
      classes={classes}
      listItemAvatar={
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={`${classes.avatar} ${extraAvatarClass}`}>
            {wasLockThrowRated ? (
              <DoneIcon className={`${classes.doneIcon}`} />
            ) : (
              <LockCylinder className={paired ? classes.iconEnabled : classes.iconDisabled} />
            )}
          </Avatar>
        </ListItemAvatar>
      }
      iconButton={
        <IconButton className={classes.iconButton} disabled={!paired} onClick={onClick}>
          <NavigateNext className={paired ? classes.iconEnabledGrey : classes.iconDisabled} />
        </IconButton>
      }
    />
  );
}
