import React from 'react';
import unit_todo_theme from '../../Common/Themes/unit_todo_theme';
import { Button, createStyles, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeviceSensor } from '../../Common/Types/cloudApi';
import LockIcon from '@material-ui/icons/Lock';
import { dweloPalette } from '../../Common/Themes/dweloPalette';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deviceName: {
      textTransform: 'uppercase',
      marginBottom: '16px'
    },
    lockButton: {
      height: '36px',
      borderRadius: '24px',
      paddingLeft: '16px',
      paddingRight: '16px',
      marginBottom: '16px'
    },
    lockStatus: {
      marginBottom: '16px',
      color: dweloPalette.primary.grey[300]
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
);

type Props = {
  lockDoor: () => void;
  isDoorLocked: boolean;
  name: DeviceSensor['name'];
};

export function LockControlWidget(props: Props) {
  const classes = useStyles(unit_todo_theme);

  return (
    <div className={classes.root}>
      <Typography className={classes.deviceName}>{props.name}</Typography>
      <Button
        variant={'contained'}
        color={'secondary'}
        disabled={props.isDoorLocked}
        className={`${classes.lockButton}`}
        startIcon={<LockIcon />}
        onClick={props.lockDoor}
      >
        Lock
      </Button>
      <Typography className={classes.lockStatus}>
        {props.isDoorLocked ? 'Locked (unlock manually)' : 'Unlocked'}
      </Typography>
    </div>
  );
}
