import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orange } from '@material-ui/core/colors';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import button_theme from '../Themes/button_theme';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: orange[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function FinishInstallDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const getReasonSelect = () => {
    if (props.installStatusRadio === 'incomplete') {
      return (
        <div>
          <FormControl
            required={true}
            style={{ minWidth: 120 }}
            disabled={props.waitingSubmitResponse}
          >
            <InputLabel id="demo-simple-select-label">Reason</InputLabel>
            <Select
              name="incompleteReason"
              required
              value={props.incompleteReason}
              autoWidth={true}
              onChange={props.handleInputChange}
              style={{ marginBottom: 15 }}
            >
              {props.incompleteReasons.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    }
    return null;
  };

  const getAddOnSwitchesQuantity = () => {
    if (props.incompleteOnly) {
      return null;
    }
    if (props.addOnSwitchesInstalled) {
      return (
        <div>
          <TextField
            name="addOnSwitchesQty"
            required
            label="Add-on Switches Qty"
            type="number"
            value={props.addOnSwitchesQty}
            onChange={props.handleInputChange}
            disabled={props.waitingSubmitResponse}
          />
        </div>
      );
    }
    return null;
  };

  const getInstallStatusRadioGroup = () => {
    if (props.incompleteOnly) {
      return (
        <div>
          <RadioGroup
            name="installStatusRadio"
            value={props.installStatusRadio}
            onChange={props.handleInputChange}
          >
            <FormControlLabel
              value="incomplete"
              control={<Radio color="primary" />}
              label="Incomplete"
              disabled
            />
          </RadioGroup>
        </div>
      );
    }
    return (
      <div>
        <FormLabel component="legend">Was the installation completed?</FormLabel>
        <RadioGroup
          name="installStatusRadio"
          value={props.installStatusRadio}
          onChange={props.handleInputChange}
        >
          <FormControlLabel
            value="complete"
            control={<Radio color="primary" />}
            label="Complete"
            disabled={props.waitingSubmitResponse}
          />
          <FormControlLabel
            value="incomplete"
            control={<Radio color="primary" />}
            label="Incomplete"
            disabled={props.waitingSubmitResponse}
          />
        </RadioGroup>
      </div>
    );
  };

  const getPrivacyLockCheckbox = () => {
    if (props.incompleteOnly) {
      return null;
    }
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name="privacyLockInstalled"
              checked={props.privacyLockInstalled}
              onChange={props.handleInputChange}
              color="primary"
              disabled={props.waitingSubmitResponse}
            />
          }
          label="Privacy lock"
        />
      </div>
    );
  };

  const getAddOnSwitchesCheckbox = () => {
    if (props.incompleteOnly) {
      return null;
    }
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name="addOnSwitchesInstalled"
              checked={props.addOnSwitchesInstalled}
              onChange={props.handleInputChange}
              color="primary"
              disabled={props.waitingSubmitResponse}
            />
          }
          label="Add-on switches"
        />
      </div>
    );
  };
  const getTransformerCheckbox = () => {
    if (props.incompleteOnly || !props.transformerNeeded) {
      return null;
    }
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name="transformerInstalled"
              checked={props.transformerInstalled}
              onChange={props.handleInputChange}
              color="primary"
              disabled={props.waitingSubmitResponse}
            />
          }
          label="Transformer"
        />
      </div>
    );
  };

  const getHubLocationTextField = () => {
    if (props.incompleteOnly) {
      return null;
    }
    return (
      <div>
        <TextField
          name="hubLocationText"
          label="Hub Location"
          type="text"
          multiline
          rowsMax="2"
          value={props.hubLocationText}
          onChange={props.handleInputChange}
          disabled={props.waitingSubmitResponse}
        />
      </div>
    );
  };

  const getClosingNotesTextField = () => {
    return (
      <div>
        <TextField
          name="closingNotesText"
          label="Closing Notes"
          type="text"
          fullWidth
          multiline
          rowsMax="6"
          required
          value={props.closingNotesText}
          onChange={props.handleInputChange}
          disabled={props.waitingSubmitResponse}
        />
      </div>
    );
  };

  const reasonSelect = getReasonSelect();
  const installStatusRadioGroup = getInstallStatusRadioGroup();
  const privacyLock = getPrivacyLockCheckbox();
  const addOnSwitches = getAddOnSwitchesCheckbox();
  const addOnSwitchesQuantity = getAddOnSwitchesQuantity();
  const transformerCheck = getTransformerCheckbox();
  const hubLocation = getHubLocationTextField();
  const closingNotes = getClosingNotesTextField();
  const otherInformationLabel = !props.incompleteOnly && (
    <FormLabel style={{ marginTop: 15 }}>Other devices installed?</FormLabel>
  );

  return (
    <div>
      <MuiThemeProvider theme={button_theme}>
        <Dialog
          open={open}
          onClose={props.handleClose}
          fullWidth={true}
          maxWidth={'xs'}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogTitle>Submit Install</DialogTitle>
          <DialogContent>
            {installStatusRadioGroup}
            {reasonSelect}
            {otherInformationLabel}
            {privacyLock}
            {addOnSwitches}
            {addOnSwitchesQuantity}
            {transformerCheck}
            {hubLocation}
            {closingNotes}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.handleClose}
              disabled={props.waitingSubmitResponse}
              color="primary"
            >
              Close
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={props.handleSubmit}
                color="primary"
                variant={'contained'}
                disabled={props.waitingSubmitResponse}
              >
                Submit
              </Button>
              {props.waitingSubmitResponse && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

FinishInstallDialog.defaultProps = {
  incompleteReasons: [
    'Not enough time',
    'Not enough equipment',
    'Unable to gain access',
    'Onsite Reschedule',
    'Offsite Reschedule',
    'Customer Not Ready',
    'Other'
  ]
};

export default FinishInstallDialog;
