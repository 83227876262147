import * as React from 'react';
import { ReactElement } from 'react';
import { Banner } from '../Banner';
import { ReactComponent as WarningIcon } from '../../../images/warning_outlined.svg';
import { createStyles, Theme, Typography } from '@material-ui/core';
import { dweloPalette } from '../../../Themes/dweloPalette';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: dweloPalette.secondary.red[400],
      fontSize: '14px'
    }
  })
);

type Props = {
  action?: ReactElement;
  body?: ReactElement;
  subtitle?: string;
  title: string;
};

export function ErrorBanner(props: Props) {
  const classes = useStyles();
  return (
    <Banner
      backgroundColor={dweloPalette.secondary.red[100]}
      icon={<WarningIcon fill={dweloPalette.secondary.red[400]} />}
      title={
        <Typography className={classes.title}>
          <strong>{props.title}</strong>
        </Typography>
      }
      subtitle={<Typography className={classes.title}>{props.subtitle}</Typography>}
      body={props.body}
      action={props.action}
    />
  );
}
