import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import * as deviceConstants from '../../Common/Constants/deviceConstants';
import * as sensorConstants from '../../Common/Constants/sensorConstants';
import extractSensors, { getSensorStateMapKey } from '../../Common/scripts/extractSensors';
import lightbulb_v2 from '../../Common/images/lightbulb_v2.svg';
import lock_v3 from '../../Common/images/lock_v3.svg';
import thermometer_v3 from '../../Common/images/thermometer_v3.svg';
import { ReactComponent as LeakSensorIcon } from '../../Common/images/leak_sensor_v2.svg';
import { ReactComponent as DoorSensorIcon } from '../../Common/images/door-closed.svg';
import { ReactComponent as SlidingDoorIcon } from '../../Common/images/door-slider-closed.svg';
import { ReactComponent as MotionSensorIcon } from '../../Common/images/motion-sensor.svg';
import { ReactComponent as WindowSensorIcon } from '../../Common/images/window-closed.svg';
import { ReactComponent as UnknownSensorIcon } from '../../Common/images/sensor_unknown.svg';
import { getDeviceDisplayName } from '../../Common/utils/devices.ts';
import { getSensorPlacementFromMetadata } from '../../Common/utils/sensorPlacement.ts';
import '../../Common/stylesheets/global.scss';
import * as sensorValueMapConstants from '../../Common/Constants/sensorValueMapConstants';
import { SENSOR_PLACEMENT } from '../../Common/Constants/sensorPlacementConstants';

const styles = (theme) => ({
  textField: {
    fontSize: '16px',
    color: 'rgb(131,131,131)',
    marginTop: '3px'
  },
  primaryText: {
    fontSize: '20px'
  },
  avatarBackground: {
    backgroundColor: 'transparent'
  },
  spacing: {
    padding: '8px 0px 8px 0px',
    marginBottom: '20px',
    marginTop: '-20px',
    cursor: 'pointer',
    borderRadius: '5px'
  },
  primaryColor: {
    color: '#000000'
  },
  errorColor: {
    color: '#991210'
  }
});

const sensorType_DisplayName_Mapping = {
  [sensorValueMapConstants.BINARY_WATER_SENSOR_WET]: 'WET',
  [sensorValueMapConstants.BINARY_WATER_SENSOR_DRY]: 'DRY',
  [sensorValueMapConstants.MOTION_DETECTED]: 'MOTION',
  [sensorValueMapConstants.MOTION_NOT_DETECTED]: 'NO MOTION',
  [sensorValueMapConstants.LOCK_CHANGE_OPEN]: 'OPEN',
  [sensorValueMapConstants.LOCK_CHANGE_CLOSED]: 'CLOSED'
};

const sensorPlacement_Icon_Mapping = {
  [SENSOR_PLACEMENT.Door]: DoorSensorIcon,
  [SENSOR_PLACEMENT.Motion]: MotionSensorIcon,
  [SENSOR_PLACEMENT.SlidingDoor]: SlidingDoorIcon,
  [SENSOR_PLACEMENT.Window]: WindowSensorIcon,
  [SENSOR_PLACEMENT.Leak]: LeakSensorIcon
};

class Device extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.classes = classes;
    this.deviceDetail = this.deviceDetail.bind(this);
    this.state = {
      device: this.props.device
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ device: nextProps.device }, () => {});
  }

  deviceDetail(e) {
    let unitId = this.props.unit.uid;
    let communityId = this.props.unit.communityId;
    let device = this.state.device;
    return this.props.history.push(
      `/community/${communityId}/unit/${unitId}/device/${device.uid}/`
    );
  }

  getLockIconAndText(sensors) {
    let icon = <img src={lock_v3} alt="Lock" className="device-icon" />;
    let text = sensors[sensorConstants.LOCK_STATE].toUpperCase();
    return [icon, text];
  }

  getThermostatIconAndText(sensors, device) {
    let composite = [];
    let icon = '';
    if (device.model !== deviceConstants.NEST) {
      icon = <img src={thermometer_v3} alt="thermometer_v3" className="device-icon" />;
      if (sensors[sensorConstants.THERMOSTAT_MODE] !== '') {
        composite.push(sensors[sensorConstants.THERMOSTAT_MODE].toUpperCase());
      }
    }
    let text = composite.join(', ');
    return [icon, text];
  }
  getSwitchIconAndText(sensors) {
    let text = '';
    let icon = <img src={lightbulb_v2} alt="lightbulb_v2" className="device-icon" />;
    if (sensors[sensorConstants.BINARY_SWITCH]) {
      text = sensors[sensorConstants.BINARY_SWITCH].value.toUpperCase();
    }
    return [icon, text];
  }
  getDimmerIconAndText(sensors) {
    let text = '';
    let icon = <img src={lightbulb_v2} alt="switch" className="device-icon" />;
    if (sensors[sensorConstants.MULTILEVEL_SWITCH]) {
      if (sensors[sensorConstants.MULTILEVEL_SWITCH].value === 'Off') {
        text = sensors[sensorConstants.MULTILEVEL_SWITCH].value.toUpperCase();
      } else {
        text = sensors[sensorConstants.MULTILEVEL_SWITCH].percent + '%';
      }
    }
    return [icon, text];
  }
  getBinarySensorIconAndText(sensors, device) {
    let sensorStateKey = getSensorStateMapKey(sensors);
    let text = sensorType_DisplayName_Mapping[sensorStateKey] || '';
    let overrideStyles = this.classes.primaryColor;
    let sensorPlacement = getSensorPlacementFromMetadata(
      this.state.device.metadata_id,
      this.state.device.device_metadata
    );

    if (!device.notifications && sensorPlacement === 'leak') {
      text = 'TEST';
      overrideStyles = this.classes.errorColor;
    }

    let IconComponent = sensorPlacement_Icon_Mapping[sensorPlacement] || UnknownSensorIcon;
    let icon = <IconComponent className={overrideStyles} />;

    return [icon, text, overrideStyles];
  }

  render() {
    let clickAction = this.props.onClickOverride
      ? this.props.onClickOverride
      : this.deviceDetail;
    let icon = '';
    let text = '';
    let rootClasses = { root: this.classes.spacing };
    let device = this.state.device;
    let overrideStyles = '';
    if (!Object.prototype.hasOwnProperty.call(device, 'sensors')) {
      return '';
    }
    let sensors = extractSensors(device);

    switch (device.deviceType) {
      case deviceConstants.LOCK:
        [icon, text] = this.getLockIconAndText(sensors);
        break;
      case deviceConstants.THERMOSTAT:
        [icon, text] = this.getThermostatIconAndText(sensors, device);
        break;
      case deviceConstants.SWITCH:
        [icon, text] = this.getSwitchIconAndText(sensors);
        break;
      case deviceConstants.DIMMER:
        [icon, text] = this.getDimmerIconAndText(sensors);
        break;
      case deviceConstants.BINARY_SENSOR:
        [icon, text, overrideStyles] = this.getBinarySensorIconAndText(sensors, device);
        break;
      default:
        return '';
    }
    const displayName = getDeviceDisplayName(
      this.state.device.name,
      this.state.device.deviceType
    );
    return (
      <ListItem button classes={rootClasses} onClick={clickAction}>
        <ListItemAvatar>
          <Avatar classes={{ colorDefault: this.classes.avatarBackground }}>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText
          classes={{ primary: this.classes.primaryText + ' ' + overrideStyles }}
          primary={displayName}
          secondary={this.props.secondary ? this.props.secondary : null}
          primaryTypographyProps={{
            noWrap: true,
            style: { width: '65%' }
          }}
        />
        <ListItemSecondaryAction style={{ maxWidth: '105px' }}>
          <Typography component="p" className={this.classes.textField} noWrap={true}>
            {text}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default withStyles(styles)(withRouter(Device));
