import React, { Component } from 'react';
import UnitList from './components/UnitList';

import * as routes from './units_apiRoutes';
import withSpinner from '../Common/utils/withSpinner';
import client from '../Common/utils/client';
import withAuth from '../Common/utils/withAuth';
import { SharedVars } from '../Common/Constants/salesforceConstants';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import {
  handle401ErrorRedirect,
  handleNon401ErrorRedirect,
  is401Error
} from '../Common/utils/errorHandling';

const UnitListWithSpinner = withSpinner(UnitList);

class UnitsPage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      community: {},
      units: []
    };
  }

  componentDidMount() {
    const communityId = this.props.match.params.communityId;
    this.setState({ loading: true });
    client
      .get(routes.ADDRESSES(communityId))
      .then((res) => {
        let sorted = res.data.results.sort((a, b) => (a.unit > b.unit ? 1 : -1));
        this.setState({
          units: sorted
        });
        return client.get(routes.COMMUNITY(communityId));
      })
      .then((res) => {
        if (res.data.results.length === 0) {
          this.props.history.push('/');
        } else {
          localStorage.setItem(
            'multiple',
            !!res.data[SharedVars.MULTIPLE_COMMUNITIES_AVAILABLE]
          );
          this.setState({
            community: res.data.results[0],
            loading: false
          });
        }
      })
      .catch((error) => {
        if (is401Error(error)) {
          handle401ErrorRedirect(error, this.props.history);
        } else {
          handleNon401ErrorRedirect(error, this.props.history);
        }
      });
  }
  render() {
    return (
      <UnitListWithSpinner
        isLoading={this.state.loading}
        units={this.state.units}
        community={this.state.community}
        {...this.props}
      />
    );
  }
}

export default withLDConsumer()(withAuth(UnitsPage));
