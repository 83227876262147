type RouteVar = number | string;

export function makeCommunityRoute(communityId: RouteVar) {
  return `/community/${communityId}`;
}

export function makeUnitRoute(communityId: RouteVar, unitId: RouteVar, isUnitV2 = false) {
  const unitVersion = isUnitV2 ? 'unit_v2' : 'unit';
  return `${makeCommunityRoute(communityId)}/${unitVersion}/${unitId}`;
}

export function makeDeviceRoute(
  communityId: RouteVar,
  unitId: RouteVar,
  deviceId: RouteVar,
  isUnitV2 = false
) {
  return `${makeUnitRoute(communityId, unitId, isUnitV2)}/device/${deviceId}`;
}

export function isUnitV2Path(path: string): boolean {
  return path.includes('unit_v2');
}
