import {
  DCM,
  DeviceSensor,
  DeviceToBeInstalled,
  Unit,
  V4_Hub_Status
} from '../../Common/Types/cloudApi';
import { LOCK } from '../../Common/Constants/deviceConstants';
import {
  CodeSyncStatuses,
  DevicesCompleteState,
  DevicesPairedByType,
  DeviceTypeCompleteState,
  LockThrowDialogReducer,
  UnitMetaAction,
  UnitMetaState
} from './types';
import {
  DeviceTypeMeta,
  hubRebootDateStorage,
  LockDevicePaired,
  LockTypeMeta,
  mergeNewPairedLockProps,
  RingTroubleshootInfo,
  UnitTodoMeta,
  unitTodoMetaStorage
} from '../../Common/utils/storageInterface';
import {
  DeviceTypes,
  SharedVars,
  WorkTicket
} from '../../Common/Constants/salesforceConstants';
import {
  GOOD_CELLULAR_SIGNAL_MINIMUM,
  REBOOT_MAX_DURATION_SECS
} from '../../Common/Constants/installerAppConstants';
import {
  SFDeviceType,
  TodoListDeviceTypes,
  TodoListDeviceTypesUnion
} from '../../Common/Constants/salesforceConstants_v2';
import { DweloDeviceType, LIGHT_TYPES } from '../../Common/Constants/deviceConstants_v2';
import { isRingDoorbellDevice } from '../../Common/utils/devices';
import { DoorbellModel } from '../../Common/Constants/deviceCapabilities';
import { TransformerModel, ChimeModel } from '../../Common/Constants/deviceCapabilities';
import {
  BINARY_SENSOR_WATER,
  LOCK_CHANGE,
  MOTION
} from '../../Common/Constants/sensorConstants';

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function isCodeSyncInProgress(dcm: DeviceSensor['dcm']): boolean {
  return (
    !!dcm &&
    dcm.version > 1 &&
    (dcm.codesSynced < dcm.codesExpected || (!dcm.masterCodeSynced && dcm.masterCodeExpected))
  );
}

/**
 * If a lock is paired, this will return the quantity of codes synced if a lock is still syncing,
 * or the final quantity if syncing has finished for all locks.
 * codeSyncInProgress will be true if codes are still syncing.
 */
export function getLegacyCodeStatuses(devices: Array<DeviceSensor>): CodeSyncStatuses {
  const allDCMs: Array<DCM> = [];
  devices.forEach((d) => {
    if (d.deviceType === LOCK) {
      allDCMs.push(d.dcm);
    }
  });
  const oldestDcmInProgressIndex = allDCMs.findIndex((dcm) => {
    return isCodeSyncInProgress(dcm);
  });
  let currentDCM: DCM | undefined;
  let legacyCodeSyncInProgress = false;
  if (oldestDcmInProgressIndex >= 0) {
    currentDCM = allDCMs[oldestDcmInProgressIndex];
    legacyCodeSyncInProgress = true;
  } else if (allDCMs.length > 0) {
    currentDCM = allDCMs[allDCMs.length - 1];
  }
  return {
    allDCMs,
    currentDCM,
    legacyCodeSyncInProgress
  };
}

/**
 * The reducer is used because primary switch
 * and dimmer switch quantities are combined.
 * Else, we could do props.devicesToPair[0].quantity
 */
export function getQuantityToPair(devicesToPair: Array<DeviceToBeInstalled>) {
  return devicesToPair.reduce((accumulator, currentDevice) => {
    return accumulator + currentDevice.quantity;
  }, 0);
}

/**
 * Creates an array per generic device type (Lights, Thermostats, Locks, WaterSensors) and returns an array of these arrays.
 * An array per generic type is used because Primary and Dimmer switches are grouped together, per UX design.
 * Example return:
 * [[Object primarySwitch, Object dimmerSwitch], [Object thermostat], [Object lock], [Object waterSensor]]
 */
export function groupDevicesToPairByType(
  devicesToPair: Array<DeviceToBeInstalled>
): Array<Array<DeviceToBeInstalled>> {
  const lights: Array<DeviceToBeInstalled> = [];
  const thermostats: Array<DeviceToBeInstalled> = [];
  const locks: Array<DeviceToBeInstalled> = [];
  const sensors: Array<DeviceToBeInstalled> = [];
  const ringDoorbells: Array<DeviceToBeInstalled> = [];

  devicesToPair.forEach((deviceToPair) => {
    switch (deviceToPair[SharedVars.DEVICE_TYPE]) {
      case DeviceTypes.PRIMARY_SWITCH:
      case DeviceTypes.DIMMER_SWITCH:
        lights.push(deviceToPair);
        break;
      case DeviceTypes.LOCK:
        locks.push(deviceToPair);
        break;
      case DeviceTypes.THERMOSTAT:
        thermostats.push(deviceToPair);
        break;
      case DeviceTypes.SENSOR:
        sensors.push(deviceToPair);
        break;
      case DeviceTypes.DOORBELL:
        if (isRingDoorbellSku(deviceToPair)) {
          ringDoorbells.push(deviceToPair);
        }
        break;
    }
  });
  // The order of devices is how it will appear on screen, from top to bottom.
  return [ringDoorbells, lights, sensors, thermostats, locks].filter(
    (devices) => devices.length > 0
  );
}

export function getDevicesPairedByType(devices: Array<DeviceSensor>): DevicesPairedByType {
  return devices.reduce(
    (allDevices, currentDevice): DevicesPairedByType => {
      const deviceType = getTodoListTypeForDevice(currentDevice);
      const supportedDeviceTypes: TodoListDeviceTypesUnion[] = Object.values(
        TodoListDeviceTypes
      );
      if (!supportedDeviceTypes.includes(deviceType as any)) {
        return { ...allDevices };
      }
      const deviceTypeArray = allDevices[deviceType];
      return {
        ...allDevices,
        [deviceType]: deviceTypeArray ? deviceTypeArray.concat(currentDevice) : [currentDevice]
      };
    },
    {
      ringDoorbell: [],
      lock: [],
      thermostat: [],
      light: [],
      sensor: []
    } as DevicesPairedByType
  );
}

/**
 * REFRESH_STATE: replace state with a new object received from action.newState
 * TOGGLE_LOCK_BATTERIES_INSTALLED: toggle based on id (either a uid if paired, or list index if not paired)
 */
export function unitMetaReducer(state: UnitMetaState, action: UnitMetaAction): UnitMetaState {
  switch (action.type) {
    case 'REFRESH_STATE': {
      return action.newState;
    }
    case 'UPDATE_LOCK_THROW_PROPERTIES': {
      const locksMeta: LockTypeMeta = state.lock || {
        locksPaired: [],
        locksToPair: []
      };

      mergeNewPairedLockProps(locksMeta, { uid: action.id, lockThrow: action.properties });

      return { ...state, lock: locksMeta };
    }
    case 'TOGGLE_LOCK_BATTERIES_INSTALLED': {
      let newState = { ...state };
      if (!newState.lock) {
        newState.lock = {
          locksPaired: [],
          locksToPair: []
        };
      }
      if (action.paired) {
        const index = newState.lock.locksPaired.findIndex((lock) => lock.uid === action.id);
        if (index === -1) {
          newState.lock.locksPaired.push({ uid: action.id, batteriesInstalled: false });
        } else {
          newState.lock.locksPaired[index].batteriesInstalled = !newState.lock.locksPaired[
            index
          ].batteriesInstalled;
        }
      } else if (action.id < newState.lock.locksToPair.length) {
        newState.lock.locksToPair[action.id].batteriesInstalled = !newState.lock.locksToPair[
          action.id
        ].batteriesInstalled;
      }
      return newState;
    }
  }
}

export const lockThrowDialogReducer: LockThrowDialogReducer = (prevState, action) => {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return {
        open: true,
        id: action.id
      };

    case 'CLOSE_DIALOG':
      return {
        ...prevState,
        open: false
      };

    default:
      return prevState;
  }
};

/**
 * Creates a new object of type UnitTodoMeta.
 * This needs to be called when initializing unitMeta,
 * and when the number of paired devices changes.
 * That way, old meta from unitMetaStorage can be removed (excluded device or reduced Salesforce quantity),
 * new meta can be created (included device or increased Salesforce quantity),
 * and old meta can be merged into new meta when necessary (included device).
 */
export function createUnitMetaCallback(
  devices: Array<DeviceSensor>,
  devicesToPair: Array<DeviceToBeInstalled>,
  unitId: Unit['uid']
): UnitMetaState {
  const unitMeta: UnitTodoMeta | null = unitTodoMetaStorage.get();
  const lockToPair = devicesToPair.find((d) => {
    return d.deviceType === LOCK;
  });
  if (!lockToPair) {
    // Locks require more validation, whereas we can just return raw metadata for other devices.
    return unitMeta ? unitMeta : {};
  }
  const lockMeta: LockTypeMeta = {
    locksPaired: [],
    locksToPair: [],
    ...unitMeta?.lock
  };
  const pairedLockIds = devices.filter((d) => d.deviceType === LOCK).map((d) => d.uid);
  lockMeta.locksPaired = lockMeta.locksPaired.filter(
    // Remove locks that are not paired anymore.
    (d) => pairedLockIds.includes(d.uid)
  );
  const metaLockIds = lockMeta.locksPaired.map((d) => d.uid);
  pairedLockIds.forEach((pairedId) => {
    // Add newly paired locks and populate by merging values from lockMeta.devicesPaired (or use default values)
    if (metaLockIds.includes(pairedId)) {
      return;
    }
    let existingDeviceToPair = lockMeta.locksToPair.shift();
    lockMeta.locksPaired.push({
      uid: pairedId,
      batteriesInstalled: !!existingDeviceToPair?.batteriesInstalled
    });
  });
  let remainingQuantityToPair =
    lockMeta.locksPaired.length < lockToPair.quantity
      ? lockToPair.quantity - lockMeta.locksPaired.length
      : 0;
  while (lockMeta.locksToPair.length < remainingQuantityToPair) {
    // Add locks to pair if we haven't paired the minimum desired quantity of locks yet.
    lockMeta.locksToPair.push({
      batteriesInstalled: false
    });
  }
  while (lockMeta.locksToPair.length > remainingQuantityToPair) {
    // Remove locks to pair if we have too many locks to pair
    lockMeta.locksToPair.pop();
  }
  unitTodoMetaStorage.updateDeviceTypeTodo(unitId, DweloDeviceType.Lock, lockMeta);
  return {
    ...unitMeta,
    lock: { ...lockMeta }
  };
}

/**
 * Returns an object containing the "complete" states (true/false) of all paired devices of the same device type,
 * as well as an "all" property (true/false) for indicating if all devices are complete and the desired number
 * of devices to pair have been paired.
 *
 * The "all" property of a non-lock type is true when quantity of paired devices >= quantityToPair.
 * And each individual device is considered complete by default, as there are no other dependencies or tasks.
 *
 * Locks are special and have more dependencies in order to be complete.
 * The "all" property of the lock type is true when the desired quantity of locks are paired,
 * and all tasks for each lock are completed, which are:
 * 1. lock is paired
 * 2. all codes have synced
 * 3. industrial batteries installed
 */
export function createDeviceTypeCompleteState(
  deviceType: TodoListDeviceTypesUnion,
  devices: Array<DeviceSensor>,
  todoMeta: LockTypeMeta | DeviceTypeMeta | undefined,
  quantityToPair: DeviceToBeInstalled['quantity']
): DeviceTypeCompleteState {
  // Only 1 ring doorbell is supported. Treat salesforce quantities greater than 1 as 1.
  const completeState = {
    all:
      deviceType === 'ringDoorbell' && devices.length > 0
        ? true
        : devices.length >= quantityToPair
  };
  if (deviceType !== LOCK) {
    return devices.reduce(
      (obj, device) => ({
        ...obj,
        [device.uid]: true
      }),
      completeState
    );
  }
  const lockMeta: LockTypeMeta | null = todoMeta as LockTypeMeta | null;
  if (!lockMeta?.locksPaired) {
    devices.forEach((d) => (completeState[d.uid] = false));
    completeState['all'] = false;
    return completeState;
  }
  const lockPairedMetaUids: Array<LockDevicePaired['uid']> = lockMeta.locksPaired.reduce(
    (uids, lockPairedMeta) => {
      const lock = devices.find((device) => device.uid === lockPairedMeta.uid);
      if (!lock) return uids;
      const dcm = lock.dcm as DCM;
      const complete =
        lockPairedMeta.batteriesInstalled &&
        (!(dcm.version > 1) || !isCodeSyncInProgress(dcm)) &&
        !!(lockPairedMeta.lockThrow?.nothingButAir || lockPairedMeta.lockThrow?.resultOfIssue);
      completeState[lockPairedMeta.uid] = complete;
      completeState['all'] = completeState['all'] && complete;
      return [...uids, lockPairedMeta.uid];
    },
    []
  );
  // remaining devices that weren't already in lockMeta
  devices
    .filter((d) => !lockPairedMetaUids.includes(d.uid))
    .forEach((d) => {
      completeState[d.uid] = false;
      completeState['all'] = false;
    });
  return completeState;
}

export function devicesCompleteStateCallback(
  devicesPaired: Array<DeviceSensor>,
  devicesToPairByType: Array<Array<DeviceToBeInstalled>>,
  todoMeta: UnitMetaState
): DevicesCompleteState {
  const devicesPairedByType = getDevicesPairedByType(devicesPaired);
  if (devicesToPairByType.length === 0) {
    return { all: false };
  }
  return devicesToPairByType.reduce(
    (result, currentDevicesToPair): DeviceTypeCompleteState => {
      const todoDeviceType = getTodoListTypeForSalesforceSku(currentDevicesToPair[0]);
      if (!todoDeviceType) {
        return result;
      }
      const devicePairedByType = devicesPairedByType[todoDeviceType];
      if (!devicePairedByType) {
        return result;
      }
      const deviceTypeCompleteState = createDeviceTypeCompleteState(
        todoDeviceType,
        devicePairedByType,
        todoMeta[todoDeviceType],
        getQuantityToPair(currentDevicesToPair)
      );
      return {
        ...result,
        [todoDeviceType]: deviceTypeCompleteState,
        all: result.all && deviceTypeCompleteState.all
      };
    },
    { all: true }
  );
}

/* *************************************************************
 * Reboot Utils
 * *************************************************************/

export function getDiffSecsBetweenNowAndDateString(dateString: string) {
  let prev = new Date(dateString);
  let now = new Date();
  return (now.getTime() - prev.getTime()) / 1000;
}

export function currentBootTimeGreaterThanStored(
  dateString: string,
  currentBootTime: V4_Hub_Status['boot_time']
) {
  return new Date(currentBootTime) > new Date(dateString);
}

/**
 * If the return is true we will assume that the reboot was done through the API.
 * We can't really know if the boot_time from our API is directly a result of the reboot command we sent,
 * so we can only assume. (It could've also been unplugged/plugged in soon after we sent the command.)
 */
export function assumeHubRebootedDueToApiCall(
  currentBootTime: V4_Hub_Status['boot_time'],
  hubSerial: Unit['hub_serial'],
  storedRebootString?: string
): boolean {
  storedRebootString = storedRebootString
    ? storedRebootString
    : hubRebootDateStorage(hubSerial).get();
  if (!storedRebootString) {
    return false;
  }
  return currentBootTimeGreaterThanStored(storedRebootString, currentBootTime);
}

export function wasHubRebootMaxDurationExceeded(
  unitId: Unit['uid'],
  hubSerial: Unit['hub_serial'],
  storedRebootString?: string
): boolean {
  storedRebootString = storedRebootString
    ? storedRebootString
    : hubRebootDateStorage(hubSerial).get();
  if (!storedRebootString) {
    return false;
  }
  return REBOOT_MAX_DURATION_SECS < getDiffSecsBetweenNowAndDateString(storedRebootString);
}

export function containsTodoListDeviceToInstall(
  devicesToInstall: Array<DeviceToBeInstalled>
): boolean {
  return !!devicesToInstall.find((device) => {
    return !!getTodoListTypeForSalesforceSku(device);
  });
}

export function isRingDoorbellSku(device: DeviceToBeInstalled): boolean {
  return (
    device.deviceType === SFDeviceType.Doorbell &&
    [DoorbellModel.RingWired, DoorbellModel.RingPro].includes(device.modelNumber as any)
  );
}
export function isTransformerSku(device: DeviceToBeInstalled): boolean {
  return device.modelNumber === TransformerModel.DoorbellTransformer;
}
export function isChimeSku(device: DeviceToBeInstalled): boolean {
  return device.modelNumber === ChimeModel.RingChime;
}

export function isMotionSensorSku(device: DeviceToBeInstalled): boolean {
  return device.sensorReadings.includes(MOTION);
}

export function isRecessedDoorSensorSku(device: DeviceToBeInstalled): boolean {
  return (
    device.wtField === WorkTicket.RECESSED_DOOR_SENSOR__R &&
    device.sensorReadings.includes(LOCK_CHANGE)
  );
}

export function isDoorWindowSensorSku(device: DeviceToBeInstalled): boolean {
  return (
    device.wtField === WorkTicket.DOOR_WINDOW_SENSOR__R &&
    device.sensorReadings.includes(LOCK_CHANGE)
  );
}

export function isLeakSensorSku(device: DeviceToBeInstalled): boolean {
  return device.sensorReadings.includes(BINARY_SENSOR_WATER);
}

export function getTodoListTypeForSalesforceSku(
  device: DeviceToBeInstalled
): TodoListDeviceTypesUnion | undefined {
  if (isRingDoorbellSku(device)) {
    return 'ringDoorbell';
  }
  switch (device.deviceType) {
    case SFDeviceType.Lock:
      return 'lock';
    case SFDeviceType.Thermostat:
      return 'thermostat';
    case SFDeviceType.PrimarySwitch:
    case SFDeviceType.DimmerSwitch:
      return 'light';
    case SFDeviceType.Sensor:
      return 'sensor';
    default:
      return undefined;
  }
}

export function getTodoListTypeForDevice(device: DeviceSensor): TodoListDeviceTypesUnion {
  if (isRingDoorbellDevice(device)) {
    return 'ringDoorbell';
  } else if (LIGHT_TYPES.includes(device.deviceType)) {
    return 'light';
  } else if (device.deviceType === DweloDeviceType.BinarySensor) {
    return 'sensor';
  } else {
    return device.deviceType as TodoListDeviceTypesUnion;
  }
}

export function appendDataToClosingNotes(
  closingNotes: string,
  data: { ringTroubleshootInfo?: RingTroubleshootInfo }
) {
  let newNotes = closingNotes;
  if (data.ringTroubleshootInfo) {
    const { issueDescription, stepsTaken, resultOfIssue } = data.ringTroubleshootInfo;
    newNotes += `\n\nRing Troubleshooting Notes\r
      Issue Description: ${issueDescription || 'no_content'}\r
      Steps Taken: ${stepsTaken || 'no_content'}\r
      Result: ${
        !resultOfIssue ? 'no_content' : resultOfIssue === 'FIXED' ? 'I Fixed It' : 'Report It'
      }
    `;
  }
  return newNotes;
}

export function isCellularSignalLow(cellularSignal: number | null) {
  if (Number.isInteger(cellularSignal)) {
    return (cellularSignal as number) < GOOD_CELLULAR_SIGNAL_MINIMUM;
  }
  return false;
}

export function isOnlyConnectedToCellular({
  connectedToCellular,
  connectedToEthernet,
  connectedToWiFi
}) {
  return connectedToCellular && !connectedToEthernet && !connectedToWiFi;
}

export function isOnlyConnectedToLowCellular({
  cellularSignal,
  connectedToCellular,
  connectedToEthernet,
  connectedToWiFi
}: {
  cellularSignal: number | null;
  connectedToCellular: boolean;
  connectedToEthernet: boolean;
  connectedToWiFi: boolean;
}) {
  return (
    isOnlyConnectedToCellular({ connectedToCellular, connectedToEthernet, connectedToWiFi }) &&
    isCellularSignalLow(cellularSignal)
  );
}

export function clearIntervals(intervals: (NodeJS.Timeout | undefined)[]) {
  intervals.forEach((interval) => {
    if (interval) {
      clearInterval(interval);
    }
  });
}

export function setFocusRequiredInterval(callback: () => void, timeout: number) {
  return setInterval(() => {
    if (document.hasFocus()) {
      callback();
    }
  }, timeout);
}
