import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as sensorConstants from '../../Common/Constants/sensorConstants';
import extractSensors, { getSensorStateMapKey } from '../../Common/scripts/extractSensors';
import '../../Common/stylesheets/global.scss';
import theme from '../../Common/Themes/login_theme';
import * as sensorValueMapConstants from '../../Common/Constants/sensorValueMapConstants';
import { getNoSensorsText } from '../common/sensorReadings';

const sensorType_DisplayName_Mapping = {
  [sensorValueMapConstants.BINARY_WATER_SENSOR_WET]: 'Water detected',
  [sensorValueMapConstants.BINARY_WATER_SENSOR_DRY]: 'Dry',
  [sensorValueMapConstants.MOTION_DETECTED]: 'Motion detected',
  [sensorValueMapConstants.MOTION_NOT_DETECTED]: 'No motion detected',
  [sensorValueMapConstants.LOCK_CHANGE_OPEN]: 'Open',
  [sensorValueMapConstants.LOCK_CHANGE_CLOSED]: 'Closed'
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%',
    borderBottom: 'none',
    marginTop: '16px',
    marginBottom: '0px'
  },
  resize: {
    fontSize: '20px'
  },
  resize_error: {
    fontSize: '20px',
    color: '#991210'
  }
});

class BinarySensor extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
    this.state = {
      unit: this.props.unit,
      device: this.props.device
    };
  }

  convertTimeIssued = (date) => {
    if (!date) {
      return date;
    } else {
      var today = new Date();
      var time = new Date(date);
      var diffMs = today - time; // milliseconds between now & Christmas
      var diffDays = Math.floor(diffMs / 86400000);
      var returnString = '';
      if (diffDays > 0) {
        return 'More than 12 hours ago';
      }
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
      if (diffHrs > 0) {
        if (diffHrs >= 12) {
          return 'More than 12 hours ago';
        }
        returnString = diffHrs;
        if (diffHrs === 1) {
          returnString += ' hour ';
        } else {
          returnString += ' hours ';
        }
      }
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      if (diffMins === 1) {
        return returnString + diffMins + ' minute ago';
      }
      return returnString + diffMins + ' minutes ago';
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ device: nextProps.device });
  }

  getStateDisplayName = (sensors) => {
    const mapKey = getSensorStateMapKey(sensors);
    return sensorType_DisplayName_Mapping[mapKey];
  };

  render() {
    const sensors = extractSensors(this.state.device);
    const fallbackText = getNoSensorsText(this.state.device.sensors);

    return (
      <MuiThemeProvider theme={theme}>
        <form className={this.classes.container}>
          <TextField
            label="SENSOR STATE"
            value={this.getStateDisplayName(sensors) || fallbackText}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="BATTERY LEVEL"
            value={
              sensors[sensorConstants.BATTERY_LEVEL]
                ? sensors[sensorConstants.BATTERY_LEVEL] + '%'
                : fallbackText
            }
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="LAST BATTERY READING"
            value={
              sensors[sensorConstants.BATTERY_LAST_READING]
                ? this.convertTimeIssued(sensors[sensorConstants.BATTERY_LAST_READING])
                : fallbackText
            }
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
        </form>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(BinarySensor);
