import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Link,
  Theme,
  createStyles,
  Typography,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getModelDisplayName } from './utils';
import {
  DWELO_CARE_PHONE,
  DWELO_CARE_PHONE_HREF,
  RING_PRO_TROUBLESHOOT_URL,
  RING_PRO_VOLTAGE,
  RING_WIRED_TROUBLESHOOT_URL,
  RING_WIRED_VOLTAGE
} from '../../../Common/Constants/installerAppConstants';
import { NumberedStep } from '../../../Common/MiscComponents/NumberedStep';
import { ButtonName, RingDeviceDetails, RingTroubleshootInfo } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: '32px 24px 76px'
    },
    dialogActions: {
      paddingLeft: '0px',
      paddingRight: '0px',
      marginTop: '16px'
    },
    link: {
      textAlign: 'right',
      fontSize: '14px'
    },
    linkContainer: {
      textAlign: 'right',
      marginTop: '12px',
      width: '100%'
    }
  })
);

interface Props {
  model: RingDeviceDetails['ringModel'];
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  troubleshootInfo: RingTroubleshootInfo;
  handleTroubleshootChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

export function TroubleshootRingContent(props: Props) {
  const classes = useStyles();

  const troubleshootUrl =
    props.model === 'pro' ? RING_PRO_TROUBLESHOOT_URL : RING_WIRED_TROUBLESHOOT_URL;
  const voltageRange = props.model === 'pro' ? RING_PRO_VOLTAGE : RING_WIRED_VOLTAGE;

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <NumberedStep number={1}>
          <Typography>
            Check the voltage and ensure that it is between {voltageRange}.
          </Typography>
        </NumberedStep>
        <NumberedStep number={2}>
          <Typography>
            See the installation instructions from{' '}
            <Link
              href="https://support.ring.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#1C1C1C', textDecoration: 'underline' }}
            >
              support.ring.com
            </Link>
            .
          </Typography>
          <div className={classes.linkContainer}>
            <Link
              className={classes.link}
              href={troubleshootUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getModelDisplayName(props.model)} Physical Installation
            </Link>
          </div>
        </NumberedStep>
        <NumberedStep number={3}>
          <Typography>
            If there is an issue with the unit or hardware provided, contact DweloCare by using
            the chat below or calling{' '}
            <Link className={classes.link} href={DWELO_CARE_PHONE_HREF}>
              {DWELO_CARE_PHONE}
            </Link>
            .
          </Typography>
        </NumberedStep>
        <NumberedStep number={4} hideDivider>
          <Typography>Tell us about the issue.</Typography>
          <TextField
            label="Issue Description"
            inputProps={{ 'aria-label': 'Issue Description' }}
            name="issueDescription"
            value={props.troubleshootInfo.issueDescription}
            onChange={props.handleTroubleshootChange}
            style={{ marginTop: '24px' }}
            type="text"
            color="secondary"
            multiline
            fullWidth
          />
          <TextField
            label="Steps Taken"
            inputProps={{ 'aria-label': 'Steps Taken' }}
            name="stepsTaken"
            value={props.troubleshootInfo.stepsTaken}
            onChange={props.handleTroubleshootChange}
            style={{ marginTop: '12px' }}
            type="text"
            color="secondary"
            multiline
            fullWidth
          />
          <Typography style={{ marginTop: '24px' }}>
            Were you able to fix the issue or do you need to report it?
          </Typography>
          <DialogActions className={classes.dialogActions}>
            <Button
              name={ButtonName.ReportedIssue}
              onClick={props.handleSubmit}
              variant="outlined"
              color="secondary"
              disabled={
                !props.troubleshootInfo.stepsTaken || !props.troubleshootInfo.stepsTaken
              }
              fullWidth
            >
              Report It
            </Button>
            <Button
              name={ButtonName.FixedIssue}
              onClick={props.handleSubmit}
              variant="contained"
              color="secondary"
              disabled={
                !props.troubleshootInfo.stepsTaken || !props.troubleshootInfo.stepsTaken
              }
              fullWidth
            >
              I Fixed It
            </Button>
          </DialogActions>
        </NumberedStep>
      </DialogContent>
    </>
  );
}
