import React from 'react';
import {
  createStyles,
  DialogActions,
  DialogContent,
  Theme,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RingDetailsSelect } from './RingDetailsSelect';
import { LoadableButton } from '../../../Common/Buttons/LoadableButton';
import { ButtonName, RingDeviceDetails, RingPage } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: '32px 24px 24px'
    },
    dialogActions: {
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '100px'
    }
  })
);

interface Props {
  deviceDetails: RingDeviceDetails;
  handleDetailChange: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>, callback?: () => void) => void;
  loading: boolean;
}

export function CreateRingContent(props: Props) {
  const classes = useStyles();

  const hasUnfilledFields =
    !props.deviceDetails.ringModel ||
    !props.deviceDetails.ringChime ||
    (props.deviceDetails.ringModel === 'pro' && !props.deviceDetails.chimeType);

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Typography>Confirm the Ring device details.</Typography>
        <RingDetailsSelect
          handleDetailChange={props.handleDetailChange}
          deviceDetails={props.deviceDetails}
          page={RingPage.Create}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <LoadableButton
          buttonProps={{
            name: ButtonName.ConfirmAndInstall,
            onClick: props.handleSubmit,
            variant: 'contained',
            color: 'secondary',
            fullWidth: true,
            disabled: hasUnfilledFields || props.loading
          }}
          progressProps={{
            size: 24,
            color: 'secondary'
          }}
          loading={props.loading}
        >
          Confirm and Install
        </LoadableButton>
      </DialogActions>
    </>
  );
}
