import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import { YALE_LOCK_MASTER_CODES } from '../Constants/launchDarklyConstants';

const styles = (theme) => ({
  statusBar: {
    color: '#fff',
    fontSize: '24px'
  },
  statusBarNormal: {
    backgroundColor: '#555555'
  },
  statusBarError: {
    backgroundColor: '#991210'
  },
  statusBarSuccess: {
    backgroundColor: '#75C24F'
  },
  textField: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '100%'
  }
});

class StatusBar extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
  }

  render() {
    let status = this.classes.statusBarNormal;
    if (this.props.error) {
      status = this.classes.statusBarError;
    } else if (this.props.success) {
      status = this.classes.statusBarSuccess;
    }

    // accommodate the YaleHeader component, which will go above the StatusBar component.
    let boxShadow = this.props.flags[YALE_LOCK_MASTER_CODES] ? '' : '0 -6px 0 #F6861E, ';
    boxShadow += '0 1px 6px rgba(0,0,0, .35)';

    return (
      <Paper
        square
        className={`${status} ${this.classes.statusBar}`}
        style={{ boxShadow: boxShadow }}
        elevation={0}
      >
        <Toolbar>{this.props.message}</Toolbar>
      </Paper>
    );
  }
}

export default withLDConsumer()(withStyles(styles)(StatusBar));
