export const REBOOT_MAX_DURATION_SECS = 240; // 4 minutes
export const RESTART_MAX_DURATION_SECS = 30; // 0.5 minutes

// The string values just help when looking at the logs when debugging
export enum INCLUSION_EXCLUSION_STAGES {
  CHOOSE_DEVICE = 'choose-device',
  PAIRING_START = 'pairing-start',

  INCLUSION_PREP = 'inclusion-prep',
  INCLUSION_START = 'inclusion-start',
  INCLUDING = 'including',
  INCLUSION_SUCCESS = 'inclusion-success',

  EXCLUSION_PREP = 'exclusion-prep',
  EXCLUSION_START = 'exclusion-start',
  EXCLUDING = 'excluding',
  EXCLUSION_SUCCESS = 'exclusion-success',

  DEVICE_INFO = 'device-info'
}

export const INITIAL_YALE_CODE_KEY = 'initialYaleCode';

export const YALE_CODE_BLACKLIST = [
  '000000',
  '111111',
  '222222',
  '333333',
  '444444',
  '555555',
  '666666',
  '777777',
  '888888',
  '999999',
  '123456',
  '123123',
  '123321',
  '112233',
  '121212',
  '654321',
  '520520',
  '789456',
  '159753',
  '123654',
  '321654',
  '520131',
  '147258'
];

// durations in seconds
export const INCLUSION_DURATION = 90;
export const EXCLUSION_DURATION = 30;
export const EXCLUSION_DURATION_YALE = 90;

// durations in milliseconds
export const INCLUSION_DURATION_MS = INCLUSION_DURATION * 1000;

// There is an issue with including S2 devices where it will
// fail to include on the first try and installers
// need to include again for it to work.
// But if the second inclusion doesnt' work, we will want
// to a slightly different UI.
// Since this number is 1, we can attempt inclusion once,
// then the next attempts will show the S2 UI.
export const INCLUSION_ATTEMPTS_BEFORE_S2_UI = 1;

export const NOTHING_BUT_AIR_VIDEO_URL =
  'https://drive.google.com/file/d/12vqAAXWsFjdWHSyrY28a3er_psdSgqXJ/view';
export const DEVICE_TROUBLESHOOTING_ARTICLES_URL =
  'https://help.dwelo.com/s/content/troubleshooting-articles-20Y1M000000TN31UAG';
export const DWELO_DOORBELL_HELP_SITE =
  'https://sites.google.com/dwelo.com/dwelo-fulfilment/device-help/doorbell-cams';

export const CENTRAL_COLUMN_MAX_WIDTH = 570;

export const DWELO_CARE_PHONE = '415-582-6701';
export const DWELO_CARE_PHONE_HREF = `tel:+1-${DWELO_CARE_PHONE}`;

const RING_SUPPORT_HOST = 'https://support.ring.com';
export const RING_PRO_TROUBLESHOOT_URL = `${RING_SUPPORT_HOST}/hc/en-us/articles/360036047831-Physically-Installing-your-Ring-Video-Doorbell-Pro`;
export const RING_WIRED_TROUBLESHOOT_URL = `${RING_SUPPORT_HOST}/hc/en-us/articles/360054984332-Installing-Ring-Video-Doorbell-Wired`;

export const RING_PRO_VOLTAGE = '16V-24V';
export const RING_WIRED_VOLTAGE = '10V-24V';

// TODO 50 is a placeholder - put in the correct value later
// https://levelhome.atlassian.net/browse/MDU1-409
export const GOOD_CELLULAR_SIGNAL_MINIMUM = 101;

export const HUB_STATUS_INTERVAL_TIMEOUT = 10000;
