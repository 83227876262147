import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import '../../Common/stylesheets/global.scss';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  textField: {
    fontSize: '20px',
    color: 'rgb(131,131,131)',
    width: '100%'
  },
  nest: {
    width: '100%',
    borderBottom: 'none',
    marginTop: '0px',
    marginBottom: '24px',
    cursor: 'pointer'
  },
  resize: {
    fontSize: '20px'
  },
  helperSize: {
    fontSize: '16px',
    marginTop: '0px',
    cursor: 'pointer'
  },
  pointer: {
    cursor: 'pointer !important'
  }
});

class WifiList extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
    this.state = {
      unit: this.props.unit
    };
  }
  getNullReturn() {
    return (
      <Typography component="p" className={this.classes.textField}>
        No Nest Devices
      </Typography>
    );
  }

  getNestReturn(device) {
    return (
      <TextField
        helperText={'S/N ' + device.localId}
        defaultValue="Nest thermostat"
        className={this.classes.nest}
        margin="normal"
        onClick={this.props.onClick}
        FormHelperTextProps={{
          className: this.classes.helperSize
        }}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          className: this.classes.resize,
          inputProps: {
            className: this.classes.pointer
          }
        }}
      />
    );
  }

  render() {
    let nestReturn = this.props.device
      ? this.getNestReturn(this.props.device)
      : this.getNullReturn();
    return <div className="full-height">{nestReturn}</div>;
  }
}

export default withStyles(styles)(WifiList);
