import React, { useState } from 'react';
import { FullScreenDialog } from '../../../Common/Popups/FullScreenDialog';
import { CreateRingContent } from './CreateRingContent';
import { createRingMetadataId, getModelDisplayName, initRingInfo } from './utils';
import { InstallStepsContent } from './InstallStepsContent';
import { TroubleshootRingContent } from './TroubleshootRingContent';
import { unitTodoMetaStorage } from '../../../Common/utils/storageInterface';
import { DoorbellDevice, Unit } from '../../../Common/Types/cloudApi';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import { EditRingContent } from './EditRingContent';
import { ButtonName, RingDeviceDetails, RingPage, RingTroubleshootInfo } from './types';
import { sendClientRequest } from '../../../Common/utils/clientRequests';
import * as routes from '../../unitdetail_apiRoutes';

type Props = Partial<Pick<RingDeviceDetails, 'ringModel' | 'chimeType' | 'ringChime'>> &
  Pick<RingDeviceDetails, 'hasTransformer'> & {
    unit: Unit;
    refreshSensors: () => Promise<AxiosError | void>;
    onClose: () => void;
    page: RingPage;
    device?: DoorbellDevice;
    topComponent?: React.ReactElement | null;
  };

export function RingManagementDialog(props: Props) {
  const [ringPage, setRingPage] = useState<RingPage>(props.page);

  const [troubleshootInfo, setTroubleshootInfo] = useState<RingTroubleshootInfo>({
    issueDescription: '',
    stepsTaken: ''
  });

  const [ringInfo, setRingInfo] = useState<RingDeviceDetails>(() => {
    const details = {
      ringModel: props.ringModel,
      chimeType: props.chimeType,
      ringChime: props.ringChime,
      hasTransformer: props.hasTransformer
    };
    return initRingInfo(ringPage, details, props.device);
  });

  const [loading, setLoading] = useState(false);

  const titles = getTitles();

  function handleDetailChange(event: React.ChangeEvent<{ name: string; value: string }>) {
    setRingInfo((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
    if (ringPage === RingPage.Edit) {
      editDoorbell({
        [event.target.name]: event.target.value
      });
    }
  }

  function handleTroubleshootChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    setTroubleshootInfo((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  }

  async function createDoorbell() {
    const metadata_id = createRingMetadataId(ringInfo);
    let body = {
      gatewayId: props.unit.gatewayId as number,
      metadata_id: metadata_id,
      deviceType: 'doorbell',
      model: 'ring'
    };
    const res = await sendClientRequest('post', routes.LEGACY_CREATE_DEVICE, body);
    return await handleResponse(res);
  }

  async function editDoorbell(newValues: Partial<RingDeviceDetails>) {
    if (!props.device?.uid) {
      return;
    }
    const metadata_id = createRingMetadataId({
      ...ringInfo,
      ...newValues
    });
    const res = await sendClientRequest('put', routes.LEGACY_EDIT_DEVICE(props.device.uid), {
      metadata_id: metadata_id
    });
    return await handleResponse(res, true);
  }

  async function deleteDoorbell() {
    if (!props.device) {
      return;
    }
    const res = await sendClientRequest(
      'delete',
      routes.LEGACY_DELETE_DEVICE(props.device.uid)
    );
    return await handleResponse(res);
  }

  async function handleResponse(res, ignoreThrow = false) {
    if (res.error) {
      Sentry.captureException(res.error);
      if (!ignoreThrow) {
        throw res.error;
      }
    }
    try {
      // Do a refresh before the UnitTodoList reappears so that the doorbell (or lack thereof) appears.
      await props.refreshSensors();
    } catch {
      return;
    }
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    switch (ringPage) {
      case RingPage.Create: {
        setLoading(true);
        createDoorbell()
          .then(() => {
            setRingPage(RingPage.InstallationSteps);
          })
          .finally(() => {
            setLoading(false);
          });
        break;
      }
      case RingPage.InstallationSteps: {
        if (event.currentTarget.name === ButtonName.GoToTroubleshoot) {
          setRingPage(RingPage.Troubleshoot);
        } else {
          props.onClose();
        }
        break;
      }
      case RingPage.Troubleshoot: {
        const resultOfIssue =
          event.currentTarget.name === ButtonName.FixedIssue ? 'FIXED' : 'REPORTED';
        // This info will be sent to Salesforce later.
        unitTodoMetaStorage.updateDeviceTypeTodo(props.unit.uid, 'ringDoorbell', {
          troubleshootInfo: {
            issueDescription: troubleshootInfo.issueDescription,
            stepsTaken: troubleshootInfo.stepsTaken,
            resultOfIssue: resultOfIssue
          }
        });
        props.onClose();
        break;
      }
      case RingPage.Edit: {
        if (event.currentTarget.name === ButtonName.RemoveRingProceed) {
          setLoading(true);
          deleteDoorbell()
            .then(() => {
              unitTodoMetaStorage.removeDeviceTypeTodo(props.unit.uid, 'ringDoorbell');
              props.onClose();
            })
            .catch(() => {
              setLoading(false);
            });
        }
        break;
      }
    }
  }

  function getTitles(): { main: string; subtitle1?: string; subtitle2: string } {
    switch (ringPage) {
      case RingPage.Create:
      case RingPage.InstallationSteps:
        return {
          main: 'Add Ring',
          subtitle1: props.unit.communityName,
          subtitle2: props.unit.unit
        };
      case RingPage.Troubleshoot:
        return {
          main: `Troubleshoot ${getModelDisplayName(ringInfo.ringModel)}`,
          subtitle1: props.unit.communityName,
          subtitle2: props.unit.unit
        };
      case RingPage.Edit:
        return {
          main: props.unit.unit,
          subtitle1: undefined,
          subtitle2: 'Ring Doorbell'
        };
    }
    return titles;
  }

  return (
    <FullScreenDialog
      topComponent={props.topComponent}
      title={titles.main}
      open={true}
      subtitle1={titles.subtitle1}
      subtitle2={titles.subtitle2}
      onClose={props.onClose}
      arrow={ringPage === RingPage.Edit}
    >
      {ringPage === RingPage.Create && (
        <CreateRingContent
          deviceDetails={ringInfo}
          handleDetailChange={handleDetailChange}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      )}
      {ringPage === RingPage.InstallationSteps && (
        <InstallStepsContent ringInfo={ringInfo} handleSubmit={handleSubmit} />
      )}
      {ringPage === RingPage.Troubleshoot && (
        <TroubleshootRingContent
          model={ringInfo.ringModel}
          troubleshootInfo={troubleshootInfo}
          handleTroubleshootChange={handleTroubleshootChange}
          handleSubmit={handleSubmit}
        />
      )}
      {ringPage === RingPage.Edit && (
        <EditRingContent
          deviceDetails={ringInfo}
          handleDetailChange={handleDetailChange}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      )}
    </FullScreenDialog>
  );
}
