import * as React from 'react';
import { PoorCellularSignalBanner } from '../PoorCellularSignalBanner';
import { MuiThemeProvider } from '@material-ui/core/styles';
import defaultTheme from '../../Themes/defaultTheme';
import { isOnlyConnectedToLowCellular } from '../../../UnitDetail/common/utils';

type Props = {
  cellularSignal: number | null;
  connectedToCellular: boolean;
  connectedToEthernet: boolean;
  connectedToWiFi: boolean;
};

export function HubConnectivityBanner(props: Props) {
  let component;

  if (
    isOnlyConnectedToLowCellular({
      cellularSignal: props.cellularSignal,
      connectedToCellular: props.connectedToCellular,
      connectedToEthernet: props.connectedToEthernet,
      connectedToWiFi: props.connectedToWiFi
    })
  ) {
    component = <PoorCellularSignalBanner />;
    // TODO hub offline banner should be the first "if",
    //  and then cellular signal should be the "else if"
  } else {
    return null;
  }

  return <MuiThemeProvider theme={defaultTheme}>{component}</MuiThemeProvider>;
}
