import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Device from './Device';
import DevicePlaceholder from './DevicePlaceholder';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import * as deviceConstants from '../../Common/Constants/deviceConstants';
import * as sfConstants from '../../Common/Constants/salesforceConstants';
import { DEVICE_TYPES_RELATIVE_TO_YALE as yale } from '../../Common/Constants/deviceConstants';
import '../../Common/stylesheets/global.scss';
import grey from '@material-ui/core/colors/grey';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import {
  DOOR_CODE_RELIABILITY,
  YALE_LOCK_MASTER_CODES
} from '../../Common/Constants/launchDarklyConstants';
import { CodeSyncDialog } from '../../Common/CodeSyncing/CodeSyncDialog';
import { findActiveCodeSync } from '../../Common/CodeSyncing/utils';
import defaultTheme from '../../Common/Themes/defaultTheme';

const styles = (theme) => ({
  textField: {
    fontSize: '20px',
    color: 'rgb(131,131,131)',
    width: '100%'
  },
  addRemoveDevice: {
    padding: '8px 0px 8px 0px',
    marginBottom: '25px',
    marginTop: '-20px',
    paddingRight: '20px',
    border: 2,
    borderStyle: 'solid',
    borderRadius: '5px',
    borderColor: grey[400],
    backgroundColor: grey[50]
  },
  addRemoveButton: {
    border: '1px solid #D7D7D7',
    marginLeft: '0.5rem'
  }
});

class ZwaveList extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
    this.exclude = this.exclude.bind(this);
    this.include = this.include.bind(this);
    this.state = {
      unit: this.props.unit,
      devices: this.props.devices
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ devices: nextProps.devices });
  }

  getZwaveSubheader = (zwaveDevices) => {
    if (zwaveDevices.length > 0) {
      return (
        <ListSubheader style={{ fontSize: '16px', marginBottom: '16px' }}>
          Z-Wave Devices Paired
        </ListSubheader>
      );
    } else {
      return null;
    }
  };

  getZwaveDevices = () => {
    let zwave = [];
    let devices = this.props.devices;
    for (let i = 0; i < devices.length; i++) {
      if (
        devices[i].model !== deviceConstants.NEST &&
        devices[i].deviceType !== deviceConstants.GATEWAY &&
        devices[i].deviceType !== deviceConstants.DOORBELL
      ) {
        zwave.push(devices[i]);
      }
    }
    return zwave;
  };

  getDevicesToBeInstalled = () => {
    return (
      <React.Fragment>
        {this.props[sfConstants.SharedVars.DEVICES_TO_BE_INSTALLED].map((device) => (
          <DevicePlaceholder
            key={device[sfConstants.SharedVars.MODEL]}
            device={device}
            unit={this.props.unit}
            startInclusion={this.props.startInclusion}
          />
        ))}
      </React.Fragment>
    );
  };

  hasNoZwaveDevices(zwaveDevices) {
    if (zwaveDevices.length === 0) {
      return (
        <Typography component="p" className={this.classes.textField}>
          No Z-Wave Devices Included
        </Typography>
      );
    } else {
      return '';
    }
  }

  getAddRemoveDevice() {
    if (this.props.flags[YALE_LOCK_MASTER_CODES]) {
      return (
        <ListItem className={this.classes.addRemoveDevice}>
          <ListItemText
            inset
            primary={'Add or Remove Z-Wave Device'}
            secondary={
              'May be used if the device is not listed or when exclusion is needed for any device.'
            }
          />
          <IconButton
            name={yale.UNKNOWN}
            className={this.classes.addRemoveButton}
            onClick={this.props.startExclusion}
          >
            <RemoveIcon />
          </IconButton>
          <IconButton
            name={yale.UNKNOWN}
            className={this.classes.addRemoveButton}
            onClick={this.props.startInclusion}
          >
            <AddIcon />
          </IconButton>
        </ListItem>
      );
    } else {
      return null;
    }
  }

  exclude(e) {
    e.preventDefault();
  }
  include(e) {
    e.preventDefault();
  }

  render() {
    let zwaveDevices = this.getZwaveDevices();
    let zwaveDevicesSubheader = this.getZwaveSubheader(zwaveDevices);
    let devicesToBeInstalled = this.getDevicesToBeInstalled();
    let addRemoveDevice = this.getAddRemoveDevice();
    let noDevices = this.hasNoZwaveDevices(zwaveDevices);
    const currentCodeSync = findActiveCodeSync(this.props.codeSyncingData);

    return (
      <div className="full-height" style={{ marginBottom: '48px' }}>
        <List>
          {devicesToBeInstalled}
          {addRemoveDevice}
        </List>
        <List subheader={zwaveDevicesSubheader}>
          {/*TODO intentionally simple ternary for now, will be updated later to reflect business logic needs*/}
          {/*https://levelhome.atlassian.net/browse/MDU2-46*/}
          {this.props.flags[DOOR_CODE_RELIABILITY] && currentCodeSync && (
            <MuiThemeProvider theme={defaultTheme}>
              <CodeSyncDialog
                //TODO: handle actions in separate ticket
                // https://levelhome.atlassian.net/browse/MDU2-26
                // https://levelhome.atlassian.net/browse/MDU2-27
                deviceName={'Front Door'}
                handleActionClick={() => {}}
                handleCantChangeBatteries={() => {}}
                handleOutOfTime={() => {}}
                open={true}
                sentProgress={currentCodeSync.sent_percent}
                status={currentCodeSync.status}
                verifiedProgress={currentCodeSync.verified_percent}
              />
            </MuiThemeProvider>
          )}
          {zwaveDevices.map((device, index) => (
            <Device key={index} device={device} unit={this.props.unit} />
          ))}
        </List>
        {noDevices}
      </div>
    );
  }
}

export default withLDConsumer()(withStyles(styles)(ZwaveList));
