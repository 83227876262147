import * as sensorConstants from './sensorConstants';

export const BINARY_WATER_SENSOR_WET = `${sensorConstants.BINARY_SENSOR_WATER}_Wet`;
export const BINARY_WATER_SENSOR_DRY = `${sensorConstants.BINARY_SENSOR_WATER}_Dry`;
export const BINARY_WATER_SENSOR = (isWet) => {
  return isWet ? BINARY_WATER_SENSOR_WET : BINARY_WATER_SENSOR_DRY;
};

export const MOTION_DETECTED = `${sensorConstants.MOTION}_Detected`;
export const MOTION_NOT_DETECTED = `${sensorConstants.MOTION}_NotDetected`;
export const MOTION = (motionDetected) => {
  return motionDetected ? MOTION_DETECTED : MOTION_NOT_DETECTED;
};

export const LOCK_CHANGE_OPEN = `${sensorConstants.LOCK_CHANGE}_DoorOpen`;
export const LOCK_CHANGE_CLOSED = `${sensorConstants.LOCK_CHANGE}_DoorClosed`;
