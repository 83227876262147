import * as React from 'react';
import { Link, Typography } from '@material-ui/core';
import { ConnectivityBannerLayout } from '../ConnectivityBannerLayout';
import { ReactComponent as LowSignalIcon } from '../../images/low-signal.svg';
import { dweloPalette } from '../../Themes/dweloPalette';

export function PoorCellularSignalBanner() {
  return (
    <ConnectivityBannerLayout
      iconElement={<LowSignalIcon />}
      textElement={
        <Typography style={{ fontSize: '14px' }}>
          <strong>Poor cellular signal.</strong>{' '}
          {/*TODO insert href URL later when it is available*/}
          <Link href={'#'} style={{ fontSize: '14px' }}>
            Get help troubleshooting.
          </Link>
        </Typography>
      }
      backgroundColor={dweloPalette.secondary.yellow[100]}
    />
  );
}
