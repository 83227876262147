import React, { useState } from 'react';
import {
  Collapse,
  createStyles,
  IconButton,
  ListItem,
  ListItemText,
  Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import unitTodoTheme from '../../Common/Themes/unit_todo_theme';
import { ReactComponent as CollapseIcon } from '../../Common/images/collapse.svg';
import { ReactComponent as ExpandIcon } from '../../Common/images/expand.svg';
import { defaultPaletteOptions, dweloPalette } from '../../Common/Themes/dweloPalette';
import Typography from '@material-ui/core/Typography';
import { DWELO_CARE_PHONE } from '../../Common/Constants/installerAppConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      color: '#4F4F4F',
      width: '48px',
      height: '48px',
      alignSelf: 'end'
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: dweloPalette.primary.grey[100],
      paddingTop: '0px',
      paddingBottom: '6px'
    },
    listHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-center',
      width: '100%'
    },
    collapse: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%'
    },
    headerText: {
      display: 'flex',
      paddingTop: '8px',
      paddingLeft: '5px'
    },
    phoneNumber: {
      color: defaultPaletteOptions.secondary.main
    },
    typography: {
      paddingLeft: '5px',
      paddingBottom: '16px',
      paddingRight: '64px'
    }
  })
);

type Props = {
  expanded: boolean;
};

export const UnitOtherDevicesMessage = (props: Props) => {
  const classes = useStyles(unitTodoTheme);
  const [expanded, setExpanded] = useState(props.expanded);

  function toggleSetExpanded() {
    setExpanded((prevState) => !prevState);
  }

  return (
    <React.Fragment>
      <ListItem className={classes.listItem}>
        <div className={classes.listHeader}>
          <ListItemText
            className={classes.headerText}
            primary={'Are there any devices missing from the list?'}
            primaryTypographyProps={{
              variant: 'body1',
              color: 'textPrimary'
            }}
          />
          <IconButton onClick={toggleSetExpanded} className={classes.iconButton}>
            {expanded ? <CollapseIcon /> : <ExpandIcon />}
          </IconButton>
        </div>

        <Collapse in={expanded} timeout={'auto'} className={classes.collapse}>
          <Typography className={classes.typography}>
            If you have devices you're supposed to install in this unit, but don't see them
            listed above, please contact DweloCare at{' '}
            <a href={`tel:+1-${DWELO_CARE_PHONE}`} className={classes.phoneNumber}>
              {DWELO_CARE_PHONE}
            </a>{' '}
            or use the "Get Help" chat below.
          </Typography>
        </Collapse>
      </ListItem>
    </React.Fragment>
  );
};
