import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { ReactComponent as NavigateNext } from '../../Common/images/navigate-next.svg';
import { ReactComponent as LockIcon } from '../../Common/images/lock_v2.svg';
import { DeviceSensor, Unit, DeviceToBeInstalled } from '../../Common/Types/cloudApi';
import { dweloPalette } from '../../Common/Themes/dweloPalette';
import { getDeviceDisplayName } from '../../Common/utils/devices';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      border: '1px solid',
      width: '20px',
      height: '20px'
    },
    avatarComplete: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    avatarIncomplete: {
      backgroundColor: dweloPalette.primary.grey[150],
      borderColor: dweloPalette.primary.grey[150]
    },
    avatarNotPaired: {
      backgroundColor: 'transparent',
      borderColor: dweloPalette.primary.grey[150]
    },
    listItemAvatar: {
      minWidth: '30px'
    },
    listItem: {
      paddingLeft: '58px',
      paddingTop: '0',
      minHeight: '56px',
      paddingBottom: '0'
    },
    doneIcon: {
      width: '16px',
      height: '16px'
    },
    deviceText: {
      color: theme.palette.text.primary
    },
    iconDisabled: {
      fill: dweloPalette.primary.grey[150]
    },
    navigateIcon: {
      fill: dweloPalette.primary.grey[400]
    },
    lockIcon: {
      width: '10px',
      height: '10px'
    },
    iconButton: {
      height: '48px',
      width: '48px'
    }
  })
);

type Props = {
  unit: Unit;
} & (
  | {
      paired: true;
      device: DeviceSensor;
      model: DeviceToBeInstalled['model'];
      complete: boolean;
      goToDeviceDetails?: (uid: number) => void;
    }
  | {
      paired: false;
      device?: never;
      deviceToPair: DeviceToBeInstalled;
      name: string;
      model?: never;
      complete?: null;
    }
);

export function Device(props: Props) {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();

  function goToDeviceDetail() {
    if (props.paired && props.goToDeviceDetails) {
      props.goToDeviceDetails(props.device.uid);
      return;
    }
    const unitPath = match.path.includes('unit_v2') ? 'unit_v2' : 'unit';
    const unitId = props.unit.uid;
    const communityId = props.unit.communityId;
    return history.push(
      `/community/${communityId}/${unitPath}/${unitId}/device/${props.device?.uid}/`
    );
  }

  function getAvatar(): JSX.Element {
    let avatarClassName;
    let icon;
    if (props.paired) {
      if (props.complete) {
        avatarClassName = classes.avatarComplete;
        icon = <DoneIcon className={classes.doneIcon} />;
      } else {
        avatarClassName = classes.avatarIncomplete;
        icon = <DoneIcon className={classes.doneIcon} />;
      }
    } else {
      avatarClassName = classes.avatarNotPaired;
      icon = <LockIcon className={`${classes.lockIcon} ${classes.iconDisabled}`} />;
    }
    return <Avatar className={`${classes.avatar} ${avatarClassName}`}>{icon}</Avatar>;
  }

  const avatar: JSX.Element = getAvatar();

  return (
    <ListItem className={classes.listItem}>
      <ListItemAvatar className={classes.listItemAvatar}>{avatar}</ListItemAvatar>
      <ListItemText
        primary={
          props.paired
            ? getDeviceDisplayName(props.device?.name, props.device?.deviceType)
            : `${props.name} (to be included)`
        }
        primaryTypographyProps={{
          variant: 'body1',
          className: classes.deviceText
        }}
      />
      {props.paired ? (
        <IconButton onClick={goToDeviceDetail} className={classes.iconButton}>
          <NavigateNext className={classes.navigateIcon} />
        </IconButton>
      ) : null}
    </ListItem>
  );
}
