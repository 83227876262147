import * as React from 'react';
import { NeutralBanner } from '../../BannerTemplates/NeutralBanner';
import { ReactComponent as ArrowCircleIcon } from '../../../images/arrow-circle.svg';
import '../../styles.scss';

export function SyncInProgressBanner() {
  return (
    <NeutralBanner
      icon={<ArrowCircleIcon className={'in-progress-icon'} />}
      title={<strong>Do not disturb the hub or lock while codes sync.</strong>}
      subtitle={'This will take several minutes.'}
    />
  );
}
