import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import theme from '../../Common/Themes/login_theme';
import LinearDeterminate from '../../Common/Loading/Linear';

const styles = {
  buttonColor: {
    color: '#4ad4d4'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%'
  },
  copyField: {
    width: '115px',
    height: '30px',
    border: 'none',
    resize: 'none',
    marginTop: '-1px',
    display: 'inline-block'
  },
  copyForm: {
    display: 'inline-block'
  },
  buttonProgress: {
    color: '#F6861E',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -34,
    marginLeft: -34
  }
};

class AlertDialog extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
    this.textAreaRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.state = {
      open: this.props.alertdialog,
      error: this.props.error,
      loading: this.props.loading ? this.props.loading : false,
      circular: this.props.circular ? this.props.circular : false,
      linear: this.props.linear ? this.props.linear : false,
      disable: false,
      totalTime: this.props.totalTime ? this.props.totalTime : 0
    };
  }

  componentDidMount() {
    if (this.props.copy) {
      this.setState({ disable: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.alertdialog,
      error: nextProps.error,
      loading: nextProps.loading ? nextProps.loading : false
    });
  }

  handleClose = (event) => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose(event);
    }
  };

  handleChange(e) {
    this.props.inputValue(e.target.value);
  }

  copyToClipboard(e) {
    this.textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ disable: false });
  }

  getCopy() {
    if (this.props.copy) {
      return (
        <div style={{ textAlign: 'center', marginTop: '16px' }}>
          <form className={this.classes.copyForm}>
            <textarea
              ref={this.textAreaRef}
              value={this.props.copyText}
              className={this.classes.copyField}
              readOnly={true}
            />
          </form>
          <MuiThemeProvider theme={theme}>
            <Button variant="contained" onClick={this.copyToClipboard} color="primary">
              Copy
            </Button>
          </MuiThemeProvider>
        </div>
      );
    } else {
      return '';
    }
  }

  getInput() {
    if (this.props.inputEnabled) {
      return (
        <MuiThemeProvider theme={theme}>
          <form className={this.classes.container}>
            <TextField
              label={this.props.inputLabel}
              name="inputValue"
              className={this.classes.textField}
              onChange={this.handleChange}
              margin="normal"
              disabled={this.state.loading}
            />
          </form>
        </MuiThemeProvider>
      );
    } else {
      return '';
    }
  }

  getDialogAction() {
    if (this.props.zwave) {
      return (
        <DialogActions>
          <Button onClick={this.props.onClick} className={this.classes.buttonColor}>
            Abort
          </Button>
          <Button onClick={this.handleClose} className={this.classes.buttonColor} autoFocus>
            Done
          </Button>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions>
          <Button
            onClick={this.handleClose}
            className={this.classes.buttonColor}
            disabled={this.state.loading}
          >
            {this.props.rejectText ? this.props.rejectText : 'No'}
          </Button>
          <Button
            onClick={this.props.onClick}
            className={this.classes.buttonColor}
            disabled={this.state.loading || this.state.disable}
            autoFocus
          >
            {this.props.acceptText ? this.props.acceptText : 'Yes'}
          </Button>
        </DialogActions>
      );
    }
  }

  render() {
    let actions = this.getDialogAction();
    let inputs = this.getInput();
    let copy = this.getCopy();
    return (
      <div>
        <Dialog
          maxWidth="xs"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={this.props.disableClick ? true : false}
          disableEscapeKeyDown={true}
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.text}
            </DialogContentText>
            {inputs}
            {copy}
            <Typography component="p" color="error" className={this.classes.textField}>
              {this.state.error}
            </Typography>
          </DialogContent>
          {this.state.loading && this.state.circular && (
            <CircularProgress size={68} className={this.classes.buttonProgress} />
          )}
          {this.state.loading && this.state.linear && (
            <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
              <LinearDeterminate totalTime={this.state.totalTime} fakeLoad={true} />
            </div>
          )}

          {actions}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AlertDialog);
