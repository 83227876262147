import { sendClientRequest } from './clientRequests';
import * as routes from '../../UnitDetail/unitdetail_apiRoutes';
import { SyncClient } from 'twilio-sync';
import SyncStream from 'twilio-sync/lib/streams/syncstream';
import SyncError from 'twilio-sync/lib/utils/syncerror';
import { AxiosError } from 'axios';

interface SyncSuccess {
  stream: SyncStream;
  error?: never;
}
interface SyncFailure {
  stream?: never;
  error: SyncError;
}
interface GetOrCreateStreamFailure {
  stream?: never;
  error: SyncError | AxiosError;
}

export async function getOrCreateStreamForHub(
  hubSerial: string,
  gatewayId: number
): Promise<SyncSuccess | GetOrCreateStreamFailure> {
  // Retrieve twilio auth token
  const tokenResult = await getTwilioToken();
  if (tokenResult.error) {
    return { error: tokenResult.error };
  }
  const token = tokenResult.response.data.token;

  // Open (or create then open) stream
  let streamResult = await openExistingStream(token, gatewayId);
  if (streamResult.error?.status === 404) {
    const createResult = await createStream(hubSerial);
    if (createResult.error) {
      return { error: createResult.error };
    }
    streamResult = await openExistingStream(token, gatewayId);
  }
  if (streamResult.error) {
    return { error: streamResult.error };
  }

  return { stream: streamResult.stream };
}

async function openExistingStream(
  token: string,
  gatewayId: number
): Promise<SyncSuccess | SyncFailure> {
  try {
    const stream = await new SyncClient(token).stream({
      id: String(gatewayId),
      mode: 'open_existing'
    });
    return { stream };
  } catch (error) {
    return { error };
  }
}

async function getTwilioToken() {
  return await sendClientRequest('get', routes.TWILIO_TOKEN);
}

async function createStream(hubSerial: string) {
  return await sendClientRequest('post', routes.CREATE_STREAM, { hub_serial: hubSerial });
}
