// https://sites.google.com/dwelo.com/dweloux

export const dweloPalette = {
  primary: {
    orange: {
      100: '#FFE9D0',
      200: '#FFB867',
      300: '#FF8800',
      400: '#B96300',
      500: '#743E01'
    },
    teal: {
      100: '#D0F6F6',
      200: '#68E3E3',
      300: '#00D1D1',
      400: '#009897',
      500: '#005F5E'
    },
    grey: {
      100: '#F3F3F3',
      150: '#D7D7D7',
      200: '#BBBBBB',
      250: '#9F9F9F',
      300: '#838383',
      400: '#4F4F4F',
      500: '#1C1C1C'
    }
  },
  secondary: {
    red: {
      100: '#FCE1E4',
      200: '#F7A7AF',
      300: '#F15F6D',
      400: '#AF454F',
      500: '#6E2B32'
    },
    yellow: {
      100: '#FFFBE0'
    }
  },
  text: {
    disabled: '#BBBBBB'
  }
};

export const defaultPaletteOptions = {
  primary: {
    main: dweloPalette.primary.orange[300],
    dark: dweloPalette.primary.orange[400],
    contrastText: '#FFF'
  },
  secondary: {
    main: dweloPalette.primary.teal[300],
    dark: dweloPalette.primary.teal[400],
    contrastText: '#FFF'
  },
  text: {
    primary: dweloPalette.primary.grey[500],
    secondary: dweloPalette.primary.grey[250],
    disabled: dweloPalette.primary.grey[200]
  }
};
