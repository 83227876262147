import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Spinner from '../../Common/Loading/Spinner';
import DeviceLocationSelect from '../../Common/Dropdowns/DeviceLocationSelect/DeviceLocationSelect';
import { resetFabPosition } from '../../Common/utils/commonUtils';

import Header from '../../Common/Headers/Header';
import StatusBar from '../../Common/Headers/StatusBar';
import BinarySensor from './BinarySensor';
import Dimmer from './Dimmer';
import Lock from './Lock';
import Switch from './Switch';
import Thermostat from './Thermostat';

import logo from '../../Common/images/logo.svg';
import client from '../../Common/utils/client';
import * as routes from '../devicedetail_apiRoutes';
import * as deviceConstants from '../../Common/Constants/deviceConstants';
import theme from '../../Common/Themes/login_theme';
import '../../Common/stylesheets/global.scss';
import axios from 'axios';
import {
  getSensorPlacementFromMetadata,
  isContactSensorPlacement
} from '../../Common/utils/sensorPlacement';
import { sensorPlacement_DisplayName_Mapping } from '../../Common/Constants/sensorPlacementConstants';
import { DweloDeviceType } from '../../Common/Constants/deviceConstants_v2';
import { SensorPlacementSelect } from '../../Common/Dropdowns/SensorPlacementSelect';
import { convertPlacementToMetadataIdUsingSalesforceDevices } from '../../Common/utils/deviceCapabilities';
import { dweloPalette } from '../../Common/Themes/dweloPalette';
import viewportHack from '../../Common/scripts/viewportHack';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import { DOOR_CODE_RELIABILITY } from '../../Common/Constants/launchDarklyConstants';
import { isCodeSyncActive } from '../../Common/CodeSyncing/utils';
import { CodeSyncDialog } from '../../Common/CodeSyncing/CodeSyncDialog';
import defaultTheme from '../../Common/Themes/defaultTheme';
import blankTheme from '../../Common/Themes/blankTheme';

const fabMargin = 64;

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: '16px',
    backgroundColor: '#4ad4d4'
  },
  done: {
    backgroundColor: dweloPalette.primary.orange[300],
    color: '#FFF',
    '&:hover': {
      // Overriding a global style that has !important.
      backgroundColor: `${dweloPalette.primary.orange[300]} !important`,
      opacity: 0.8
    }
  },
  textField: {
    width: '100%'
  },
  instructionsHeader: {
    backgroundColor: '#F3F3F3',
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '24px'
    }
  },
  instructionsText: {
    color: '#4F4F4F',
    lineHeight: 2
  },
  modelTextField: {
    marginTop: '0'
  },
  inputText: {
    fontSize: '18px'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fourthFab: {
    marginBottom: `${fabMargin * 3}px`
  },
  thirdFab: {
    marginBottom: `${fabMargin * 2}px`
  },
  secondFab: {
    marginBottom: `${fabMargin}px`
  },
  topLight: {
    marginBottom: `${fabMargin}px`,
    width: '84px'
  },
  topLock: {
    marginBottom: `${fabMargin}px`,
    width: '122.36px'
  },
  limitSize: {
    height: '16px',
    width: '16px',
    marginRight: '5px',
    marginLeft: '1px'
  },
  red: {
    backgroundColor: '#991210',
    color: 'white'
  },
  green: {
    backgroundColor: '#75C24F',
    color: 'white'
  }
});

class DeviceDetail extends Component {
  constructor(props) {
    super(props);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const cancel = {
      cancelToken: source.token
    };
    this.__source = source;
    this.__cancel = cancel;
    this.lockDoor = this.lockDoor.bind(this);
    this.turnOnLight = this.turnOnLight.bind(this);
    this.turnOffLight = this.turnOffLight.bind(this);
    this.setHeatAndTemp = this.setHeatAndTemp.bind(this);
    this.setCoolAndTemp = this.setCoolAndTemp.bind(this);
    this.turnOffThermostat = this.turnOffThermostat.bind(this);
    this.updateAndReturn = this.updateAndReturn.bind(this);
    this.updateDevice = this.updateDevice.bind(this);
    const { classes } = this.props;
    this.classes = classes;
    this.state = {
      unit: this.props.unit,
      devices: this.props.devices,
      device: this.props.device,
      device_name: this.props.device.name,
      sensorPlacement: this.getOnMountPlacement(),
      codeSyncingData: this.props.codeSyncingData
    };
  }

  returnToUnit = () => {
    let unitPath = this.props.match.path.includes('unit_v2') ? 'unit_v2' : 'unit';
    const communityId = this.props.match.params.communityId;
    const unitId = this.props.match.params.unitId;
    this.props.history.push(`/community/${communityId}/${unitPath}/${unitId}`);
  };

  updateDevice() {
    const metadata_id = convertPlacementToMetadataIdUsingSalesforceDevices(
      this.state.sensorPlacement
    );
    if (!this.state.device_name && !metadata_id) {
      return;
    }
    const body = {
      givenName: this.state.device_name || undefined,
      metadata_id: metadata_id || undefined
    };
    client
      .put(routes.UPDATE_NAME(this.props.device.uid), body, this.__cancel)
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          Sentry.captureException(thrown);
        }
      });
  }

  updateAndReturn() {
    clearInterval(this.state.sensorInterval);
    this.returnToUnit();
  }

  /**
   * Replaces the existing device with the new device (based on uid)
   * using the existing devices array, so that we don't need to continue pulling
   * api data for these other devices since it isn't necessary.
   */
  createDevicesWithNewDevice = (newDevice) => {
    const devices = [...this.state.devices];
    const deviceIndex = devices.findIndex((device) => device.uid === newDevice.uid);
    if (deviceIndex !== -1) {
      devices[deviceIndex] = newDevice;
    }
    return devices;
  };

  refreshSensors() {
    client
      .get(
        routes.SINGLE_DEVICE_SENSOR(this.props.unit.hub_serial, this.props.device.uid),
        this.__cancel
      )
      .then((res) => {
        let data = res.data.results;
        if (data.length && data[0] !== this.state.device) {
          const device = data[0];
          this.setState({
            sensorPlacement: getSensorPlacementFromMetadata(
              device.metadata_id,
              device.device_metadata
            ),
            device: device,
            devices: this.createDevicesWithNewDevice(device)
          });
        }
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          Sentry.captureException(thrown);
        }
      });
  }

  componentDidMount() {
    resetFabPosition();
    if (this.props.unit.hub_serial) {
      var sensorRefresh = setInterval(() => {
        if (document.hasFocus()) {
          this.refreshSensors();
        }
      }, parseInt(process.env.REACT_APP_SENSOR_POLL, 10));

      this.setState({
        sensorInterval: sensorRefresh
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.sensorPlacement !== this.state.sensorPlacement) {
      // To account for the Enable Alerts fab appearing
      viewportHack();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.sensorInterval);
    if (this.props.unit.hub_serial) {
      this.__source.cancel('Operation canceled by the user.');
    }
  }

  handleDeviceNameChange = (event, value) => {
    if (value) {
      this.setState(
        {
          device_name: value
        },
        this.updateDevice
      );
    }
  };

  handleSensorPlacementChange = (event) => {
    if (event.target.value) {
      this.setState(
        {
          sensorPlacement: event.target.value
        },
        this.updateDevice
      );
    }
  };

  sendCommand(command, commandValue) {
    let data = {
      command: command,
      commandValue: commandValue
    };
    return client.post(routes.COMMAND(this.state.device.uid), data).catch((error) => {
      if (!axios.isCancel(error)) {
        Sentry.captureException(error);
      }
    });
  }

  lockDoor(e) {
    e.preventDefault();
    this.sendCommand('lock', null);
  }
  turnOffLight(e) {
    e.preventDefault();
    this.sendCommand('off', null);
  }
  turnOnLight(e) {
    e.preventDefault();
    this.sendCommand('on', null);
  }
  setCoolAndTemp(e) {
    e.preventDefault();
    this.sendCommand('cool', null).then(() => {
      setTimeout(() => {
        this.sendCommand('cool', 72);
      }, 500);
    });
  }
  setHeatAndTemp(e) {
    e.preventDefault();
    this.sendCommand('heat', null).then(() => {
      setTimeout(() => {
        this.sendCommand('heat', 68);
      }, 500);
    });
  }
  turnOffThermostat(e) {
    e.preventDefault();
    this.sendCommand('off', null);
  }

  suppressDeviceAlerts = () => {
    return client
      .post(routes.SET_NOTIFICATIONS(this.state.device.uid), {
        notifications: false
      })
      .then(() => {
        let device = this.state.device;
        device.notifications = false;
        this.setState({ device: device });
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  allowDeviceAlerts = () => {
    return client
      .post(routes.SET_NOTIFICATIONS(this.state.device.uid), {
        notifications: true
      })
      .then(() => {
        let device = this.state.device;
        device.notifications = true;
        this.setState({ device: device });
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  capitalize(name) {
    if (name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  }

  getFabs(device) {
    let deviceType = device.deviceType;
    if (deviceType === deviceConstants.LOCK) {
      return (
        <React.Fragment>
          <Fab
            variant="extended"
            aria-label="Lock Door"
            className={`${this.classes.fab} keep-fixed ${this.classes.secondFab}`}
            onClick={this.lockDoor}
          >
            <LockIcon className={this.classes.extendedIcon} />
            Lock
          </Fab>
        </React.Fragment>
      );
    } else if (deviceType === deviceConstants.THERMOSTAT) {
      return (
        <React.Fragment>
          <Fab
            variant="extended"
            aria-label="Cool to 72"
            color="primary"
            className={`${this.classes.fab} keep-fixed ${this.classes.fourthFab}`}
            onClick={this.setCoolAndTemp}
          >
            <AcUnitIcon className={this.classes.extendedIcon} />
            {'72\u00b0F'}
          </Fab>
          <Fab
            variant="extended"
            aria-label="Heat to 68"
            color="primary"
            className={`${this.classes.fab} keep-fixed ${this.classes.thirdFab}`}
            onClick={this.setHeatAndTemp}
          >
            <WhatshotIcon className={this.classes.extendedIcon} />
            {'68\u00b0F'}
          </Fab>
          <Fab
            variant="extended"
            aria-label="Turn off"
            className={`${this.classes.fab} keep-fixed ${this.classes.secondFab}`}
            onClick={this.turnOffThermostat}
          >
            <PowerSettingsNewIcon className={this.classes.extendedIcon} />
            Off
          </Fab>
        </React.Fragment>
      );
    } else if (deviceType === deviceConstants.SWITCH) {
      return (
        <React.Fragment>
          <Fab
            variant="extended"
            aria-label="Turn on"
            color="primary"
            className={`${this.classes.fab} keep-fixed ${this.classes.topLight}`}
            onClick={this.turnOnLight}
          >
            <WbSunnyIcon className={this.classes.extendedIcon} />
            On
          </Fab>
          <Fab
            variant="extended"
            aria-label="Turn off"
            className={`${this.classes.fab} keep-fixed ${this.classes.secondFab}`}
            onClick={this.turnOffLight}
          >
            <Brightness1Icon className={this.classes.limitSize} />
            Off
          </Fab>
        </React.Fragment>
      );
    } else if (deviceType === deviceConstants.DIMMER) {
      return (
        <React.Fragment>
          <Fab
            variant="extended"
            aria-label="Turn on"
            color="primary"
            className={`${this.classes.fab} keep-fixed ${this.classes.topLight}`}
            onClick={this.turnOnLight}
          >
            <WbSunnyIcon className={this.classes.extendedIcon} />
            On
          </Fab>
          <Fab
            variant="extended"
            aria-label="Turn off"
            className={`${this.classes.fab} keep-fixed ${this.classes.secondFab}`}
            onClick={this.turnOffLight}
          >
            <Brightness1Icon className={this.classes.limitSize} />
            Off
          </Fab>
        </React.Fragment>
      );
    } else if (
      deviceType === deviceConstants.BINARY_SENSOR &&
      this.state.sensorPlacement === 'leak'
    ) {
      if (device.notifications) {
        return (
          <React.Fragment>
            <Fab
              variant="extended"
              aria-label="Suppress Alerts"
              className={`${this.classes.fab} keep-fixed delete ${this.classes.red} ${this.classes.secondFab}`}
              onClick={this.suppressDeviceAlerts}
            >
              Disable alerts
            </Fab>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Fab
              variant="extended"
              aria-label="Allow Alerts"
              className={`${this.classes.fab} keep-fixed success ${this.classes.green} ${this.classes.secondFab}`}
              onClick={this.allowDeviceAlerts}
            >
              Enable alerts
            </Fab>
          </React.Fragment>
        );
      }
    }
  }

  getStatusBar(device) {
    if (
      device.deviceType === deviceConstants.BINARY_SENSOR &&
      this.state.sensorPlacement === 'leak'
    ) {
      if (device.notifications) {
        return <StatusBar message="Monitoring mode. Alerts on." success={true} />;
      } else {
        return <StatusBar message="Test mode. Alerts off." error={true} />;
      }
    } else {
      return '';
    }
  }

  getSubHeaderText2(device) {
    if (device.deviceType === deviceConstants.BINARY_SENSOR) {
      const displayName = sensorPlacement_DisplayName_Mapping[this.state.sensorPlacement];
      return displayName ? `${displayName} Sensor` : 'Sensor';
    } else {
      return this.capitalize(device.deviceType);
    }
  }

  getSubHeaderText(device) {
    return 'Node ID ' + device.localId;
  }

  getModelText() {
    return (
      <TextField
        label="DEVICE MODEL"
        value={this.state.device.device_metadata.long_name || 'Unknown'}
        className={`${this.classes.textField} ${this.classes.modelTextField}`}
        margin="normal"
        multiline
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          style: { fontSize: '20px' }
        }}
      />
    );
  }

  getOnMountPlacement = () => {
    return getSensorPlacementFromMetadata(
      this.props.device.metadata_id,
      this.props.device.device_metadata
    );
  };

  getSensorPlacementSelect = () => {
    if (this.state.device.deviceType !== DweloDeviceType.BinarySensor) {
      return null;
    }
    const placementExistedOnMount = this.getOnMountPlacement();
    const isContactSensor = isContactSensorPlacement(this.state.sensorPlacement);
    if (!placementExistedOnMount || !this.state.sensorPlacement || isContactSensor) {
      return (
        <SensorPlacementSelect
          unknownPlaceholder
          onChange={this.handleSensorPlacementChange}
          value={this.state.sensorPlacement}
          contactSensorsOnly={placementExistedOnMount && isContactSensor}
          style={{ marginBottom: '16px' }}
        />
      );
    }
  };

  render() {
    let device = '';
    let fabs = this.getFabs(this.state.device);
    if (this.state.device.deviceType === deviceConstants.LOCK) {
      device = <Lock device={this.state.device} unit={this.props.unit} />;
    } else if (this.state.device.deviceType === deviceConstants.THERMOSTAT) {
      device = <Thermostat device={this.state.device} unit={this.props.unit} />;
    } else if (this.state.device.deviceType === deviceConstants.SWITCH) {
      device = <Switch device={this.state.device} unit={this.props.unit} />;
    } else if (this.state.device.deviceType === deviceConstants.DIMMER) {
      device = <Dimmer device={this.state.device} unit={this.props.unit} />;
    } else if (this.state.device.deviceType === deviceConstants.BINARY_SENSOR) {
      device = (
        <BinarySensor
          device={this.state.device}
          unit={this.props.unit}
          sensorPlacement={this.state.sensorPlacement}
        />
      );
    }
    let statusBar = this.getStatusBar(this.state.device);
    let subheaderText = this.getSubHeaderText(this.state.device);
    let subheaderText2 = this.getSubHeaderText2(this.state.device);
    let modelText = this.getModelText();
    let sensorPlacementSelect = this.getSensorPlacementSelect();

    return (
      // See comment in blankTheme file. This top-level MuiThemeProvider is a temporary work-around.
      <MuiThemeProvider theme={blankTheme}>
        <div className="full-height">
          <Header
            headerText={this.props.unit.unit}
            back={true}
            return={this.updateAndReturn}
            arrow={true}
            unit={this.props.unit}
            user={this.props.user}
            history={this.props.history}
            subheaderText={subheaderText}
            subheaderText2={subheaderText2}
            unitmenu={this.state.device.deviceType}
            device={this.state.device}
          />
          {statusBar}
          <div className={this.classes.instructionsHeader}>
            <Typography variant={'body2'} className={this.classes.instructionsText}>
              Please be sure to test things out by operating the device and observing changes
              in state.
            </Typography>
          </div>
          <Typography component="div" style={{ padding: '24px' }}>
            {modelText}
            <MuiThemeProvider theme={theme}>
              {sensorPlacementSelect}
              <DeviceLocationSelect
                sfConfiguration={this.props.sfConfiguration}
                currentDevice={this.state.device}
                devices={this.state.devices}
                includeCurrentNameFirst={true}
                value={this.state.device_name}
                id="device_name"
                label="Device Name"
                name="device_name"
                options={deviceConstants.DEVICE_LOCATIONS}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField {...params} label="Device Name" variant="outlined" />
                )}
                onChange={this.handleDeviceNameChange}
              />
            </MuiThemeProvider>
            {device}
          </Typography>
          {fabs}
          <Fab
            variant="extended"
            aria-label="Done"
            className={`${this.classes.fab} keep-fixed ${this.classes.done}`}
            onClick={this.returnToUnit}
          >
            <CheckCircleIcon className={this.classes.extendedIcon} />
            Done
          </Fab>
          <Spinner name="default" loadingImage={logo} />
          {this.props.flags[DOOR_CODE_RELIABILITY] &&
            isCodeSyncActive(this.state.codeSyncingData) && (
              <MuiThemeProvider theme={defaultTheme}>
                <CodeSyncDialog
                  deviceName={'Front Door'}
                  handleActionClick={() => undefined}
                  handleCantChangeBatteries={() => undefined}
                  handleOutOfTime={() => undefined}
                  open={true}
                  sentProgress={this.state.codeSyncingData.sent_percent}
                  status={this.state.codeSyncingData.status}
                  verifiedProgress={this.state.codeSyncingData.verified_percent}
                />
              </MuiThemeProvider>
            )}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withLDConsumer()(withStyles(styles)(DeviceDetail));
