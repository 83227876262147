import React, { Component } from 'react';
import Header from '../../Common/Headers/Header';
import SearchFilter from '../../Common/Search/SearchFilter';
import NoMatch from '../../Common/Search/NoMatch';
import Unit from './Unit';
import List from '@material-ui/core/List';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import withStyles from '@material-ui/core/styles/withStyles';
import '../../Common/stylesheets/global.scss';
import '../unit.css';
import { getPendingWorkTicket } from '../../Common/utils/storageInterface';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import header_theme from '../../Common/Themes/header_theme';

const styles = (theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  buttonProgress: {
    color: '#F6861E',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -34,
    marginLeft: -34
  },
  disabledDiv: {
    pointerEvents: 'none',
    opacity: 0.4
  }
});

class UnitList extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    const { classes } = props;
    this.classes = classes;
    this.state = {
      filteredUnits: this.props.units,
      hasUnits: this.props.units.length > 0,
      pendingWorkTicket: null,
      workTicketLoading: false
    };
  }

  componentDidMount() {
    let element = document.getElementById('central-column');
    let fixedFooter = document.getElementsByClassName('keep-fixed')[0];
    if (fixedFooter && element) {
      fixedFooter.style.marginLeft = parseInt(element.style.width, 10) - 72 + 'px';
    }
    if (this.props.units) {
      const pendingWorkTicket = getPendingWorkTicket();
      if (pendingWorkTicket) {
        this.setState({
          pendingWorkTicket: pendingWorkTicket
        });
      }
    }
  }

  handleSearch(data) {
    let filteredUnits = this.props.units.filter((unit) => {
      if (unit.unit) {
        return unit.unit.toLowerCase().indexOf(data.toLowerCase()) !== -1;
      }
      return false;
    });
    this.setState({ filteredUnits: filteredUnits });
    this.setState({
      noMatch: filteredUnits.length <= 0
    });
  }

  setLoader = () => {
    this.setState({
      workTicketLoading: true
    });
  };

  removeLoader = () => {
    this.setState({
      workTicketLoading: false
    });
  };

  render() {
    let hasUnits = (
      <div>
        <SearchFilter placeholderText="Search" filterText={this.handleSearch} />
        <List>
          {this.state.filteredUnits.map((unit, index) => (
            <Unit
              key={index}
              unit={unit}
              community={this.props.community}
              pendingWorkTicket={this.state.pendingWorkTicket}
              setLoader={this.setLoader}
              removeLoader={this.removeLoader}
            />
          ))}
        </List>
        {this.state.filteredUnits.length === 0 ? <NoMatch matchText="No units match" /> : ''}
      </div>
    );

    let noUnits = <NoMatch matchText="No units" />;
    return (
      <div className="full-height">
        <div
          className={`unit-container ${
            this.state.workTicketLoading ? this.classes.disabledDiv : ''
          }`}
          aria-describedby={'circularLoader'}
          aria-busy={this.state.workTicketLoading}
        >
          <MuiThemeProvider theme={header_theme}>
            <Header
              headerText={this.props.community.name}
              community={this.props.community}
              user={this.props.user}
              back={false}
              history={this.props.history}
              showCommunityInfoButton={true}
            />
          </MuiThemeProvider>
          {this.state.hasUnits ? hasUnits : noUnits}
        </div>
        {this.state.workTicketLoading ? (
          <CircularProgress
            id={'circularLoader'}
            size={68}
            className={this.classes.buttonProgress}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UnitList);
