import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { INCLUSION_EXCLUSION_STAGES as stages } from '../../Common/Constants/installerAppConstants';

const useStyles = makeStyles({
  rootContainer: {
    width: '100%',
    padding: '1rem 0',
    backgroundColor: '#F3F3F3'
  },
  deviceNameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  deviceName: {
    marginRight: '1rem'
  },
  deviceNameAndProgress: {
    marginRight: '24px',
    marginLeft: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  titleContainer: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '20px',
    marginRight: '24px'
  },
  circle: {
    borderRadius: '50%',
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.5rem',
    width: '1.5rem'
  },
  progressContainer: {
    width: '35%'
  },
  circles: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 auto'
  },
  line: {
    border: '0.5px solid #000000',
    width: '95%',
    marginTop: '-0.75rem', // 0.75 is half of circle height
    marginBottom: '0.75rem',
    zIndex: 5
  }
});

type ValidStages = Exclude<stages, stages.CHOOSE_DEVICE>;

interface Props {
  stage: ValidStages;
  error: boolean;
  exclusion: boolean;
}

const YaleHeader: React.FC<Props> = ({ stage, error, exclusion }) => {
  const classes = useStyles();

  const [current, setCurrent] = useState<number>(() => {
    return getCurrentValue(stage, error);
  });
  const [progressLength, setProgressLength] = useState<number>(() => {
    return getCircleQuantity(stage);
  });

  useEffect(() => {
    setCurrent(getCurrentValue(stage, error));
    setProgressLength(getCircleQuantity(stage));
  }, [stage, error]);

  // the small circle that corresponds to the value will be highlighted
  function getCurrentValue(stage: ValidStages, error: boolean): number {
    if (
      [
        stages.PAIRING_START,
        stages.EXCLUSION_PREP,
        stages.EXCLUSION_START,
        stages.EXCLUDING,
        stages.EXCLUSION_SUCCESS
      ].includes(stage)
    ) {
      return 1;
    } else if (
      ([
        stages.INCLUSION_PREP,
        stages.INCLUSION_START,
        stages.INCLUDING,
        stages.DEVICE_INFO
      ].includes(stage) &&
        !error) ||
      (stage === stages.INCLUSION_START && error)
    ) {
      return 2;
    } else {
      // would only be inclusion success
      return 3;
    }
  }

  function getCircleQuantity(stage: ValidStages): number {
    if (
      [
        stages.EXCLUSION_PREP,
        stages.EXCLUSION_START,
        stages.EXCLUDING,
        stages.EXCLUSION_SUCCESS
      ].includes(stage)
    ) {
      return 1;
    } else {
      return 3;
    }
  }

  const circle = (value: number): JSX.Element => (
    <div
      key={value}
      className={classes.circle}
      style={{ backgroundColor: value === current ? '#BBBBBB' : '#FFFFFF' }}
    >
      <Typography variant={'body2'} style={{ color: '#1C1C1C' }}>
        {value}
      </Typography>
    </div>
  );

  const getTitle = (): string => {
    if (current === 3) return "Set the lock's location";
    else if (current === 2) return 'Put the lock in inclusion mode';
    else {
      if (exclusion) return 'Put the lock in exclusion mode';
      else return 'Program the master code';
    }
  };

  const getCircles = (): Array<JSX.Element> => {
    let circles: Array<JSX.Element> = [];
    for (let value = 1; value <= progressLength; value++) {
      circles.push(circle(value));
    }
    return circles;
  };

  const getLine = (): JSX.Element | null => {
    if (progressLength > 1) {
      return (
        <div>
          <hr className={classes.line} />
        </div>
      );
    } else {
      return null;
    }
  };

  const title = getTitle();
  const circles = getCircles();
  const line = getLine();

  return (
    <div
      className={classes.rootContainer}
      style={{ borderBottom: '1px solid black' }}
    >
      <div className={classes.deviceNameAndProgress}>
        <div className={classes.deviceNameContainer}>
          <Typography className={classes.deviceName} variant={'body2'}>
            {exclusion ? 'Excluding' : 'Including'} Yale Lock:
          </Typography>
        </div>
        <div className={progressLength > 1 ? classes.progressContainer : ''}>
          <div className={classes.circles}>{circles}</div>
          {line}
        </div>
      </div>
      <div className={classes.titleContainer}>
        <Typography variant={'h6'}>
          <strong>{title}</strong>
        </Typography>
      </div>
    </div>
  );
};

export default YaleHeader;
