import * as React from 'react';
import ExpandableSelect from '../ExpandableSelect';
import { USE_SALESFORCE_INSTALL_LOCATIONS } from '../../Constants/launchDarklyConstants';
import { DEVICE_LOCATIONS } from '../../Constants/deviceConstants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DweloDeviceType } from '../../Constants/deviceConstants_v2';
import { DeviceSensor, V4_SF_Unit_Configuration } from '../../Types/cloudApi';
import { getPopulatedInstallLocationsArray } from './utils';

interface Props {
  value: string;
  onChange: (event, value) => void;
  currentDevice: DeviceSensor;
  devices: DeviceSensor[];
  sfConfiguration?: V4_SF_Unit_Configuration;
  id?: string;
  label?: string;
  name?: string;
  options: string[];
  getOptionLabel: (value: any) => string;
  style: any;
  autoComplete: boolean;
  includeInputInList: boolean;
  renderInput: (params: any) => React.ReactNode;
  // We won't want to show the device's current name immediately after it is paired,
  // because by default it is "Unnamed" and we want to encourage
  // the user to name the device properly.
  includeCurrentNameFirst?: boolean;
}

// This array will always be in this order:
// [[unit install locations], [community install locations], [hard-coded install locations]]
// In order words, the unit array's index will always be smaller than all the other indexes,
// community will always be greater than unit, etc.
// It's possible that the unit and/or community install locations arrays won't exist in certain scenarios,
// such as [[unit install locations], [hard-coded install locations]]
export type InstallLocationsArray = string[][];

/**
 * Dropdown that is used for naming or renaming a device.
 */
function DeviceLocationSelect(props: Props) {
  const { [USE_SALESFORCE_INSTALL_LOCATIONS]: installLocationsFlag } = useFlags();

  function getExpandableSelectOptions(): InstallLocationsArray {
    if (!installLocationsFlag) {
      return [DEVICE_LOCATIONS];
    }
    return getPopulatedInstallLocationsArray({
      sfConfiguration: props.sfConfiguration || { unitConfigurations: [] },
      currentDevice: props.currentDevice,
      devices: props.devices,
      includeCurrentNameFirst: props.includeCurrentNameFirst
    });
  }

  return installLocationsFlag ? (
    <ExpandableSelect
      // Only thermostats are different, due to business logic.
      label={
        props.currentDevice.deviceType === DweloDeviceType.Thermostat
          ? 'Device Name'
          : 'Device Location'
      }
      options={getExpandableSelectOptions()}
      value={props.value}
      onChange={props.onChange}
      fullWidth
    />
  ) : (
    <Autocomplete
      value={props.value}
      id="device_name"
      onChange={(event, value) => {
        props.onChange(event, value);
      }}
      options={DEVICE_LOCATIONS}
      getOptionLabel={(option) => option}
      style={{ width: '100%' }}
      autoComplete
      includeInputInList
      renderInput={(params) => (
        <TextField {...params} label="Device Name" variant="outlined" />
      )}
    />
  );
}

export default DeviceLocationSelect;
