import { CENTRAL_COLUMN_MAX_WIDTH } from '../Constants/installerAppConstants';

export default function viewportHack() {
  window.onresize = function () {
    document.body.style.height = window.innerHeight + 'px';
    let innerWidth = window.innerWidth;
    let element = document.getElementById('central-column');
    let fixedFooter = document.getElementsByClassName('keep-fixed');
    if (innerWidth >= CENTRAL_COLUMN_MAX_WIDTH) {
      element.style.width = `${CENTRAL_COLUMN_MAX_WIDTH}px`;
    } else if (innerWidth >= 350) {
      element.style.width = innerWidth + 'px';
    } else {
      element.style.width = '350px';
    }
    if (fixedFooter && element) {
      for (let i = 0; i < fixedFooter.length; i++) {
        fixedFooter[i].style.marginLeft =
          parseInt(element.style.width, 10) - fixedFooter[i].offsetWidth - 16 + 'px';
      }
    }
  };
  window.onresize();
}
