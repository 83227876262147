import * as React from 'react';
import { createStyles, LinearProgress, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { dweloPalette } from '../../../Themes/dweloPalette';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      fontSize: '14px',
      // TODO Remember to swap this with dwelo palette color in a separate PR soon once we have the updated palette.
      color: '#6A6A6A'
    },
    linearProgress: {
      marginBottom: '10px'
    },
    linearProgressRoot: {
      height: '6px'
    },
    progressBar: {
      marginTop: '30px'
    },
    progressColorSecondary: {
      backgroundColor: dweloPalette.primary.grey[100]
    },
    progressDisabled: {
      opacity: 0.15
    }
  })
);

type Props = {
  progress: number;
  description: string;
  disabled?: boolean;
  style?: React.CSSProperties;
};

export function ProgressBar(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.progressBar} style={props.style}>
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={props.progress}
        className={classes.linearProgress}
        classes={{
          root: classes.linearProgressRoot,
          colorSecondary: classes.progressColorSecondary,
          barColorSecondary: props.disabled ? classes.progressDisabled : ''
        }}
      />
      <Typography
        className={classes.description}
        style={{ opacity: props.disabled ? 0.3 : undefined }}
      >
        {props.description}
      </Typography>
    </div>
  );
}
