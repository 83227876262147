import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as sensorConstants from '../../Common/Constants/sensorConstants';
import extractSensors from '../../Common/scripts/extractSensors';
import '../../Common/stylesheets/global.scss';
import theme from '../../Common/Themes/login_theme';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%',
    borderBottom: 'none',
    marginTop: '16px',
    marginBottom: '0px'
  },
  resize: {
    fontSize: '20px'
  }
});

class Lock extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
    this.state = {
      unit: this.props.unit,
      device: this.props.device
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ device: nextProps.device });
  }

  getCodeSynced() {
    if (this.state.device.dcm && this.state.device.dcm.version > 1) {
      const { codesSynced, codesExpected, masterCodeSynced } = this.state.device.dcm;
      return (
        <TextField
          label="DOOR CODES"
          value={`${codesSynced} of ${codesExpected} synced`}
          className={this.classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: this.classes.resize
          }}
          helperText={masterCodeSynced ? 'Master code was reset' : undefined}
        />
      );
    } else {
      return '';
    }
  }

  render() {
    let sensors = extractSensors(this.state.device);
    let codes_synced = this.getCodeSynced();
    return (
      <MuiThemeProvider theme={theme}>
        <form className={this.classes.container}>
          <TextField
            label="DOOR LOCK STATE"
            value={sensors[sensorConstants.LOCK_STATE]}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="BATTERY LEVEL"
            value={sensors[sensorConstants.BATTERY_LEVEL] + '%'}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          {codes_synced}
        </form>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Lock);
