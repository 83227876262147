import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme, Typography } from '@material-ui/core';
import { Banner } from '../Banner';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      paddingTop: 0,
      paddingBottom: 0
    },
    title: {
      fontSize: '16px'
    }
  })
);

type Props = {
  icon: React.ReactElement;
  title: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
  subtitle?: string;
  body?: ReactElement;
  action?: ReactElement;
};

export function NeutralBanner(props: Props) {
  const classes = useStyles();

  return (
    <Banner
      className={classes.banner}
      backgroundColor={'#FFF'}
      icon={props.icon}
      title={<Typography className={classes.title}>{props.title}</Typography>}
      subtitle={<Typography className={classes.title}>{props.subtitle}</Typography>}
      body={props.body}
      action={props.action}
    />
  );
}
