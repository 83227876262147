import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#4ad4d4',
      main: '#4ad4d4',
      dark: '#4ad4d4',
      contrastText: '#fff'
    },
    secondary: {
      light: '#991210',
      main: '#991210',
      dark: '#991210',
      contrastText: '#fff'
    }
  }
});
