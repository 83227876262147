import React, { Component } from 'react';
import Header from '../../Common/Headers/Header';
import StatusBar from '../../Common/Headers/StatusBar';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { resetFabPosition } from '../../Common/utils/commonUtils';

import '../../Common/stylesheets/global.scss';

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: '16px',
    backgroundColor: '#4ad4d4'
  },
  textField: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100%'
  },
  heading: {
    marginTop: '24px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%'
  }
});

class WifiOffline extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.classes = classes;
  }

  componentDidMount() {
    resetFabPosition();
  }

  returnFunction = () => {
    this.props.return(1);
  };

  render() {
    return (
      <div className="full-height">
        <div className="addunit-container">
          <Header
            headerText="Nest Thermostat"
            back={true}
            user={this.props.user}
            history={this.props.history}
            subheaderText={this.props.unit.communityName}
            subheaderText2={this.props.unit.unit}
            return={this.returnFunction}
            unit={this.props.unit}
            unitmenu={this.props.device.deviceType}
            device={this.props.device}
          />
          <StatusBar message="Nest thermostat is offline" error={true} />
          <Typography variant="h6" className={this.classes.heading} gutterBottom={true}>
            1. Make sure the Nest is connected to Wifi.
          </Typography>
          <Typography variant="body1" className={this.classes.textField} gutterBottom={true}>
            <SettingsApplicationsIcon /> Settings <ChevronRightIcon /> Network
          </Typography>
          <Typography variant="body1" className={this.classes.textField}>
            Select network name and enter password.
          </Typography>
          <Typography variant="h6" className={this.classes.heading} gutterBottom={true}>
            2. Tap the button below
          </Typography>
        </div>
        <Fab
          color="primary"
          aria-label="Next"
          className={this.classes.fab + ' keep-fixed'}
          onClick={this.returnFunction}
        >
          <ArrowForwardIcon />
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(WifiOffline);
