import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import * as sfConstants from '../../Common/Constants/salesforceConstants';
import { Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  contentText: {
    marginBottom: theme.spacing(2),
    color: grey[900],
    fontSize: '1rem'
  },
  dialogTitle: {
    marginBottom: theme.spacing(2)
  },
  dialogContent: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
    color: grey[900],
    fontSize: '1rem'
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500]
  }
}));

function UnitInfoDialog(props) {
  const classes = useStyles();
  const { unit } = props;
  let notesToTechnician = null;
  let hubLocation = '';
  let pteNotes = '';

  if (unit && unit.installerPreviewData) {
    const notesText = unit.installerPreviewData[sfConstants.SharedVars.NOTES];
    if (notesText) {
      notesToTechnician = (
        <DialogContent>
          <Typography variant={'overline'}>Notes To Technician</Typography>
          <Typography variant={'body1'} className={classes.contentText}>
            {notesText}
          </Typography>
        </DialogContent>
      );
    }
    const hubLocationText = unit.installerPreviewData[sfConstants.SharedVars.HUB_LOCATION];
    if (hubLocationText) {
      hubLocation = (
        <DialogContent>
          <Typography variant={'overline'}>Hub Location</Typography>
          <Typography variant={'body1'} className={classes.contentText}>
            {hubLocationText}
          </Typography>
        </DialogContent>
      );
    }
    if (unit.installerPreviewData[sfConstants.SharedVars.PTE_NOTES]) {
      pteNotes = (
        <DialogContent className={classes.dialogContent}>
          <Typography variant={'overline'}>PTE Notes</Typography>
          <Typography variant={'body1'}>
            {unit.installerPreviewData[sfConstants.SharedVars.PTE_NOTES]}
          </Typography>
        </DialogContent>
      );
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle className={classes.dialogTitle}>
        {unit && 'Unit ' + unit.unit}
        <IconButton className={classes.closeIcon} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {notesToTechnician}
      {hubLocation}
      {pteNotes}
    </Dialog>
  );
}

export default UnitInfoDialog;
