import React, { useState } from 'react';
import { Button, Collapse, createStyles, List, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  DeviceTypeMeta,
  LockTypeMeta,
  unitTodoMetaStorage
} from '../../Common/utils/storageInterface';
import {
  Community,
  DCM,
  DeviceSensor,
  DeviceToBeInstalled,
  Unit
} from '../../Common/Types/cloudApi';
import unitTodoTheme from '../../Common/Themes/unit_todo_theme';
import '../../Common/stylesheets/global.scss';
import { DeviceTypeTodoHeader } from './DeviceTypeTodoHeader';
import {
  SF_Dwelo_DeviceMappings,
  TodoListDeviceTypes,
  TodoListDeviceTypesUnion
} from '../../Common/Constants/salesforceConstants_v2';
import { LOCK } from '../../Common/Constants/deviceConstants';
import {
  capitalizeFirstLetter,
  getQuantityToPair,
  getTodoListTypeForSalesforceSku,
  isCodeSyncInProgress
} from '../common/utils';
import {
  DeviceTypeCompleteState,
  LockThrowDialogDispatch,
  UnitMetaDispatch
} from '../common/types';
import { Device } from './Device_v2';
import { DeviceTasks } from './DeviceTasks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      backgroundColor: '#FFF',
      marginBottom: '10px',
      boxShadow: '0px 1px 1px #BBBBBB'
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingRight: '16px',
      paddingBottom: '20px'
    },
    pairingButton: {
      width: '82px'
    },
    devicesList: {
      marginBottom: '16px',
      paddingTop: '0px'
    },
    removeButton: {
      marginRight: '16px'
    }
  })
);

type Props = {
  unit: Unit;
  dcm2Community: Community['usesPrefilledPins'];
  devicesToPair: Array<DeviceToBeInstalled>;
  devices: Array<DeviceSensor>;
  deviceType: TodoListDeviceTypesUnion;
  todoMeta: LockTypeMeta | DeviceTypeMeta | undefined;
  completeState: DeviceTypeCompleteState;
  startInclusion: (e: React.MouseEvent<HTMLButtonElement>) => void;
  startExclusion: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dispatchUnitMeta: UnitMetaDispatch;
  dispatchLockThrowDialog: LockThrowDialogDispatch;
  goToDeviceDetails?: (uid: number) => void;
};

export const DeviceTypeTodo = (props: Props) => {
  const classes = useStyles(unitTodoTheme);
  const [expanded, setExpanded] = useState(
    props.todoMeta?.expanded === undefined ? true : props.todoMeta.expanded
  );
  const [quantityToPair] = useState(() => getQuantityToPair(props.devicesToPair));

  function handleSetExpanded() {
    const deviceType = getTodoListTypeForSalesforceSku(props.devicesToPair[0]);
    if (deviceType) {
      unitTodoMetaStorage.updateDeviceTypeTodo(props.unit.uid, deviceType, {
        expanded: !expanded
      });
    }
    // Not storing the "expanded" state in unitTodoMeta in UnitTodoList,
    // because the state of expanded doesn't need to be elevated.
    setExpanded((prevState) => !prevState);
  }

  function getPropsForPairedDeviceComponents(device: DeviceSensor) {
    const deviceToPairEquivalent = props.devicesToPair.find((deviceToPair) => {
      const deviceType = device.deviceType;
      return SF_Dwelo_DeviceMappings[deviceToPair.deviceType] === deviceType;
    });
    const isLock = props.deviceType === LOCK;
    const codesExpected = isLock && (device.dcm as DCM).version > 1;
    const lockPaired = isLock
      ? (props.todoMeta as LockTypeMeta | null)?.locksPaired?.find(
          (lockPaired) => lockPaired.uid === device.uid
        )
      : null;
    const wasLockThrowRated = lockPaired
      ? lockPaired.lockThrow?.nothingButAir || !!lockPaired.lockThrow?.resultOfIssue
      : false;
    return {
      codesExpected,
      wasLockThrowRated,
      isLock,
      batteriesInstalled: !!lockPaired?.batteriesInstalled,
      model: deviceToPairEquivalent ? deviceToPairEquivalent.model : null
    };
  }

  /**
   * For each paired device, a Device component is returned.
   * If the device is a lock, then DeviceTasks will be returned as well.
   */
  function getDevicesPairedAndTasks(): Array<JSX.Element> {
    if (props.devices.length === 0) {
      return [];
    }
    return props.devices.map((device, index) => {
      const {
        codesExpected,
        wasLockThrowRated,
        isLock,
        batteriesInstalled,
        model
      } = getPropsForPairedDeviceComponents(device);
      return (
        <React.Fragment key={`DeviceDeviceTasks-paired-${index}`}>
          <Device
            unit={props.unit}
            paired={true}
            device={device}
            model={model}
            complete={props.completeState[device.uid]}
            goToDeviceDetails={props.goToDeviceDetails}
          />
          {isLock ? (
            <DeviceTasks
              paired={true}
              unitId={props.unit.uid}
              dcm2Community={props.dcm2Community}
              codesExpected={codesExpected}
              codeSyncInProgress={codesExpected && isCodeSyncInProgress(device.dcm as DCM)}
              batteriesInstalled={batteriesInstalled}
              wasLockThrowRated={wasLockThrowRated}
              dispatchUnitMeta={props.dispatchUnitMeta}
              dispatchLockThrowDialog={props.dispatchLockThrowDialog}
              dispatchId={device.uid}
            />
          ) : null}
        </React.Fragment>
      );
    });
  }

  /**
   * Locks only.
   * A Device "placeholder' and DeviceTasks will be returned,
   * based on the number of locks that still need to be paired.
   */
  function getDevicesToPairAndTasks(): Array<JSX.Element> {
    let deviceItems: Array<JSX.Element> = [];
    if (
      props.devicesToPair.length === 0 ||
      props.devices.length >= quantityToPair ||
      props.deviceType !== LOCK
    ) {
      return deviceItems;
    }
    for (let i = 0; i < quantityToPair - props.devices.length; i++) {
      const displayedDeviceType = capitalizeFirstLetter(props.deviceType);
      const lockMeta = props.todoMeta as LockTypeMeta | null;
      const batteriesInstalled =
        lockMeta?.locksToPair && lockMeta.locksToPair.length > i
          ? lockMeta.locksToPair[i].batteriesInstalled
          : false;
      deviceItems.push(
        <React.Fragment key={`DeviceDeviceTasks-toPair-${i}`}>
          <Device
            unit={props.unit}
            paired={false}
            deviceToPair={props.devicesToPair[0]}
            name={`${displayedDeviceType} ${props.devices.length + i + 1}`}
          />
          <DeviceTasks
            paired={false}
            unitId={props.unit.uid}
            dcm2Community={props.dcm2Community}
            batteriesInstalled={batteriesInstalled}
            dispatchUnitMeta={props.dispatchUnitMeta}
            dispatchId={i}
          />
        </React.Fragment>
      );
    }
    return deviceItems;
  }

  function getDeviceItems(): JSX.Element | null {
    const deviceItems = [...getDevicesPairedAndTasks(), ...getDevicesToPairAndTasks()];
    if (deviceItems.length === 0) {
      return null;
    }
    return <List className={classes.devicesList}>{deviceItems}</List>;
  }

  function getPairingButtons(): JSX.Element | null {
    if (props.deviceType === TodoListDeviceTypes.RingDoorbell) {
      if (props.devices.length > 0) {
        return null;
      } else {
        return (
          <div className={classes.buttonsContainer}>
            <Button
              name={String(props.devicesToPair[0].model)}
              variant="contained"
              color="secondary"
              onClick={props.startInclusion}
              style={{ width: '98px' }}
            >
              <p>ADD RING</p>
            </Button>
          </div>
        );
      }
    } else {
      return (
        <div className={classes.buttonsContainer}>
          <Button
            name={String(props.devicesToPair[0].model)}
            variant="outlined"
            color="secondary"
            className={`${classes.pairingButton} ${classes.removeButton}`}
            onClick={props.startExclusion}
          >
            <p>EXCLUDE</p>
          </Button>
          <Button
            name={String(props.devicesToPair[0].model)}
            variant="contained"
            color="secondary"
            className={`${classes.pairingButton}`}
            onClick={props.startInclusion}
          >
            <p>INCLUDE</p>
          </Button>
        </div>
      );
    }
  }

  const deviceItems = getDeviceItems();

  return (
    <div className={classes.listItem}>
      <DeviceTypeTodoHeader
        complete={props.completeState.all}
        quantityPaired={props.devices.length}
        deviceType={props.deviceType}
        devicesToPair={props.devicesToPair}
        quantityToPair={quantityToPair}
        expanded={expanded}
        handleSetExpanded={handleSetExpanded}
      >
        <Collapse in={expanded} timeout={'auto'}>
          {deviceItems}
          {getPairingButtons()}
        </Collapse>
      </DeviceTypeTodoHeader>
    </div>
  );
};
