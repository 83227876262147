import { Unit } from '../Types/cloudApi';
import { OptionalExceptFor } from '../Types/utils';
import { TodoListDeviceTypesUnion } from '../Constants/salesforceConstants_v2';

interface StorageInterface<TSet, TGet> {
  key: string;
  get: () => TGet;
  remove: () => void;
  set: (value: TSet) => void;
}

export class BaseStorage<TSet, TGet> implements StorageInterface<TSet, TGet> {
  key: string;

  constructor(key: string) {
    this.key = key;
  }
  get(): TGet {
    const value = localStorage.getItem(this.key);
    return value ? JSON.parse(value) : null;
  }

  set(value: TSet) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  remove() {
    localStorage.removeItem(this.key);
  }
}
// used for classes that store json and load it again.
export class BaseStorageObjectStore<T> extends BaseStorage<T, T> {}

/*
 * Pending Work Ticket Functions
 */

export function setPendingWorkTicket(
  addressId,
  unit,
  communityId,
  communityName,
  unitRoute,
  workTicketRoute
) {
  const pendingWorkTicket = {
    addressId: addressId,
    unit: unit,
    communityId: communityId,
    communityName: communityName,
    unitRoute: unitRoute,
    workTicketRoute: workTicketRoute
  };
  localStorage.setItem('pendingWorkTicket', JSON.stringify(pendingWorkTicket));
}

export function getPendingWorkTicket() {
  const pendingWorkTicket = localStorage.getItem('pendingWorkTicket');
  return pendingWorkTicket ? JSON.parse(pendingWorkTicket) : null;
}

export function removePendingWorkTicket() {
  localStorage.removeItem('pendingWorkTicket');
}

/*
 * UnitTodoMetaStorage class and types
 */

export type LockMetaWithIdentifiers =
  | {
      paired: true;
      lock: OptionalExceptFor<LockDevicePaired, 'uid'>;
    }
  | {
      paired: false;
      lock: LockDeviceToPair;
      arrayIndex: number;
    };

export type LockThrow = {
  nothingButAir?: boolean; // If the deadbolt extended smoothly or not
  description?: string;
  resultOfIssue?: 'REPORTED' | 'FIXED';
};

export interface RingTroubleshootInfo {
  issueDescription?: string;
  stepsTaken?: string;
  resultOfIssue?: 'REPORTED' | 'FIXED';
}

export type LockDevice = {
  batteriesInstalled: boolean;
};

export type LockDeviceToPair = LockDevice;

export type LockDevicePaired = LockDevice & {
  uid: number;
  lockThrow?: LockThrow;
};

export interface DeviceTypeMeta {
  expanded?: boolean;
}

export interface RingDoorbellTypeMeta extends DeviceTypeMeta {
  troubleshootInfo?: RingTroubleshootInfo;
}

export interface LockTypeMeta extends DeviceTypeMeta {
  locksPaired: Array<LockDevicePaired>;
  locksToPair: Array<LockDeviceToPair>;
}

export interface UnitTodoMeta {
  addressId: Unit['uid'];
  // hub?: HubTypeMeta;
  ringDoorbell?: RingDoorbellTypeMeta;
  light?: DeviceTypeMeta;
  thermostat?: DeviceTypeMeta;
  lock?: LockTypeMeta;
  sensor?: DeviceTypeMeta;
}

class UnitTodoMetaStorage extends BaseStorageObjectStore<UnitTodoMeta> {
  getValid(addressId: number): UnitTodoMeta {
    const meta = this.get();
    return meta ? meta : { addressId: addressId };
  }

  /**
   * Replaces UnitTodoMeta[DeviceType] with a new DeviceTypeMeta or LockTypeMeta object.
   * Existing values will be used as defaults, and the "value" parameter will override if it differs.
   */
  updateDeviceTypeTodo(
    addressId: UnitTodoMeta['addressId'],
    deviceType: TodoListDeviceTypesUnion,
    value: DeviceTypeMeta | LockTypeMeta | RingDoorbellTypeMeta
  ) {
    const todoMeta: UnitTodoMeta = this.getValid(addressId);
    this.set({
      ...todoMeta,
      [deviceType]: { ...todoMeta[deviceType], ...value }
    });
  }

  /**
   * Updates a single or multiple LockDevicePaired and/or LockDeviceToPair objects.
   * in the LockTypeMeta['locksPaired'] or LockTypeMeta['locksToPair'] arrays, respectively.
   *
   * Pass an array to newLockData for updating multiple objects,
   * or pass a single object without the array.
   */
  updateLockDevices(
    addressId: UnitTodoMeta['addressId'],
    newLockMeta: LockMetaWithIdentifiers
  ): void {
    const previousUnitMeta: UnitTodoMeta = this.getValid(addressId);
    const previousLockMeta: LockTypeMeta = previousUnitMeta.lock || {
      locksPaired: [],
      locksToPair: []
    };

    mergeNewLockTypeMeta(previousLockMeta, newLockMeta);

    this.set({
      ...previousUnitMeta,
      lock: { ...previousLockMeta }
    });
  }

  removeDeviceTypeTodo(
    addressId: UnitTodoMeta['addressId'],
    deviceType: TodoListDeviceTypesUnion
  ) {
    const todoMeta: UnitTodoMeta = this.getValid(addressId);
    this.set({
      ...todoMeta,
      [deviceType]: undefined
    });
  }
}

/**
 * Merge newLockMeta into previousLockMeta.
 * newLockMeta.lock will overwrite any existing properties from previousLockMeta.
 */
function mergeNewLockTypeMeta(
  previousLockMeta: LockTypeMeta,
  newLockMeta: LockMetaWithIdentifiers
): void {
  if (newLockMeta.paired) {
    mergeNewPairedLockProps(previousLockMeta, newLockMeta.lock);
  } else if (newLockMeta.arrayIndex < previousLockMeta.locksToPair.length) {
    previousLockMeta.locksToPair[newLockMeta.arrayIndex] = {
      ...previousLockMeta.locksToPair[newLockMeta.arrayIndex],
      ...newLockMeta.lock
    };
  }
}

export function mergeNewPairedLockProps(
  previousLocksMeta: LockTypeMeta,
  newLock: OptionalExceptFor<LockDevicePaired, 'uid'>
): void {
  const indexOfPrevious = previousLocksMeta.locksPaired.findIndex(
    (lockPaired) => lockPaired.uid === newLock.uid
  );

  if (indexOfPrevious === -1) {
    return;
  }

  const previousLock = previousLocksMeta.locksPaired[indexOfPrevious];

  previousLocksMeta.locksPaired[indexOfPrevious] = {
    ...previousLock,
    ...newLock,
    lockThrow: createMergedLockThrow(previousLock.lockThrow, newLock.lockThrow)
  };
}

export function createMergedLockThrow(
  oldLockThrow: LockThrow | undefined,
  newLockThrow: LockThrow | undefined
): LockThrow {
  const mergedLockThrow: LockThrow = {
    ...oldLockThrow,
    ...newLockThrow
  };

  // If nothingButAir === true, there shouldn't have been an issue
  if (newLockThrow?.nothingButAir) {
    mergedLockThrow.resultOfIssue = undefined;
  }

  return mergedLockThrow;
}

/**
 * Meant for storing a Date of when we sent a hub reboot or restart command to our cloud
 */
class HubRebootRestartDateStorage extends BaseStorage<Date, string> {}

export const unitTodoMetaStorage = new UnitTodoMetaStorage('unitTodoMeta');
export const hubRebootDateStorage = (hubSerial: Unit['hub_serial']) => {
  return new HubRebootRestartDateStorage(`${hubSerial}_reboot`);
};
export const hubRestartDateStorage = (hubSerial: Unit['hub_serial']) => {
  return new HubRebootRestartDateStorage(`${hubSerial}_restart`);
};
