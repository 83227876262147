export enum DweloDeviceType {
  Lock = 'lock',
  Thermostat = 'thermostat',
  Switch = 'switch',
  Dimmer = 'dimmer',
  BinarySensor = 'binary_sensor',
  Nest = 'nest',
  Gateway = 'gateway',
  Doorbell = 'doorbell'
}

// For types that refer to more than one device type
// e.g. light = switch and/or dimmer
export enum GroupedDeviceType {
  Light = 'light'
}

// Device types that are considered a light (from GroupedDeviceType.Light)
export const LIGHT_TYPES = [DweloDeviceType.Switch, DweloDeviceType.Dimmer];

Object.freeze(LIGHT_TYPES);
