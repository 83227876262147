import React from 'react';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import unit_todo_theme from '../../Themes/unit_todo_theme';
import { SyncInProgressBanner } from '../Banners/SyncInProgressBanner';
import { SyncCompleteBanner } from '../Banners/SyncCompleteBanner';
import { SentProgressBar } from '../ProgressBars/SentProgressBar';
import { VerifiedProgressBar } from '../ProgressBars/VerifiedProgressBar';
import { PoorCellularSignalBanner } from '../Banners/PoorCellularSignalBanner';
import { CloudErrorBanner } from '../Banners/CloudErrorBanner';
import { FailingCodesBanner } from '../Banners/FailingCodesBanner';
import { HubOfflineBanner } from '../Banners/HubOfflineBanner';
import { LockOfflineBanner } from '../Banners/LockOfflineBanner/LockOfflineBanner';
import { LowBatteryBanner } from '../Banners/LowBatteryBanner';
import { PairingIssueBanner } from '../Banners/PairingIssueBanner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      textAlign: 'center',
      paddingTop: '24px',
      paddingBottom: '24px'
    },
    dialogContent: {
      padding: '0 20px 40px',
      justifyContent: 'center'
    },
    titleText: {
      fontSize: '14px'
    }
  })
);

type Props = {
  deviceName: string;
  handleActionClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  handleCantChangeBatteries: (event?: React.MouseEvent<HTMLAnchorElement>) => void;
  handleOutOfTime: (event?: React.MouseEvent<HTMLAnchorElement>) => void;
  open: boolean;
  sentProgress: number;
  status:
    | 'in_progress'
    | 'complete'
    | 'cloud_error'
    | 'codes_failing'
    | 'hub_offline'
    | 'lock_offline'
    | 'low_battery'
    | 'pairing_issue'
    | 'poor_cell_signal';
  transitionDuration?: number;
  verifiedProgress: number;
};

/**
 * Dialog for displaying overall progress of codes that are syncing.
 * While open, it prevents user from interacting with any other part of the screen.
 */
export function CodeSyncDialog(props: Props) {
  const classes = useStyles(unit_todo_theme);

  function getBannerComponent() {
    switch (props.status) {
      // TODO revisit these strings since they may not be exact in the actual implementation later
      case 'in_progress':
        return <SyncInProgressBanner />;
      case 'complete':
        return <SyncCompleteBanner />;
      case 'cloud_error':
        return <CloudErrorBanner onActionClick={props.handleActionClick} />;
      case 'codes_failing':
        return <FailingCodesBanner onActionClick={props.handleActionClick} />;
      case 'hub_offline':
        return <HubOfflineBanner onActionClick={props.handleActionClick} />;
      case 'lock_offline':
        return (
          <LockOfflineBanner
            deviceName={props.deviceName}
            onActionClick={props.handleActionClick}
          />
        );
      case 'low_battery':
        return (
          <LowBatteryBanner
            deviceName={props.deviceName}
            handleCantChangeBatteries={props.handleCantChangeBatteries}
          />
        );
      case 'pairing_issue':
        return (
          <PairingIssueBanner
            deviceName={props.deviceName}
            onActionClick={props.handleActionClick}
          />
        );
      case 'poor_cell_signal':
        return <PoorCellularSignalBanner handleOutOfTime={props.handleOutOfTime} />;
    }
  }

  function shouldDisableProgress() {
    const errorStatuses = [
      'cloud_error',
      'codes_failing',
      'hub_offline',
      'lock_offline',
      'low_battery',
      'pairing_issue'
    ];
    return errorStatuses.includes(props.status);
  }

  const progressDisabled = shouldDisableProgress();

  return (
    <Dialog
      open={props.open}
      PaperProps={{ square: true }}
      maxWidth="xs"
      fullWidth
      transitionDuration={props.transitionDuration}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.titleText}>Syncing Door Codes</Typography>
      </DialogTitle>
      {getBannerComponent()}
      <DialogContent className={classes.dialogContent}>
        <SentProgressBar progress={props.sentProgress} disabled={progressDisabled} />
        <VerifiedProgressBar progress={props.verifiedProgress} disabled={progressDisabled} />
      </DialogContent>
    </Dialog>
  );
}
