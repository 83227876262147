import { DweloDeviceType } from './deviceConstants_v2';
import { LockThrow } from '../utils/storageInterface';
import { ValueOf } from '../Types/utils';

export enum SFDeviceType {
  Lock = 'lock',
  Thermostat = 'thermostat',
  Sensor = 'sensor',
  PrimarySwitch = 'primary switch',
  DimmerSwitch = 'dimmer switch',
  AddOnSwitch = 'add-on switch',
  Outlet = 'outlet',
  PrivacyLock = 'privacy lock',
  VoiceControl = 'voice control',
  Doorbell = 'doorbell'
}

// Hardcoded model numbers
export enum SFModelNumbers {
  DoorbellTransformer = 'DOORBELL_TRANSFORMER'
}

export enum SFProductFamily {
  Locks = 'locks',
  Thermostats = 'thermostats',
  Lights = 'lights',
  Sensor = 'sensor',
  Hub = 'hub',
  Doorbell = 'doorbell' // Note: doorbell includes doorbells and chimes...
}

export const TodoListDeviceTypes = {
  Lock: 'lock',
  Thermostat: 'thermostat',
  Light: 'light',
  Sensor: 'sensor',
  RingDoorbell: 'ringDoorbell'
} as const;
export type TodoListDeviceTypesUnion = ValueOf<typeof TodoListDeviceTypes>;

export const DweloDevice_SFProductFamily_Mappings = {
  [DweloDeviceType.Lock]: SFProductFamily.Locks,
  [DweloDeviceType.Thermostat]: SFProductFamily.Thermostats,
  [DweloDeviceType.Switch]: SFProductFamily.Lights,
  [DweloDeviceType.Dimmer]: SFProductFamily.Lights,
  [DweloDeviceType.Gateway]: SFProductFamily.Hub,
  [DweloDeviceType.Doorbell]: SFProductFamily.Doorbell,
  [DweloDeviceType.BinarySensor]: SFProductFamily.Sensor
};

// BaseMappings and the other mapppings probably should be in a different file,
// but leaving here for consistency with old file for now.
const BaseMappings = {
  [SFDeviceType.Lock]: DweloDeviceType.Lock,
  [SFDeviceType.Thermostat]: DweloDeviceType.Thermostat
} as const;

// The default way of mapping salesforce device types to dwelo device types
export const SF_Dwelo_DeviceMappings = {
  ...BaseMappings,
  [SFDeviceType.PrimarySwitch]: DweloDeviceType.Switch,
  [SFDeviceType.DimmerSwitch]: DweloDeviceType.Dimmer,
  [SFDeviceType.Sensor]: DweloDeviceType.BinarySensor
} as const;

// Mainly for salesforce checkboxes.
// There's a cloud issue with sending booleans to salesforce, so its much simpler to do it this way for now.
export type JavascriptBooleanString = 'true' | 'false';

// Values are the possible field values in salesforce.
export type WorkTicketLockDetails = {
  batteriesReplaced?: JavascriptBooleanString;
  lockThrowStatus?: LockThrow['resultOfIssue'] | 'Nothing But Air' | 'Not Specified';
  lockThrowDetails?: string;
  masterCodeSynced?: 'Yes' | 'No' | 'Not Yale' | 'DCM1';
  pinsSynced?: 'Yes - DCM2' | 'No - DCM2' | 'DCM1';
};
