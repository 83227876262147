import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core';
import button_theme_v2 from '../../Common/Themes/button_theme_v2';
import { withStyles } from '@material-ui/core/styles';
import { DEVICE_TYPES_RELATIVE_TO_YALE as yale } from '../../Common/Constants/deviceConstants';
import {
  INCLUSION_ATTEMPTS_BEFORE_S2_UI,
  INCLUSION_EXCLUSION_STAGES as stages
} from '../../Common/Constants/installerAppConstants';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import * as commonStyles from '../../Common/styles/styles';
import { HANDLE_S2_INCLUSION_ATTEMPTS } from '../../Common/Constants/launchDarklyConstants';
import { StartPairingMessage } from './Pairing/StartPairingMessage';

const styles = (theme) => ({
  chooseDeviceDiv: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4)
  },
  chooseDeviceTitle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'center'
  },
  noButton: {
    marginRight: theme.spacing(2),
    width: '76px'
  },
  yesButton: {
    marginLeft: theme.spacing(2),
    width: '76px'
  },
  yaleCodeInline: {
    fontWeight: 'bold',
    backgroundColor: '#FFE9D0',
    color: '#743E01'
  },
  circularProgress: {
    ...commonStyles.buttonCircularProgress
  },
  textBottomMargin: {
    marginBottom: '24px'
  },
  clickableText: {
    color: '#007bff',
    '&:hover': {
      color: '#428bca',
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  pairingTextBlock: {
    padding: '24px 24px 0px 24px'
  }
});

class PairingMessage extends Component {
  constructor(props) {
    super(props);
    this.classes = props.classes;
  }

  getStart() {
    return (
      <StartPairingMessage
        pairingTextClass={this.classes.pairingTextBlock}
        splitStyleHeight="72px"
      />
    );
  }

  getStartError() {
    return (
      <div>
        <p style={{ padding: '24px' }}>
          {this.props.errorMessage ||
            'We are unable to establish a line of direct communication with the hub.'}
          <br />
          <br />
          <strong>
            {
              'Please contact DweloCare to resolve this issue and provide them with the error above.'
            }
          </strong>
        </p>
      </div>
    );
  }

  getPrepError() {
    return (
      <div>
        <p style={{ padding: '24px' }}>
          We are unable to communicate with the hub. Try the following troubleshooting steps:
        </p>
        <ol style={{ listStyleType: 'decimal', paddingRight: '24px' }}>
          <li>Make sure the hub is plugged in and connected to the internet.</li>
          <br />
          <li>Check status.dwelo.com to see if there's a problem with the Dwelo service.</li>
        </ol>
      </div>
    );
  }

  getExcludeStartError() {
    return (
      <div>
        <p style={{ padding: '24px' }}>
          The hub wasn’t able to exclude the new device. Try the following troubleshooting
          steps:
        </p>
        <ol style={{ listStyleType: 'decimal', paddingRight: '24px' }}>
          <li>
            Did you run out of time to put the device in exclusion mode? If so, try again.
          </li>
        </ol>
        <p
          style={{
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '16px'
          }}
        >
          If your device is not able to be put in exclusion mode, such as certain thermostats,
          you may skip the exclusion step.
        </p>
      </div>
    );
  }

  getExcludeError() {
    return (
      <div>
        <p style={{ padding: '24px' }}>
          The hub wasn’t able to exclude the new device. Try the following troubleshooting
          steps:
        </p>
        <ol style={{ listStyleType: 'decimal', paddingRight: '24px' }}>
          <li>
            Did you run out of time to put the device in exclusion mode? If so, try again.
          </li>
        </ol>
      </div>
    );
  }

  getIncludeError() {
    return (
      <div>
        <p style={{ padding: '24px' }}>
          The hub wasn’t able to include the new device. Try the following troubleshooting
          steps:
        </p>
        <ol style={{ listStyleType: 'decimal', paddingRight: '24px' }}>
          <li>
            Did you run out of time to put the device in inclusion mode? If so, try again.
          </li>
          <br />
          <li>Some devices need to be included twice. You may need to try again.</li>
          <br />
          {!this.props.flags[HANDLE_S2_INCLUSION_ATTEMPTS] ||
          this.props.inclusionAttempts > INCLUSION_ATTEMPTS_BEFORE_S2_UI ? (
            <li>
              Still not working? Try excluding the device before you try adding it again.
            </li>
          ) : null}
        </ol>
      </div>
    );
  }

  getSensorsError() {
    return (
      <div>
        <p style={{ padding: '24px' }}>
          The device looks like it included but the hub is not able to get sensor readings from
          the device.
          <br />
          <br />
          You can try again, but if the hub is still not able to get sensor readings you should
          remove the device and then try adding it again.
        </p>
      </div>
    );
  }

  getSensorsFailed() {
    return (
      <div>
        <p style={{ padding: '24px' }}>
          The device looks like it was added but the hub is not able to get sensor readings
          from the device.
          <br />
          <br />
          Please remove the device and and then try adding it again.
        </p>
      </div>
    );
  }

  getChooseDevice(exclusion) {
    let includingExcluding, includeExclude;
    if (exclusion) {
      includingExcluding = 'excluding';
      includeExclude = 'Exclude';
    } else {
      includingExcluding = 'including';
      includeExclude = 'Include';
    }

    const waitingForStream = this.props.exclusion && !this.props.streaming;
    return (
      <div>
        <p style={{ padding: '24px' }}>{includeExclude} device</p>
        <Typography variant={'h5'} className={this.classes.chooseDeviceTitle}>
          Is the device you're {includingExcluding} a Yale lock?
        </Typography>
        <MuiThemeProvider theme={button_theme_v2}>
          <div className={this.classes.chooseDeviceDiv}>
            <Button
              name={yale.NOT_YALE}
              size={'large'}
              color={'secondary'}
              variant={'contained'}
              className={this.classes.noButton}
              disabled={waitingForStream}
              onClick={this.props.handleChooseDevice}
            >
              No
              {waitingForStream && (
                <CircularProgress size={24} className={this.classes.circularProgress} />
              )}
            </Button>
            <Button
              name={yale.IS_YALE}
              size={'large'}
              color={'secondary'}
              variant={'outlined'}
              className={this.classes.yesButton}
              disabled={waitingForStream}
              onClick={this.props.handleChooseDevice}
            >
              Yes
              {waitingForStream && (
                <CircularProgress size={24} className={this.classes.circularProgress} />
              )}
            </Button>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }

  getStartYale() {
    return {
      preStepText:
        'If this lock already has a master code, you may skip this step and start inclusion now OR factory reset the lock and program the new master code.',
      listOfSteps: (
        <ol style={{ paddingLeft: '42px' }}>
          <li>
            Wake up the lock (if touchscreen use your palm, if push-button press any key)
          </li>
          <li>Press the menu key (gear key or #)</li>
          <li>Press "1"</li>
          <li>Press the menu key</li>
          <li>Enter the master code above exactly</li>
          <li>Press the menu key</li>
          <li>
            The lock will confirm the master code is set with a beep, flash or by displaying
            the code back
          </li>
          <li>
            Hand the lock (press the checkmark, *, or follow the instructions in the manual)
          </li>
        </ol>
      )
    };
  }

  renderInactiveYaleCodes() {
    let codes = this.props.inactiveYaleCodes.map((code) => <li key={code}>{code}</li>);
    return <ol>{codes}</ol>;
  }

  getInclusionExclusionStartYale(status) {
    let keypadNumber;
    let inclusionExclusion;

    if (status === stages.INCLUSION_START || status === 'start') {
      keypadNumber = '1';
      inclusionExclusion = 'inclusion';
    } else if (status === stages.EXCLUSION_START) {
      keypadNumber = '3';
      inclusionExclusion = 'exclusion';
    } else {
      return null;
    }
    let masterCodeLi;
    if (this.props.yaleCode && this.props.inactiveYaleCodes.length > 0) {
      masterCodeLi = (
        <li>
          Enter the master code above. <br />
          You may also try the following codes:
          <br />
          {this.renderInactiveYaleCodes()}
        </li>
      );
    } else {
      masterCodeLi = <li>Enter the master code above.</li>;
    }

    return (
      <ol style={{ paddingLeft: '42px' }}>
        <li>Wake up the lock (if touchscreen use your palm, if push-button press any key)</li>
        {masterCodeLi}
        <li>Press the menu key (gear key or #)</li>
        <li>Press "7"</li>
        <li>Press the menu key</li>
        <li>Press "{keypadNumber}"</li>
        <li>Press the menu key</li>
        <li>The lock will confirm {inclusionExclusion} (a voice or three beeps)</li>
      </ol>
    );
  }

  getCorrectYaleMasterCode(status) {
    return this.props.yaleCode;
  }

  getYaleMasterCodeDiv(masterCode) {
    return (
      <div style={{ marginBottom: '24px' }}>
        <Typography variant={'h6'} style={{ marginTop: '24px', textAlign: 'center' }}>
          MASTER CODE
        </Typography>
        <Typography variant={'h3'} style={{ fontWeight: '300', textAlign: 'center' }}>
          {masterCode}
        </Typography>
      </div>
    );
  }

  getYale(status) {
    let masterCodeDiv;
    let masterCode = this.getCorrectYaleMasterCode(status);
    if (masterCode) {
      masterCodeDiv = this.getYaleMasterCodeDiv(masterCode);
    }

    let preStepText;
    let listOfSteps;
    if (status === 'start') {
      let displayData = this.getStartYale();
      preStepText = displayData.preStepText;
      listOfSteps = displayData.listOfSteps;
    } else {
      listOfSteps = this.getInclusionExclusionStartYale(status);
    }

    const preStep = preStepText && (
      <p style={{ textAlign: 'left', marginBottom: '8px', marginLeft: '22px' }}>
        <em>
          <strong>{preStepText}</strong>
        </em>
      </p>
    );

    const steps = listOfSteps && (
      <div style={{ marginRight: '24px' }}>
        <Typography
          variant={'h6'}
          style={{ textAlign: 'left', marginBottom: '8px', marginLeft: '22px' }}
        >
          STEPS
        </Typography>
        {preStep}
        {listOfSteps}
      </div>
    );

    return (
      <div style={{ marginBottom: '64px' }}>
        {masterCodeDiv}
        {steps}
      </div>
    );
  }

  goToExclusion = (e) => {
    e.preventDefault();
    this.props.goToExclusion();
  };

  goToInclusion = (e) => {
    e.preventDefault();
    this.props.goToInclusion();
  };

  openResetDialog = (e) => {
    e.preventDefault();
    this.props.openResetDialog();
  };

  getOLForYaleError() {
    if (this.props.exclusion) {
      return (
        <ol>
          <li className={this.classes.textBottomMargin}>
            Was there a problem with the master code? If so, you may try the following codes or
            factory reset the lock. <br />
            {this.renderInactiveYaleCodes()}
          </li>
          <li className={this.classes.textBottomMargin}>
            Did the device go into exclusion mode but still not exclude from the hub? If so,
            make sure the hub is online and try{' '}
            <span className={this.classes.clickableText} onClick={this.goToExclusion}>
              excluding
            </span>{' '}
            again up to 3 times.
          </li>
          <li className={this.classes.textBottomMargin}>
            Still not working? Try{' '}
            <span
              id={'resetController'}
              className={this.classes.clickableText}
              onClick={this.openResetDialog}
            >
              resetting the controller.
            </span>{' '}
            <em>
              Note: Resetting the controller will remove all of the devices you have added.
            </em>
          </li>
        </ol>
      );
    } else {
      return (
        <ol>
          <li className={this.classes.textBottomMargin}>
            Was there a problem with the master code? If so, you may try the following codes or
            factory reset the lock. <br />
            {this.renderInactiveYaleCodes()}
          </li>
          <li className={this.classes.textBottomMargin}>
            Did you run out of time to put the device in inclusion mode? If so,{' '}
            <span className={this.classes.clickableText} onClick={this.goToInclusion}>
              try again
            </span>
            .
          </li>
          <li className={this.classes.textBottomMargin}>
            Still not working? Try{' '}
            <span className={this.classes.clickableText} onClick={this.goToExclusion}>
              excluding
            </span>{' '}
            the device before you try adding it again.
          </li>
        </ol>
      );
    }
  }

  getYaleIncludeExcludeError() {
    const masterCodeDiv = this.getYaleMasterCodeDiv(this.getCorrectYaleMasterCode());
    const ol = this.getOLForYaleError();
    const action = this.props.exclusion ? 'exclude' : 'include';
    return (
      <div style={{ margin: '0px 24px 64px' }}>
        {masterCodeDiv}
        <p style={{ marginBottom: '24px' }}>
          The hub wasn’t able to {action} the new device. Try the following troubleshooting
          steps:
        </p>
        {ol}
      </div>
    );
  }

  getInclusionMessage(status) {
    const isYale = this.props.yaleLock === yale.IS_YALE;
    switch (status) {
      case stages.CHOOSE_DEVICE:
        return this.getChooseDevice(this.props.exclusion);
      case stages.INCLUSION_START:
        return isYale ? this.getYale(status) : '';
      case stages.EXCLUSION_START:
        return isYale ? this.getYale(status) : '';
      case 'start':
        return isYale ? this.getYale(status) : this.getStart();
      case 'exclude-start-error':
        return isYale ? this.getYaleIncludeExcludeError() : this.getExcludeStartError();
      case 'exclude-error':
        return isYale ? this.getYaleIncludeExcludeError() : this.getExcludeError();
      case 'start-error':
        return this.getStartError();
      case 'prep-error':
        return this.getPrepError();
      case 'include-error':
        return isYale ? this.getYaleIncludeExcludeError() : this.getIncludeError();
      case 'sensors-error':
        return this.getSensorsError();
      case 'sensors-failed':
        return this.getSensorsFailed();
      default:
        return '';
    }
  }

  render() {
    return this.getInclusionMessage(this.props.status);
  }
}

export default withLDConsumer()(withStyles(styles)(PairingMessage));
