import {
  ContactSensorModel,
  WaterSensorModel,
  MotionSensorModel,
  FenestrationAttrId
} from './deviceCapabilities';
import { ValueOf } from '../Types/utils';
import { BINARY_SENSOR_WATER, LOCK_CHANGE, MOTION } from './sensorConstants';
import { WorkTicket } from './salesforceConstants';

export const SENSOR_PLACEMENT = {
  Door: 'door',
  SlidingDoor: 'slidingDoor',
  Window: 'window',
  Motion: 'motion',
  Leak: 'leak'
} as const;
export type SensorPlacement = ValueOf<typeof SENSOR_PLACEMENT>;

export const CONTACT_SENSOR_PLACEMENTS = [
  SENSOR_PLACEMENT.Door,
  SENSOR_PLACEMENT.SlidingDoor,
  SENSOR_PLACEMENT.Window
];

export const contactSensorPlacement_Fenestration_Mapping = {
  [SENSOR_PLACEMENT.Door]: FenestrationAttrId.ConventionalDoor,
  [SENSOR_PLACEMENT.SlidingDoor]: FenestrationAttrId.SlidingDoor,
  [SENSOR_PLACEMENT.Window]: FenestrationAttrId.Window
} as const;

export const fenestration_ContactSensorPlacement_Mapping = {
  [FenestrationAttrId.ConventionalDoor]: SENSOR_PLACEMENT.Door,
  [FenestrationAttrId.SlidingDoor]: SENSOR_PLACEMENT.SlidingDoor,
  [FenestrationAttrId.Window]: SENSOR_PLACEMENT.Window
} as const;

export const sensorPlacement_WTSkuField_Mapping = {
  [SENSOR_PLACEMENT.Door]: WorkTicket.RECESSED_DOOR_SENSOR__R,
  [SENSOR_PLACEMENT.SlidingDoor]: WorkTicket.DOOR_WINDOW_SENSOR__R,
  [SENSOR_PLACEMENT.Window]: WorkTicket.DOOR_WINDOW_SENSOR__R
} as const;

export const sensorPlacement_DisplayName_Mapping = {
  [SENSOR_PLACEMENT.Door]: 'Door',
  [SENSOR_PLACEMENT.SlidingDoor]: 'Sliding Door',
  [SENSOR_PLACEMENT.Window]: 'Window',
  [SENSOR_PLACEMENT.Motion]: 'Motion',
  [SENSOR_PLACEMENT.Leak]: 'Leak'
} as const;

export const sensorPlacement_DefaultMetadataId_Mapping = {
  [SENSOR_PLACEMENT.Door]: ContactSensorModel.Default,
  [SENSOR_PLACEMENT.SlidingDoor]: ContactSensorModel.Default,
  [SENSOR_PLACEMENT.Window]: ContactSensorModel.Default,
  [SENSOR_PLACEMENT.Motion]: MotionSensorModel.Default,
  [SENSOR_PLACEMENT.Leak]: WaterSensorModel.Default
} as const;

export const sensorPlacement_SensorReading_Mapping = {
  [SENSOR_PLACEMENT.Door]: LOCK_CHANGE,
  [SENSOR_PLACEMENT.SlidingDoor]: LOCK_CHANGE,
  [SENSOR_PLACEMENT.Window]: LOCK_CHANGE,
  [SENSOR_PLACEMENT.Motion]: MOTION,
  [SENSOR_PLACEMENT.Leak]: BINARY_SENSOR_WATER
} as const;

// LockChange isn't here because it is one-to-many.
export const sensorReading_SensorPlacement_Mapping = {
  [MOTION]: SENSOR_PLACEMENT.Motion,
  [BINARY_SENSOR_WATER]: SENSOR_PLACEMENT.Leak
};
