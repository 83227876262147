import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme } from '@material-ui/core';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    banner: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    body: {
      marginTop: '20px'
    },
    heading: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center'
    },
    icon: {
      marginRight: '10px',
      overflow: 'visible'
    },
    title: {
      display: 'inline'
    }
  })
);

type Props = {
  action?: ReactElement;
  backgroundColor?: string;
  body?: ReactElement;
  className?: string;
  icon: ReactElement;
  subtitle?: ReactElement;
  title: ReactElement;
};

export function Banner(props: Props) {
  const classes = useStyles();

  const icon = React.cloneElement(props.icon, {
    className: `${props.icon.props.className} ${classes.icon}`
  });
  const title = React.cloneElement(props.title, {
    className: `${props.title.props.className} ${classes.title}`
  });
  const subtitle =
    props.subtitle &&
    React.cloneElement(props.subtitle, {
      className: `${props.subtitle.props.className} ${classes.title}`
    });

  return (
    <div
      className={`${classes.banner} ${props.className}`}
      style={{
        backgroundColor: props.backgroundColor || '#FFF'
      }}
    >
      <div className={classes.heading}>
        {icon}
        <div>
          {title} {subtitle}
        </div>
      </div>
      {props.body && <div className={classes.body}>{props.body}</div>}
      {props.action && <div className={classes.action}>{props.action}</div>}
    </div>
  );
}
