import * as React from 'react';
import { ErrorBanner } from '../../BannerTemplates/ErrorBanner';
import { Button, Typography } from '@material-ui/core';

type Props = {
  deviceName: string;
  onActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function PairingIssueBanner(props: Props) {
  const name = props.deviceName ? `${props.deviceName} lock` : 'Lock';

  return (
    <ErrorBanner
      title={`${name} pairing issue.`}
      body={<Typography>This lock needs to be excluded and re-included.</Typography>}
      action={
        <Button variant="contained" color="secondary" onClick={props.onActionClick}>
          OK
        </Button>
      }
    />
  );
}
