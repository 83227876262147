import client from './client';
import * as routes from '../../Login/login_apiRoutes';
import * as Sentry from '@sentry/browser';

export default class AuthService {
  login(email, password) {
    return client
      .post(routes.LOGIN, {
        email: email,
        password: password,
        applicationId: process.env.REACT_APP_APPLICATION_ID
      })
      .then(this._checkStatus)
      .then((res) => {
        this.setToken(res.data.token);
        this.setUser(res.data.user);
        return Promise.resolve(res);
      });
  }

  loggedIn() {
    const token = this.getToken();
    return !!token;
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  removeUser() {
    if (window.__insp) {
      window.__insp.push(['identify', null]);
    }
    localStorage.removeItem('user');
    Sentry.setUser({
      id: undefined
    });
  }

  logout() {
    return client
      .post(routes.LOGOUT, {})
      .then(this._checkStatus)
      .then((res) => {
        this.removeToken();
        this.removeUser();
        return Promise.resolve(res);
      })
      .catch((error) => {
        this.removeToken();
        this.removeUser();
        return Promise.resolve(error);
      });
  }

  setUser(user) {
    if (window.__insp) {
      window.__insp.push(['identify', '' + user.uid]);
      window.__insp.push([
        'tagSession',
        {
          displayName: user.firstName + ' ' + user.lastName,
          email: user.email
        }
      ]);
    }
    localStorage.setItem('user', JSON.stringify(user));
    Sentry.setUser({
      id: user.uid
    });
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      Sentry.captureException(error);
      throw error;
    }
  }
}
