import React from 'react';
import { ChatContext } from './chatContext';

export default function withChatContext(Component) {
  return function chatContextHOC(props) {
    return (
      <ChatContext.Consumer>
        {(context) => <Component {...props} context={context} />}
      </ChatContext.Consumer>
    );
  };
}
