import * as dweloDeviceConstants from './deviceConstants';

/*
 * Please use salesforceConstants_v2.ts if an alternative is available there.
 * The contents in salesforceConstants.js should be eventually moved over to _v2
 * in the form of enums (or something else, if at all) when it is reasonable to do so as we gradually move to more typescript.
 */

export const INSTALL = 'install';

export const Unit = {
  LOCK__C: 'Lock__c',
  LOCK_QTY__C: 'Lock_QTY__c',
  PIMARY_SWITCH_SKU__C: 'Pimary_Switch_SKU__c',
  PRIMARY_SWITCHES_QTY__C: 'Primary_Switches_QTY__c',
  THERMOSTAT__C: 'Thermostat__c',
  THERMOSTAT_QTY__C: 'Thermostat_QTY__c',
  OUTLET__C: 'Outlet__c',
  OUTLET_QTY__C: 'Outlet_QTY__c',
  VOICE_CONTROL__C: 'Voice_Control__c',
  VOICE_CONTROL_QTY__C: 'Voice_Control_QTY__c',
  PRIVACY_LOCK__C: 'Privacy_Lock__c',
  PRIVACY_LOCK_QTY__C: 'Privacy_Lock_QTY__c',
  ADD_ON_SWITCH_QTY__C: 'Add_on_Switch_QTY__c',
  ADD_ON_SWITCH_SKU__C: 'Add_on_Switch_SKU__c',
  HUB_LOCATION__C: 'Hub_Location__c',
  LEAK_SENSOR_QTY__C: 'Leak_Sensor_QTY__c',
  LEAK_SENSOR_SKU__C: 'Leak_Sensor_SKU__c',
  DOOR_WINDOW_SENSOR__C: 'Door_Window_Sensor__c',
  DOOR_WINDOW_SENSOR_QTY__C: 'Door_Window_Sensor_Qty__c',
  MOTION_SENSOR__C: 'Motion_Sensor__c',
  MOTION_SENSOR_QTY__C: 'Motion_Sensor_Qty__c',
  RECESSED_DOOR_SENSOR__C: 'Recessed_Door_Sensor__c',
  RECESSED_DOOR_SENSOR_QTY__C: 'Recessed_Door_Sensor_Qty__c',
  TRANSFORMER_SKU__C: 'Transformer_SKU__c',
  TRANSFORMER_QTY__C: 'Transformer_Qty__c',
  CHIME_SKU__C: 'Chime_SKU__c',
  CHIME_QTY__C: 'Chime_Qty__c',
  DOORBELL_SKU__C: 'Doorbell_SKU__c',
  DOORBELL_QTY__C: 'Doorbell_Qty__c'
};

export const WorkTicket = {
  STATUS__C: 'Status__c',
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete',
  INCOMPLETE_REASON__C: 'Incomplete_reason__c',
  NOTES__C: 'Notes__c',
  BATTERIES_REPLACED__C: 'Batteries_Replaced__c',
  PINS_SYNCED__C: 'Pins_Synced__c',
  MASTER_CODE_SYNCED__C: 'Master_Code_Synced__c',
  LOCK_THROW_STATUS__C: 'Lock_Throw_Status__c',
  LOCK_THROW_DETAILS__C: 'Lock_Throw_Details__c',
  DID_YOU_LEAVE_DEVICES_ON_SITE__C: 'Did_you_leave_devices_on_site__c',
  DID_YOU_TAKE_DEVICES_FROM_THE_SITE__C: 'Did_you_take_devices_from_the_site__c',
  SUBMITTED_STATUSES: ['Complete', 'Incomplete'],
  LEAK_SENSOR__R: 'Leak_Sensor__r',
  RECESSED_DOOR_SENSOR__R: 'Recessed_Door_Sensor__r',
  DOOR_WINDOW_SENSOR__R: 'Door_Window_Sensor__r',
  MOTION_SENSOR__R: 'Motion_Sensor__r'
};

// Salesforce device types based on the Sku object.
// Please use SFDeviceType (in the _v2 file) instead of this
export const DeviceTypes = {
  LOCK: 'lock',
  OUTLET: 'outlet',
  THERMOSTAT: 'thermostat',
  SENSOR: 'sensor',
  PRIVACY_LOCK: 'privacy lock',
  PRIMARY_SWITCH: 'primary switch',
  ADD_ON_SWITCH: 'add-on switch',
  DIMMER_SWITCH: 'dimmer switch',
  VOICE_CONTROL: 'voice control',
  DOORBELL: 'doorbell'
};

// Variable names shared between dwelo cloud and the installer app.
export const SharedVars = {
  // Fields sent TO dwelo cloud
  UNIT_FIELDS: 'unitFields',
  WORK_TICKET_FIELDS: 'workTicketFields',
  // Fields received FROM dwelo cloud
  SALESFORCE_INFO: 'salesforceInfo',
  HUB_LOCATION: 'hubLocation', // unit location
  DEFAULT_HUB_LOCATION: 'defaultHubLocation', // community default location
  MULTIPLE_COMMUNITIES_AVAILABLE: 'multipleCommunitiesAvailable',
  LIGHTING_LAYOUT_LINK: 'lightingLayoutLink',
  INSTALL_PAGE_LINK: 'installPageLink',
  PROPERTY_MANAGER: 'propertyManager',
  PHONE_NUMBER: 'phoneNumber',
  INSTALLER_PREVIEW_DATA: 'installerPreviewData',
  ID: 'id',
  NAME: 'name',
  MODEL: 'model',
  MODEL_NUMBER: 'modelNumber',
  QUANTITY: 'quantity',
  DEVICE_TYPE: 'deviceType',
  ARRIVAL_WINDOW: 'arrivalWindow',
  SPECIAL_HANDLING: 'specialHandling',
  PTE_NOTES: 'pteNotes',
  PTE: 'pte',
  NOTES: 'notes',
  SERVICE: 'service',
  SCHEDULED_DATE: 'scheduledDate',
  WORK_TICKET_STATUS: 'workTicketStatus',
  WORK_TYPE: 'workType',
  DEVICES_TO_BE_INSTALLED: 'devicesToBeInstalled',
  INCOMPLETE_REASONS: 'incompleteReasons',
  PRIVACY_LOCK: 'privacyLock',
  PRIMARY_SWITCH: 'primarySwitch',
  ADD_ON_SWITCH: 'addOnSwitch',
  DIMMER_SWITCH: 'dimmerSwitch',
  VOICE_CONTROL: 'voiceControl',
  LEAK_SENSOR: 'leakSensor',
  LOCK: 'lock',
  OUTLET: 'outlet',
  THERMOSTAT: 'thermostat'
};

export const SalesforceDweloMappings = {
  [DeviceTypes.LOCK]: dweloDeviceConstants.LOCK,
  [DeviceTypes.THERMOSTAT]: dweloDeviceConstants.THERMOSTAT,
  [DeviceTypes.PRIMARY_SWITCH]: dweloDeviceConstants.SWITCH,
  [DeviceTypes.DIMMER_SWITCH]: dweloDeviceConstants.DIMMER
};

export const Salesforce_TodoList_DeviceTypeMappings = {
  [DeviceTypes.LOCK]: dweloDeviceConstants.LOCK,
  [DeviceTypes.THERMOSTAT]: dweloDeviceConstants.THERMOSTAT,
  [DeviceTypes.PRIMARY_SWITCH]: dweloDeviceConstants.LIGHT,
  [DeviceTypes.DIMMER_SWITCH]: dweloDeviceConstants.LIGHT
};

export const DEFAULT_PRIVACY_LOCK_SKU = 'a1J1M00000CE2NGUA1';
export const DEFAULT_ADD_ON_SWITCH_SKU = 'a1J1M00000COjrlUAD';

Object.freeze(Unit);
Object.freeze(WorkTicket);
Object.freeze(SharedVars);
Object.freeze(DeviceTypes);
Object.freeze(SalesforceDweloMappings);
