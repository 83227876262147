import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import AlertDialog from '../Popups/AlertDialog';
import { hubRebootDateStorage, hubRestartDateStorage } from '../utils/storageInterface';
import RemoveHubDialog from '../Popups/RemoveHubDialog/';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import { CUSTOMER_SUPPORT_USER } from '../Constants/launchDarklyConstants';

import * as deviceConstants from '../Constants/deviceConstants';
import * as routes from './header_apiRoutes';
import client from '../utils/client';

class UnitMenu extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      open: false,
      alertFail: false,
      alertReboot: false,
      alertReset: false,
      alertPermissions: false,
      alertRemoveHub: false,
      alertInclude: false,
      alertExclude: false,
      alertRestart: false,
      alertRemoveNest: false,
      inputValue: '',
      error: '',
      loading: false
    };
  }

  closeDialog = (_) => {
    this.setState({
      alertRestart: false,
      alertReboot: false,
      alertReset: false,
      alertPermissions: false,
      alertRemoveHub: false,
      alertInclude: false,
      alertExclude: false,
      alertFail: false,
      alertRemoveNest: false
    });
  };

  navigateToPage = (path) => {
    if (window.location.pathname === path) {
      window.location.reload();
    } else {
      this.props.history.push(path);
    }
  };

  resetMessages = () => {
    this.setState({
      inputValue: '',
      error: ''
    });
  };

  closeAll() {
    this.closeDialog();
    this.resetMessages();
    this.setState({
      open: false
    });
  }

  getActionData(action) {
    return {
      action: action,
      client: 'concierge',
      userId: this.props.user.uid
    };
  }
  getStatusData() {
    return {
      client: 'concierge',
      userId: this.props.user.uid
    };
  }

  genericCatchFunction = (error, messagestring) => {
    this.setState({ loading: false });
    Sentry.captureException(error);
    try {
      if (error.response.status === 401) {
        this.props.history.push('/login');
      } else {
        this.setState({
          error: error.response.data[messagestring]
        });
      }
    } catch (err) {
      this.setState({
        error: 'Something went wrong. Please contact support.'
      });
    }
  };
  confirmRestart = () => {
    this.setState({ alertRestart: true });
  };
  confirmReboot = () => {
    this.setState({ alertReboot: true });
  };
  confirmReset = () => {
    this.setState({ alertReset: true });
  };
  confirmFail = () => {
    this.setState({ alertFail: true });
  };
  confirmRemoveNest = () => {
    this.setState({ alertRemoveNest: true });
  };

  failDevice = () => {
    let data = {
      client: 'concierge',
      userId: this.props.user.uid,
      nodeId: this.props.device.localId
    };
    this.setState({ loading: true });
    client
      .post(routes.FAIL_DEVICE(this.props.unit.hub_serial), data)
      .then(() => {
        this.setState({ loading: false });
        this.props.history.push(
          `/community/${this.props.unit.communityId}/unit/${this.props.unit.uid}`
        );
        return Promise.resolve();
      })
      .catch((error) => {
        this.genericCatchFunction(error, 'message');
      });
    this.closeAll();
  };

  repairNetwork = () => {
    //let data = this.getStatusData();
    //client.post(routes.HEAL_NETWORK(this.props.unit.hub_serial), data);
    this.closeAll();
  };

  refreshStatus = () => {
    let data = this.getStatusData();
    client.post(routes.REFRESH_STATUS(this.props.device.uid), data);
    this.closeAll();
  };

  rebootHub = () => {
    let data = {};
    this.setState({ loading: true });
    client
      .post(routes.REBOOT_HUB(this.props.unit.hub_serial), data)
      .then(() => {
        this.setState({ loading: false });
        hubRebootDateStorage(this.props.unit.hub_serial).set(new Date());
        this.closeAll();
        window.location.reload();
        return Promise.resolve();
      })
      .catch((error) => {
        this.genericCatchFunction(error, 'message');
      });
  };

  restartHub = () => {
    let data = {};
    this.setState({ loading: true });
    client
      .post(routes.RESTART_HUB(this.props.unit.hub_serial), data)
      .then(() => {
        this.setState({ loading: false });
        hubRestartDateStorage(this.props.unit.hub_serial).set(new Date());
        this.closeAll();
        window.location.reload();
        return Promise.resolve();
      })
      .catch((error) => {
        this.genericCatchFunction(error, 'message');
      });
  };

  resetController = () => {
    let data = this.getStatusData();
    client.post(routes.RESET_CONTROLLER(this.props.unit.hub_serial), data);
    this.closeAll();
  };

  confirmTransferPermissions = () => {
    this.setState({ alertPermissions: true });
  };

  confirmRemoveHub = () => {
    this.setState({ alertRemoveHub: true });
  };

  removeHub = (e) => {
    e.preventDefault();
    let data = {
      gateway_id: this.props.unit.gatewayId,
      address_id: this.props.unit.uid
    };
    this.setState({ loading: true });
    client
      .post(routes.REMOVE_HUB, data)
      .then(() => {
        this.setState({ loading: false });
        this.closeAll();
        window.location.reload();
        return Promise.resolve();
      })
      .catch((error) => {
        this.genericCatchFunction(error, 'custom');
      });
  };

  transferPermissions = (e) => {
    e.preventDefault();
    let data = {
      from_gateway_id: this.state.inputValue,
      to_gateway_id: this.props.unit.gatewayId,
      address_id: this.props.unit.uid
    };
    this.setState({ loading: true });
    client
      .post(routes.TRANSFER_PERMISSIONS, data)
      .then(() => {
        this.setState({ loading: false });
        this.closeAll();
        return Promise.resolve();
      })
      .catch((error) => {
        this.genericCatchFunction(error, 'custom');
      });
  };

  removeNest = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    client
      .delete(
        routes.DELETE_NEST_DEVICE(this.props.device.gatewayId, this.props.device.localId)
      )
      .then((res) => {
        this.setState({ loading: false });
        this.closeAll();
        this.navigateToPage(
          `/community/${this.props.unit.communityId}/unit/${this.props.unit.uid}`
        );
        return Promise.resolve();
      })
      .catch((error) => {
        this.genericCatchFunction(error, 'message');
      });
  };

  exclude = () => {
    let data = this.getActionData('start');
    client.post(routes.EXCLUDE(this.props.unit.hub_serial), data);
    this.setState({ alertExclude: true });
  };
  include = () => {
    let data = this.getActionData('start');
    client.post(routes.INCLUDE(this.props.unit.hub_serial), data);
    this.setState({ alertInclude: true });
  };

  abortInclusion = () => {
    let data = this.getActionData('abort');
    client.post(routes.INCLUDE(this.props.unit.hub_serial), data);
    this.closeAll();
  };

  abortExclusion = () => {
    let data = this.getActionData('abort');
    client.post(routes.EXCLUDE(this.props.unit.hub_serial), data);
    this.closeAll();
  };

  resendPins = () => {
    client.post(routes.RESEND_PINS(this.props.device.gatewayId));
    this.closeAll();
  };

  handleToggle = () => {
    this.setState((state) => ({
      open: !state.open
    }));
    this.closeDialog();
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleInputChange(data) {
    this.setState({ inputValue: data });
  }

  handleCommunityInfoClick = () => {
    this.setState({ open: false });
    this.props.handleCommunityInfoClick();
  };

  handleUnitInfoClick = () => {
    this.setState({ open: false });
    this.props.handleUnitInfoClick();
  };

  getMenu() {
    if (this.props.menu === 0) {
      let communityInfo = (
        <MenuItem onClick={this.handleCommunityInfoClick}>Community Info</MenuItem>
      );

      return (
        <MenuList>
          <MenuItem onClick={this.repairNetwork}>Repair Z-wave network</MenuItem>
          {communityInfo}
          <MenuItem onClick={this.handleUnitInfoClick}>Unit Info</MenuItem>
        </MenuList>
      );
    } else if (this.props.menu === 2) {
      return (
        <MenuList>
          <MenuItem onClick={this.confirmRestart}>Restart hub</MenuItem>
          <MenuItem onClick={this.confirmReboot}>Reboot hub</MenuItem>
          <MenuItem onClick={this.confirmReset}>Reset controller</MenuItem>
          <MenuItem onClick={this.confirmRemoveHub}>Remove hub</MenuItem>
          <MenuItem onClick={this.confirmTransferPermissions}>Transfer permissions</MenuItem>
        </MenuList>
      );
    } else if (this.props.menu === deviceConstants.LOCK) {
      return (
        <MenuList>
          <MenuItem onClick={this.resendPins}>Resend pins</MenuItem>
          <MenuItem onClick={this.refreshStatus}>Refresh status</MenuItem>
        </MenuList>
      );
    } else {
      return (
        <MenuList>
          <MenuItem onClick={this.refreshStatus}>Refresh status</MenuItem>
        </MenuList>
      );
    }
  }

  render() {
    const { [CUSTOMER_SUPPORT_USER]: isSupport } = this.props.flags;
    const { open } = this.state;
    let menu = this.getMenu();
    return (
      <div>
        <IconButton
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          aria-label="More"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <MoreVertIcon style={{ color: 'white' }} />
        </IconButton>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal={false}
          placement="left-start"
          style={{ zIndex: '2000' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>{menu}</ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <AlertDialog
          alertdialog={this.state.alertRemoveNest}
          onClick={this.removeNest}
          onClose={this.closeDialog}
          circular={true}
          error={this.state.error}
          loading={this.state.loading}
          disableClick={true}
          acceptText="Remove Nest"
          rejectText="Cancel"
          text="This action will remove the Nest device"
          title="Remove Nest device?"
        />
        <AlertDialog
          alertdialog={this.state.alertRestart}
          onClick={this.restartHub}
          onClose={this.closeDialog}
          error={this.state.error}
          loading={this.state.loading}
          linear={true}
          totalTime={3}
          disableClick={true}
          acceptText="Restart"
          rejectText="Cancel"
          text="Restart will restart the processes on the hub without turning it off and on. This will take about 30 seconds. Do not unplug the hub while it is starting."
          title="Restart Hub?"
        />
        <AlertDialog
          alertdialog={this.state.alertReboot}
          onClick={this.rebootHub}
          onClose={this.closeDialog}
          error={this.state.error}
          loading={this.state.loading}
          linear={true}
          totalTime={3}
          disableClick={true}
          acceptText="Reboot"
          rejectText="Cancel"
          text="Reboot will take about four minutes. Do not unplug the hub while it is rebooting."
          title="Reboot Hub?"
        />
        <AlertDialog
          alertdialog={this.state.alertFail}
          onClick={this.failDevice}
          onClose={this.closeDialog}
          error={this.state.error}
          loading={this.state.loading}
          circular={true}
          disableClick={true}
          text="This action will remove devices only if they are non-responsive. Please allow up to 2 minutes for the devices list to update."
          title="Fail Device?"
          acceptText="Fail Device"
          rejectText="Cancel"
        />
        <AlertDialog
          alertdialog={this.state.alertReset}
          onClick={this.resetController}
          onClose={this.closeDialog}
          text="Only perform this action if no Z-wave devices are included."
          title="Reset Controller?"
        />
        <AlertDialog
          alertdialog={this.state.alertInclude}
          zwave={true}
          text="Place your Z-wave device into inclusion mode now. Please wait 2 minutes for the device to appear in the device list, then click done."
          title="Hub in Inclusion Mode"
          onClose={this.closeDialog}
          disableClick={true}
          onClick={this.abortInclusion}
        />
        <AlertDialog
          alertdialog={this.state.alertExclude}
          zwave={true}
          text="Place your Z-wave device into exclusion mode now. Please wait 2 minutes for the device to disappear from the device list, then click done."
          title="Hub in Exclusion Mode"
          onClose={this.closeDialog}
          disableClick={true}
          onClick={this.abortExclusion}
        />
        <AlertDialog
          alertdialog={this.state.alertPermissions}
          onClick={this.transferPermissions}
          onClose={this.closeDialog}
          error={this.state.error}
          loading={this.state.loading}
          circular={true}
          disableClick={true}
          text="Only perform this action if you are done including devices, and have not previously transferred permissions. Please enter the old gateway id of the replaced hub."
          inputEnabled={true}
          inputLabel="Old Gateway ID"
          inputValue={this.handleInputChange}
          title="Transfer Permissions?"
        />
        {isSupport ? (
          <RemoveHubDialog
            isOpen={this.state.alertRemoveHub}
            loading={this.state.loading}
            unit={this.props.unit}
            handleInputChange={this.handleInputChange}
            handleClose={this.closeDialog}
            removeHub={this.removeHub}
            apiErrorMessage={this.state.error}
          />
        ) : (
          <AlertDialog
            alertdialog={this.state.alertRemoveHub}
            onClick={this.removeHub}
            onClose={this.closeDialog}
            error={this.state.error}
            loading={this.state.loading}
            circular={true}
            disableClick={true}
            copy={true}
            copyText={this.props.unit.gatewayId}
            text="This will remove the hub and all included devices from the unit. This action cannot be undone. Please copy the old gateway id if you wish to proceed - this will be necessary to transfer permissions later."
            title="Remove hub?"
          />
        )}
      </div>
    );
  }
}

export default withLDConsumer()(UnitMenu);
