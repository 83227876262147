type StartPairingProps = {
  pairingTextClass: string;
  splitStyleHeight: string;
};

export const StartPairingMessage = (props: StartPairingProps) => {
  return (
    <div>
      <p className={props.pairingTextClass}>You are about to include a device.</p>
      <p className={props.pairingTextClass}>
        When you select continue, the hub will enter inclusion mode. You will then have 90
        seconds to put the device in inclusion mode.
      </p>
      <p className={props.pairingTextClass}>
        <strong>
          Note: You may need to include some devices twice in order for them to work properly.
        </strong>
      </p>
      <div style={{ height: props.splitStyleHeight }} />
    </div>
  );
};
