import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import * as Sentry from '@sentry/browser';
import UnitDetail from './components/UnitDetail';
import UnitDetail_v2 from './components/UnitDetail_v2';

import * as routes from './unitdetail_apiRoutes';
import { INSTALL, SharedVars } from '../Common/Constants/salesforceConstants';
import withSpinner from '../Common/utils/withSpinner';
import client from '../Common/utils/client';
import withAuth from '../Common/utils/withAuth';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import {
  DOOR_CODE_RELIABILITY,
  UNIT_VERSION_2
} from '../Common/Constants/launchDarklyConstants';
import { AxiosPromise, AxiosResponse } from 'axios';
import {
  Community,
  DeviceSensor,
  Unit,
  UpdateStatus,
  V4_Address,
  V4_Hub_Status,
  V4_SF_Unit_Configuration,
  V4_Update_Status,
  V4_WorkTicket
} from '../Common/Types/cloudApi';
import {
  hubRebootDateStorage,
  hubRestartDateStorage,
  unitTodoMetaStorage
} from '../Common/utils/storageInterface';
import { RESTART_MAX_DURATION_SECS } from '../Common/Constants/installerAppConstants';
import {
  getDiffSecsBetweenNowAndDateString,
  wasHubRebootMaxDurationExceeded,
  assumeHubRebootedDueToApiCall
} from './common/utils';
import { getCodeSyncStatusByAddress } from '../Common/CodeSyncing/apiService/api';
import { GetAddressSyncStatusResponse } from '../Common/CodeSyncing/apiService/types';
import { makeGetStatusByAddressRoute } from '../Common/CodeSyncing/apiService/routes';
import { INSTALLER } from '../Common/Constants/userConstants';
import {
  captureExceptionIfNot424,
  handle401ErrorRedirect,
  handleNon401ErrorRedirect,
  is401Error
} from '../Common/utils/errorHandling';

type InitialPromises = [AxiosPromise<V4_Address>, AxiosPromise<V4_WorkTicket>];
type InitialResponses = [AxiosResponse<V4_Address>, AxiosResponse<V4_WorkTicket>];

type Params = {
  communityId: string;
  unitId: string;
};

type State = {
  loading: boolean;
  devices: Array<DeviceSensor>;
  unit: Unit;
  useUnitV2: boolean;
  community: Partial<Community>;
  updating: UpdateStatus['update_pending'];
  rebooting: boolean;
  restarting: boolean;
  initial_status: V4_Update_Status | null;
  hubStatus: V4_Hub_Status | null;
  sfConfiguration: V4_SF_Unit_Configuration;
  wtData: {
    workTicketAvailable: boolean;
  } & Pick<V4_WorkTicket, 'devicesToBeInstalled' | 'incompleteReasons'>;
  codeSyncingData: GetAddressSyncStatusResponse['syncs'];
};

type Props = RouteComponentProps<Params> & {
  flags: LDFlagSet;
};

const UnitDetailWithSpinner = withSpinner(UnitDetail);
const UnitDetailV2WithSpinner = withSpinner(UnitDetail_v2);

class UnitDetailPage extends Component<Props, State> {
  state: State = {
    loading: true,
    devices: [],
    unit: {
      id: null,
      uid: 0,
      unit: '',
      communityId: 0,
      communityName: '',
      gatewayId: 0,
      permission: false,
      hub_serial: '',
      residents: [],
      role: null
    },
    useUnitV2: false,
    community: {},
    updating: false,
    rebooting: false,
    restarting: false,
    initial_status: null,
    hubStatus: null,
    sfConfiguration: {
      unitConfigurations: []
    },
    wtData: {
      // Work Ticket Data
      devicesToBeInstalled: [],
      incompleteReasons: [],
      workTicketAvailable: false
    },
    codeSyncingData: []
  };

  componentDidMount() {
    this.performApiCalls()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        if (is401Error(error)) {
          handle401ErrorRedirect(error, this.props.history);
        } else {
          handleNon401ErrorRedirect(error, this.props.history);
        }
      });
  }

  /**
   * Handles all necessary API calls (in two sets) prior to accessing a unit.
   * The first set will always occur.
   * The second may not happen and depends on the first.
   */
  async performApiCalls() {
    // First set of api calls
    const initialResponses: InitialResponses = await Promise.all(this.getInitialPromises());
    if (!this.validateUnitRoute(initialResponses)) {
      return;
    }
    this.updateStateWithResponses(initialResponses);
    const hubSerial: Unit['hub_serial'] = initialResponses[0].data.results[0].hub_serial;
    if (hubSerial) {
      // Last set of api calls
      const role = initialResponses[0].data.results[0].role;
      const remainingResponses = await Promise.all(this.getRemainingPromises(hubSerial, role));
      this.updateStateWithResponses(remainingResponses);
    }
  }

  /**
   * Checks what the correct unit route should be (so either "unit" or "unit_v2"),
   * and updates the current unit route if its incorrect.
   * @param initialResponses
   */
  validateUnitRoute(initialResponses: InitialResponses): boolean {
    const communityId = this.props.match.params.communityId;
    const unitId = this.props.match.params.unitId;
    const unitData = initialResponses[0].data.results;
    const usingUnitV2Route = this.props.match.path.includes('unit_v2');

    if (unitData.length === 0 || unitData[0]?.communityId !== Number(communityId)) {
      this.props.history.push('/');
      return false;
    }

    const isInstall = unitData[0].installerPreviewData?.workType.includes(INSTALL);

    if (isInstall && this.props.flags[UNIT_VERSION_2]) {
      this.setState({ useUnitV2: true });
      if (!usingUnitV2Route) {
        this.props.history.push(`/community/${communityId}/unit_v2/${unitId}`);
      }
    } else if (usingUnitV2Route) {
      this.props.history.push(`/community/${communityId}/unit/${unitId}`);
    }
    return true;
  }

  /**
   * Constructs and returns an object from an API response
   * to be used as an argument for updating state
   * @param response
   */
  getStateFromResponse(response: AxiosResponse<any>) {
    const communityId = this.props.match.params.communityId;
    const unitId = this.props.match.params.unitId;
    // Bypass inconsistency with axios-mock-adapter and axios itself.
    // Hasn't been fixed yet https://github.com/ctimmerm/axios-mock-adapter/pull/264
    const responseURL =
      // @ts-ignore
      response.isAxiosError && response.response?.request?.responseURL
        ? // @ts-ignore axios-mock-adapter implementation
          response.response.request.responseURL
        : // axios implementation
          response.request.responseURL;

    const route = responseURL.replace(client.defaults.baseURL, '');

    switch (route) {
      case routes.ADDRESS(unitId): {
        return { unit: response.data.results[0] };
      }
      case routes.COMMUNITY(communityId): {
        return { community: response.data.results[0] };
      }
      case routes.COMMUNITY_AND_UNIT_CONFIGURATIONS(communityId, unitId): {
        return { sfConfiguration: response.data };
      }
      case routes.SENSOR(this.state.unit.hub_serial): {
        return { devices: response.data.results };
      }
      case routes.HUB_STATUS(this.state.unit.hub_serial): {
        return { hubStatus: response.data };
      }
      case routes.GET_WORK_TICKET_DATA(unitId): {
        const {
          [SharedVars.DEVICES_TO_BE_INSTALLED]: devicesToBeInstalled,
          [SharedVars.INCOMPLETE_REASONS]: incompleteReasons,
          [SharedVars.WORK_TICKET_STATUS]: workTicketStatus
        } = response.data;
        return {
          wtData: {
            devicesToBeInstalled: devicesToBeInstalled ? devicesToBeInstalled : [],
            incompleteReasons: incompleteReasons ? incompleteReasons : [],
            workTicketAvailable: !!workTicketStatus
          }
        };
      }
      case routes.UPDATE_STATUS(this.state.unit.hub_serial): {
        if (response && response.status === 200) {
          let status = response.data.result;
          return {
            updating: status ? status.update_pending : false,
            initial_status: status
          };
        } else {
          return null;
        }
      }
      case makeGetStatusByAddressRoute(unitId): {
        return {
          codeSyncingData: response?.status === 200 ? response.data.syncs : []
        };
      }
    }

    return null;
  }

  /**
   * Updates state by looping through each response
   * and retrieving the respective state object from getStateFromResponse()
   */
  updateStateWithResponses(responses: Array<AxiosResponse<any>>) {
    let runningState: Partial<State> = {};
    responses.forEach((response) => {
      let state = this.getStateFromResponse(response);
      if (state) {
        runningState = Object.assign(runningState, state);
      }
    });
    this.removeUnitTodoMetaForDifferentUnit(runningState);
    this.insertRebootingState(runningState);
    this.insertRestartingState(runningState);
    this.setState(runningState as State);
  }

  insertRebootingState(runningState: Partial<State>) {
    if (!runningState.hubStatus?.hub_serial_number) {
      return;
    }
    const dateStorage = hubRebootDateStorage(runningState.hubStatus.hub_serial_number);
    let storedRebootString: string | null;
    try {
      storedRebootString = dateStorage.get();
    } catch (e) {
      // Our "new" way of storing _reboot or _restart key-pairs involves passing our Date object
      // into JSON.stringify(). Since we used to store a Date without JSON.stringify, this new feature
      // would cause an exception for units that have an existing _reboot or _restart string stored
      // from when we used old way without stringify (especially in QA), such as localStorage.setItem(new Date()).
      dateStorage.remove();
      return;
    }
    if (!storedRebootString) {
      return;
    }
    const maxDurationExceeded = wasHubRebootMaxDurationExceeded(
      this.state.unit.uid,
      runningState.hubStatus.hub_serial_number,
      storedRebootString
    );
    if (runningState.hubStatus?.boot_time) {
      const apiRebootCompleted = assumeHubRebootedDueToApiCall(
        runningState.hubStatus.boot_time,
        runningState.hubStatus.hub_serial_number,
        storedRebootString
      );
      // Not doing dataStorage.remove(), similar to what insertRestartingState() does,
      // since another component down the tree still needs this and will remove it.
      runningState.rebooting = !maxDurationExceeded && !apiRebootCompleted;
    } else {
      runningState.rebooting = !maxDurationExceeded;
    }
  }

  insertRestartingState(runningState: Partial<State>) {
    if (!runningState.hubStatus?.hub_serial_number) {
      return;
    }
    const dateStorage = hubRestartDateStorage(runningState.hubStatus.hub_serial_number);
    let storedRestartString: string | null;
    try {
      storedRestartString = dateStorage.get();
    } catch (e) {
      dateStorage.remove();
      return;
    }
    if (!storedRestartString) {
      return;
    }
    if (RESTART_MAX_DURATION_SECS > getDiffSecsBetweenNowAndDateString(storedRestartString)) {
      runningState.restarting = true;
    } else {
      dateStorage.remove();
    }
  }

  removeUnitTodoMetaForDifferentUnit(runningState: Partial<State>) {
    if (!runningState.unit) {
      return;
    }
    const unitTodoMeta = unitTodoMetaStorage.get();
    if (unitTodoMeta && unitTodoMeta.addressId !== runningState.unit.uid) {
      unitTodoMetaStorage.remove();
    }
  }

  getInitialPromises(): InitialPromises {
    const unitId = this.props.match.params.unitId;
    return [
      client.get(routes.ADDRESS(unitId)),
      client.get(routes.GET_WORK_TICKET_DATA(unitId))
    ];
  }

  getRemainingPromises(hubSerial: string, role: Unit['role']): Promise<AxiosResponse>[] {
    const communityId = this.props.match.params.communityId;
    const unitId = this.props.match.params.unitId;
    const requests = [
      client.get(routes.COMMUNITY(communityId)),
      // Its normal for update_status to give 424 errors if hub is not updating.
      // We don't care about the error itself as long as it doesn't cause an exception on
      // Promise.all(), so we need to catch it here.
      client.get(routes.UPDATE_STATUS(hubSerial)).catch((error) => {
        captureExceptionIfNot424(error);
        return error;
      }),
      client.get(routes.SENSOR(hubSerial)),
      client.get(routes.HUB_STATUS(hubSerial)),
      client
        .get(routes.COMMUNITY_AND_UNIT_CONFIGURATIONS(communityId, unitId))
        .catch((err) => {
          // Only applicable for installers, and even then it may or may not matter for their scenario.
          if (role === INSTALLER) {
            Sentry.captureException(err, { level: Sentry.Severity.Warning });
          }
          return err;
        })
    ];
    if (this.props.flags[DOOR_CODE_RELIABILITY]) {
      requests.push(
        getCodeSyncStatusByAddress(unitId).catch((error) => {
          Sentry.captureException(error);
          return error;
        })
      );
    }
    return requests;
  }

  render() {
    let UnitDetailComponent;
    if (this.state.useUnitV2) {
      UnitDetailComponent = UnitDetailV2WithSpinner;
    } else {
      UnitDetailComponent = UnitDetailWithSpinner;
    }
    return (
      <UnitDetailComponent
        isLoading={this.state.loading}
        devices={this.state.devices}
        wtData={this.state.wtData}
        unit={this.state.unit}
        community={this.state.community}
        updating={this.state.updating}
        initial_status={this.state.initial_status}
        rebooting={this.state.rebooting}
        restarting={this.state.restarting}
        hubStatus={this.state.hubStatus}
        sfConfiguration={this.state.sfConfiguration}
        codeSyncingData={this.state.codeSyncingData}
        {...this.props}
      />
    );
  }
}

export default withLDConsumer()(withAuth(UnitDetailPage));
