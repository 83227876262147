import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { captureException } from '@sentry/browser';
import { createStyles, List, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  JavascriptBooleanString,
  SFDeviceType,
  SFModelNumbers,
  WorkTicketLockDetails
} from '../../Common/Constants/salesforceConstants_v2';
import {
  DOOR_CODE_RELIABILITY,
  WAIT_FOR_CODES_TO_SYNC
} from '../../Common/Constants/launchDarklyConstants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DeviceTypeTodo } from './DeviceTypeTodo';
import {
  createUnitMetaCallback,
  devicesCompleteStateCallback,
  getLegacyCodeStatuses,
  getDevicesPairedByType,
  getTodoListTypeForSalesforceSku,
  groupDevicesToPairByType,
  lockThrowDialogReducer,
  unitMetaReducer
} from '../common/utils';
import {
  CodeSyncStatuses,
  DevicesCompleteState,
  LockThrowDialogReducer,
  UnitMetaReducer,
  UnitMetaState
} from '../common/types';
import {
  Community,
  DeviceSensor,
  DeviceToBeInstalled,
  DoorbellDevice,
  LockStateSensor,
  SalesforceCommunityInfo,
  Unit,
  V4_Hub_Status
} from '../../Common/Types/cloudApi';
import unit_todo_theme from '../../Common/Themes/unit_todo_theme';
import { LockThrow, unitTodoMetaStorage } from '../../Common/utils/storageInterface';
import { LockThrowDialog } from './LockThrowDialog';
import client from '../../Common/utils/client';
import { UnitOtherDevicesMessage } from './UnitOtherDevicesMessage';
import { AxiosError } from 'axios';
import { dweloPalette } from '../../Common/Themes/dweloPalette';
import { DeviceCommand } from '../../Common/Constants/deviceConstants';
import { COMMAND } from '../../DeviceDetail/devicedetail_apiRoutes';
import { RingManagementDialog, RingPage } from './RingManagementDialog';
import { GetAddressSyncStatusResponse } from '../../Common/CodeSyncing/apiService/types';
import { CodeSyncDialog } from '../../Common/CodeSyncing/CodeSyncDialog';
import { LegacyCodeSyncDialog } from '../../Common/CodeSyncing/LegacyCodeSyncDialog';
import { findActiveCodeSync } from '../../Common/CodeSyncing/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '48px'
    },
    deviceList: {
      paddingTop: '10px'
    },
    todoListInstructions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#FFF',
      paddingLeft: '48px',
      paddingRight: '48px',
      boxShadow: `inset 0 7px 6px -6px #BBBBBB, 0px 1px 1px #BBBBBB`,
      height: '80px',
      lineHeight: '26px',
      color: dweloPalette.primary.grey[300]
    }
  })
);

type Props = {
  unit: Unit;
  community: Community;
  devices: Array<DeviceSensor>;
  devicesToPair: Array<DeviceToBeInstalled>;
  bootTime: V4_Hub_Status['boot_time'];
  submitWorkTicket: (lockData?: WorkTicketLockDetails) => void;
  FinishInstallDialog: JSX.Element | undefined;
  startInclusion: (e: React.MouseEvent<HTMLButtonElement>) => void;
  startExclusion: (e: React.MouseEvent<HTMLButtonElement>) => void;
  refreshSensors: () => Promise<AxiosError | void>;
  codeSyncingData: GetAddressSyncStatusResponse['syncs'];
  hubConnectivityBanner: JSX.Element | null;
};

type LockState = {
  device?: DeviceSensor;
  isDoorLocked?: boolean;
};

// Would make a little more sense as a reducer, but simple for now.
type RingDialogState =
  | {
      page: RingPage.Create;
      open: true;
      uid: undefined;
    }
  | {
      page: RingPage.Edit;
      open: true;
      uid: number;
    }
  | {
      open: false;
    };

export const PLEASE_COMPLETE_THIS_MESSAGE =
  'Please complete this to-do list in the order given below.';

export function UnitTodoList(props: Props) {
  const classes = useStyles(unit_todo_theme);
  const {
    [WAIT_FOR_CODES_TO_SYNC]: waitForCodesToSyncFlag,
    [DOOR_CODE_RELIABILITY]: doorCodeReliabilityFlag
  } = useFlags();
  const {
    allDCMs,
    currentDCM,
    legacyCodeSyncInProgress
  }: CodeSyncStatuses = getLegacyCodeStatuses(props.devices);

  const [ringDialog, setRingDialog] = useState<RingDialogState>({ open: false });
  const [showLegacySyncDialog, setShowLegacySyncDialog] = useState(
    waitForCodesToSyncFlag && legacyCodeSyncInProgress
  );
  const [devicesToPairByType] = useState(() => groupDevicesToPairByType(props.devicesToPair));

  const createUnitMeta = useCallback((): UnitMetaState => {
    return createUnitMetaCallback(props.devices, props.devicesToPair, props.unit.uid);
    // From props.devices, we just need uid and deviceType - which don't change.
    // So we only care if the change to props.devices was the length.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.devices.length, props.devicesToPair, props.unit.uid]);
  const [lockThrowDialogMeta, dispatchLockThrowDialog] = useReducer<LockThrowDialogReducer>(
    lockThrowDialogReducer,
    { open: false }
  );
  const [unitMeta, dispatchUnitMeta] = useReducer<UnitMetaReducer, Props>(
    unitMetaReducer,
    props,
    createUnitMeta
  );

  const createDevicesCompleteState = useCallback((): DevicesCompleteState => {
    return devicesCompleteStateCallback(props.devices, devicesToPairByType, unitMeta);
  }, [props.devices, devicesToPairByType, unitMeta]);
  const [devicesCompleteState, setDevicesCompleteState] = useState(() =>
    createDevicesCompleteState()
  );

  useEffect(() => {
    setDevicesCompleteState(createDevicesCompleteState());
  }, [createDevicesCompleteState]);

  useEffect(() => {
    if (legacyCodeSyncInProgress && waitForCodesToSyncFlag) {
      setShowLegacySyncDialog(true);
    } else {
      // For UX reasons, show code syncing dialog for one sec more after codes finish syncing.
      setTimeout(() => setShowLegacySyncDialog(false), 1000);
    }
  }, [legacyCodeSyncInProgress, waitForCodesToSyncFlag]);

  /**
   * Updates unitMeta when devices are included or excluded
   * while still on the UnitDetail page.
   */
  useEffect(() => {
    dispatchUnitMeta({ type: 'REFRESH_STATE', newState: createUnitMeta() });
  }, [createUnitMeta]);

  function openAddRingDialog() {
    setRingDialog({
      open: true,
      page: RingPage.Create,
      uid: undefined
    });
  }

  function openRingDeviceDetailsDialog(uid: number) {
    setRingDialog({
      open: true,
      uid: uid,
      page: RingPage.Edit
    });
  }

  /**
   * Creates one DeviceTypeTodo per generic device type (currently only Lights, Thermostats, Locks)
   */
  function constructDeviceTypeTodos(): Array<JSX.Element> {
    const devicesByType = getDevicesPairedByType(props.devices);
    return devicesToPairByType
      .map((devicesToPair, index) => {
        const todoDeviceType = getTodoListTypeForSalesforceSku(devicesToPair[0]);
        if (!todoDeviceType) {
          return null;
        }
        const todoMeta = unitMeta ? unitMeta[todoDeviceType] : undefined;
        const devices = devicesByType[todoDeviceType];
        if (!devices) {
          return null;
        }
        return (
          <DeviceTypeTodo
            key={`DeviceTypeTodo-${todoDeviceType}-${index}`}
            unit={props.unit}
            dcm2Community={props.community.usesPrefilledPins}
            devices={devices}
            devicesToPair={devicesToPair}
            deviceType={todoDeviceType}
            todoMeta={todoMeta}
            completeState={devicesCompleteState[todoDeviceType]}
            goToDeviceDetails={
              todoDeviceType === 'ringDoorbell'
                ? (uid: number) => openRingDeviceDetailsDialog(uid)
                : undefined
            }
            startInclusion={
              todoDeviceType === 'ringDoorbell' ? openAddRingDialog : props.startInclusion
            }
            startExclusion={props.startExclusion}
            dispatchUnitMeta={dispatchUnitMeta}
            dispatchLockThrowDialog={dispatchLockThrowDialog}
          />
        );
      })
      .filter((deviceTypeTodo): deviceTypeTodo is JSX.Element => !!deviceTypeTodo);
  }

  /**
   * Retrieves the lockThrow.description property from a paired lock in unitMeta,
   * based on the id stored in lockThrowDialogMeta.id
   */
  function getLockThrowDescription(): string {
    if (!lockThrowDialogMeta.id) {
      return '';
    }

    const locksPaired = unitMeta.lock?.locksPaired;
    if (!locksPaired) {
      return '';
    }

    const lockIndex = locksPaired.findIndex((lock) => lock.uid === lockThrowDialogMeta.id);
    if (lockIndex === -1) {
      return '';
    }

    return locksPaired[lockIndex].lockThrow?.description || '';
  }

  /**
   * Returns a LockState object, so that we don't have to parse out the DoorLocked property later.
   */
  function getLockState(): LockState {
    const lockState: LockState = {};

    if (!lockThrowDialogMeta.id) {
      return lockState;
    }

    const lock = props.devices.find((device) => device.uid === lockThrowDialogMeta.id);
    if (!lock) {
      return lockState;
    }

    lockState.device = lock;

    const sensor = lock.sensors.find((sensor) => sensor.sensorType === 'DoorLocked');
    if (!sensor) {
      return lockState;
    }

    lockState.isDoorLocked = (sensor as LockStateSensor).value === 'True';

    return lockState;
  }

  function closeLockThrowDialog() {
    dispatchLockThrowDialog({ type: 'CLOSE_DIALOG' });
  }

  function lockDoor() {
    if (lockThrowDialogMeta.id) {
      client
        .post(COMMAND(lockThrowDialogMeta.id), {
          command: DeviceCommand.Lock,
          commandValue: null
        })
        .catch((error) => {
          captureException(error);
        });
    }
  }

  /**
   * Updates the unitMeta state as well as its counterpart in localStorage
   */
  function updateLockThrowProperties(properties: Partial<LockThrow>) {
    if (lockThrowDialogMeta.id || lockThrowDialogMeta.id === 0) {
      dispatchUnitMeta({
        type: 'UPDATE_LOCK_THROW_PROPERTIES',
        id: lockThrowDialogMeta.id,
        properties
      });

      unitTodoMetaStorage.updateLockDevices(props.unit.uid, {
        paired: true,
        lock: {
          uid: lockThrowDialogMeta.id,
          lockThrow: {
            ...properties
          }
        }
      });
    }
  }

  function didDweloInstallLock(): boolean {
    const installedByDweloValues: NonNullable<
      SalesforceCommunityInfo['deadboltInstallerType']
    >[] = ['Dwelo', 'Powers IoT', 'CallTek', 'HelloTech'];
    const lowerCasedValues = installedByDweloValues.map((value) => value.toLowerCase());
    const deadboltInstallerType = props.community.salesforceInfo?.deadboltInstallerType;
    return (
      !!deadboltInstallerType && lowerCasedValues.includes(deadboltInstallerType.toLowerCase())
    );
  }
  /**
   * This function calls props.handleWorkTicketSubmit(),
   * but before doing so it will determine what additional lock data (if any) needs to be sent as a param.
   */
  function handleWorkTicketSubmit(): void {
    const shouldLockBePaired = !!props.devicesToPair.find(
      (device) => device.deviceType === SFDeviceType.Lock
    );

    if (!shouldLockBePaired) {
      props.submitWorkTicket();
      return;
    }

    let allLockDetails: WorkTicketLockDetails | undefined = undefined;
    const locksPaired = unitMeta.lock?.locksPaired;
    const locksToPair = unitMeta.lock?.locksToPair;

    if (locksPaired && locksPaired.length > 0) {
      locksPaired.forEach((lock, index) => {
        const dcm = allDCMs[index];
        const arePinsSynced: boolean =
          !!dcm?.codesExpected && dcm.codesSynced >= dcm.codesExpected;
        const wereBatteriesReplaced: JavascriptBooleanString = (!!lock.batteriesInstalled as boolean).toString() as JavascriptBooleanString;
        const actualLock = props.devices.find((d) => d.uid === lock.uid);

        const currentDetails: WorkTicketLockDetails = {
          batteriesReplaced: wereBatteriesReplaced,
          pinsSynced: dcm?.version === 1 ? 'DCM1' : arePinsSynced ? 'Yes - DCM2' : 'No - DCM2',
          masterCodeSynced:
            dcm?.version === 1
              ? 'DCM1'
              : !dcm?.masterCodeExpected
              ? 'Not Yale'
              : dcm.masterCodeSynced
              ? 'Yes'
              : 'No',
          lockThrowStatus: lock.lockThrow?.nothingButAir
            ? 'Nothing But Air'
            : lock.lockThrow?.resultOfIssue
            ? lock.lockThrow.resultOfIssue
            : 'Not Specified',
          // N/A and Not Specified for lockThrowDetails are not Typescript typed,
          // but lockThrowDetails is a free-form text field anyway so no specific values are required.
          lockThrowDetails: lock.lockThrow?.nothingButAir
            ? 'N/A'
            : lock.lockThrow?.description
            ? lock.lockThrow.description
            : 'Not Specified'
        };

        if (index === 0) {
          currentDetails.lockThrowDetails = `**Additional info for lock #${index + 1}**\r
            Name: ${actualLock?.name}\r
            Paired: Yes\r
            Lock throw details: ${currentDetails.lockThrowDetails}`;
          allLockDetails = currentDetails;
        } else if (allLockDetails) {
          // For now, we are appending the details of second/third/etc. locks (paired or unpaired)
          // to the end of the lockThrowDetails string until a better solution is available in Salesforce.
          allLockDetails.lockThrowDetails =
            allLockDetails.lockThrowDetails +
            `\n\n**Info for lock #${index + 1}**\r
            Name: ${actualLock?.name}\r
            Paired: Yes\r
            Batteries replaced: ${wereBatteriesReplaced === 'true' ? 'Yes' : 'No'}\r
            Pins synced: ${currentDetails.pinsSynced}\r
            Master code synced: ${currentDetails.masterCodeSynced}\r
            Lock throw status: ${currentDetails.lockThrowStatus}\r
            Lock throw details: ${currentDetails.lockThrowDetails}`;
        }
      });
    }

    if (locksToPair && locksToPair.length > 0) {
      locksToPair.forEach((lock, index) => {
        const wereBatteriesReplaced: JavascriptBooleanString = (!!locksToPair[0]
          ?.batteriesInstalled as boolean).toString() as JavascriptBooleanString;
        const lockNumber: number =
          locksPaired && locksPaired.length > 0 ? locksPaired.length + index : index;
        if (!allLockDetails) {
          allLockDetails = {
            batteriesReplaced: wereBatteriesReplaced,
            lockThrowDetails: `\n\n**Additional info for lock #${lockNumber + 1}**\r
            Paired: No`
          };
        } else {
          allLockDetails.lockThrowDetails =
            (allLockDetails.lockThrowDetails || '') +
            `\n\n**Info for lock #${lockNumber + 1}**\r
            Paired: No\r
            Batteries replaced: ${wereBatteriesReplaced === 'true' ? 'Yes' : 'No'}`;
        }
      });
    }

    props.submitWorkTicket(allLockDetails);
  }

  const currentCodeSync = findActiveCodeSync(props.codeSyncingData);
  const showCodeSyncDialog =
    doorCodeReliabilityFlag &&
    !!currentCodeSync &&
    !lockThrowDialogMeta.open &&
    !ringDialog.open;

  return (
    <div className={`full-height ${classes.root}`}>
      <div className={classes.todoListInstructions}>
        <Typography>{PLEASE_COMPLETE_THIS_MESSAGE}</Typography>
      </div>
      <List className={classes.deviceList}>
        {/*<GatewayTodo gatewaySerial={props.unit.hub_serial} />*/}
        {constructDeviceTypeTodos()}
        <UnitOtherDevicesMessage expanded={false} />
      </List>
      {/*TODO will update actions and other props in future story*/}
      {/*https://levelhome.atlassian.net/browse/MDU2-26*/}
      {/*https://levelhome.atlassian.net/browse/MDU2-27*/}
      {/*https://levelhome.atlassian.net/browse/MDU2-46*/}
      {showCodeSyncDialog && !!currentCodeSync ? (
        <CodeSyncDialog
          deviceName={'Front Door'}
          handleActionClick={() => undefined}
          handleCantChangeBatteries={() => undefined}
          handleOutOfTime={() => undefined}
          open={true}
          sentProgress={currentCodeSync.sent_percent}
          status={currentCodeSync.status as any}
          verifiedProgress={currentCodeSync.verified_percent}
        />
      ) : (
        <LegacyCodeSyncDialog open={showLegacySyncDialog} dcm={currentDCM} />
      )}
      <LockThrowDialog
        isDialogOpen={lockThrowDialogMeta.open}
        unitName={props.unit.unit}
        communityName={props.community.name}
        lockDoor={lockDoor}
        didDweloInstallLock={didDweloInstallLock()}
        description={getLockThrowDescription()}
        closeDialog={closeLockThrowDialog}
        updateProperties={updateLockThrowProperties}
        topComponent={props.hubConnectivityBanner}
        {...getLockState()}
      />
      {ringDialog.open && (
        <RingManagementDialog
          device={
            ringDialog.page === RingPage.Edit
              ? (props.devices.find((d) => d.uid === ringDialog.uid) as DoorbellDevice)
              : undefined
          }
          page={ringDialog.page}
          unit={props.unit}
          hasTransformer={props.devicesToPair.some(
            (d) => d.modelNumber === SFModelNumbers.DoorbellTransformer && d.quantity > 0
          )}
          refreshSensors={props.refreshSensors}
          onClose={() => setRingDialog({ open: false })}
          topComponent={props.hubConnectivityBanner}
        />
      )}
      {props.FinishInstallDialog &&
        React.cloneElement(props.FinishInstallDialog, {
          handleSubmit: handleWorkTicketSubmit
        })}
    </div>
  );
}
