import React, { Component } from 'react';
import AuthService from '../utils/AuthService';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from './Menu';
import UnitMenu from './UnitMenu';

import * as deviceConstants from '../Constants/deviceConstants';
import theme from '../Themes/header_theme';
import '../stylesheets/global.scss';
import Button from '@material-ui/core/Button';
import CommunityInfoDialog from '../Popups/CommunityInfoDialog';
import UnitInfoDialog from '../Popups/UnitInfoDialog';

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10
  },
  text: {
    marginTop: '2px',
    fontSize: '18px !important'
  },
  h6style: {
    fontSize: '24px !important'
  },
  h6style2: {
    fontSize: '40px !important',
    marginTop: '-16px',
    marginBottom: '10px'
  }
};
const Auth = new AuthService();

class Header extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.classes = classes;
    this.state = {
      left: false,
      showUnitInfo: false,
      showCommunityInfo: false
    };
  }

  handleLogout(e) {
    Auth.logout().then((res) => {
      this.props.history.push('/login');
      window.location.reload();
      return;
    });
  }

  closeDrawer = () => {
    this.setState({
      left: false
    });
  };

  toggleDrawer(e) {
    this.setState({
      left: true
    });
    if (this.props.back && this.props.return) {
      this.props.return();
    } else if (this.props.back) {
      return this.props.history.goBack();
    }
  }

  getMenu() {
    if (this.props.back === false) {
      return <Menu left={this.state.left} user={this.props.user} close={this.closeDrawer} />;
    } else {
      return '';
    }
  }

  getIcon() {
    if (this.props.back === false) {
      return <MenuIcon />;
    } else if (this.props.arrow === true) {
      return <ArrowBackIcon />;
    } else {
      return <CloseIcon />;
    }
  }
  getSubHeader() {
    if (this.props.subheaderText) {
      return (
        <Toolbar>
          <Typography variant="h6" color="textPrimary" className={this.classes.h6style}>
            {this.props.subheaderText}
          </Typography>
        </Toolbar>
      );
    } else {
      return '';
    }
  }
  getSubHeader2() {
    if (this.props.subheaderText2) {
      return (
        <Toolbar>
          <Typography variant="h6" color="textPrimary" className={this.classes.h6style2}>
            {this.props.subheaderText2}
          </Typography>
        </Toolbar>
      );
    } else {
      return '';
    }
  }
  getUnitMenu() {
    if (
      [
        0,
        2,
        deviceConstants.LOCK,
        deviceConstants.THERMOSTAT,
        deviceConstants.DIMMER,
        deviceConstants.SWITCH,
        deviceConstants.BINARY_SENSOR
      ].includes(this.props.unitmenu)
    ) {
      return (
        <UnitMenu
          unit={this.props.unit}
          user={this.props.user}
          menu={this.props.unitmenu}
          device={this.props.device}
          history={this.props.history}
          handleCommunityInfoClick={this.handleCommunityInfoClick}
          handleUnitInfoClick={this.handleUnitInfoClick}
        />
      );
    } else {
      return '';
    }
  }

  getCommunityInfoButton() {
    if (this.props.showCommunityInfoButton) {
      return (
        <Button color="inherit" onClick={this.handleCommunityInfoClick}>
          Community Info
        </Button>
      );
    }
    return null;
  }

  handleUnitInfoClick = (e) => {
    this.setState({ showUnitInfo: true });
  };

  handleUnitInfoClose = (e) => {
    this.setState({ showUnitInfo: false });
  };

  handleCommunityInfoClick = () => {
    this.setState({ showCommunityInfo: true });
  };

  handleCommunityInfoClose = () => {
    this.setState({ showCommunityInfo: false });
  };

  render() {
    let icon = this.getIcon();
    let menu = this.getMenu();
    let subheader = this.getSubHeader();
    let subheader2 = this.getSubHeader2();
    let unitmenu = this.getUnitMenu();
    const communityInfoButton = this.getCommunityInfoButton();
    return (
      <div className={this.classes.root}>
        <MuiThemeProvider theme={theme}>
          <AppBar elevation={this.props.elevation ? 0 : 3} position="static">
            <Toolbar>
              {!this.props.hideClose && (
                <IconButton
                  className={this.classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  onClick={this.toggleDrawer}
                >
                  {icon}
                </IconButton>
              )}
              <Typography
                variant="h6"
                color="inherit"
                className={this.classes.grow + ' ' + this.classes.text}
              >
                {this.props.headerText}
              </Typography>
              {unitmenu}
              {communityInfoButton}
            </Toolbar>
            {subheader}
            {subheader2}
          </AppBar>

          <UnitInfoDialog
            open={this.state.showUnitInfo}
            onClose={this.handleUnitInfoClose}
            unit={this.props.unit}
          />

          <CommunityInfoDialog
            open={this.state.showCommunityInfo}
            onClose={this.handleCommunityInfoClose}
            community={this.props.community}
          />
        </MuiThemeProvider>
        {menu}
      </div>
    );
  }
}

export default withStyles(styles)(Header);
