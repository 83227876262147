import * as React from 'react';
import { ErrorBanner } from '../../BannerTemplates/ErrorBanner';
import { Button, Link, Typography } from '@material-ui/core';
import {
  DWELO_CARE_PHONE,
  DWELO_CARE_PHONE_HREF
} from '../../../Constants/installerAppConstants';

type Props = {
  onActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function CloudErrorBanner(props: Props) {
  return (
    <ErrorBanner
      title={'A cloud error is preventing syncing.'}
      body={
        <Typography>
          Dwelo needs to resolve an issue on our side. Please contact us at{' '}
          <Link href={DWELO_CARE_PHONE_HREF}>{DWELO_CARE_PHONE}</Link> or ‘Get Help’ below.
        </Typography>
      }
      action={
        <Button variant="contained" color="secondary" onClick={props.onActionClick}>
          OK
        </Button>
      }
    />
  );
}
