import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DomainIcon from '@material-ui/icons/Domain';

import '../../Common/stylesheets/global.scss';
import '../community.css';

import convertRegion from '../../Common/utils/convertRegion';

class Community extends Component {
  constructor(props) {
    super(props);
    this.navigateToCommunity = this.navigateToCommunity.bind(this);
  }
  navigateToCommunity(e) {
    e.preventDefault();
    return this.props.history.push('/community/' + this.props.community.uid);
  }
  render() {
    return (
      <ListItem onClick={this.navigateToCommunity} button={true}>
        <ListItemAvatar>
          <Avatar className="dwelo-blue">
            <DomainIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={this.props.community.name}
          secondary={
            this.props.community.street +
            ', ' +
            this.props.community.city +
            ', ' +
            convertRegion(this.props.community.state, 2) +
            ' ' +
            this.props.community.postal
          }
        />
      </ListItem>
    );
  }
}

export default withRouter(Community);
