import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { RingDeviceDetails, RingPage } from './types';

interface Props {
  handleDetailChange: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
  deviceDetails: RingDeviceDetails;
  page: RingPage.Create | RingPage.Edit;
}

export function RingDetailsSelect(props: Props) {
  const ringChimeText =
    props.page === RingPage.Edit ? 'Ring Chime installed?' : 'Installing a Ring Chime?';

  return (
    <>
      <FormControl
        fullWidth
        variant={'outlined'}
        style={{ marginTop: '24px' }}
        color="secondary"
      >
        <InputLabel id="ring-model-label">Ring Model</InputLabel>
        <Select
          labelId="ring-model-label"
          name="ringModel"
          id="ring-model-select"
          value={props.deviceDetails.ringModel}
          label="Ring Model"
          onChange={props.handleDetailChange}
        >
          <MenuItem value={'pro'} aria-label={'ring doorbell pro'}>
            Ring Doorbell Pro
          </MenuItem>
          <MenuItem value={'wired'} aria-label={'ring doorbell wired'}>
            Ring Doorbell Wired
          </MenuItem>
        </Select>
      </FormControl>
      {/*Wired doorbells don't support hard-wired chimes*/}
      {props.deviceDetails.ringModel === 'pro' && (
        <FormControl
          fullWidth
          variant={'outlined'}
          style={{ marginTop: '24px' }}
          color="secondary"
        >
          <InputLabel id="chime-type-label">Hardwired Chime Type</InputLabel>
          <Select
            labelId="chime-type-label"
            name="chimeType"
            id="chime-type-select"
            value={props.deviceDetails.chimeType}
            label="Hardwired Chime Type"
            onChange={props.handleDetailChange}
          >
            <MenuItem value={'digital'} aria-label={'digital'}>
              Digital
            </MenuItem>
            <MenuItem value={'mechanical'} aria-label={'mechanical'}>
              Mechanical
            </MenuItem>
            <MenuItem value={'none'} aria-label={'none'}>
              None
            </MenuItem>
          </Select>
        </FormControl>
      )}
      <FormControl
        fullWidth
        variant={'outlined'}
        style={{ marginTop: '24px' }}
        color="secondary"
      >
        <InputLabel id="ring-chime-label">{ringChimeText}</InputLabel>
        <Select
          labelId="ring-chime-label"
          name="ringChime"
          id="ring-chime-select"
          value={props.deviceDetails.ringChime}
          label={ringChimeText}
          onChange={props.handleDetailChange}
        >
          <MenuItem value={'yes'} aria-label={'yes'}>
            Yes
          </MenuItem>
          <MenuItem value={'no'} aria-label={'no'}>
            No
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
