import * as React from 'react';
import { ProgressBar } from '../ProgressBar';

type Props = {
  progress: number;
  disabled?: boolean;
};

export function VerifiedProgressBar(props: Props) {
  return (
    <ProgressBar
      progress={props.progress}
      description={
        props.progress >= 100
          ? 'Verification complete'
          : `Verifying: ${props.progress}% complete`
      }
      disabled={!!props.disabled}
    />
  );
}
