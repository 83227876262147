export const BINARY_SWITCH = 'Switch';
export const LOCK_STATE = 'DoorLocked';
export const LOCK_ACCESS = 'lockAccess';
export const BATTERY_LEVEL = 'BatteryLevel';
export const MULTILEVEL_SWITCH = 'Dimmer';
export const THERMOSTAT_MODE = 'ThermostatMode';
export const THERMOSTAT_SET_POINT_COOL = 'ThermostatCoolSetpoint';
export const THERMOSTAT_SET_POINT_HEAT = 'ThermostatHeatSetpoint';
export const THERMOSTAT_HUMIDITY = 'Humidity';
export const THERMOSTAT_TEMPERATURE = 'Temperature';
export const THERMOSTAT_OPERATING_STATE = 'ThermostatOperatingState';
export const THERMOSTAT_FAN_MODE = 'FanMode';
export const THERMOSTAT_FAN_STATE = 'FanState';
export const HUB_OCCUPANCY_MODE = 'occupancyMode';
export const HUB_OCCUPIED_THERMOSTAT_MODE = 'occupiedThermostatMode';
export const HUB_AWAY_THERMOSTAT_MODE = 'awayThermostatMode';
export const HUB_NIGHT_THERMOSTAT_MODE = 'nightThermostatMode';
export const HUB_OCCUPIED_HEATPOINT = 'occupiedHeatTemperature';
export const HUB_OCCUPIED_COOLPOINT = 'occupiedCoolTemperature';
export const HUB_AWAY_HEATPOINT = 'awayHeatTemperature';
export const HUB_AWAY_COOLPOINT = 'awayCoolTemperature';
export const HUB_NIGHT_HEATPOINT = 'nightHeatTemperature';
export const HUB_NIGHT_COOLPOINT = 'nightCoolTemperature';
export const MOTION = 'Motion';
export const DISTURBANCE_RESET = 'DisturbanceReset';
export const BINARY_SENSOR = 'BinarySensor';
export const BINARY_SENSOR_WATER = 'BinarySensorWater';
export const BINARY_SENSOR_TAMPER = 'BinarySensorTamper';
export const LOCK_CHANGE = 'LockChange';

export const SENSOR_DEVICE_PRIMARY_READINGS = [LOCK_CHANGE, MOTION, BINARY_SENSOR_WATER];

//Custom Installer app values
export const BATTERY_LAST_READING = 'LastBatteryReading';
