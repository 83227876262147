import * as React from 'react';
import { NeutralBanner } from '../../BannerTemplates/NeutralBanner';
import { createStyles, Theme } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { dweloPalette } from '../../../Themes/dweloPalette';
import '../../styles.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: dweloPalette.primary.orange[300]
    }
  })
);

export function SyncCompleteBanner() {
  const classes = useStyles();

  return (
    <NeutralBanner
      icon={<CheckCircle className={classes.icon} />}
      title={'Syncing complete!'}
    />
  );
}
