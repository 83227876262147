import React, { Component } from 'react';
import '../stylesheets/global.scss';
import { spinnerService } from '../utils/spinnerService';

export default class Spinner extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: !!this.props.show
    };

    if (Object.prototype.hasOwnProperty.call(this.props, 'spinnerService')) {
      this.spinnerService = this.props.spinnerService;
    } else {
      this.spinnerService = spinnerService;
    }

    this.spinnerService._register(this);
  }

  componentWillUnmount() {
    this.spinnerService._unregister(this);
  }

  get name() {
    return this.props.name;
  }

  get group() {
    return this.props.group;
  }

  get show() {
    return this.state.show;
  }

  set show(show) {
    this.setState({ show });
  }

  render() {
    let divStyle = { display: 'inline-block' };
    if (this.state.show) {
      const { loadingImage } = this.props;
      return (
        <div>
          <div className="spinner-overlay" style={divStyle} />
          {loadingImage && <img className="spinner" src={loadingImage} alt="logo" />}
        </div>
      );
    }
    return <div style={divStyle} />;
  }
}
