import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CENTRAL_COLUMN_MAX_WIDTH } from '../Constants/installerAppConstants';
import Header from '../Headers/Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      right: '8px',
      top: '8px'
    },
    root: {
      maxWidth: `${CENTRAL_COLUMN_MAX_WIDTH}px`,
      display: 'block',
      margin: 'auto'
    }
  })
);

type Props = {
  title: string;
  open: boolean;
  subtitle1?: string;
  subtitle2?: string;
  arrow?: boolean;
  children: React.ReactNode;
  onClose: () => void;
  topComponent?: React.ReactElement | null;
};

/**
 * This is a meant as a temporary workaround to this dialog equivalent seen in Inclusion.js.
 * It is used in conjunction with the existing Header component.
 * When UnitDetail_v2.tsx switches to hooks, FullScreenDialog won't be needed.
 * One caveat is that on mobile, scrolling up may cause content under dialog to be visible slightly:
 * https://github.com/mui-org/material-ui/issues/10521
 */
export function FullScreenDialog(props: Props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      fullWidth
      fullScreen
      hideBackdrop
      PaperProps={{ elevation: 0 }}
      transitionDuration={0}
      scroll={'paper'}
      className={classes.root}
    >
      <div className="full-height">
        {props.topComponent}
        <Header
          headerText={props.title}
          subheaderText={props.subtitle1}
          subheaderText2={props.subtitle2}
          return={props.onClose}
          arrow={!!props.arrow}
          back
        />
        {props.children}
      </div>
    </Dialog>
  );
}
