import React from 'react';

import { createStyles, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';

import unitTodoTheme from '../../Common/Themes/unit_todo_theme';
import { ReactComponent as LightBulbIcon } from '../../Common/images/lightbulb.svg';
import { ReactComponent as DoorbellIcon } from '../../Common/images/doorbell.svg';
import { ReactComponent as LockIcon } from '../../Common/images/lock_v2.svg';
import { ReactComponent as ThermometerIcon } from '../../Common/images/thermometer_v2.svg';
import { ReactComponent as SensorIcon } from '../../Common/images/signal-stream.svg';
import { ReactComponent as CollapseIcon } from '../../Common/images/collapse.svg';
import { ReactComponent as ExpandIcon } from '../../Common/images/expand.svg';
import { DeviceToBeInstalled } from '../../Common/Types/cloudApi';
import { TodoListItem } from './TodoListItem';
import {
  TodoListDeviceTypes,
  TodoListDeviceTypesUnion
} from '../../Common/Constants/salesforceConstants_v2';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemAvatar: {
      minWidth: '38px'
    },
    avatar: {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      width: '28px',
      height: '28px'
    },
    avatarComplete: {
      backgroundColor: theme.palette.primary.main
    },
    avatarIncomplete: {
      backgroundColor: 'transparent'
    },
    deviceIconIncomplete: {
      fill: theme.palette.primary.main
    },
    listItem: {
      paddingLeft: '20px'
    },
    doneIcon: {
      width: '22px',
      height: '22px'
    },
    iconButton: {
      color: '#4F4F4F',
      width: '48px',
      height: '48px'
    }
  })
);

type Props = {
  complete: boolean;
  quantityPaired: number;
  expanded: boolean;
  handleSetExpanded: () => void;
  devicesToPair: Array<DeviceToBeInstalled>;
  quantityToPair: number;
  deviceType: TodoListDeviceTypesUnion;
  children: JSX.Element;
};

export const DeviceTypeTodoHeader = (props: Props) => {
  const classes = useStyles(unitTodoTheme);

  /**
   * Spits out the quantity and type of device to pair, such as "Include 2 lights"
   * Primary and Dimmer Switch are changed to light, but others are left untouched.
   */
  function getPrimaryTitle(): string {
    if (props.deviceType === TodoListDeviceTypes.RingDoorbell) {
      // Quantity number for doorbells aren't shown.
      if (props.quantityPaired > 0) {
        return 'Ring doorbell installed';
      } else {
        return 'Add Ring doorbell';
      }
    }
    if (props.quantityPaired > 0) {
      return `${props.quantityPaired} of ${props.quantityToPair} ${props.deviceType}s included`;
    }
    const s = props.quantityToPair > 1 ? 's' : '';
    return `Include ${props.quantityToPair} ${props.deviceType}${s}`;
  }

  function getDeviceIcon() {
    if (props.complete) {
      return <DoneIcon className={classes.doneIcon} />;
    }
    switch (props.deviceType) {
      case 'light':
        return <LightBulbIcon className={classes.deviceIconIncomplete} />;
      case 'thermostat':
        return <ThermometerIcon className={classes.deviceIconIncomplete} />;
      case 'lock':
        return <LockIcon className={classes.deviceIconIncomplete} />;
      case 'sensor':
        return <SensorIcon className={classes.deviceIconIncomplete} />;
      case TodoListDeviceTypes.RingDoorbell:
        return <DoorbellIcon className={classes.deviceIconIncomplete} />;
    }
  }

  const quantityAndType: string = getPrimaryTitle();
  const deviceIcon = getDeviceIcon();

  return (
    <TodoListItem
      complete={props.complete}
      primaryText={quantityAndType}
      avatarIcon={deviceIcon}
      disableBottomStyles={true}
      children={props.children}
      iconButton={
        <IconButton onClick={props.handleSetExpanded}>
          {props.expanded ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>
      }
    />
  );
};
