import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

class LinearDeterminate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      totalTime: this.props.totalTime ? this.props.totalTime : 0,
      fakeLoad: this.props.fakeLoad ? this.props.fakeLoad : false,
      completed: 0
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      completed: nextProps.completed
        ? nextProps.completed
        : this.state.completed
    });
  }

  componentDidMount() {
    if (this.state.fakeLoad) {
      this.setState({
        timer: setInterval(() => {
          this.setCompleted();
        }, 500)
      });
    }
  }

  setCompleted = () => {
    if (this.state.completed === 100) {
      clearInterval(this.state.timer);
      return;
    }
    const diff = (0.5 / this.state.totalTime) * 100;
    const value = Math.min(this.state.completed + diff, 100);
    this.setState({
      completed: value
    });
  };

  componentWillUnmount() {
    if (this.state.fakeLoad) {
      clearInterval(this.state.timer);
    }
  }

  render() {
    return (
      <LinearProgress variant="determinate" value={this.state.completed} />
    );
  }
}

export default LinearDeterminate;
