import { RingTroubleshootInfo as StoredRingTroubleshootInfo } from '../../../Common/utils/storageInterface';

export enum RingPage {
  Create = 'create-ring',
  InstallationSteps = 'installation-steps',
  Troubleshoot = 'troubleshoot-ring',
  Edit = 'edit-ring'
}

export enum ButtonName {
  CancelRemove = 'cancelRemove',
  ConfirmAndInstall = 'confirmAndInstall',
  GoToTroubleshoot = 'goToTroubleshoot',
  RemoveRingProceed = 'removeRingProceed',
  StepsDone = 'stepsDone',
  ReportedIssue = 'reported',
  FixedIssue = 'fixed'
}

export interface RingDeviceDetails {
  ringModel: 'pro' | 'wired' | '';
  chimeType: 'mechanical' | 'digital' | 'none' | '';
  ringChime: 'yes' | 'no' | '';
  hasTransformer: boolean;
}

export type RingTroubleshootInfo = Required<
  Pick<StoredRingTroubleshootInfo, 'issueDescription' | 'stepsTaken'>
>;
