import React from 'react';
import { Avatar, CircularProgress, createStyles, Theme, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';
import { dweloPalette } from '../../Themes/dweloPalette';
import unit_todo_theme from '../../Themes/unit_todo_theme';
import { DCM } from '../../Types/cloudApi';
import { CircularProgressWithLabel } from '../../Loading/CircularProgressWithLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    doneIcon: {
      fill: '#FFF'
    },
    codeSyncRoot: {
      display: 'flex',
      marginBottom: '30px'
    },
    codeSyncAvatarContainer: {
      marginTop: '4px'
    },
    codeSyncAvatar: {
      width: '36px',
      height: '36px',
      marginRight: '20px'
    },
    syncProgressText: {
      fontWeight: 300,
      fontSize: '36px',
      minWidth: '135px'
    }
  })
);

type Props = {
  useMasterCode: boolean;
  dcm: DCM | undefined;
};

/**
 * Shows how many codes have been synced. Works for user codes and master codes.
 */
export function CodeSyncProgress(props: Props) {
  const classes = useStyles(unit_todo_theme);
  const { secondaryText, expected, codesSynced } = props.useMasterCode
    ? {
        secondaryText: 'master codes synced',
        expected: props.dcm?.masterCodeExpected ? 1 : 0,
        codesSynced: props.dcm?.masterCodeSynced ? 1 : 0
      }
    : {
        secondaryText: 'door codes synced',
        expected: props.dcm?.codesExpected || 0,
        codesSynced: props.dcm?.codesSynced || 0
      };
  const icon =
    codesSynced === expected ? (
      <DoneIcon className={classes.doneIcon} />
    ) : props.useMasterCode ? (
      <CircularProgress
        variant={props.dcm && props.dcm.codesSynced < 30 ? 'static' : 'indeterminate'}
        size={34}
      />
    ) : (
      <CircularProgressWithLabel
        variant="determinate"
        size={34}
        value={(Number(codesSynced) / expected) * 100}
      />
    );
  return (
    <div className={classes.codeSyncRoot}>
      <div className={classes.codeSyncAvatarContainer}>
        <Avatar
          className={classes.codeSyncAvatar}
          style={{
            backgroundColor:
              codesSynced === expected ? dweloPalette.primary.orange[300] : 'transparent'
          }}
        >
          {icon}
        </Avatar>
      </div>
      <div>
        <Typography variant={'h4'} className={classes.syncProgressText}>
          {codesSynced} of {expected}
        </Typography>
        <Typography variant={'body1'}>{secondaryText}</Typography>
      </div>
    </div>
  );
}
