import client from './client';
import { AxiosError, AxiosResponse } from 'axios';

type RequestMethod = 'get' | 'post' | 'put' | 'delete';

interface SuccessfulReturn {
  response: AxiosResponse;
  error?: never;
}
interface ErrorReturn {
  response?: never;
  error: AxiosError;
}

// Could also return [response, error] to make it easier to access the values,
// but Typescript doesn't like that due to type inferences partially getting lost when destructuring arrays.
// https://github.com/microsoft/TypeScript/issues/12184
export async function sendClientRequest(
  method: RequestMethod,
  route: string,
  bodyData?: Record<string, unknown>
): Promise<SuccessfulReturn | ErrorReturn> {
  try {
    const response = await client[method](route, bodyData);
    return { response };
  } catch (error) {
    return { error };
  }
}
