import client from '../../../Common/utils/client';
import * as Sentry from '@sentry/browser';
import { deleteStoredYaleCode, getOrCreateInitialYaleCode,  } from './codeGeneration';

const MASTER_CODE_ROUTE = '/v4/installer/yale_lock/master_code/';

export const getYaleCode = (old_codes: string[]) => {
  // pull from local storage or generate new code.
  return getOrCreateInitialYaleCode(old_codes);
};

const apiGetMasterCodes: (addressId: number) => Promise<MasterCodesResponse> = (
  addressId: number
) => {
  return client.get(MASTER_CODE_ROUTE, { params: { addressId } }).then((r) => r.data);
};

const apiPostMasterCodes: (
  addressId: number,
  oldCode: string
) => Promise<MasterCodesResponse> = (addressId: number, oldCode: string) => {
  return client
    .post(MASTER_CODE_ROUTE, { initialMasterCode: oldCode }, { params: { addressId } })
    .then((r) => r.data);
};

export const getMasterCodes = async (addressId: number) => {
  let masterCodes: MasterCodesResponse = {
    activeMasterCode: null,
    inactiveMasterCodes: []
  };
  try {
    masterCodes = await apiGetMasterCodes(addressId);
  } catch (err) {
    Sentry.captureException(err);
  }
  if (!masterCodes.activeMasterCode) {
    // There will always be a cached master code when getYaleCode is called
    masterCodes.activeMasterCode = getYaleCode(masterCodes.inactiveMasterCodes);
  }
  return masterCodes;
};

export const createMasterCode = async (addressId: number) => {
  let masterCodes: MasterCodesResponse | null = null;
  try {
    masterCodes = await apiPostMasterCodes(addressId, getYaleCode([]));
    // clear out code so if we need another one we can make it.
    deleteStoredYaleCode();
    
  } catch (error) {
    Sentry.captureException(error);
    masterCodes = { inactiveMasterCodes: [], activeMasterCode: getYaleCode([]) };
  }
  return masterCodes;
};

export interface MasterCodesResponse {
  activeMasterCode: string | null;
  inactiveMasterCodes: string[];
}
