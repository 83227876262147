import React, { useState } from 'react';
import { Button, DialogContent, Typography } from '@material-ui/core';
import { getModelDisplayName } from './utils';
import { RingDetailsSelect } from './RingDetailsSelect';
import { dweloPalette } from '../../../Common/Themes/dweloPalette';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { LoadableButton } from '../../../Common/Buttons/LoadableButton';
import { ButtonName, RingDeviceDetails, RingPage } from './types';

interface Props {
  deviceDetails: RingDeviceDetails;
  handleDetailChange: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>, callback?: () => void) => void;
  loading: boolean;
}

export function EditRingContent(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen() {
    setIsOpen((prev) => !prev);
  }

  return (
    <DialogContent style={{ paddingBottom: '100px' }}>
      <RingDetailsSelect
        handleDetailChange={props.handleDetailChange}
        deviceDetails={props.deviceDetails}
        page={RingPage.Edit}
      />
      <Button
        onClick={toggleIsOpen}
        variant="outlined"
        style={{
          color: dweloPalette.secondary.red[300],
          border: `1px solid ${dweloPalette.secondary.red[300]}`,
          float: 'right',
          marginTop: '100px'
        }}
      >
        Remove Ring
      </Button>
      <Dialog maxWidth={'xs'} open={isOpen} aria-labelledby="remove-ring-dialog-title">
        <DialogTitle id="remove-ring-dialog-title">{`Remove the ${getModelDisplayName(
          props.deviceDetails.ringModel
        )}?`}</DialogTitle>
        <DialogContent>
          <Typography>This will permanently remove this device from the unit.</Typography>
        </DialogContent>
        <DialogActions style={{ padding: '32px 24px 24px' }}>
          <Button
            name={ButtonName.CancelRemove}
            onClick={toggleIsOpen}
            color="secondary"
            variant="contained"
            disabled={props.loading}
          >
            Cancel
          </Button>
          <LoadableButton
            buttonProps={{
              name: ButtonName.RemoveRingProceed,
              onClick: props.handleSubmit,
              disabled: props.loading,
              variant: 'contained',
              style: {
                color: 'white',
                backgroundColor: props.loading
                  ? dweloPalette.primary.grey[150]
                  : dweloPalette.secondary.red[300]
              }
            }}
            progressProps={{
              size: 24,
              style: {
                color: dweloPalette.secondary.red[300]
              }
            }}
            loading={props.loading}
          >
            Remove
          </LoadableButton>
        </DialogActions>
      </Dialog>
    </DialogContent>
  );
}
