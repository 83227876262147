import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import * as sensorConstants from '../../Common/Constants/sensorConstants';
import extractSensors from '../../Common/scripts/extractSensors';
import '../../Common/stylesheets/global.scss';
import theme from '../../Common/Themes/login_theme';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%',
    borderBottom: 'none',
    marginTop: '16px',
    marginBottom: '0px'
  },
  resize: {
    fontSize: '20px'
  }
});

class Switch extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
    this.state = {
      unit: this.props.unit,
      device: this.props.device
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ device: nextProps.device });
  }

  render() {
    let sensors = extractSensors(this.state.device);
    return (
      <MuiThemeProvider theme={theme}>
        <form className={this.classes.container}>
          <TextField
            label="SWITCH STATE"
            value={sensors[sensorConstants.BINARY_SWITCH].value}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
        </form>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Switch);
