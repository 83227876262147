import * as React from 'react';
import { Banner } from '../../BannerTemplates/Banner';
import { ReactComponent as ArrowCircleIcon } from '../../../images/arrow-circle.svg';
import { createStyles, Link, Theme, Typography } from '@material-ui/core';
import '../../styles.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '14px'
    }
  })
);

type Props = {
  handleOutOfTime: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

export function PoorCellularSignalBanner(props: Props) {
  const classes = useStyles();

  return (
    <Banner
      backgroundColor={'#FFFBE0'}
      icon={<ArrowCircleIcon className={'slow-cell-icon'} />}
      title={
        <Typography className={classes.title}>
          <strong>Syncing slow due to poor cellular signal.</strong>
        </Typography>
      }
      body={
        <Typography>
          Try to connect the hub to ethernet or move it near a window. You may disconnect the
          hub to troubleshoot. Syncing will resume automatically when the hub is back online.{' '}
          <Link onClick={props.handleOutOfTime}>I’m out of time.</Link>
        </Typography>
      }
    />
  );
}
