import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import * as sensorConstants from '../../Common/Constants/sensorConstants';
import extractSensors from '../../Common/scripts/extractSensors';
import '../../Common/stylesheets/global.scss';
import theme from '../../Common/Themes/login_theme';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%',
    borderBottom: 'none',
    marginTop: '16px',
    marginBottom: '0px'
  },
  resize: {
    fontSize: '20px'
  }
});

class Thermostat extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.classes = classes;
    this.state = {
      unit: this.props.unit,
      device: this.props.device
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ device: nextProps.device });
  }

  getBattery(sensor) {
    if (sensor !== '') {
      return (
        <TextField
          label="BATTERY LEVEL"
          value={sensor + '%'}
          className={this.classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: this.classes.resize
          }}
        />
      );
    } else {
      return '';
    }
  }
  getHumidity(sensor) {
    if (sensor !== '') {
      return (
        <TextField
          label="HUMIDITY"
          value={sensor + '%'}
          className={this.classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: this.classes.resize
          }}
        />
      );
    } else {
      return '';
    }
  }
  getOperatingState(sensor) {
    if (sensor !== '') {
      return (
        <TextField
          label="OPERATING STATE"
          value={sensor}
          className={this.classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: this.classes.resize
          }}
        />
      );
    } else {
      return '';
    }
  }
  getFanMode(sensor) {
    if (sensor !== '') {
      return (
        <TextField
          label="FAN MODE"
          value={sensor}
          className={this.classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: this.classes.resize
          }}
        />
      );
    } else {
      return '';
    }
  }
  getFanState(sensor) {
    if (sensor !== '') {
      return (
        <TextField
          label="FAN STATE"
          value={sensor}
          className={this.classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: this.classes.resize
          }}
        />
      );
    } else {
      return '';
    }
  }

  render() {
    let sensors = extractSensors(this.state.device);
    let battery = this.getBattery(sensors[sensorConstants.BATTERY_LEVEL]);
    let humidity = this.getHumidity(sensors[sensorConstants.THERMOSTAT_HUMIDITY]);
    let operatingState = this.getOperatingState(
      sensors[sensorConstants.THERMOSTAT_OPERATING_STATE]
    );
    let fanmode = this.getFanMode(sensors[sensorConstants.THERMOSTAT_FAN_MODE]);
    let fanstate = this.getFanState(sensors[sensorConstants.THERMOSTAT_FAN_STATE]);
    return (
      <MuiThemeProvider theme={theme}>
        <form className={this.classes.container}>
          <TextField
            label="MODE"
            value={sensors[sensorConstants.THERMOSTAT_MODE]}
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="COOL SETPOINT"
            value={
              sensors[sensorConstants.THERMOSTAT_SET_POINT_COOL].value +
              '\u00b0' +
              sensors[sensorConstants.THERMOSTAT_SET_POINT_COOL].unit
            }
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          <TextField
            label="HEAT SETPOINT"
            value={
              sensors[sensorConstants.THERMOSTAT_SET_POINT_HEAT].value +
              '\u00b0' +
              sensors[sensorConstants.THERMOSTAT_SET_POINT_HEAT].unit
            }
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          {fanmode}
          {operatingState}
          <TextField
            label="CURRENT TEMPERATURE"
            value={
              sensors[sensorConstants.THERMOSTAT_TEMPERATURE].value +
              '\u00b0' +
              sensors[sensorConstants.THERMOSTAT_TEMPERATURE].unit
            }
            className={this.classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              className: this.classes.resize
            }}
          />
          {fanstate}
          {humidity}
          {battery}
        </form>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Thermostat);
