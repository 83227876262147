export function resetFabPosition(): void {
  let centralColumn: HTMLElement | null = document.getElementById('central-column');
  let fixedFooter: HTMLElement[] = Array.from(
    document.getElementsByClassName('keep-fixed') as HTMLCollectionOf<HTMLElement>
  );
  if (fixedFooter && centralColumn) {
    for (let i = 0; i < fixedFooter.length; i++) {
      fixedFooter[i].style.marginLeft =
        parseInt(centralColumn.style.width, 10) - fixedFooter[i].offsetWidth - 16 + 'px';
    }
  }
}
